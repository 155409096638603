import CertificationTable from "./CertificationTable";
import Loading from "../../components/Loading/Loading";
import {
  getCertificationExpiredOrExpiringWithin30Days,
  getCertigicateTypeList,
  getCertificateDetails,
} from "../../api/Certification.api";
import { useState, useEffect } from "react";
import _ from "lodash";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import { useSelector } from "react-redux";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";

export default function Certification() {
  const [certificateExpiredAndExpireSoon, setCertificateExpiredAndExpireSoon] =
    useState([]);
  const [certificateExpiringSoon, setCertificateExpiringSoon] = useState([]);
  const [certificateType, setCertificateType] = useState([]);
  const [certificateUser, setCertificateUser] = useState([]);
  const [certificateIds, setCertificateIds] = useState([]);
  const [loadingValCertificatesTable, setLoadingValCertificatesTable] =
    useState(false);
  const [certificatesTableMsg, setCertificatesTableMsg] = useState({
    msg: "",
    status_code: "",
  });
  const [certificatesTable1Msg, setCertificatesTable1Msg] = useState({
    msg: "",
    status_code: "",
  });
  const [getStatusOption, setStatusOption] = useState([0, 1]);
  const { headers, headersList } = useSelector((state) => state.envVariables);
  let certificateData = {
    certificateExpiredAndExpireSoon,
    setCertificateExpiredAndExpireSoon,
    certificateExpiringSoon,
    setCertificateExpiringSoon,
    certificatesTableMsg,
    setCertificatesTableMsg,
    certificatesTable1Msg,
    setCertificatesTable1Msg,
    loadingValCertificatesTable,
    setLoadingValCertificatesTable,
  };
  let data = {
    certificateType,
    setCertificateType,
    certificateUser,
    setCertificateUser,
    setCertificateIds,
    certificateIds,
    getStatusOption,
    setStatusOption,
  };

  useEffect(() => {
    setLoadingValCertificatesTable(true);

    getCertigicateTypeList(
      data.setCertificateType,
      data.setCertificateIds,
      headers
    );
    const param = {
      certificateId: data.certificateIds,
      status: data.getStatusOption,
    };
    getCertificationExpiredOrExpiringWithin30Days(
      certificateData,
      headersList,
      param
    );
  }, []);

  const CallGetUser = () => {
    getCertificateDetails(certificateData, data, headersList);
  };

  const disableButtton =
    certificateData.loadingValCertificatesTable ||
    _.isNull(data.certificateIds) ||
    _.isNull(data.getStatusOption) ||
    data.getStatusOption.length === 0 ||
    data.certificateIds.length === 0;
  const closeAlert = () => {
    certificateData.setCertificatesTableMsg({ msg: "", status_code: 200 });
  };
  return (
    <div className="row">
      <div className="tile filterDiv">
        <CustomDatePickerForm
          formName="certification"
          datepickerData={data}
          filterData={data}
          callApply={CallGetUser}
          disableButtton={disableButtton}
          filtersAccess={{}}
        />
      </div>

      <div className="tile sm-12 lg-12">
        <div className="content">
          {loadingValCertificatesTable ? (
            <Loading />
          ) : (
            <>
              {certificateData.certificatesTableMsg.msg !== "" && (
                <div className="tile">
                  <AlertEDS
                    errMsg={certificateData.certificatesTableMsg.msg}
                    status_code={
                      certificateData.certificatesTableMsg.status_code
                    }
                    close={true}
                    closeAlert={closeAlert}
                  />
                </div>
              )}
              {!_.isEmpty(certificateData.certificateExpiredAndExpireSoon) && (
                <CertificationTable
                  certificateDetails={
                    certificateData.certificateExpiredAndExpireSoon
                  }
                  tableId="certificatesTable"
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
