import { URL } from "../utils/Constant";
import moment from "moment-timezone";
export const getReports = async (
  param,
  setLoading,
  setErrMsg,
  setData,
  headersList
) => {
  setLoading({
    reportsTotalTime: true,
  });
  setErrMsg({
    reportsTotalTime: "",
  });
  setData({});
  const jsonData = JSON.stringify(param);
  const url = URL.ERICSION.GET_EHS_REPORTS_DATA;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: jsonData,
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      if (data.status_code === 200) {
        setData({ ...data?.data });
      }
      setErrMsg({ reportsTotalTime: data.message });
      setLoading({ reportsTotalTime: false });
    } else {
      setErrMsg({ reportsTotalTime: "Internal Server Error" });
      setLoading({ reportsTotalTime: false });
    }
  } catch (err) {
    setErrMsg({ reportsTotalTime: "Internal Server Error" });
    setLoading({ reportsTotalTime: false });
  }
};
export const getTotalTimeTakenStatics = async (
  param,
  setLoading,
  setErrMsg,
  setData,
  headersList
) => {
  setLoading({ totalTimeStatistics: true });
  setErrMsg({ totalTimeStatistics: "" });
  setData({});
  let url = URL.ERICSION.GET_EHS_TOTAL_TIME_COUNT;
  const resp2 = await fetch(url, {
    method: "POST",
    body: JSON.stringify(param),
    headers: headersList,
  });
  if (resp2.ok) {
    const data = await resp2.json();
    if (data.status_code === 200) {
      setData({ ...data?.data });
    }
    setLoading({ totalTimeStatistics: false });
    setErrMsg({ totalTimeStatistics: data.message });
  } else {
    setLoading({ totalTimeStatistics: false });
    setErrMsg({ totalTimeStatistics: "Internal Server Error" });
  }
};
//update approval and reject status
// for approve status id is 2 and reject it is 3,
export const updateStatusReportsApi = async (
  param,
  setLoading,
  setErrMsg,
  setShowMdlA,
  setStatusAR,
  headers
) => {
  setLoading(true);
  const formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  const url = URL.ERICSION.REPORTS_UPDATE_STATUS_URL;
  try {
    const resp = await fetch(url, {
      method: "PUT",
      body: formData,
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      setErrMsg(data.message);
      setLoading(false);
      setStatusAR(param.reportId);
      return data?.status_code;
    } else {
      setShowMdlA(false);
      return 201;
    }
  } catch (err) {
    setShowMdlA(false);
  }
};
export const getSubReports = async (
  param,
  setChildReportPopData,
  setLoading,
  setErrMsgPop,
  headersList
) => {
  setLoading(true);
  setErrMsgPop("");
  try {
    const resp = await fetch(URL.ERICSION.GET_EHS_REPORTS_DATA, {
      method: "POST",
      body: JSON.stringify(param),
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      if (data.status_code === 200) {
        const _data = JSON.parse(data.data.totaltime_js);
        setChildReportPopData(_data);
      } else {
        setErrMsgPop(data.message);
      }
    } else {
      setErrMsgPop("Internal Server Error");
    }
  } catch (err) {
    setErrMsgPop("Internal Server Error");
  }
  setLoading(false);
};

export const getReportsByReportId = async (
  param,
  datepickerData,
  setLoading,
  setErrMsg,
  setData,
  headersList
) => {
  setLoading({
    reportsTotalTime: true,
  });
  setErrMsg({
    reportsTotalTime: "",
  });
  setLoading({ reportsTotalTime: true });
  setErrMsg({ reportsTotalTime: "" });
  setData({});
  const url = URL.ERICSION.GET_EHS_REPORTS_DATA;

  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(param),
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      if (data.status_code === 200) {
        setData({ ...data?.data });
        const reportsData = JSON.parse(data?.data?.totaltime_js || "{}");
        if (Object.values(reportsData).length) {
          const date = moment(
            reportsData?.["Submitted Datetime"]?.[0],
            "YYYY/MM/DD HH:mm:ss"
          ).format("YYYY-MM-DD");
          datepickerData.setStartDate(date);
          datepickerData.setEndDate(date);
        }
      }
      setErrMsg({ reportsTotalTime: data.message });
      setLoading({
        reportsTotalTime: false,
      });
    } else {
      setLoading({ reportsTotalTime: false });
      setErrMsg({ reportsTotalTime: "Internal Server Error" });
    }
  } catch (err) {
    setLoading({ reportsTotalTime: false });
    setErrMsg({ reportsTotalTime: "Internal Server Error" });
  }
};
export const callGetApproveRejectOptions = async (
  param,
  setLoading,
  setErrMsg,
  headers
) => {
  setLoading(true);
  setErrMsg("");
  const url = URL.ERICSION.GET_APPROVAL_REJECTION_OPTION;
  try {
    const resp = await fetch(`${url}?status_id=${param?.status_id}`, {
      method: "GET",
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      setErrMsg(data.message);
      setLoading(false);
      return data?.data?.comments;
    } else {
      setErrMsg("Internal Server Error");
      setLoading(false);
      return [];
    }
  } catch (err) {
    setErrMsg("Internal Server Error");
    setLoading(false);
    return [];
  }
};

export const getReportsFiltersData = async (
  param,
  setfiltersData,
  setFiltersLoading,
  setFiltersErrMsg,
  headers
) => {
  setFiltersLoading(true);
  setFiltersErrMsg("");
  const url = `${URL.ERICSION.GET_REPORTS_FILTERS}?timeZone=${param?.timeZone}&start_date=${param?.start_date}&end_date=${param?.end_date}`;

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      setfiltersData(
        JSON.parse(Object.keys(data?.data).length ? data?.data : "{}")
      );
      setFiltersErrMsg(data.message);
      setFiltersLoading(false);
    } else {
      setFiltersErrMsg("Internal Server error");
      setFiltersLoading(false);
    }
  } catch (err) {
    setFiltersErrMsg("Internal Server error");
    setFiltersLoading(false);
  }
};
