import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../components/Loading/Loading";
import AlertEDS from "../../../../components/AlertWarn/AlertEDS";
import {
  getFormWiseData,
  getFormList,
  getFormWiseCCList,
} from "../../../../api/Notification.api";
import NotificationTable from "./NotificationTable";
import NotificationPopup1 from "./NotificationPopup1";

export default function FormNotifications() {
  const [loading, setLoading] = useState(true); // Table related loader
  const [getTableData, setTableData] = useState([]);
  const [getCCList, setCCList] = useState([]);
  const [getFormsList, setFormsList] = useState([]);
  const [getFormOption, setFormOption] = useState([]);
  const [getCCOption, setCCOption] = useState([]);
  const [newEmailIds, setNewEmailIds] = useState([]);
  const [isOthers, setIsOthers] = useState(false);
  const [errMsg, setErrMsg] = useState({
    msg: "",
    status_code: "",
  });
  const [tableActionHappend, setTableActionHappend] = useState(false);
  const [actionPopUp, setActionPopUp] = useState({
    isOpen: false,
  });
  const [isDeletionCompleted, setIsdeletionCompleted] = useState(false);
  const { headers, headersList } = useSelector((state) => state.envVariables);
  let distributionData = {
    getFormsList,
    setFormsList,
    getCCList,
    setCCList,
    getFormOption,
    setFormOption,
    getCCOption,
    setCCOption,
    newEmailIds,
    setNewEmailIds,
    setTableActionHappend,
    loading,
    setLoading,
    errMsg,
    setErrMsg,
    isOthers,
    setIsOthers,
    isDeletionCompleted,
    setIsdeletionCompleted,
  };

  useEffect(() => {
    getFormList(
      distributionData.setFormsList,
      headers,
      distributionData.setErrMsg
    );
    getFormWiseCCList(distributionData.setCCList, headers);
    getFormWiseData(
      setTableData,
      distributionData.setTableActionHappend,
      distributionData.setErrMsg,
      distributionData.setLoading,
      headers
    );
  }, []);

  const closeAlert = () => {
    distributionData.setErrMsg({ msg: "", status_code: 200 });
  };
  return (
    <div className="tile sm-12 md-12 lg-6">
      <div className="content">
        <div className="header">
          <div className="left">
            <div className="title">Form notifications</div>
            <div className="subtitle">
              Manage recipients who will receive email notifications for various
              form events e.g. form submission, form approval/rejection etc.
            </div>
          </div>
        </div>
        {distributionData.errMsg.msg !== "" && (
          <div className="tile">
            <AlertEDS
              errMsg={distributionData.errMsg.msg}
              status_code={distributionData.errMsg.status_code}
              close={true}
              closeAlert={closeAlert}
            />
          </div>
        )}
        {actionPopUp?.isOpen && (
          <NotificationPopup1
            actionPopUp={actionPopUp}
            setActionPopUp={setActionPopUp}
            distributionData={distributionData}
            getTableData={getTableData}
            setTableData={setTableData}
            headers={headersList}
            setTableActionHappend={distributionData.setTableActionHappend}
          />
        )}
        {loading ? (
          <Loading />
        ) : (
          <>
            {(getTableData.length > 0 || tableActionHappend) &&
              !loading &&
              distributionData.getCCList.length > 0 && (
                <NotificationTable
                  setActionPopUp={setActionPopUp}
                  getTableData={getTableData}
                  distributionData={distributionData}
                ></NotificationTable>
              )}
          </>
        )}
      </div>
    </div>
  );
}
