import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog } from "@eds/vanilla";
import { useLogout } from "../../api/login.api";

export const InactiveUserDialog = () => {
  const [callLogout] = useLogout();
  const isUserActiveStatusMessage = useSelector(
    (state) => state.loggedInUser.isUserActiveStatusMessage
  );

  useEffect(() => {
    const dialogDOM = document.getElementById("inactive-user-dialog");
    const dialog = new Dialog(dialogDOM);
    dialog.init();

    if (isUserActiveStatusMessage) {
      dialog.show();
    }

    return () => {
      dialog.destroy();
    };
  }, [isUserActiveStatusMessage]);

  return (
    <div id="inactive-user-dialog" className="dialog" data-type="simple">
      <div className="content">
        <div className="top">
          <div className="title">Message</div>
        </div>
        <div className="body">
          <p>{isUserActiveStatusMessage}</p>
        </div>

        <div className="bottom">
          <button id="btnSubmit" className="btn warning" onClick={callLogout}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
