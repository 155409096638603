import { createSlice } from "@reduxjs/toolkit";
import authApi from "../services/authApi";

const initialState = {
  isLoggedIn: false,
  user: {},
  isNewUser: false,
  policyStatus: false,
  tokenExp: "",
  sessionExp: false,
  isUserActiveStatusMessage: "",
  lastLoginDate: "",
  lastLoginDateMessage: "",
  enduserStatement: false
};

export const userSlice = createSlice({
  name: "loggedInUser",
  initialState,
  reducers: {
    updateLoggedInUserDetails: (state = initialState, action) => {
      state.isLoggedIn = true;
      state.user = { ...action.payload };
    },
    updateisNewUser: (state = initialState, action) => {
      state.isNewUser = action.payload;
    },
    updatePolicyStatus: (state = initialState, action) => {
      state.policyStatus = action.payload;
    },
    updateEndUserStatement: (state = initialState, action) => {
      state.enduserStatement = action.payload;
    },
    removeLoggedInUserDetails: (state = initialState) => {
      state.isLoggedIn = false;
      state.isLoggedIn = false;
      state.user = {};
      state.isNewUser = false;
      state.policyStatus = false;
      state.enduserStatement = false;
    },
    updateTokenExp: (state = initialState, action) => {
      state.tokenExp = action.payload;
    },
    updateSessionExp: (state = initialState, action) => {
      state.sessionExp = action.payload;
    },
    updateIsUserActive: (state = initialState, action) => {
      state.isUserActiveStatusMessage = action.payload;
    },
    updateLastLoginDate: (state = initialState, action) => {
      state.lastLoginDate = action.payload.lastLoginDate;
      state.lastLoginDateMessage = action.payload.message;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.userDetails.matchFulfilled,
      (state, { payload }) => {
        const { data = {}, message, status_code } = payload;
        state.user = data;
        state.policyStatus = data.policyStatus;
        state.enduserStatement = data.enduserStatement;
        if (status_code !== 200) {
          state.isUserActiveStatusMessage = message;
        }
      }
    );

    builder.addMatcher(
      authApi.endpoints.userLastLogin.matchFulfilled,
      (state, { payload }) => {
        const { data = {}, message, status_code } = payload;
        state.lastLoginDate = data.lastSignInDateTime;
        state.lastLoginDateMessage = message;
      }
    );
  },
});

export const {
  updateLoggedInUserDetails,
  updateisNewUser,
  updatePolicyStatus,
  removeLoggedInUserDetails,
  updateTokenExp,
  updateSessionExp,
  updateIsUserActive,
  updateLastLoginDate,
  updateEndUserStatement
} = userSlice.actions;

export default userSlice.reducer;
