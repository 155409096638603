import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL } from "../../utils/Constant";

export const configuartionApi = createApi({
  reducerPath: "configuartionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().envVariables.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  tagTypes: ["Formsconfig", "IncidentsManagement"],
  endpoints: (builder) => ({
    getFormReview: builder.query({
      query: () => URL?.ERICSION?.FORM_REVIEW,
      providesTags: ["Formsconfig"],
    }),

    updateFormReview: builder.mutation({
      query: (body) => ({
        url: URL?.ERICSION?.FORM_REVIEW,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Formsconfig"],
    }),

    incidentRecipents: builder.query({
      query: () => URL.ERICSION.INCIDENT_MANAGEMENT,
      providesTags: ["IncidentsManagement"],
    }),

    addIncidentRecipents: builder.mutation({
      query: (body) => ({
        url: URL.ERICSION.INCIDENT_MANAGEMENT,
        method: "POST",
        body,
      }),
      invalidatesTags: ["IncidentsManagement"],
    }),

    deleteIncidentRecipents: builder.mutation({
      query: (body) => ({
        url: URL.ERICSION.INCIDENT_MANAGEMENT,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["IncidentsManagement"],
    }),
  }),
});

export const {
  useGetFormReviewQuery,
  useUpdateFormReviewMutation,
  useIncidentRecipentsQuery,
  useAddIncidentRecipentsMutation,
  useDeleteIncidentRecipentsMutation,
} = configuartionApi;

export default configuartionApi;
