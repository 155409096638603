import React, { useEffect, useState } from "react";
import { ColorScale, HorizontalBarChartStacked } from "@eds/vanilla";
import { useSelector } from "react-redux";
import { getSiteWalkAuditCountData } from "../../../api/SiteWalk.api";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";

export default function AuditCountByWorkerTypeGraph({
  param,
  isAutoCall,
  getAnalyticsGraphsToggle,
  setAnalyticsGraphsToggle,
  validationMsg,
  validateFilterValues,
}) {
  const [getData, setData] = useState({ loading: false, error: "", data: [] });
  const headersList = useSelector((state) => state.envVariables.headersList);
  const error = validationMsg || (getData.error !== "Success" && getData.error);

  const handleIsOpen = () => {
    const isValid = validateFilterValues("SiteWalkByWorkType");
    isValid &&
      !getAnalyticsGraphsToggle.auditCountByWorkerType &&
      getSiteWalkAuditCountData(param, setData, headersList);
    setAnalyticsGraphsToggle((val) => ({
      ...val,
      auditCountByWorkerType: !val.auditCountByWorkerType,
    }));
  };

  const getChartData = (data) => {
    const locations = param.region || [];
    const groupByType = Object.groupBy(data, ({ worker_type }) => worker_type);
    const formatedData = {
      common: locations,
      series: Object.entries(groupByType).map(([name, val]) => {
        const values = locations.map(
          (location) => val.find((obj) => obj.region === location)?.count || 0
        );
        return {
          name,
          values,
        };
      }),
    };
    return formatedData;
  };

  useEffect(() => {
    const isValid = validateFilterValues("SiteWalkByWorkType");
    isValid &&
      isAutoCall &&
      getAnalyticsGraphsToggle.auditCountByWorkerType &&
      getSiteWalkAuditCountData(param, setData, headersList);
  }, [isAutoCall]);

  useEffect(() => {
    if (getData.data.length) {
      const data = getChartData(getData.data);
      const chartHeight = data?.common?.length * 60;
      const mycolorScale = new ColorScale({ reverse: true });
      const chart = new HorizontalBarChartStacked({
        element: document.getElementById("auditCountbyWorkerTypeGraph"),
        data: data,
        x: { unit: "Count" },
        height: chartHeight,
        colorScale: mycolorScale,
      });
      mycolorScale.init();
      chart.init();
      return () => {
        chart.destroy();
      };
    }
  }, [getData.data]);

  return (
    <li
      tabIndex={6}
      className={`tile mb-sm accordion-item ${
        getAnalyticsGraphsToggle.auditCountByWorkerType ? "opened" : ""
      }`}
    >
      <div className="title stickyTileHeader" onClick={handleIsOpen}>
        <div className="header p-sm">
          <div className="left">Audit Count by Worker Type</div>
        </div>
      </div>
      <div className="content">
        {getData.loading && <Loading />}
        {error && <AlertEDS errMsg={error} status_code={201} close={false} />}
        {!error && getData.data.length ? (
          <>
            <CustomGraphActions
              nodeId={"auditCountbyWorkerTypeGraph"}
              fileName={"Audit_CountByWorkerType"}
              data={getData.data}
            />
            <div id="auditCountbyWorkerTypeGraph"></div>
          </>
        ) : null}
      </div>
    </li>
  );
}
