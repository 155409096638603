import { Notification } from "@eds/vanilla";

export const useNotification = ({ title }) => {
  const success = (description, timeout = 5000, action) => {
    const notification = new Notification({
      title,
      description,
      icon: "icon-check",
      stripeColor: "green",
      timeout,
      action,
    });
    notification.init();
  };

  const failed = (description, timeout = 5000, action) => {
    const notification = new Notification({
      title: title,
      description: description,
      icon: "icon-triangle-warning",
      stripeColor: "red",
      timeout,
      action,
    });
    notification.init();
  };

  return [success, failed];
};
