import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChartStacked } from "@eds/vanilla";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import _ from "lodash";

export const CommentsNoCommentsGraph = ({ CommentsNoCommentsData, getAnalyticsGraphsToggle, getSiteWalkDataByAnalyticsToggle, loadingVal, errMsg }) => {


  useEffect(() => {
    if (!_.isEmpty(CommentsNoCommentsData) && !loadingVal.comment_stats) {
      const data = {
        common: CommentsNoCommentsData?.map((obj) => obj?.Category),
        series: [
          {
            name: "Comment",
            values: CommentsNoCommentsData?.map((obj) => obj?.Comment),
          },
          {
            name: "No-comment",
            values: CommentsNoCommentsData?.map((obj) => obj?.Nocomment),
          },
        ],
      };
      const chartHeight = data?.common?.length * 40;
      const mycolorScale = new ColorScale({ reverse: true });
      const chart = new HorizontalBarChartStacked({
        element: document.getElementById("commentsNoCommentsGraph"),
        data: data,
        x: { unit: "Count" },
        height: chartHeight,
        colorScale: mycolorScale,
      });
      mycolorScale.init();
      chart.init();
      return () => {
        chart.destroy();
        let element = document.getElementById(
          "commentsNoCommentsGraph"
        );
        while (element?.firstChild) {
          element.removeChild(element.firstChild);
        }
      };
    }
  }, [CommentsNoCommentsData, loadingVal.comment_stats]);

  return (
    <>
      <li
        tabIndex={11}
        className={`tile mb-sm accordion-item ${getAnalyticsGraphsToggle.comment_stats ? "opened" : ""
          }`}
      >
        <div
          className="title stickyTileHeader"
          onClick={() => {
            getSiteWalkDataByAnalyticsToggle("comment_stats");
          }}
        >
          <div className="header p-sm">
            <div className="left">
              Comments Statistics
            </div>
          </div>
        </div>
        <div className="content">
          {loadingVal.comment_stats ? (
            <Loading />
          ) : (
            <>
              {errMsg.comment_stats !== "Successful" ? (
                <AlertEDS
                  errMsg={errMsg.comment_stats}
                  status_code={201}
                  close={false}
                />
              ) : (
                <>
                  <CustomGraphActions
                    nodeId={"commentsNoCommentsGraph"}
                    fileName={"Comments_Nocomments_"}
                    data={CommentsNoCommentsData}
                  />
                  <div id="commentsNoCommentsGraph"></div>
                </>
              )}
            </>
          )}
        </div>
      </li>


    </>
  );
};