import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL } from "../../utils/Constant";

export const safetyFormsApi = createApi({
  reducerPath: "safetyFormsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().envVariables.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getERCAnalytics: builder.mutation({
      query: (body) => ({
        url: URL.ERICSION.REPORT_STATS_URL,
        method: "POST",
        body,
      }),
    }),
    getFormSubmission: builder.mutation({
      query: (body) => ({
        url: URL.ERICSION.GET_FORM_SUBMISSION_DATA,
        method: "POST",
        body,
      }),
    }),
    getClimber: builder.mutation({
      query: (body) => ({
        url: URL.ERICSION.GET_CLIMBER_DATA,
        method: "POST",
        body,
      }),
    }),
    getScores: builder.mutation({
      query: (body) => ({
        url: URL.ERICSION.GET_SCORE_DETAILS,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetERCAnalyticsMutation,
  useGetFormSubmissionMutation,
  useGetClimberMutation,
  useGetScoresMutation,
} = safetyFormsApi;

export default safetyFormsApi;
