import React, { useEffect } from "react";
import moment from "moment";
import { ColorScale, StackedAreaChart } from "@eds/vanilla";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";

export default function FormSubmissionsGraph({
  data: { data: { data, message } = {}, isLoading },
}) {
  const getChartData = (apiData) => {
    const dateGraph = apiData?.["Date"] || [];
    const countGraph = apiData?.["count"] || [];
    const statusGraph = apiData?.["status"] || [];
    const formattedData = [];
    for (const property in dateGraph) {
      const index = formattedData.findIndex(
        (val) =>
          val.date ===
          moment(dateGraph[property], "DD/MM/YYYY").format("YYYY-MM-DD")
      );
      if (index > -1) {
        if (statusGraph[property]?.toLowerCase() === "submitted") {
          formattedData[index].submitted += countGraph[property];
        } else if (statusGraph[property]?.toLowerCase() === "approved") {
          formattedData[index].approved += countGraph[property];
        } else if (statusGraph[property]?.toLowerCase() === "rejected") {
          formattedData[index].rejected += countGraph[property];
        } else if (statusGraph[property]?.toLowerCase() === "ai verified") {
          formattedData[index].aiApproved += countGraph[property];
        } else if (statusGraph[property]?.toLowerCase() === "ai rejected") {
          formattedData[index].aiRejected += countGraph[property];
        }
      } else {
        if (statusGraph[property]?.toLowerCase() === "submitted") {
          formattedData.push({
            date: moment(dateGraph[property], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            submitted: countGraph[property],
            approved: 0,
            rejected: 0,
            aiApproved: 0,
            aiRejected: 0,
          });
        } else if (statusGraph[property]?.toLowerCase() === "approved") {
          formattedData.push({
            date: moment(dateGraph[property], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            submitted: 0,
            approved: countGraph[property],
            rejected: 0,
            aiApproved: 0,
            aiRejected: 0,
          });
        } else if (statusGraph[property]?.toLowerCase() === "rejected") {
          formattedData.push({
            date: moment(dateGraph[property], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            submitted: 0,
            approved: 0,
            rejected: countGraph[property],
            aiApproved: 0,
            aiRejected: 0,
          });
        } else if (statusGraph[property]?.toLowerCase() === "ai verified") {
          formattedData.push({
            date: moment(dateGraph[property], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            submitted: 0,
            approved: 0,
            rejected: 0,
            aiApproved: countGraph[property],
            aiRejected: 0,
          });
        } else if (statusGraph[property]?.toLowerCase() === "ai rejected") {
          formattedData.push({
            date: moment(dateGraph[property], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            submitted: 0,
            approved: 0,
            rejected: 0,
            aiApproved: 0,
            aiRejected: countGraph[property],
          });
        } else {
          formattedData.push({
            date: moment(dateGraph[property], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            submitted: 0,
            approved: 0,
            rejected: 0,
            aiApproved: 0,
            aiRejected: 0,
          });
        }
      }
    }
    formattedData.sort((a, b) => {
      if (a.date > b.date) return 1;
      else if (a.date < b.date) return -1;
      else return 0;
    });
    const resultData = {
      common: formattedData?.map((data) => data?.date),
      series: [
        {
          name: "Submitted",
          values: formattedData?.map((data) => data?.submitted),
        },
        {
          name: "Approved",
          values: formattedData?.map((data) => data?.approved),
        },
        {
          name: "Rejected",
          values: formattedData?.map((data) => data?.rejected),
        },
        {
          name: "AI Verified",
          values: formattedData?.map((data) => data?.aiApproved),
        },
        {
          name: "AI Rejected",
          values: formattedData?.map((data) => data?.aiRejected),
        },
      ],
    };
    return resultData;
  };

  useEffect(() => {
    const apiData = JSON.parse(data?.approvereject_js || "{}");
    if (Object.values(apiData).length) {
      const myColors = new ColorScale({ offset: 1 });
      myColors.init();
      const data = getChartData(apiData);

      const chart = new StackedAreaChart({
        element: document.getElementById("formSubmissionsGraph-reportsGraph"),
        data: data,
        y: { unit: "Count" },
        colorScale: myColors,
      });
      chart.init();
      return () => chart.destroy();
    }
  }, [data]);

  return (
    <div className="tile sm-12 md-12 lg-12 xl-12">
      <div className="header">
        <div className="left">
          <div className="title">Form Submissions</div>
        </div>
      </div>
      <div className="content">
        <div className="column">
          {isLoading && <Loading />}
          {message && message !== "Success" && (
            <AlertEDS errMsg={message} status_code={201} close={false} />
          )}
          {data && <div id="formSubmissionsGraph-reportsGraph"></div>}
        </div>
      </div>
    </div>
  );
}
