import React, { useEffect, useMemo, useState, useRef } from "react";
import moment from "moment";
import { EDSTable } from "../../components/EDSTable/EDSTable";
import ShowCertficates from "./ShowCertficates";

export default function CertificationTable({ certificateDetails }) {
  const tableRef = useRef(null);
  const [showCertficates, setShowCertificates] = useState([]);

  const tableOptions = useMemo(
    () => ({
      data: [],
      columns: [
        {
          key: "types",
          title: "Type",
          sort: "none",
        },
        {
          title: "status",
          key: "status",
          onCreatedCell: (td, data) => {
            if (data === "Expired") {
              td.innerHTML = `<div>
            <kbd class="tag big">
            <i class="icon icon-alarm-level4 color-red"></i>
            <span>Expired</span>
          </kbd>
           
           
        </div>`;
            } else if (data === "Expiring") {
              td.innerHTML = `<div>
            <kbd class="tag big">
            <i class="icon icon-alarm-level4 color-yellow"></i>
            <span>Expiring</span>
          </kbd>
            
          </div>`;
            } else {
              td.innerHTML = `<div>
            <kbd class="tag big">
            <i class="icon icon-alarm-level4 color-green"></i>
            <span>Valid</span>
          </kbd>
            
          </div>`;
            }
          },
        },
        {
          title: "View Details",
          key: "images",
          headerStyle: "text-align:center",
          onCreatedCell: (td, data) => {
            if (data.length) {
              td.innerHTML = `<img src=${
                data?.[0] || ""
              } alt="No image" ></img>`;

              td.addEventListener("click", () => {
                setShowCertificates(data);
              });
            } else {
              td.innerHTML = "No image";
            }
          },
        },
        {
          key: "user_name",
          title: "Name",
          sort: "none",
        },
        {
          key: "user_email",
          title: "Email",
          sort: "none",
          cellClass: "text-wrap",
        },

        {
          key: "CreatedOn",
          title: "Uploaded Date",
          sort: "none",
        },
        {
          key: "ExpiringOn",
          title: "Expiration Date",
          sort: "asc",
          onCreatedCell: (td, data) => {
            td.innerHTML = moment(data, "X").format("YYYY-MM-DD");
          },
        },
      ],
      sortable: true,
      height: "300px",
    }),
    []
  );

  useEffect(() => {
    if (certificateDetails.length > 0) {
      const data = certificateDetails.map((certificate) => ({
        types: certificate.certificateData.map((val) => val.certificateName),
        status: certificate.status,
        images: certificate.img,
        user_name: certificate.username,
        user_email: certificate.useremail,
        CreatedOn: certificate.CreatedOn,
        ExpiringOn: moment(certificate.expiryOn, "D/M/YYYY").format("X"),
      }));

      tableRef.current.update(data);
    }
  }, [certificateDetails]);

  return (
    <>
      <EDSTable
        classType="split-dashed"
        id="certifications"
        options={tableOptions}
        tableRef={tableRef}
      />

      <ShowCertficates
        showCertficates={showCertficates}
        setShowCertificates={setShowCertificates}
      />
    </>
  );
}
