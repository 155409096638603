import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import DeficienciesActionTable from "./DeficienciesActionsTable";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import {
  useLazyGetSafetyActionsFiltersQuery,
  useGetDeficienciesMutation,
} from "../../redux/services/safetyActionsApi";
import { parseReduxAPIResponse } from "../../utils/helperMethod";

export default function Actions() {
  const [getPayLoad, setPaload] = useState({
    start_date: new Date(
      moment().local().subtract(1, "weeks").format("YYYY-MM-DD 00:00:00")
    ),
    end_date: new Date(moment().local().format("YYYY-MM-DD 23:59:59")),
    formId: [],
    status: [],
    timeZone: moment.tz.guess(true),
    markets: [],
    customers: [],
    locations: [],
    suppliers: [],
    reporter: [],
    business_unit: [],
  });
  const [errMsg, setErrMsg] = useState("");
  const [currentDay, setCurrentDay] = useState(
    moment().local().format("YYYY-MM-DD")
  );
  const [isAutoCall, setIsAutoCall] = useState(false);
  const { employeeId } = useSelector((state) => state.loggedInUser.user);
  const rolesMap = useSelector((state) => state.userAccess.roles);
  const features = useSelector((state) => state.userAccess.features);
  const navigate = useNavigate();
  const [searchParams, setSearchParmas] = useSearchParams();
  const id = searchParams.get("id");
  const [toggleById, setToggleById] = useState(id && true);

  const [
    getSafetyActionsFilters,
    { data: filters, isLoading: isLoadingFilters, error: isErrorFilters },
  ] = useLazyGetSafetyActionsFiltersQuery({
    selectFromResult: parseReduxAPIResponse,
  });

  const [
    getDeficiencies,
    {
      data: deficiencies,
      isLoading: deficiencyIsloading,
      isError: isErrorDeficiency,
    },
  ] = useGetDeficienciesMutation({
    selectFromResult: parseReduxAPIResponse,
  });

  const isLoading = isLoadingFilters || deficiencyIsloading;

  const error =
    (filters.status_code !== 200 && filters.message) ||
    errMsg ||
    (deficiencies.status_code !== 200 && deficiencies.message) ||
    ((isErrorFilters || isErrorDeficiency) && "Internal Server Error");

  let datepickerData = {
    startDate: getPayLoad.start_date,
    setStartDate: (value) => {
      setPaload((palyoad) => ({ ...palyoad, start_date: value }));
    },
    endDate: getPayLoad.end_date,
    setEndDate: (value) => {
      setPaload((palyoad) => ({ ...palyoad, end_date: value }));
    },
    getStatusOption: getPayLoad.status,
    setStatusOption: (value) => {
      setPaload((palyoad) => ({ ...palyoad, status: value }));
    },
    getUsersOption: getPayLoad.reporter,
    setUsersOption: (value) => {
      setPaload((palyoad) => ({ ...palyoad, reporter: value }));
    },
    getMarketDetails: getPayLoad.markets,
    setMarketDetails: (value) => {
      setPaload((palyoad) => ({ ...palyoad, markets: value }));
    },
    getCustomerDetails: getPayLoad.customers,
    setCustomerDetails: (value) => {
      setPaload((palyoad) => ({ ...palyoad, customers: value }));
    },
    getRegionDetails: getPayLoad.locations,
    setRegionDetails: (value) => {
      setPaload((palyoad) => ({ ...palyoad, locations: value }));
    },
    getSupplierDetails: getPayLoad.suppliers,
    setSupplierDetails: (value) => {
      setPaload((palyoad) => ({ ...palyoad, suppliers: value }));
    },
    formIdData: getPayLoad.formId,
    setFormIdData: (value) => {
      setPaload((palyoad) => ({ ...palyoad, formId: value }));
    },
    selectedBusinessUnit: getPayLoad.business_unit,
    setSelecetedBusinessUnit: (value) => {
      setPaload((palyoad) => ({ ...palyoad, business_unit: value }));
    },
  };

  useEffect(() => {
    if (getPayLoad.start_date && getPayLoad.end_date) {
      const param = {
        start_date: moment(getPayLoad.start_date).format("YYYY-MM-DD"),
        end_date: moment(getPayLoad.end_date).format("YYYY-MM-DD"),
        timeZone: getPayLoad.timeZone,
      };

      const fetchData = async () => {
        await getSafetyActionsFilters(param)
          .unwrap()
          .then((response) => {
            if (_.isEmpty(response.data)) return;

            const filterData = JSON.parse(response.data);
            const defaultReporter = Object.entries(
              filterData?.reporters?.find(
                (val) => Object.entries(val)?.[0]?.[0] === employeeId
              ) || []
            )?.[0]?.[0];
            setPaload((payload) => ({
              ...payload,
              formId: filterData?.form?.map((form) => form.formId) || [],
              status: ["1", "0"],
              customers: filterData?.customers || [],
              markets: filterData?.markets || [],
              suppliers: filterData?.suppliers || [],
              reporter: _.isEmpty(defaultReporter) ? [] : [defaultReporter],
              locations: filterData?.locations || [],
              business_unit: filterData?.business_unit || [],
            }));
            setIsAutoCall(true);
          })
          .catch((error) => {
            console.error(error);
          });
      };

      if (id && toggleById) {
        setIsAutoCall(true);
      } else if (id && !toggleById) {
        setSearchParmas("");
      } else {
        fetchData();
      }
    }
  }, [
    employeeId,
    navigate,
    getSafetyActionsFilters,
    getPayLoad.start_date,
    getPayLoad.end_date,
    getPayLoad.timeZone,
    id,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newDay = moment().local().format("YYYY-MM-DD");
      if (currentDay !== newDay) {
        setCurrentDay(newDay);
        setPaload((payload) => ({
          ...payload,
          start_date: new Date(
            moment(currentDay, "YYYY-MM-DD").format("YYYY-MM-DD 00:00:00")
          ),
        }));
        setPaload((payload) => ({
          ...payload,
          end_date: new Date(
            new Date(moment(newDay, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59"))
          ),
        }));
        setIsAutoCall(true);
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [currentDay]);

  useEffect(() => {
    if (isAutoCall) {
      if (id) {
        getDeficiencies(JSON.stringify({ EswUniqueId: id }));
        setToggleById(false);
      } else {
        const param = {
          ...getPayLoad,
          start_date: moment(getPayLoad.start_date).format("YYYY-MM-DD"),
          end_date: moment(getPayLoad.end_date).format("YYYY-MM-DD"),
        };
        const haveMarket = features.some(
          (val) => val.name === "MARKET" && val.value
        );
        const haveCustomer = features.some(
          (val) => val.name === "CUSTOMER" && val.value
        );

        const isNotValid = Object.entries(param).find(
          (val) => val[1].length < 1
        )?.[0];

        if (
          (isNotValid === "markets" && haveMarket) ||
          (isNotValid === "customers" && haveCustomer) ||
          (!["markets", "customers"].includes(isNotValid) && isNotValid)
        ) {
          setErrMsg(`Please select valid data range`);
        } else {
          setErrMsg("");
          getDeficiencies(JSON.stringify(param));
        }
      }

      setIsAutoCall(false);
    }
  }, [getPayLoad, getDeficiencies, isAutoCall, setSearchParmas]);

  return (
    <div className="row ">
      <div className="tile filterDiv">
        <CustomDatePickerForm
          formName="actions"
          datepickerData={datepickerData}
          filterData={filters?.data || {}}
          callApply={() => {
            setIsAutoCall((val) => !val);
          }}
          disableButtton={isLoading}
          getPayLoad={getPayLoad}
          setPaload={setPaload}
        />
      </div>
      <div className="tile sm-12 md-12 lg-12 xl-12">
        <div className="header">
          <div className="left">
            <div className="title">Safety Actions</div>
          </div>
        </div>

        <div className="content">
          <div className="column">
            {isLoading && <Loading />}
            {error && !isLoading && (
              <AlertEDS errMsg={error} status_code={201} close={false} />
            )}
            {!error && !_.isEmpty(deficiencies.data) && (
              <DeficienciesActionTable
                totaltimeData={deficiencies.data}
                refetch={() => {
                  setIsAutoCall((val) => !val);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
