import { GeographicalViewChart } from "@eds/vanilla";
import _ from "lodash";
import { useEffect, useState } from "react";
export default function LoneWorkerMap({
  loneworkerData,
  loneWorkerusergroup_location,
}) {
  const [getButton, setButton] = useState([]);
  const [activenew, setactivenew] = useState();
  const [getMapDraw, setMapDraw] = useState();
  let ActiveCord = [];
  let _filter = [];
  const parseGeoCoordinates = (geoCoordinatesStr) => {
    try {
      return JSON.parse(geoCoordinatesStr);
    } catch (error) {
      return null;
    }
  };
  const { geo_coordinates, zoomlevel } = loneWorkerusergroup_location || {};
  const parsedGeoCoordinates = parseGeoCoordinates(geo_coordinates);

  const AlertData = (alert) => {
    let data =
      alert === "Overrun-Session"
        ? "Major"
        : alert === "Active-Session"
        ? "Minor"
        : alert === "Panic-Alert" && "Critical";
    return [{ name: data, value: alert }];
  };

  const products = {
    alerts: [{ name: "Critical" }, { name: "Major" }, { name: "Minor" }],
    nodes: "",
  };

  useEffect(() => {
    const mapElement = document.querySelector("#selection-map");
    const initializeMap = () => {
      let node = [];
      let btn = [];
      Object.values(loneworkerData["Status"]).filter((i, k) => {
        btn.push(loneworkerData["Status"][k]);
        if (i === "Active-Session") {
          ActiveCord.push({
            x: parseFloat(loneworkerData["Location"][k].split(",")[1]),
            y: parseFloat(loneworkerData["Location"][k].split(",")[0]),
          });
        }
        node.push({
          key: k + 1,
          name:
            loneworkerData["Fullname"][k] +
            "<br>(" +
            parseFloat(loneworkerData["Location"][k].split(",")[0]) +
            ", " +
            parseFloat(loneworkerData["Location"][k].split(",")[1]) +
            ")",
          icon: "icon-profile",
          x: parseFloat(loneworkerData["Location"][k].split(",")[1]),
          y: parseFloat(loneworkerData["Location"][k].split(",")[0]),
          alerts: AlertData(loneworkerData["Status"][k]),
        });
      });

      setButton([...new Set(btn)]);
      products.nodes = node;
      setactivenew(products);
      if (getMapDraw) {
        const filterContainer = document.querySelector(".filterBtn");
        const activeButtons = Array.from(
          filterContainer.querySelectorAll(".switch.active span")
        ).map((spanElement) => spanElement.textContent);

        const filteredNodes = activenew.nodes.filter((node) => {
          const nodeAlerts = node.alerts.map((alert) => alert.value);
          return nodeAlerts.every((value) => activeButtons.includes(value));
        });
        const filteredData = {
          alerts: products.alerts,
          nodes: filteredNodes,
        };
        getMapDraw.draw(filteredData);
      } else {
        if (!mapElement) {
          console.error("Map element not found");
          return;
        }
        const map = new GeographicalViewChart({
          element: mapElement,
          showScrollLock: true,
        });
        map.init();
        map?.setView(
          !_.isEmpty(ActiveCord)
            ? [
                ActiveCord[ActiveCord.length - 1].y,
                ActiveCord[ActiveCord.length - 1].x,
              ]
            : parsedGeoCoordinates || [0, 0],
          zoomlevel ? parseFloat(zoomlevel) : 2
        );
        map.draw(products);

        setMapDraw(map);
      }
    };

    initializeMap();
  }, [loneworkerData, getMapDraw]);

  const btnFilter = (e, status) => {
    let _node = [];
    e.target.parentNode.classList.toggle("active");
    if (_filter.includes(status)) {
      _filter = _filter.filter((i) => i !== status);
    } else {
      _filter.push(status);
    }

    ActiveCord = [];
    Object.values(loneworkerData["Status"]).filter((i, k) => {
      if (!_filter.includes(i)) {
        ActiveCord.push({
          x: parseFloat(loneworkerData["Location"][k].split(",")[1]),
          y: parseFloat(loneworkerData["Location"][k].split(",")[0]),
        });
        _node.push({
          key: k + 1,
          name:
            loneworkerData["Fullname"][k] +
            "<br>(" +
            parseFloat(loneworkerData["Location"][k].split(",")[0]) +
            ", " +
            parseFloat(loneworkerData["Location"][k].split(",")[1]) +
            ")",
          icon: "icon-profile",
          x: parseFloat(loneworkerData["Location"][k].split(",")[1]),
          y: parseFloat(loneworkerData["Location"][k].split(",")[0]),
          alerts: AlertData(loneworkerData["Status"][k]),
        });
      }
    });

    products.nodes = _node;

    getMapDraw?.setView(
      !_.isEmpty(ActiveCord)
        ? [
            ActiveCord[ActiveCord.length - 1].y,
            ActiveCord[ActiveCord.length - 1].x,
          ]
        : parsedGeoCoordinates || [0, 0],
      zoomlevel ? parseFloat(zoomlevel) : 2
    );

    getMapDraw.draw(products);
  };

  return (
    <div>
      <div className="filterBtn">
        {getButton?.map((i, k) => {
          return (
            <label
              className={
                i === "Active-Session"
                  ? "switch mb-bs activeSession active"
                  : i === "Panic-Alert"
                  ? "switch mb-bs panicAlert active"
                  : i === "Overrun-Session"
                  ? "switch mb-bs overrunSession active"
                  : i === "Miss Check-In"
                  ? "switch mb-bs missCheckIn active"
                  : "switch mb-bs completedSession active"
              }
              key={k}
              onClick={(e) => btnFilter(e, i)}
            >
              <input
                type="checkbox"
                onClick={(e) => btnFilter(e, i)}
                defaultChecked
              />
              <i className="ball"></i>
              <span>{i}</span>
            </label>
          );
        })}
      </div>
      <div id="selection-map"></div>
    </div>
  );
}
