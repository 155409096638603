import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading/Loading';
import { Table } from "@eds/vanilla";

function RolePopupDashboard({ getRolePopData, setRolePopUp }) {

    const onCloseHandler = () => {
        setRolePopUp(false);
    };

    const checkClick = (e) => {
        e.target.previousSibling.checked = !e.target.previousSibling.checked;
    }

    const handleTableData = () => {
        let products = [];
        products.push({
            "permission": getRolePopData.Screen,
            "check": getRolePopData.UserAccess,
        });
        getRolePopData.Subcomponent.map((i, k) => {
            products.push({
                "permission": i.Action,
                "check": i.UserAccess,
            });
        })
        return products;
    }

    useEffect(() => {
        const tableDOM = document.querySelector("#displayDashboardRolePop");
        const table = new Table(tableDOM, {
            data: handleTableData(),
            columns: [
                {
                    key: "permission",
                    title: "Permission",
                    sort: "none",
                },
                {
                    key: "check",
                    title: "",
                    onCreatedCell: (td, data) => {
                        td.innerHTML = data === 'Y' ? `<input type="checkbox" checked/><label class="checkBox" ></label>` : `<input type="checkbox" /><label class="checkBox"></label>`;
                        td.querySelector('label.checkBox').addEventListener('click', (e) => {
                            checkClick(e);
                        });
                    },
                },

            ],
            sortable: true,
        });
        table.init();
    }, [getRolePopData]);

    return (
        <div>
            <div className="dialog RolePopup show modal-xs" data-trigger="#roleTag" data-type="simple">
                <div className="content">
                    <div className="top">
                        <div className="title">Update Role for - {getRolePopData.role}</div>
                        <div className="right">
                            <button className="btn-icon" data-close="true" onClick={() => onCloseHandler()}>
                                <i className="icon icon-cross"></i>
                            </button>
                        </div>
                    </div>
                    <div className="body">
                        <div className="tile sm-12 md-12 lg-12 xl-12">
                            <div className="table-top">
                                <div className="table-top-left">
                                    <div className="table-info"></div>
                                </div>
                            </div>
                            <table id="displayDashboardRolePop" className="table dashed stickyHeader horizonatalScroll" style={{ tableLayout: `auto` }}></table>
                            <div className="table-bottom">
                                <div className="pagination-group">
                                    <ul className="pagination"></ul>
                                    <div className="actions">
                                        <label className="left">Go to</label>
                                        <input type="number" min="1" />
                                        <label className="left">Show</label>
                                        <div className="select" data-type="single">
                                            <button className="btn current-options">5</button>
                                            <div className="options-list">
                                                <div className="item active">5</div>
                                                <div className="item">10</div>
                                                <div className="item">25</div>
                                                <div className="item">All</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column sm-12 md-12 lg-12 xl-12 text-center">
                                <button className='btn'>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default RolePopupDashboard;