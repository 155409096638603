import React, { useEffect, useState } from "react";
import { Dialog } from "@eds/vanilla";
import { Select } from "@eds/vanilla";
import {
  getCustomerDetails,
  getCustomerMarket,
  getCustomerRegion,
  generateTicket,
} from "../../../api/incidentManagement.api";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import { useSelector } from "react-redux";
import _ from "lodash";

function IncidentPopup(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [customerDetails, setCustomerDetails] = useState([]);
  const [customerRegion, setCustomerRegion] = useState([]);
  const [customerMarket, setCustomerMarket] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedMarket, setSelectedMarket] = useState("");
  const [ticketDetails, setTicketDetails] = useState({
    IMTID: "",
    "INCIDENT NUMBER": "",
  });
  const { headers } = useSelector((state) => state.envVariables);

  useEffect(() => {
    const dialogs = document.getElementById("incidentPop");
    if (dialogs) {
      Array.from(dialogs).forEach((dialogDOM) => {
        const dialog = new Dialog(dialogDOM);
        dialog.init();
      });
    }
  }, []);

  const getCustomerDetailsFromApi = async () => {
    setIsLoading(true);
    const customerDetails = await getCustomerDetails(headers);
    setCustomerDetails(customerDetails.data.customer);
    setIsLoading(false);
  };
  const getCustomerRegionFromApi = async (customer) => {
    setIsLoading(true);
    const customerDetails = await getCustomerRegion({ customer }, headers);
    setCustomerRegion(customerDetails.data.region);
    // setErrMsg(customerDetails.message)
    setIsLoading(false);
  };

  const getCustomerMarketFromApi = async (region) => {
    setIsLoading(true);
    const customerMarketDetails = await getCustomerMarket(
      {
        customer: selectedCustomer,
        region,
      },
      headers
    );
    setCustomerMarket(customerMarketDetails.data.market);
    // setErrMsg(customerMarketDetails.message)
    setIsLoading(false);
  };

  useEffect(() => {
    getCustomerDetailsFromApi();
  }, []);

  const handleCustomerChange = async (event) => {
    const customer = event.target.textContent;
    setSelectedCustomer(customer);
    if (customer) {
      await getCustomerRegionFromApi(customer);
    } else {
      setCustomerRegion([]);
    }
  };
  const handleRegionChange = async (event) => {
    const region = event.target.textContent;
    setSelectedRegion(region);
    if (region && selectedCustomer) {
      await getCustomerMarketFromApi(region);
    } else {
      setCustomerMarket([]);
    }
  };
  const handleMarketChange = async (event) => {
    setSelectedMarket(event.target.textContent);
  };
  let dropdowns = [
    {
      name: "Customer",
      options: customerDetails,
      handler: handleCustomerChange,
    },
    {
      name: "Region",
      options: customerRegion,
      handler: handleRegionChange,
    },
    {
      name: "Market",
      options: customerMarket,
      handler: handleMarketChange,
    },
  ];

  const submitData1 = async () => {
    if (
      !selectedRegion ||
      !selectedCustomer ||
      !selectedMarket ||
      !props.reportId
    )
      return;
    const response = await generateTicket(
      {
        customer: selectedCustomer,
        region: selectedRegion,
        market: selectedMarket,
        reportId: props.reportId,
      },
      setIsLoading,
      setErrMsg,
      headers
    );
    if (!_.isEmpty(response)) {
      setTicketDetails(response.data);
      props.setReportsData((prevValue) => {
        const updated_totaltime_js = JSON.parse(prevValue.totaltime_js);
        updated_totaltime_js.imtIds = {
          ...updated_totaltime_js.imtIds,
          [props.dataIndex]: response.data.IMTID,
        };
        updated_totaltime_js.incidentnumber = {
          ...updated_totaltime_js.incidentnumber,
          [props.dataIndex]: response.data["INCIDENT NUMBER"],
        };
        return {
          ...prevValue,
          totaltime_js: JSON.stringify(updated_totaltime_js),
        };
      });
    }
  };

  const handleClosePopup = () => {
    setTicketDetails({ IMTID: "", "INCIDENT NUMBER": "" });
    props.handleCloseIncidentPopup();
  };

  useEffect(() => {
    const selects = document.querySelectorAll(".select");

    if (selects) {
      Array.from(selects).forEach((selectDOM) => {
        const select = new Select(selectDOM);
        select.init();
      });
    }
  }, []);

  return (
    <div>
      <div
        id="incidentPop"
        className="dialog show"
        data-trigger="#open-modalList"
        data-type="fullscreen"
      >
        <div className="content">
          <div className="top">
            <div className="title">
              {ticketDetails.IMTID && ticketDetails["INCIDENT NUMBER"]
                ? "Ticket Details"
                : "Report an Incident"}
            </div>
            <div className="right">
              <button
                className="btn-icon"
                data-close="true"
                onClick={() => handleClosePopup()}
              >
                <i className="icon icon-cross"></i>
              </button>
            </div>
          </div>
          <div className="body">
            {isLoading && (
              <div className="SiderBarLoader">
                <Loading />
              </div>
            )}

            <div className="row">
              {!ticketDetails.IMTID || !ticketDetails["INCIDENT NUMBER"] ? (
                dropdowns.map((dropdownData, index1) => {
                  return (
                    <div
                      className="tile sm-12 md-12 lg-12 xl-12 select"
                      data-type="single"
                      key={index1}
                    >
                      <button className="btn current-options" type="button">
                        Select {dropdownData.name}
                      </button>
                      <div className="options-list">
                        {dropdownData.options.map((dropdownOption, index2) => {
                          return (
                            <div
                              className="item"
                              onClick={dropdownData.handler}
                              key={index2}
                              value={dropdownOption}
                            >
                              {dropdownOption}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="tile sm-12 md-12 lg-12 xl-12">
                  <AlertEDS
                    errMsg={"Successfully Raised an Incident"}
                    status_code={200}
                    close={false}
                  />
                  <p className="my-2 SuccessID">IMTID: {ticketDetails.IMTID}</p>
                  <p className="my-2 SuccessIncident">
                    Incident Number: {ticketDetails["INCIDENT NUMBER"]}
                  </p>
                  <div className="text-center">
                    <button className="btn" onClick={handleClosePopup}>
                      Close
                    </button>
                  </div>
                </div>
              )}
              {errMsg.length ? <p className="color-red">{errMsg}</p> : null}

              {!ticketDetails.IMTID || !ticketDetails["INCIDENT NUMBER"] ? (
                <div className="tile sm-12 md-12 lg-12 xl-12">
                  <div className="text-center">
                    <button className="btn" onClick={submitData1}>
                      Submit
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default IncidentPopup;
