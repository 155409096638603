import { useEffect, useState } from "react";
import { Table, Breadcrumb, TabGroup } from "@eds/vanilla";
import './supplier.scss'
import _ from "lodash";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AddUpdateSupplier from "./AddUpdateSupplier";
import { getFormWiseCCList } from "../../api/Notification.api";
import DeleteSupplier from "./DeleteSupplier";
import Loading from "../../components/Loading/Loading";
// import { getValidSupplier } from "../../api/Validomain.api";
import AlertEDS from "../../components/AlertWarn/AlertEDS";

export default function ViewMoreInfo() {
  let _data = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [getvalidsupplierlist, setvalidsupplierlist] = useState(_data.state.data);
  const [deleteSupplier, setdeleteSupplier] = useState({ isOpen: false });
  const [showconfirmationpopup, setshowconfirmationpopup] = useState({ isOpen: false });
  const [addnewsupplier, setaddnewsupplier] = useState({ isOpen: false, type: "", data: getvalidsupplierlist });
  const [getAdministrators, setAdministrators] = useState(getvalidsupplierlist.supplier_administrators);
  const [getRecipients, setRecipients] = useState(getvalidsupplierlist.notification_recipients);
  const [getCCList, setCCList] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [loadingVal, setLoading] = useState(false);
  const [confirmationMessage, setconfirmationMessage] = useState({ errorMsg: "", statusCode: "" });
  const { headersList, headers } = useSelector((state) => state.envVariables);
  // const InternalSupplierID = _data.state.data.internal_supplier_id;
  let distributionData = {
    getCCList,
    setCCList,
  }

  useEffect(() => {
    const tabs = document.querySelector('.tabs');

    const tabGroup = new TabGroup(tabs);
    tabGroup.init();
    return () => {
      tabGroup.destroy();
    }
  }, []);

  useEffect(() => {
    const element = document.getElementById("supplier-breadcrumb-action");
    if (element && pathname.includes('/suppliers-management/supplierID')) {
      const breadcrumb = new Breadcrumb(element);
      breadcrumb.init([
        {
          title: "suppliers-management",
          action: () => {
            navigate("/suppliers-management");
          },
        },
        {
          title: `${getvalidsupplierlist.supplier_name}`,
        },
      ]);
      return () => {
        breadcrumb.destroy();
      };
    }
  }, [pathname]);

  useEffect(() => {
    getFormWiseCCList(distributionData.setCCList, headers);
  }, [headers]);


  const handleUpdatecall = async (val) => {
    setshowconfirmationpopup({ isOpen: true });
  };

  const ValidsupplierList = async (val) => {
    setvalidsupplierlist({
      ...getvalidsupplierlist,
      allowed_domain: val.formData.domainName,
      notification_recipients: val.getRecipients,
      supplier_administrators: val.getAdministrators,
      supplier_id: val.formData.supplier_id,
      supplier_name: val.formData.supplier,
      verified: val.formData.verified
    });
  };

  const DeleteSupplierItem = () => {
    navigate("/suppliers-management");
  }

  const onConfirmation = () => {
    setaddnewsupplier({
      ...addnewsupplier,
      isOpen: true,
      type: "Edit",
    });
  };

  return (
    <div className="row">
      {pathname.includes('/suppliers-management/supplierID') &&
        <div><div className="breadcrumb m-lg" id="supplier-breadcrumb-action"></div></div>
      }
      <div className="tile sm-12 md-12 lg-12 xl-12">
        {/* <div className="header">
          <div className="left">
            <div className="title">Supplier Name - {getvalidsupplierlist.supplier_name}</div>
          </div>
        </div> */}
        <div className="content">
          {addnewsupplier.isOpen && distributionData.getCCList && (
            <AddUpdateSupplier
              addnewsupplier={addnewsupplier}
              setaddnewsupplier={setaddnewsupplier}
              confirmationMessage={confirmationMessage}
              setconfirmationMessage={setconfirmationMessage}
              setLoading={setLoading}
              setErrMsg={setErrMsg}
              headersList={headersList}
              ValidsupplierList={ValidsupplierList}
              loadingVal={loadingVal}
              distributionData={distributionData}
              getAdministrators={getAdministrators}
              setAdministrators={setAdministrators}
              getRecipients={getRecipients}
              setRecipients={setRecipients}
            />
          )}

          {deleteSupplier.isOpen && (
            <DeleteSupplier
              setdeleteSupplier={setdeleteSupplier}
              deleteSupplier={deleteSupplier}
              setconfirmationMessage={setconfirmationMessage}
              setLoading={setLoading}
              confirmationMessage={confirmationMessage}
              setErrMsg={setErrMsg}
              loadingVal={loadingVal}
              headersList={headersList}
              ValidsupplierList={DeleteSupplierItem}
            />
          )}

          {showconfirmationpopup.isOpen && (
            <div
              className="dialog show sm"
              data-trigger="#open-warning"
              data-type="simple"
            >
              {" "}
              <div className="content">
                <div className="top">
                  <div className="title center">{showconfirmationpopup.isOpen ? 'Notice' : 'Confirmation'}</div>
                  <div className="right">
                    <button
                      className="btn-icon"
                      data-close="true"
                      onClick={() => setshowconfirmationpopup({ isOpen: false })}
                    >
                      <i className="icon icon-cross"></i>
                    </button>
                  </div>
                </div>


                {errMsg !== "Success" && errMsg !== "" && (
                  <AlertEDS errMsg={errMsg} status_code="error" close={false} />
                )
                }
                {loadingVal ? (
                  <Loading />
                ) : confirmationMessage.errorMsg !== "" ? (
                  <div className="tile sm-12">
                    <AlertEDS
                      errMsg={confirmationMessage.errorMsg}
                      status_code={
                        confirmationMessage.statusCode === 200 ? 200 : "error"
                      }
                      close={false}
                    />
                  </div>
                ) : (
                  <>
                    <div className="body">
                      Please be aware if the domain list is changed, new
                      users can no longer be created using the domains which
                      are part of the previous list.
                    </div>

                    <div className="bottom">
                      <button
                        className="btn primary"
                        onClick={() => { setshowconfirmationpopup({ isOpen: false }); onConfirmation() }}
                      >
                        Continue
                      </button>
                      {/* <button
                        className="btn warning"
                        onClick={() => setshowconfirmationpopup({ isOpen: false })}
                      >
                        No
                      </button> */}
                    </div>
                  </>
                )}{" "}
              </div>
            </div>
          )}
          <div className="column">
            <div className="tabs supplierViewMore">
              <div className="titles">
                <div className="title selected" tabIndex="0">Supplier Details</div>
                {/* <div className="title" tabIndex="0">Tab 2</div>
                <div className="title" tabIndex="0">Tab 3</div> */}
              </div>

              <div className="contents">
                <div className="content selected">
                  <div className="static">
                    <div className="StaticInfo">
                      <div className="dataInfo">
                        <div className="dataLabel">Supplier</div>
                        <div className="info">{getvalidsupplierlist.supplier_name}</div>
                      </div>

                      <div className="dataInfo">
                        <div className="dataLabel">Verification Status</div>
                        <div className="info">{getvalidsupplierlist.verified ? (<kbd class="tag green">Verified</kbd>) : (<kbd class="tag orange">Unverified</kbd>)}</div>
                      </div>

                      <div className="dataInfo">
                        <div className="dataLabel">Supplier ID</div>
                        <div className="info">{getvalidsupplierlist.supplier_id}</div>
                      </div>

                      <div className="dataInfo">
                        <div className="dataLabel">Supplier Representative</div>
                        <div className="info">{getvalidsupplierlist.supplier_administrators?.map((i, k) => <div className="mailID" key={k}>{i}</div>)}</div>
                      </div>

                      <div className="dataInfo">
                        <div className="dataLabel">Notification Recipients</div>
                        <div className="info">{getvalidsupplierlist.notification_recipients?.map((i, k) => <div className="mailID" key={k}>{i}</div>)}</div>
                      </div>

                      <div className="dataInfo">
                        <div className="dataLabel">Domain</div>
                        <div className="info">{getvalidsupplierlist.allowed_domain?.map((i, k) => <div className="mailID" key={k}>{i}</div>)}</div>
                      </div>

                      <div className="dataInfo">
                        <div className="dataLabel">Created Date</div>
                        <div className="info">{getvalidsupplierlist.supplier_created_date}</div>
                      </div>

                      <div className="dataInfo">
                        <div className="dataLabel">Users</div>
                        <div className="info">{getvalidsupplierlist.total_count}</div>
                      </div>
                    </div>
                    <div className="actionInfo">
                      <button className="btn primary icon-btn editRoles" title="Edit Supplier" onClick={handleUpdatecall}>
                        <i className="icon icon-edit"></i></button>
                      <button className="btn primary icon-btn deleteUser" title="Delete Supplier" onClick={() => {
                        setdeleteSupplier({
                          isOpen: true,
                          Supplier: {
                            id: getvalidsupplierlist.internal_supplier_id,
                            name: getvalidsupplierlist.supplier_name,
                          },
                        });
                      }} >
                        <i className="icon icon-trashcan"></i></button>
                    </div>
                  </div>
                </div>

                {/* <div className="content">
                  <p>
                    This is the content for the second tab.
                    It shows when you have the second tab selected.
                    If not, it is hidden.
                  </p>
                </div>

                <div className="content">
                  <p>
                    This is the content for the third tab.
                    It shows when you have the third tab selected.
                    If not, it is hidden.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
