import { URL } from "../utils/Constant";
import _ from "lodash";
import moment from "moment";
export const getSitewalkGoalsFilterDetails = async (
  setLoading,
  setErrorMsg,
  setFilterData,
  headers
) => {
  setLoading(true);
  setErrorMsg("");
  const url = URL.ERICSION.GET_AUDITORS_LIST;
  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      setFilterData(_.cloneDeep(data?.data));
      setLoading(false);
      setErrorMsg(data?.message);
    } else {
      setLoading(false);
      setErrorMsg("Internal Server Error. Please try again later !");
      setFilterData({});
    }
  } catch {
    setLoading(false);
    setErrorMsg("Internal Server Error. Please try again later !");
    setFilterData({});
  }
};

export const getSitewalkGoalsByAuditors = async (
  param,
  setLoading,
  setErrorMsg,
  setData,
  tabname = "settargets",
  headersList
) => {
  setLoading((val) => ({ ...val, goalsByAuditor: true }));
  setErrorMsg((val) => ({ ...val, goalsByAuditor: "" }));
  const url = URL.ERICSION.GET_SITEWALK_GOALS_BY_AUDITORS;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(param),
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      const isGroupByYear =
        Math.abs(
          moment(param.end_date).diff(moment(param.start_date), "months", true)
        ) >= 12
          ? true
          : false;

      if (isGroupByYear && tabname === "goalsAnalytics") {
        const auditors = data?.data?.reduce((acc, val) => {
          const index = acc.findIndex(
            (x) =>
              x.Auditor.id === val.Auditor.id &&
              x.Date === moment(val.Month).format("YYYY")
          );
          if (index > -1) {
            const obj = acc[index];
            acc.splice(index, 1, {
              Auditor: { ...val?.Auditor },
              Date: moment(val.Month).format("YYYY"),
              "Total Walks Completed":
                obj["Total Walks Completed"] + val["Completed Count"],
              "Stretch Target": obj["Stretch Target"] + +val["Target Count"],
            });
          } else {
            acc.push({
              Auditor: { ...val?.Auditor },
              Date: moment(val.Month).format("YYYY"),
              "Total Walks Completed": +val?.["Completed Count"],
              "Stretch Target": +val?.["Target Count"] || 0,
            });
          }
          return acc;
        }, []);
        setData((val) => ({
          ...val,
          goalsByAuditor: auditors,
        }));
      } else {
        setData((val) => ({
          ...val,
          goalsByAuditor: data?.data?.map((val) => ({
            Auditor: { ...val?.Auditor },
            Date: val?.Month,
            "Total Walks Completed": +val?.["Completed Count"] || 0,
            "Stretch Target": +val?.["Target Count"] || 0,
          })),
        }));
      }
      setLoading((val) => ({ ...val, goalsByAuditor: false }));
      setErrorMsg((val) => ({
        ...val,
        goalsByAuditor: data?.data?.length
          ? data?.message
          : "No data available in this date range",
      }));
    } else {
      setLoading((val) => ({ ...val, goalsByAuditor: false }));
      setErrorMsg((val) => ({
        ...val,
        goalsByAuditor: "Internal Server Error. Please try again later !",
      }));
      setData((val) => ({ ...val, goalsByAuditor: [] }));
    }
  } catch {
    setLoading((val) => ({ ...val, goalsByAuditor: true }));
    setErrorMsg((val) => ({
      ...val,
      goalsByAuditor: "Internal Server Error. Please try again later !",
    }));
    setData((val) => ({ ...val, goalsByAuditor: [] }));
  }
};

export const updateSiteWalkGoals = async (
  param,
  setLoading,
  setErrorMsg,
  method,
  headersList
) => {
  setLoading(true);
  setErrorMsg("");
  const url = URL.ERICSION.ADD_SITEWALK_GOALS;
  try {
    const resp = await fetch(url, {
      method: method,
      body: JSON.stringify(param),
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      setLoading(false);
      setErrorMsg(data?.message);
      if (data?.status_code && data?.message === "Successfull") {
        return data?.status_code;
      }
    } else {
      setLoading(false);
      setErrorMsg("Internal Server Error. Please try again later !");
    }
  } catch {
    setLoading(false);
    setErrorMsg("Internal Server Error. Please try again later !");
  }
  return 201;
};

export const getSitewalkGoalsByRegions = async (
  param,
  setLoading,
  setErrorMsg,
  setData,
  headersList
) => {
  setLoading((val) => ({ ...val, goalsByRegion: true }));
  setErrorMsg((val) => ({ ...val, goalsByRegion: "" }));
  // const url = URL.ERICSION.GET_ACTIONS_FILTERS;
  try {
    const resp = {};
    // const resp = await fetch(url, {
    //   method: "GET",
    //   headers: headers,
    // });
    if (resp?.ok || true) {
      // const data = await resp.json();
      const data = {};
      data.data = [
        {
          Region: "East",
          Date: "2023-05",
          "Total Walks Completed": 10,
          "Stretch Target": 15,
        },
        {
          Region: "East",
          Date: "2023-06",
          "Total Walks Completed": 13,
          "Stretch Target": 20,
        },
        {
          Region: "West",
          Date: "2023-05",
          "Total Walks Completed": 10,
          "Stretch Target": 8,
        },
        {
          Region: "West",
          Date: "2023-07",
          "Total Walks Completed": 5,
          "Stretch Target": 25,
        },
      ];
      data.message = "Successfull";
      setData((val) => ({ ...val, goalsByRegions: _.cloneDeep(data?.data) }));
      setLoading((val) => ({ ...val, goalsByRegion: false }));
      setErrorMsg((val) => ({
        ...val,
        goalsByRegion: data?.data?.length
          ? data?.message
          : "No data available in this date range",
      }));
    } else {
    }
  } catch {
    setLoading((val) => ({ ...val, goalsByRegion: true }));
    setErrorMsg((val) => ({
      ...val,
      goalsByRegion: "Internal Server Error. Please try again later !",
    }));
    setData((val) => ({ ...val, goalsByRegions: [] }));
  }
};
