import { URL } from "../utils/Constant";
export const uploadSafetyDialogueTopics = async (
  param,
  setLoading,
  setErrMsg,
  headers
) => {
  setErrMsg("");
  setLoading(true);
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  let url = URL.ERICSION.UPLOAD_SAFETY_DIALOGUE_TOPIC;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: formData,
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      if (data?.message === "File saved to cloud") {
        setErrMsg("Data has been saved successfully");
      } else {
        setErrMsg(data.message);
      }
      setLoading(false);
    } else {
      setErrMsg("Internal Server error. Please try again later....");
      setLoading(false);
    }
  } catch {
    setErrMsg("Internal Server error. Please try again later....");
    setLoading(false);
  }
};
export const getSafetyDialogueTopics = async (
  setLoading,
  setErrMsg,
  setData,
  headers
) => {
  setErrMsg("");
  setLoading(true);
  setData({});

  let url = URL.ERICSION.GET_SAFETY_DIALOGUE_TOPIC;
  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      setData(JSON.parse(data?.data?.dialogue_topic_reports || "{}"));
      setErrMsg(data.message);
      setLoading(false);
    } else {
      setErrMsg("Internal Server error. Please try again later....");
      setLoading(false);
    }
  } catch {
    setErrMsg("Internal Server error. Please try again later....");
    setLoading(false);
  }
};
export const uploadEmployeeList = async (
  param,
  setLoading,
  setErrMsg,
  headers
) => {
  setErrMsg("");
  setLoading(true);
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  let url = URL.ERICSION.UPLOAD_EMPLOYEE_LIST;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: formData,
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      if (data?.message === "File saved to cloud") {
        setErrMsg("Data has been saved successfully");
      } else {
        setErrMsg(data.message);
      }
      setLoading(false);
    } else {
      setErrMsg("Internal Server error. Please try again later....");
      setLoading(false);
    }
  } catch {
    setErrMsg("Internal Server error. Please try again later....");
    setLoading(false);
  }
};
