import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChartGrouped } from "@eds/vanilla";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";
export default function OpenCloseGraph({ getActionItemsByRegions }) {
  useEffect(() => {
    const data = {
      common: getActionItemsByRegions?.map((obj) => obj?.Region),
      series: [
        {
          name: "Open",
          values: getActionItemsByRegions?.map((obj) => obj?.OpenCount),
        },
        {
          name: "Closed",
          values: getActionItemsByRegions?.map((obj) => obj?.CloseCount),
        },
      ],
    };
    const chartHeight = data?.common?.length * 60;
    const mycolorScale = new ColorScale({ reverse: true });
    const chart = new HorizontalBarChartGrouped({
      element: document.getElementById("actionItemsOpenVsClosedGraph"),
      data: data,
      x: { unit: "Count" },
      height: chartHeight,
      colorScale: mycolorScale,
    });
    mycolorScale.init();
    chart.init();
    return () => {
      chart.destroy();
      let element = document.getElementById("actionItemsOpenVsClosedGraph");
      while (element?.firstChild) {
        element.removeChild(element.firstChild);
      }
    };
  }, [getActionItemsByRegions]);

  return (
    <>
      <CustomGraphActions
        nodeId={"actionItemsOpenVsClosedGraph"}
        fileName={"ActionItems_OpenVsClosed"}
        data={getActionItemsByRegions}
      />
      <div id="actionItemsOpenVsClosedGraph"></div>
    </>
  );
}
