export const impactOptions = [
  { formId: "Company Wide", form_name: "Company Wide" },
  { formId: "Multiple Users Affected", form_name: "Multiple Users Affected" },
  { formId: "Single User Affected", form_name: "Single User Affected" },
  { formId: "Other", form_name: "Other" },
];

export const urgencyOptions = [
  { formId: "High", form_name: "High" },
  { formId: "Medium", form_name: "Medium" },
  { formId: "Low", form_name: "Low" },
];
