import { useEffect, useRef } from "react";
import _ from "lodash";
import { Table } from "@eds/vanilla";
import Loading from "../../components/Loading/Loading";
import { useSelector } from "react-redux";
import { useSwitchOrganizationMutation } from "../../redux/services/authApi";
import { EDSDialog } from "../../components/EDSDialog/EDSDialog";

export default function SwitchAccount({}) {
  const dialogRef = useRef(null);
  const tableRef = useRef(null);

  const { authorized_groups, usergroupId } = useSelector(
    (state) => state.loggedInUser.user
  );

  const [
    switchOrganization,
    { data: { message, status_code } = {}, isLoading, error },
  ] = useSwitchOrganizationMutation();

  useEffect(() => {
    const tableData =
      authorized_groups?.map((val) => ({
        GroupName: val.GroupName,
        action: val,
      })) || [];

    if (tableRef.current) {
      tableRef.current.update(tableData);
    } else {
      const tableDOM = document.getElementById("switch-organization-table");

      if (tableDOM) {
        const table = new Table(tableDOM, {
          data: tableData,
          columns: [
            { key: "GroupName", title: "Organization", sort: "asc" },
            {
              key: "action",
              onCreatedCell: (td, data) => {
                if (data.id === usergroupId) {
                  td.innerHTML = `<span class="text-center success"><i class="icon icon-success text-lg color-green"></i>&nbsp;Current</button>`;
                } else {
                  td.innerHTML = `<button class="switchAccount btn">Switch</button>`;
                  td.querySelector("button.switchAccount")?.addEventListener(
                    "click",
                    async (event) => {
                      await switchOrganization(JSON.stringify({ Id: data.id }))
                        .unwrap()
                        .then(({ status_code }) => {
                          status_code === 200 && window.location.reload();
                        });
                    }
                  );
                }
              },
            },
          ],
          sortable: true,
          scroll: true,
        });

        table.init();
        tableRef.current = table;
      }
    }
  }, [authorized_groups, tableRef]);

  const dialogBody = (
    <div>
      <div className="table-top">
        <div className="table-top-left">
          <div className="table-info"></div>
        </div>
      </div>
      <table id="switch-organization-table" className="table dashed"></table>

      {isLoading && <Loading />}

      {(status_code && status_code !== 200) || error ? (
        <span className="text-md color-red">
          <i className="icon icon-failed color-red mr-sm"></i>
          {message || "Internal Server Error"}
        </span>
      ) : null}
    </div>
  );

  return (
    <EDSDialog
      dataTrigger="#switch-organization"
      dataType="simple"
      dialogBody={dialogBody}
      dialogRef={dialogRef}
      dialogTitle={<div className="title">Switch Organization</div>}
      id="switch-organization"
    />
  );
}
