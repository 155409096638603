import Select from "react-select";
import "./CustomSingleSelect.scss";

export default function SingleSelect({ formsDetails, setdatepickerData, getdatepickerData }) {
  // this custom Style function is for give react single select component look and fill as EDS single select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      //background: "#fff",
      borderColor: "#767676",
      minHeight: "32px",
      height: "32px",
      boxShadow: state.isFocused ? null : null,
      borderRadius: "0px",
      // background: document.querySelector("label.switch input").checked
      //   ? "#181818 !important"
      //   : "#fff !important",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "#8b8b8b",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      color: "#f2f2f2",
    }),
  };

const handleOnchange = (e) => {
    setdatepickerData(e.value);
}
  return (
    <Select
      className="singleSelect"
      // styles={customStyles}
      // className="react-select-container"
      classNamePrefix="react-select"
      options={formsDetails}
      value={
        formsDetails.find(
          (val) => val.value === getdatepickerData
        ) || getdatepickerData
      }
      isSearchable="true"
      onChange={(value) => {handleOnchange(value);
      }}
    ></Select>
  );
}
