import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChart } from "@eds/vanilla";
import _ from "lodash";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";

export default function CrewLeadScores({ data: { data, isLoading } }) {
  const getDistibutionData = (distribution) => {
    const common = [];
    const values = [];
    for (let key in distribution) {
      common.push(key);
      values.push(distribution?.[key]?.[0] || 0);
    }
    return {
      common: common,
      series: [
        {
          name: "Scores Distribution",
          values: values,
        },
      ],
    };
  };

  const getAvgScoresData = (avgScores) => {
    const formattedData = [];
    for (let key in avgScores) {
      formattedData.push({
        name: key,
        value: (avgScores?.[key] || 0).toFixed(0),
      });
    }
    formattedData.sort((a, b) => a.value - b.value);
    return {
      common: formattedData?.map((val) => val?.name),
      series: [
        {
          name: "Average Scores",
          values: formattedData?.map((val) => val?.value),
        },
      ],
    };
  };

  useEffect(() => {
    if (data?.average_data) {
      const distribution = JSON.parse(data?.distribution_data || "{}");
      const leadScores = JSON.parse(data?.average_data || "{}")?.avgScore || {};
      const myColors = new ColorScale({ offset: 1 });
      myColors.init();
      const chart1 = new HorizontalBarChart({
        element: document.getElementById("crewLeadsScoresDistributionGraph"),
        data: getDistibutionData(distribution),
        legend: { isHidden: true },
        colorScale: myColors,
      });
      const chart2 = new HorizontalBarChart({
        element: document.getElementById("crewLeadsAvgScoresGraph"),
        data: getAvgScoresData(leadScores),
        legend: { isHidden: true },
        colorScale: myColors,
      });
      chart1.init();
      chart2.init();
      return () => {
        chart1.destroy();
        chart2.destroy();
      };
    }
  }, [data]);

  return (
    <>
      <div className="tile xl-6 lg-6 md-12 sm-12">
        <div className="header">
          <div className="left">
            <div className="title">Crew Leads Average Scores</div>
          </div>
        </div>
        <div className="content">
          <div className="column">
            {isLoading && <Loading />}
            {data?.message && data?.message !== "Success" && (
              <AlertEDS
                errMsg={data?.message}
                status_code={201}
                close={false}
              />
            )}
            {data?.average_data && (
              <div
                id="crewLeadsAvgScoresGraph"
                className="column xl-6 lg-6 md-12 sm-12"
              ></div>
            )}
          </div>
        </div>
      </div>
      <div className="tile xl-6 lg-6 md-12 sm-12">
        <div className="header">
          <div className="left">
            <div className="title">Crew Leads Scores Distribution</div>
          </div>
        </div>
        <div className="content">
          <div className="column">
            {isLoading && <Loading />}
            {data?.message && data?.message !== "Success" && (
              <AlertEDS
                errMsg={data?.message}
                status_code={201}
                close={false}
              />
            )}
            {data?.average_data && (
              <div
                id="crewLeadsScoresDistributionGraph"
                className="column xl-6 lg-6 md-12 sm-12"
              ></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
