import { Table } from "@eds/vanilla";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { domain_url } from "../../../utils/Constant";
import { callGenerateReport } from "../../../api/reports.api";
import { exportToCSVMethod } from "../../../utils/helperMethod";
import { useSelector } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import ChildReportPopup from "./ChildReportPopup";
import { getSubReports } from "../../../api/EHSReports.api";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import ModelReportPreview from "../../../components/Modal/ModelReportPreview";

export default function ReportWithTotalTimeTakenTable({
  totaltimeData,
  callGetReports,
  getParam,
}) {
  const [totaltime_js, setTotaltimeJS] = useState({});
  const [tableData, setTableData] = useState({});
  const { headersList } = useSelector((state) => state.envVariables);
  const [showSubReportModal, setShowSubReportModal] = useState(false);
  const [loadingVal, setLoadingPop] = useState(false);
  const [getChildReportPopData, setChildReportPopData] = useState({});
  const [errMsgPop, setErrMsgPop] = useState("");
  const [Childreportdata, setChildreportdata] = useState("");
  const [previewpopup, setpreviewpopup] = useState({
    isOpen: false,
  });
  useEffect(() => {
    setTotaltimeJS(_.cloneDeep(totaltimeData));
  }, []);

  const getProducts = () => {
    const products = [];
    for (let tblData in totaltime_js?.["ReportID"]) {
      products.push({
        ReportVersion: [
          totaltime_js["ReportID"][tblData],
          totaltime_js["parent_reportId"][tblData],
          totaltime_js["count"][tblData],
          tblData,
        ],
        Esw_unique_id: totaltime_js?.["esw_unique_id"]?.[tblData] || "",
        "Project ID": totaltime_js?.["Unique ID"]?.[tblData] || "",
        "Report Type": totaltime_js?.["App Type"]?.[tblData] || "",
        "Business Unit": totaltime_js?.["business_unit"]?.[tblData] || "Others",
        "Crew Lead": totaltime_js?.["Crew Lead"]?.[tblData] || "",
        "Additional Crew":
          totaltime_js?.["Crew Names"]?.[tblData].join(", ") || "",
        "Submitted Datetime (CST)":
          totaltime_js?.["Submitted Datetime"]?.[tblData] || "",
        "SNTP Datetime (CST)":
          totaltime_js?.["SNTP Datetime (CST)"]?.[tblData] || "",
        "Total time (mins)": totaltime_js?.["Total time"]?.[tblData] || "",
        key: totaltime_js?.["ReportID"]?.[tblData] || "",
        ReportID: totaltime_js?.["ReportID"]?.[tblData] || "",
        "Site ID": totaltime_js?.["Site_ID"]?.[tblData] || "",
        Customer: totaltime_js?.["customer"]?.[tblData] || "",
        "Crew Lead Email": totaltime_js?.["email"]?.[tblData] || "",
        "Crew Lead Company Name": totaltime_js?.["company"]?.[tblData] || "",
        "Link to Item":
          domain_url +
            "/show_report/" +
            totaltime_js?.["ReportID"]?.[tblData] || "",
        "OHS Status": totaltime_js?.["Ohs_Status"]?.[tblData] || "",
        Market: totaltime_js?.["Market"]?.[tblData] || "",
        Region: totaltime_js?.["Region"]?.[tblData] || "",
        Supplier: totaltime_js?.["Supplier"]?.[tblData] || "",
        State: totaltime_js?.["State"]?.[tblData] || "",
        audit_type:totaltime_js?.["audit_type"]?.[tblData] || "",
      });
    }
    return products;
  };

  const viewSubReportPopup = async (selectedReportID, parentReportID, idx) => {
    let _getParam = _.cloneDeep(getParam);
    [
      "customers",
      "locations",
      "markets",
      "reporter",
      "response_type",
      "state",
      "suppliers",
    ].forEach((i) => delete _getParam[i]);

    const param = {
      ..._getParam,
      childdata: "Y",
      parent_report_Id: parentReportID,
      selected_report_Id: selectedReportID,
    };
    await getSubReports(
      param,
      setChildReportPopData,
      setLoadingPop,
      setErrMsgPop,
      headersList
    );
    setShowSubReportModal(true);
  };

  useEffect(() => {
    let tableDOM = null;
    if (!_.isEmpty(totaltime_js)) {
      tableDOM = document.getElementById("reportwithtotaltimetaken-table");

      if (_.isEmpty(tableData)) {
        const table = new Table(tableDOM, {
          data: getProducts(),
          columns: [
            {
              key: "ReportVersion",
              title: "",
              width: "40px",
              // headerClass: "custom-table-header-1",
              cellClass: "checkVersionTD",
              // cellClass: "custom-table-body-1",
              onCreatedCell: (td, data) => {
                td.innerHTML =
                  data[2] !== 1
                    ? '<span class="tooltip pointer text-center"><button class="viewSubReport btn" id="open-subReports"><i class="icon icon-chevron-right text-lg"></i></button><span class="message right">Click here to check report version</span></span>'
                    : "";
                data[2] !== 1 &&
                  td
                    .querySelector("button.viewSubReport")
                    .addEventListener("click", () => {
                      viewSubReportPopup(data[0], data[1], data[3]);
                    });
              },
            },
            {
              key: "Esw_unique_id",
              title: "Report ID",
              sort: "none",
              cellClass: "text-wrap",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message right">${data}</span></span>`;
              },
            },
            {
              key: "Project ID",
              title: "Project ID",
              sort: "none",
              cellClass: "text-wrap",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message right">${data}</span></span>`;
              },
            },
            {
              key: "Report Type",
              title: "Form",
              sort: "none",
              cellClass: "text-wrap",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message right">${data}</span></span>`;
              },
            },
            {
              key: "Business Unit",
              title: "Business Unit",
              sort: "none",
              cellClass: "text-wrap",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message right">${data}</span></span>`;
              },
            },

            {
              key: "State",
              title: "State",
              sort: "none",
              cellClass: "text-wrap",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message right">${data}</span></span>`;
              },
            },
            {
              key: "Crew Lead",
              title: "Auditor",
              sort: "none",
              cellClass: "text-wrap",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message right">${data}</span></span>`;
              },
            },
            {
              key: "Additional Crew",
              title: "Crew",
              sort: "none",
              // headerClass: "custom-table-header-1",
              cellClass: "custom-table-body-1",
            },

            {
              key: "Submitted Datetime (CST)",
              title: "Submitted Datetime",
              sort: "desc",
              cellClass: "custom-table-body-1",
            },
            {
              key: "Total time (mins)",
              title: "Total time (mins)",
              sort: "none",
              cellClass: "custom-table-body-1",
            },

            {
              key: "Link to Item",
              title: "Details",
              headerClass: "text-center",
              cellStyle: "text-align: center",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<button class="btn icon-btn viewReport primary" title="Review detailed report"><span><i class="icon icon-launch"></i></span></button>`;
                td.querySelector("button.viewReport").addEventListener(
                  "click",
                  async (evt) => {
                    setpreviewpopup({ isOpen: true });
                    setChildreportdata(
                      await callGenerateReport(data, headersList)
                    );
                  }
                );
              },
            },
            {
              key: "Region",
              title: "Region",
              sort: "none",
              // headerClass: "custom-table-header-1",
              cellClass: "custom-table-body-1",
            },
            {
              key: "Customer",
              title: "Customer",
              sort: "none",
              cellClass: "text-wrap",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message left">${data}</span></span>`;
              },
            },
            {
              key: "Supplier",
              title: "Supplier",
              sort: "none",
              cellClass: "text-wrap",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message left">${data}</span></span>`;
              },
            },
            {
              key: "Market",
              title: "Market",
              sort: "none",
              cellClass: "text-wrap",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message left">${data}</span></span>`;
              },
            },
          ],

          sortable: true,
          expandable: false,
          // onCreatedDetailsRow: (td, data) => {
          //   td.innerHTML = `<div>Additional Crew : ${data?.["Additional Crew"]}</div><div>Total time (mins) : ${data?.["Total time (mins)"]}</div>`;
          // },
        });
        table.init();
        document
          .querySelector("#export-reports")
          .addEventListener("click", () => {
            exportToCSVHandler(table.data);
          });
        setTableData(table);
      } else {
        tableData.update(getProducts());
      }
      handleResize();
    }
  }, [totaltime_js]);

  const exportToCSVHandler = (data) => {
    const csvData = [];
    data?.forEach((val) => {
      csvData.push({
        "Project ID": val?.["Project ID"],
        "Site ID": val?.["Site ID"],
        Form: val?.["Report Type"],
        "Business Unit": val?.["Business Unit"],
        State: val?.State,
        Region: val?.Region,
        Market: val?.Market,
        Customer: val?.Customer,
        Supplier: val?.Supplier,
        Auditor: val?.["Crew Lead"],
        "Auditor Email": val?.["Crew Lead Email"],
        "Auditor Company Name": val?.["Crew Lead Company Name"],
        "Additional Crew": val?.["Additional Crew"],
        "Submitted Datetime": val?.["Submitted Datetime (CST)"],
        "Total time (mins)": val?.["Total time (mins)"],
        Details: val?.["Link to Item"],
        "Report ID": val?.["Esw_unique_id"],
        "Audit Type": val?.["audit_type"]
      });
    });
    exportToCSVMethod(csvData, "EHS Site Walk Reports with total time taken");
  };

  const closeAlert = () => {
    setErrMsgPop("");
  };

  const handleResize = () => {
    let _tr = document.querySelector(
      ".EHS-reports-table.stickyHeader thead tr"
    );
    let _top = document.querySelector(".filterDiv").offsetHeight;
    if (_tr) {
      _tr.style.top = _top + "px";
    }
  };

  return (
    <div>
      {loadingVal && (
        <div className="SiderBarLoader">
          <Loading />
        </div>
      )}
      {showSubReportModal && !_.isEmpty(getChildReportPopData) && (
        <ChildReportPopup
          getChildReportPopData={getChildReportPopData}
          setChildReportPopData={setChildReportPopData}
          setShowSubReportModal={setShowSubReportModal}
        />
      )}
      {previewpopup.isOpen && (
        <ModelReportPreview
          setpreviewpopup={setpreviewpopup}
          Childreportdata={Childreportdata}
          setChildreportdata={setChildreportdata}
          setShowSubReportModal={setShowSubReportModal}
        />
      )}
      {errMsgPop !== "" && (
        <AlertEDS
          errMsg={errMsgPop}
          status_code={201}
          close={true}
          closeAlert={closeAlert}
        />
      )}
      <div className="table-top">
        <div className="table-top-left">
          <div className="table-info"></div>
        </div>
        <div className="table-top-right">
          <div className="table-buttons">
            <button className="btn" onClick={() => callGetReports()}>
              Refresh
            </button>
            <button className="btn" id="export-reports">
              Export
            </button>
          </div>
          <div className="table-search">
            <input
              type="text"
              className="with-icon"
              placeholder="Search for..."
            />
            <div className="suffix icon-inside">
              <i className="icon icon-search"></i>
            </div>
          </div>
        </div>
      </div>
      <table
        id="reportwithtotaltimetaken-table"
        className="table dashed EHS-reports-table stickyHeader horizonatalScroll"
      ></table>

      <div className="table-bottom">
        <div className="pagination-group">
          <ul className="pagination"></ul>

          <div className="actions">
            <label className="left">Go to</label>
            <input type="number" min="1" />

            <label className="left">Show</label>
            <div className="select" data-type="single">
              <button className="btn current-options">5</button>

              <div className="options-list">
                <div className="item active">5</div>
                <div className="item">10</div>
                <div className="item">15</div>
                <div className="item">All</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
