import React, { useEffect } from "react";
import { Table } from "@eds/vanilla";
import "../EDSTable/EDSTable.scss";

export const EDSTable = ({
  classType,
  exportToCSV,
  id,
  refresh,
  options,
  tableButtons,
  //tableFilters,
  tableRef,
}) => {
  useEffect(() => {
    const tableDOM = document.getElementById(`${id}-edstable`);

    const table = new Table(tableDOM, options);

    table.init();

    if (tableRef) tableRef.current = table;

    return () => {
      table.destroy();

      tableRef.current = null;
    };
  }, [id, tableRef]);

  return (
    <div>
      <div className="table-top">
        <div className="table-top-left">
          <div className="table-info"></div>
        </div>
        <div className="table-top-right">
          <div className="table-buttons">
            {tableButtons}
            {refresh && (
              <button className="btn" onClick={refresh}>
                Refresh
              </button>
            )}
            {exportToCSV && (
              <button
                className="btn"
                onClick={() => {
                  exportToCSV(tableRef.current.data || []);
                }}
              >
                Export
              </button>
            )}
            {/* Table filters feature have a bug in EDS */}

            {/* {tableFilters && (
              <button className="btn table-filter">
                <i className="icon icon-filter-off"></i>
                Filter
              </button>
            )} */}
          </div>
          <div className="table-search">
            <input
              type="text"
              className="with-icon"
              placeholder="Search for..."
            />
            <div className="suffix icon-inside">
              <i className="icon icon-search"></i>
            </div>
          </div>
          <div className="table-actions">
            <button
              className="btn-icon table-settings"
              id="settings1"
              title="Manage columns"
            >
              <i className="icon icon-settings"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="table-middle"></div>
      <table id={`${id}-edstable`} className={`table ${classType}`}></table>

      <div className="table-bottom">
        <div id={`${id}-table-pagination`} className="pagination-group">
          <ul className="pagination"></ul>
          <div className="actions">
            <label className="left">Go to</label>
            <input type="number" min="1" />
            <label className="left">Show</label>
            <div className="select" data-type="single">
              <button className="btn current-options">5</button>
              <div className="options-list">
                <div className="item active">5</div>
                <div className="item">10</div>
                <div className="item">15</div>
                <div className="item">All</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
