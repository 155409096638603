import { useState } from "react";
import SafetyDialogueTopicTable from "./SafetyDialogueTopicTable";
import Loading from "../../../components/Loading/Loading";
import _ from "lodash";
import "../Settings.scss";
import { uploadSafetyDialogueTopics } from "../../../api/Settings.api";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import { useSelector } from "react-redux";

export default function SafetyDialogueTopic({
  loadingTable,
  errMsgTable,
  safetyTopicsData,
  getSafetyTopicsDetails,
}) {
  const [loadingVal, setLoadingVal] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [safetyTopic, setSafetyTopic] = useState("");
  const [safetyFile, setSafetyFile] = useState({});
  const { headers } = useSelector((state) => state.envVariables);

  const callUpdateSafetyDialogueTopic = async () => {
    const param = { safety_dialogue_topic: safetyTopic, doc_file: safetyFile };
    await uploadSafetyDialogueTopics(param, setLoadingVal, setErrMsg, headers);
    setSafetyTopic("");
  };

  return (
    <>
      <div className="tile column sm-12">
        <div className="header">
          <div className="left">
            <div className="title">Safety Dialogue Topic</div>
          </div>
        </div>
        <div className="content">
          <div className="row form-element-css">
            <div className="column sm-12 md-4 lg-2">
              <label className="required ">
                <span>Safety Dialogue Topic</span>
              </label>
            </div>
            <div className="column sm-12 md-8 lg-4">
              <input
                type="text"
                className="w-100"
                value={safetyTopic}
                placeholder="Enter Safety topic"
                onChange={(e) => {
                  setSafetyTopic(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="row form-element-css">
            <div className="column sm-12 md-4 lg-2">
              <label className="required ">
                <span>Upload File </span>
              </label>
            </div>
            <div className="column sm-12 md-8 lg-4">
              <div className="fileDiv">
                <input
                  id="fileUpload"
                  className="w-100"
                  type="file"
                  onChange={(e) => {
                    setSafetyFile(e.target.files[0]);
                  }}
                />
                <label htmlFor="fileUpload" className="fileUpload"></label>
              </div>
            </div>
          </div>
          <div className="row form-element-css">
            <div className="column sm-12 md-2"></div>
            <div className="column sm-12 md-4">
              <button
                type="button"
                className="btn primary"
                onClick={() => callUpdateSafetyDialogueTopic()}
                disabled={_.isEmpty(safetyTopic) || _.isEmpty(safetyFile?.type)}
              >
                Upload
              </button>
            </div>
          </div>
          <div className="form-element-css">
            {loadingVal ? (
              <Loading />
            ) : (
              errMsg !== "" && (
                <p className="success-message">
                  <AlertEDS
                    errMsg={errMsg}
                    status_code={
                      errMsg === "Data has been saved successfully" ? 200 : 201
                    }
                    close={false}
                  />
                  &emsp; Click on&nbsp;
                  <a href="/settings" title="Click here to Refresh">
                    Refresh
                  </a>
                </p>
              )
            )}
          </div>
        </div>
      </div>
      <div className="tile sm-12 md-12 lg-12 xl-12">
        <div className="header">
          <div className="left">
            <div className="title">Safety Dialogue Topics Table</div>
          </div>
        </div>
        <div className="content">
          {loadingTable ? (
            <Loading />
          ) : (
            <>
              {errMsgTable !== "Success" ? (
                <div className="empty-state">
                  <div className="message">
                    <p>{errMsgTable}</p>
                  </div>
                </div>
              ) : (
                !_.isEmpty(safetyTopicsData) && (
                  <SafetyDialogueTopicTable
                    topicsData={safetyTopicsData}
                    getSafetyTopicsDetails={getSafetyTopicsDetails}
                  />
                )
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
