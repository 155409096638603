import { useEffect } from "react";
import { GlobalNavigation } from "@eds/vanilla";
import { appURL } from "../../utils/Constant";
import "../EDSGlobalNavigation/EDSGlobalNavigation.scss";
import logo from "../../asset/Icons/sw_logo.svg";

export default function EDSGlobalNavigation() {
  useEffect(() => {
    const globalNavDOM = document.querySelector(".globalnav");

    if (globalNavDOM) {
      const gn = new GlobalNavigation(globalNavDOM);
      gn.init();

      const globalNavTrigger = document.querySelector("#global-nav-trigger");

      if (globalNavTrigger) {
        globalNavTrigger.addEventListener("click", () => gn.show());

        return () => {
          globalNavTrigger.removeEventListener("click", () => gn.show());
        };
      }
    }
  }, []);

  return (
    <aside className="globalnav hidden">
      <div className="overlay hidden"></div>
      <div className="tile sm-12">
        {/* <!-- Header --> */}
        <div className="header">
          <div className="left">
            <div className="title">Safe Worker</div>
          </div>
          <div className="right">
            <div className="action close-global-nav">
              <i className="icon icon-cross"></i>
            </div>
          </div>
        </div>

        {/* <!-- Content --> */}
        <div className="content">
          <div className="items">
            <div className="title">Products</div>

            <div
              className="item"
              onClick={() => {
                window.open(appURL, "_blank");
              }}
            >
              <div className="product-symbol">
                <img src={logo} alt="Safe Worker" width={35} height={35} />
              </div>
              <div className="label">
                <span className="description">SW Webapp</span>
                <span className="acronym">SW</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
