import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import _ from "lodash";
import {
  getReports,
  getReportsByReportId,
  getCrewSubmissionCount,
  getTotalTimeTakenStatics,
  // getReportsFiltersData,
} from "../../api/reports.api";
import ReportWithTotalTimeTakenTable from "./Tables/ReportWithTotalTimeTakenTable";
import CrewSubmissionCountTable from "./Tables/CrewSubmissionCountTable";
import TotalTimeTakenStatisticsTable from "./Tables/TotalTimeTakenStatisticsTable";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";

export default function Reports({ formList, validRoles }) {
  const [startDate, setStartDate] = useState(
    new Date(
      moment().local().subtract(1, "weeks").format("YYYY-MM-DD 00:00:00")
    )
  );
  const [endDate, setEndDate] = useState(
    new Date(moment().local().format("YYYY-MM-DD 23:59:59"))
  );
  const [loadingVal, setLoading] = useState({
    reportsTotalTime: true,
    crewSubmissionCount: true,
    totalTimeStatistics: true,
  });
  const [reportsData, setReportsData] = useState({});
  const [crewSubmissionCountData, setCrewSubmissionCountData] = useState({});
  const [totalTimeStatisticsData, setTotalTimeStatisticsData] = useState({});
  const [formIdData, setFormIdData] = useState([]);
  const [getStatusOption, setStatusOption] = useState([1, 4, 6]);
  const [getRequireReview, setRequireReview] = useState([true]);
  const [errMsg, setErrMsg] = useState({
    reportsTotalTime: "",
    crewSubmissionCount: "",
    totalTimeStatistics: "",
  });
  const [getStatusAR, setStatusAR] = useState("");
  const [currentDay, setCurrentDay] = useState(
    moment().local().format("YYYY-MM-DD")
  );
  const [isAutoCall, setIsAutoCall] = useState(false);
  const [businessUnit, setbusinessUnit] = useState([]);
  const { reportId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [getParam, setParam] = useState({});
  const { usergroupId } = useSelector((state) => state.loggedInUser.user);
  const { headers } = useSelector((state) => state.envVariables);
  const features = useSelector((state) => state.userAccess.features);

  let datepickerData = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setFormIdData,
    formIdData,
    getStatusOption,
    setStatusOption,
    businessUnit,
    setbusinessUnit,
    getRequireReview,
    setRequireReview
  };

  const setErrMsgHandler = (val) => {
    setErrMsg((prevState) => ({
      ...prevState,
      [Object.keys(val)[0]]: Object.values(val)[0],
    }));
  };
  const setLoadingHandler = (val) => {
    setLoading((prevState) => ({
      ...prevState,
      [Object.keys(val)[0]]: Object.values(val)[0],
    }));
  };

  const callGetReports = (formId = []) => {
    if (typeof reportId !== "undefined") {
      const currentPath = location.pathname.split("/");
      navigate(`/${currentPath?.[1] || ""}`);
    }
    let param = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      formId: _.isEmpty(formId) ? formIdData : formId,
      response_type: "2",
      status: getStatusOption,
      timeZone: moment.tz.guess(true),
      requireReview : getRequireReview
    };
    if (
      !_.isNull(startDate) &&
      !_.isNull(endDate) &&
      !_.isEmpty(param.formId) &&
      !_.isEmpty(param.status)
    ) {
      // if (
      //   !_.isEmpty(
      //     reportsAccess.find(
      //       (val) =>
      //         val?.Action === "ReportWithTotalTimeTakenTable" &&
      //         val?.UserAccess === "Y"
      //     )
      //   )
      // ) {
      getReports(
        param,
        setLoadingHandler,
        setErrMsgHandler,
        setReportsData,
        headers
      );
      // } else {
      //   setLoadingHandler({ reportsTotalTime: false });
      // }
      if (typeof reportId === "undefined") {
        getCrewSubmissionCount(
          param,
          setLoadingHandler,
          setErrMsgHandler,
          setCrewSubmissionCountData,
          headers
        );
        getTotalTimeTakenStatics(
          param,
          setLoadingHandler,
          setErrMsgHandler,
          setTotalTimeStatisticsData,
          headers
        );
      } else {
        setLoadingHandler({ crewSubmissionCount: false });
        setLoadingHandler({ totalTimeStatistics: false });
      }
    } else {
      setErrMsg({
        reportsTotalTime: "Incorrect date range",
        //  formSubmissionsGraph: "Incorrect date range",
        //  climberGraph: "Incorrect date range",
        crewSubmissionCount: "Incorrect date range",
        totalTimeStatistics: "Incorrect date range",
      });
      setLoading({
        reportsTotalTime: false,
        //   formSubmissionsGraph: false,
        //  climberGraph: false,
        crewSubmissionCount: false,
        totalTimeStatistics: false,
      });
    }
    setParam(param);
  };
  const callGetReportsByReportId = () => {
    setLoading({
      reportsTotalTime: false,
      //  formSubmissionsGraph: false,
      //  climberGraph: false,
      crewSubmissionCount: false,
      totalTimeStatistics: false,
    });
    if (typeof reportId !== "undefined") {
      getReportsByReportId(
        {
          report_id: reportId,
        },
        setStartDate,
        setEndDate,
        setFormIdData,
        setStatusOption,
        setLoadingHandler,
        setErrMsgHandler,
        setReportsData,
        headers
      );
    }
  };

  useEffect(() => {
    if (!_.isEmpty(formList)) {
      if (typeof reportId !== "undefined") {
        callGetReportsByReportId();
        //setFormIdData(["10", "14"]);
      } else {
        let formIdData = formList?.map((form) => form.formId) || [];
        // if (usergroupId === "1") {
        //   let defaultForm = ["10", "14"];
        //   const form_id = formIdData?.filter((item) =>
        //     defaultForm.includes(item)
        //   );
        //   formIdData = form_id;
        // }
        setFormIdData(formIdData);
        ["30"].includes(usergroupId) && setStatusOption([6, 4, 2, 3, 1]);
        setIsAutoCall(true);
      }
    }
  }, [formList, usergroupId, validRoles]);

  useEffect(() => {
      const intervalId = setInterval(() => {
        const newDay = moment().local().format("YYYY-MM-DD");
        if (currentDay !== newDay) {
          setCurrentDay(newDay);
          setStartDate(
            new Date(
              moment(currentDay, "YYYY-MM-DD").format("YYYY-MM-DD 00:00:00")
            )
          );
          setEndDate(
            new Date(moment(newDay, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59"))
          );
          setIsAutoCall(true);
        }
      }, 60000);
      return () => clearInterval(intervalId);
    
  }, [currentDay]);

  useEffect(() => {
    if (isAutoCall) {
      callGetReports();
      setIsAutoCall(false);
    }
  }, [isAutoCall]);

  return (
    !_.isEmpty(features) &&
    !_.isEmpty(formList) && (
      <div className="row">
        <div className="tile filterDiv">
          <CustomDatePickerForm
            formName="reports"
            datepickerData={datepickerData}
            filterData={formList}
            callApply={() => {
              setIsAutoCall(true);
            }}
            disableButtton={
              loadingVal?.reportsTotalTime ||
              loadingVal?.crewSubmissionCount ||
              loadingVal?.totalTimeStatistics
            }
          />
        </div>

        <>
          <div className="tile sm-12 md-12 lg-12 xl-12">
            <div className="header">
              <div className="left">
                <div className="title">Reports</div>
              </div>
            </div>
            <div className="content">
              <div className="column">
                {loadingVal?.reportsTotalTime ? (
                  <Loading />
                ) : (
                  <>
                    {errMsg?.reportsTotalTime !== "Success" ? (
                      <AlertEDS
                        errMsg={errMsg?.reportsTotalTime}
                        status_code={201}
                        close={false}
                      />
                    ) : (
                      <ReportWithTotalTimeTakenTable
                        totaltimeData={
                          typeof reportsData.totaltime_js !== "undefined" &&
                          JSON.parse(reportsData.totaltime_js)
                        }
                        setStatusAR={setStatusAR}
                        getStatusOption={getStatusOption}
                        setReportsData={setReportsData}
                        callGetReports={() => {
                          setIsAutoCall(true);
                        }}
                        getParam={getParam}
                        formList={formList}
                        // reportAccessDetails={rolesMap}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          {typeof reportId === "undefined" && (
            <>
              <div className="tile sm-12 md-12 lg-6">
                <div className="header">
                  <div className="left">
                    <div className="title">Crew Participation Count</div>
                  </div>
                </div>
                <div className="content">
                  <div className="column">
                    {loadingVal?.crewSubmissionCount ? (
                      <Loading />
                    ) : (
                      <>
                        {errMsg?.crewSubmissionCount !== "Success" ? (
                          <AlertEDS
                            errMsg={errMsg?.crewSubmissionCount}
                            status_code={201}
                            close={false}
                          />
                        ) : (
                          <CrewSubmissionCountTable
                            crewsubmsn_group_js={
                              typeof crewSubmissionCountData?.crewsubmsn_group_js !=
                                "undefined" &&
                              JSON.parse(
                                crewSubmissionCountData?.crewsubmsn_group_js
                              )
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="tile sm-12 md-12 lg-6">
                <div className="header">
                  <div className="left">
                    <div className="title">
                      Total Time Taken - Statistics (minutes)
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="column">
                    {loadingVal?.totalTimeStatistics ? (
                      <Loading />
                    ) : (
                      <>
                        {errMsg?.totalTimeStatistics !== "Success" ? (
                          <AlertEDS
                            errMsg={errMsg?.totalTimeStatistics}
                            status_code={201}
                            close={false}
                          />
                        ) : (
                          <TotalTimeTakenStatisticsTable
                            totaltime_stats_js={
                              typeof totalTimeStatisticsData.totaltime_stats_js !=
                                "undefined" &&
                              JSON.parse(
                                totalTimeStatisticsData.totaltime_stats_js
                              )
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </div>
    )
  );
}

export function JHAReports() {
  const forms = useSelector((state) => state.userAccess.forms);

  return (
    <Reports
      formList={forms?.safety_forms?.map(
        (i) =>
          i.value && {
            formId: i.formid,
            form_name: i.formname,
            enforce_manual_review: i.enforce_manual_review,
          }
      )}
      validRoles={[
        "CONSOLE_SAFETY_FORM_VIEWER",
        "CONSOLE_SAFETY_FORM_APPROVALS",
        "CONSOLE_SAFETYFORM_SUPPLIER"
      ]}
    />
  );
}

export function SiteWalkReports() {
  const forms = useSelector((state) => state.userAccess.forms);

  return (
    <Reports
      formList={forms?.audit_forms?.map(
        (i) =>
          i.value && {
            formId: i.formid,
            form_name: i.formname,
            enforce_manual_review: i.enforce_manual_review,
          }
      )}
      validRoles={[
        "CONSOLE_SITE_SAFETYFORM_VIEWER",
        "CONSOLE_SITE_SAFETYFORM_APPROVALS",
        "CONSOLE_SAFETYFORM_SUPPLIER"
      ]}
    />
  );
}
