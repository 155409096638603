import "../TaskItem/TaskItem.scss";

export const TaskItem = ({ task, deleteTask }) => {
  return (
    <div className="task-group-item">
      {task.text}
      <div>
        {deleteTask && (
          <button className="btn icon-btn" onClick={() => deleteTask(task.id)}>
            <i className="icon icon-trashcan"></i>
          </button>
        )}
      </div>
    </div>
  );
};
