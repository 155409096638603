import React, { useEffect } from "react";
import { Dialog } from "@eds/vanilla";

export const EDSDialog = ({
  dataTrigger,
  dataType,
  dialogBody,
  dialogBottom,
  dialogRef,
  dialogTitle,
  id,
  cancelHandler = () => {},
}) => {
  useEffect(() => {
    const dialogDOM = document.getElementById(`${id}-EDS-dialog`);
    const dialog = new Dialog(dialogDOM);
    dialog.init();

    if (dialogRef) dialogRef.current = dialog;

    if (dataTrigger) {
      const triggerDOM = document.querySelector(dataTrigger);

      if (triggerDOM) {
        triggerDOM.addEventListener("click", () => {
          dialog.show();
        });
      }
    }

    return () => {
      if (dataTrigger) {
        const triggerDOM = document.querySelector(dataTrigger);

        if (triggerDOM) {
          triggerDOM.removeEventListener("click", () => {
            dialog.show();
          });
        }
      }

      dialog.destroy();
    };
  }, [id, dialogRef]);

  return (
    <div id={`${id}-EDS-dialog`} className="dialog" data-type={dataType}>
      <div className="content">
        <div className="top">
          {dialogTitle}
          <div className="right">
            <button
              className="btn-icon"
              data-close="true"
              onClick={cancelHandler}
            >
              <i className="icon icon-cross"></i>
            </button>
          </div>
        </div>
        <div className="body">{dialogBody}</div>
        {dialogBottom && <div className="bottom">{dialogBottom}</div>}
      </div>
    </div>
  );
};
