import { useEffect, useRef } from "react";
import { EDSDialog } from "../../components/EDSDialog/EDSDialog";

export default function ModalPreview({
  showMdl,
  setShowMdl,
  dataMdl,
  setDataMdl,
}) {
  const dialogRef = useRef(null);

  useEffect(() => {
    if (showMdl) dialogRef.current.show();
    else dialogRef.current.hide();
  }, [showMdl]);

  const onCloseHandler = () => {
    setShowMdl(false);
    setDataMdl({
      showPreviewCarousal: true,
      heading: "Preview Attachments",
      body: "",
      showClose: true,
      saveBtnName: "",
      picUrl: [],
      updatedPicUrl: [],
    });
    dialogRef.current.hide();
  };

  const dialogTitle = <div className="title">{dataMdl.heading}</div>;

  const dialogBody = (
    <div className="row">
      <div className="tile sm-12 md-12 lg-12 xl-12">
        <p>Original Attachments :</p>
        {dataMdl?.picUrl?.url?.length > 0 ? (
          <ul className="list-group">
            {dataMdl?.picUrl?.url.map((url, ind) => (
              <li key={ind} className="list-group-item">
                {dataMdl?.picUrl?.date?.[ind] + " : "}
                <a href={url} className="link" target="_blank" rel="noreferrer">
                  {dataMdl?.picUrl?.name?.[ind]}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center text-warning">No Attachments </div>
        )}
      </div>
      <div className="tile sm-12 md-12 lg-12 xl-12">
        <p>Additional Attachments :</p>
        {dataMdl?.updatedPicUrl?.url?.length > 0 ? (
          <ul className="list-group">
            {dataMdl?.updatedPicUrl?.url?.map((url, ind) => (
              <li key={ind} className="list-group-item">
                {dataMdl?.updatedPicUrl?.date?.[ind] + " : "}
                <a href={url} className="link" target="_blank" rel="noreferrer">
                  {dataMdl?.updatedPicUrl?.name?.[ind]}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center text-warning">No Attachments </div>
        )}
      </div>
      <div className="tile sm-12 md-12 lg-12 xl-12">
        <div className="text-end">
          {dataMdl.showClose && (
            <button className="btn" onClick={onCloseHandler}>
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <EDSDialog
      dataType="simple"
      dialogBody={dialogBody}
      dialogRef={dialogRef}
      dialogTitle={dialogTitle}
      id="deficiencies-attachments"
    />
  );
}
