import React, { useState, useEffect, useRef } from "react";
import { ColorScale, Donut } from "@eds/vanilla";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";

export default function OverriddenCountPerPPE({ overriddengrp_js }) {
  const [isPercentageMode, setIsPercentageMode] = useState(false);
  const chartRef = useRef(null);

  const getChartData = () => {
    const ppeGraph = overriddengrp_js["ppe"];
    const overriddenGraph = overriddengrp_js["overridden"];
    const formattedData = [];
    for (const property in ppeGraph) {
      const count = overriddenGraph[property];
      formattedData.push({
        name: ppeGraph[property],
        values: [count],
      });
    }
    return formattedData;
  };

  useEffect(() => {
    if (Object.values(overriddengrp_js?.ppe || {}).length) {
      const myColors = new ColorScale({ offset: 1 });
      myColors.init();
      const data = getChartData();

      const chart = new Donut({
        element: document.getElementById("overriddenCountPerPPEGraph"),
        data: { series: data },
        showValue: true,
        showAbsoluteValue: !isPercentageMode,
        unit: "Count",
        colorScale: myColors,
      });
      chart.init();
      chartRef.current = chart;
    }
  }, [overriddengrp_js]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.showAbsoluteValue = !isPercentageMode;
      chartRef.current.unit = "Count";
      chartRef.current.draw();
    }
  }, [isPercentageMode]);

  const handleToggleSwitch = () => {
    setIsPercentageMode(!isPercentageMode);
  };

  return (
    <div>
      {Object.values(overriddengrp_js?.ppe || {}).length ? (
        <div id="overriddenCountPerPPEGraph">
          <br />
          <div>
            <label className="switch">
              <input
                type="checkbox"
                onChange={handleToggleSwitch}
                checked={isPercentageMode}
              />
              <i className="ball"></i>
              <span
                data-enabled="Percentage"
                data-disabled="Count"
                onClick={handleToggleSwitch}
              ></span>
            </label>
          </div>
        </div>
      ) : (
        <AlertEDS errMsg={"No Data Found"} status_code={201} close={false} />
      )}
    </div>
  );
}
