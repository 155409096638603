import RolesData from '../Roles/Roles'
function RolesInfo({ setShowRolesInfo }) {
    const onCloseHandler = () => {
        setShowRolesInfo(false);
    };
    return (
        <div>
            <div className="dialog RoleInfo show modal-md" data-trigger="#roleInfo" data-type="simple">
                <div className="content">
                    <div className="top">
                        <div className="title">Roles Information</div>
                        <div className="right">
                            <button className="btn-icon" data-close="true" onClick={() => onCloseHandler()}>
                                <i className="icon icon-cross"></i>
                            </button>
                        </div>
                    </div>
                    <div className="body">
                        <RolesData />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default RolesInfo;