import React, { useEffect } from "react";
import moment from "moment";
import { ColorScale, VerticalBarChartStacked } from "@eds/vanilla";
export default function UserStatsStackGraph({ graphData }) {
  const getChartData = () => {
    const dateGraph = graphData?.["Date"] || [];
    const userTypeGraph = graphData?.["User Type"] || [];
    const countGraph = graphData?.["count"] || [];
    const formattedData = [];
    for (const property in dateGraph) {
      const index = formattedData.findIndex(
        (val) =>
          val.date ===
          moment(dateGraph[property], "DD/MM/YYYY").format("YYYY-MM-DD")
      );
      if (index < 0) {
        if (userTypeGraph[property] === "Crew Lead") {
          formattedData.push({
            date: moment(dateGraph[property], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            crewLeadCount: countGraph[property],
            approverCount: 0,
            otherCount: 0,
          });
        } else if (userTypeGraph[property] === "Approver") {
          formattedData.push({
            date: moment(dateGraph[property], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            crewLeadCount: 0,
            approverCount: countGraph[property],
            otherCount: 0,
          });
        } else {
          formattedData.push({
            date: moment(dateGraph[property], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            crewLeadCount: 0,
            approverCount: 0,
            otherCount: countGraph[property],
          });
        }
      } else {
        if (userTypeGraph[property] === "Crew Lead") {
          formattedData[index].crewLeadCount += countGraph[property];
        } else if (userTypeGraph[property] === "Approver") {
          formattedData[index].approverCount += countGraph[property];
        } else {
          formattedData[index].otherCount += countGraph[property];
        }
      }
    }
    formattedData.sort((a, b) => {
      if (a.date > b.date) return 1;
      else if (a.date < b.date) return -1;
      else return 0;
    });
    return formattedData;
  };

  useEffect(() => {
    const myColorScale = new ColorScale({ reverse: true });
    myColorScale.init();
    const data = getChartData();
    const chart = new VerticalBarChartStacked({
      element: document.getElementById("userStatsStackGraph"),
      data: {
        common: data.map((val) => val?.date),
        series: [
          {
            name: "Crew Lead",
            values: data.map((val) => val?.crewLeadCount),
          },
          {
            name: "Reviewer",
            values: data.map((val) => val?.approverCount),
          },
        ],
      },
      y: { unit: "Count" },
      colorScale: myColorScale,
    });
    chart.init();
    return () => {
      chart.destroy();
    };
  }, []);

  return <div id="userStatsStackGraph"></div>;
}
