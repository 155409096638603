import { useEffect } from "react";
import { Select } from "@eds/vanilla";
import "../EDSCustomMultiselect/EDSCustomMultiselect.scss";

export default function EDSCustomMultiselect({
  setStatusOption,
  formDetailsUG,
  defaultValues,
  filterName,
  dataType,
  disabled,
  selectRef,
}) {
  useEffect(() => {
    const selectDOM = document.getElementById(
      "eds-select-dropdown-custom-" + filterName
    );
    const select = new Select(selectDOM);
    select.init();
    if (selectRef) selectRef.current = select;
    if ((formDetailsUG.some((val) => val.formId === defaultValues[0])) || (formDetailsUG.some((val) => val.form_name === defaultValues[0]))) {
      setStatusOption(defaultValues);
      select.value = defaultValues;
    }

    const handleChange = (event) => {
      event.preventDefault();
      setStatusOption(select.value);
      event.stopPropagation();
    };

    selectDOM.addEventListener("selectOption", handleChange);

    return () => {
      selectDOM.removeEventListener("selectOption", handleChange);
      select.destroy();
    };
  }, []);

  return (
    <div
      id={"eds-select-dropdown-custom-" + filterName}
      className={disabled ? "select disabled" : "select"}
      data-type={dataType}
    >
      {["input-multi", "input-single"].includes(dataType) ? (
        <div className="clickable current-options">
          <input type="text" placeholder="Select option" />
        </div>
      ) : (
        <button className="btn current-options">Select option</button>
      )}
      <div className="options-list">
        {formDetailsUG?.map((val, ind) => {
          if (typeof val?.formId === "string" ) {
            return (
              <div
                className={"item"}
                data-value={val?.formId}
                key={ind + val?.formId}
              >
                {val?.form_name}
              </div>)
          } else {
            return (
              <div
                className={"item"}
                data-value={val?.form_name}
                key={ind}
              >
                {val?.form_name}
              </div>
            )
          }
        })}
      </div>
    </div>
  );
}
