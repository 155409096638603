import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChartStacked } from "@eds/vanilla";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import Loading from "../../../components/Loading/Loading";
import { useAuditsByCustomerMutation } from "../../../redux/services/sitewalksAnalyticsApi";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";

export const AuditsByCustomer = ({
  param,
  isAutoCall,
  getAnalyticsGraphsToggle,
  setAnalyticsGraphsToggle,
  validationMsg,
  validateFilterValues,
}) => {
  const [auditsByCustomer, { data: { data, message } = {}, isLoading, error }] =
    useAuditsByCustomerMutation();

  const errorMessage =
    validationMsg ||
    (message !== "Success" && message) ||
    (error && "Internal Server Error. Please try again");

  const handleIsOpen = (event) => {
    event.preventDefault();

    const isValid = validateFilterValues("audit_count_by_customer");

    isValid &&
      !getAnalyticsGraphsToggle.audit_count_by_customer &&
      auditsByCustomer(JSON.stringify(param));

    setAnalyticsGraphsToggle((val) => ({
      ...val,
      audit_count_by_customer: !val.audit_count_by_customer,
    }));
  };

  useEffect(() => {
    const isValid = validateFilterValues("audit_count_by_customer");

    isValid &&
      isAutoCall &&
      getAnalyticsGraphsToggle.audit_count_by_customer &&
      auditsByCustomer(JSON.stringify(param));
  }, [isAutoCall]);

  useEffect(() => {
    if (data?.length) {
      const chartData = (() => {
        const locations = param.region || [];
        const groupByType = Object.groupBy(data, ({ customer }) => customer);

        const formatedData = {
          common: locations,
          series: Object.entries(groupByType).map(([name, val]) => {
            const values = locations.map(
              (location) =>
                val.find((obj) => obj.region === location)?.count || 0
            );
            return {
              name,
              values,
            };
          }),
        };

        return formatedData;
      })();

      const chartHeight = chartData.common.length * 60;
      const mycolorScale = new ColorScale({ reverse: true });

      const chart = new HorizontalBarChartStacked({
        element: document.getElementById("auditsByCustomer-graph"),
        data: chartData,
        x: { unit: "Count" },
        height: chartHeight,
        colorScale: mycolorScale,
      });

      mycolorScale.init();

      chart.init();

      return () => {
        chart.destroy();
      };
    }
  }, [data]);

  return (
    <li
      tabIndex={6}
      className={`tile mb-sm accordion-item ${
        getAnalyticsGraphsToggle?.audit_count_by_customer ? "opened" : ""
      }`}
    >
      <div className="title stickyTileHeader" onClick={handleIsOpen}>
        <div className="header p-sm">
          <div className="left">Audit Count by Customer</div>
        </div>
      </div>
      <div className="content">
        {isLoading && <Loading />}

        {errorMessage && (
          <AlertEDS errMsg={errorMessage} status_code={201} close={false} />
        )}

        {data?.length && !errorMessage ? (
          <>
            <CustomGraphActions
              nodeId={"auditsByCustomer-graph"}
              fileName={"AuditsByCustomer-Graph"}
              data={data || []}
            />
            <div id="auditsByCustomer-graph"></div>
          </>
        ) : null}
      </div>
    </li>
  );
};
