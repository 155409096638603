import "./CustomGraphActions.scss";
import useHtmlToImage from "../../CustomHooks/useHtmlToImage";
import { exportToCSVMethod } from "../../utils/helperMethod";
export default function CustomGraphActions(props) {
  const [takeScreenShot] = useHtmlToImage({
    nodeId: props.nodeId || "",
    fileName: props.fileName || "",
  });

  return (
    <div className="tile p-0">
      <div className="top">
        <div className="right">
          <div className="action" onClick={takeScreenShot}>
            <span className="tooltip pointer">
              <i className="icon icon-camera-photo"></i>
              <span className="message left blue">Screenshot</span>
            </span>
          </div>
          <div
            className="action"
            onClick={() => {
              exportToCSVMethod(props.data, props.fileName);
            }}
          >
            <span className="tooltip pointer">
              <i className="icon icon-download-save"></i>{" "}
              <span className="message left blue">Export to CSV</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
