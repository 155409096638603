import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: "",
  headers: {},
  headersList: { "Content-Type": "application/json" },
};

export const envVariablesSlice = createSlice({
  name: "envVariables",
  initialState,
  reducers: {
    updateTokenDetails: (state = initialState, action) => {
      const token = action.payload;
      state.accessToken = token;
      state.headers = {
        ...state.headers,
        ...{ Authorization: "Bearer " + token },
      };
      state.headersList = {
        ...state.headersList,
        ...{ Authorization: "Bearer " + token },
      };
    },
    resetEnvVariables: (state) => {
      state.accessToken = "";
      state.headers = {};
      state.headersList = { "Content-Type": "application/json" };
    },
  },
});

export const { updateTokenDetails, resetEnvVariables } =
  envVariablesSlice.actions;

export default envVariablesSlice.reducer;
