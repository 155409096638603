import AlertEDS from "../../components/AlertWarn/AlertEDS";
import { Dialog } from "@eds/vanilla";
import { Table } from "@eds/vanilla";
import { useEffect, useState } from "react";
import { emailValidation, phoneValidation } from "../../utils/helperMethod";
import {
  getLoneWorkerAddSenderList,
  getLoneWorkerRemoveSenderList,
} from "../../api/loneWorker.api";
import Loading from "../../components/Loading/Loading";
import CountryCode from "./countryCode.json";
import { Select } from "@eds/vanilla";
import { useSelector } from "react-redux";
export default function ModalList({
  getLoneList,
  setModalShow,
  getModalShow,
  loadingVal,
  errMsg,
  handleSettings,
}) {
  const [getCountryRef, setCountryRef] = useState(false);
  const [getLoading, setLoading] = useState(false);
  const [getResponce, setResponce] = useState({
    status_code: "",
    message: "",
  });
  const [getError, setError] = useState({
    emailID: "",
    phoneNo: "",
  });
  const [getInput, setInput] = useState({
    emailID: "",
    countryCode: "",
    phoneNo: "",
  });
  const { headers } = useSelector((state) => state.envVariables);
  useEffect(() => {
    const dialogs = document.querySelectorAll(".dialog");
    if (dialogs) {
      Array.from(dialogs).forEach((dialogDOM) => {
        const dialog = new Dialog(dialogDOM);
        dialog.init();
      });
    }
  }, []);

  const setResponceHandler = (val) => {
    setResponce((prevState) => ({
      ...prevState,
      [Object.keys(val)[0]]: Object.values(val)[0],
      [Object.keys(val)[1]]: Object.values(val)[1],
    }));
  };

  const onCloseHandler = () => {
    setModalShow(false);
  };

  const handleDelete = async (evt) => {
    const mail = evt.target.closest("button").getAttribute("data-email");
    const phone = evt.target.closest("button").getAttribute("data-phone");
    let param = {
      mail: mail,
      number: phone === "undefined" ? "" : phone,
      // usergroup: UserGroupId
    };
    await getLoneWorkerRemoveSenderList(
      setLoading,
      param,
      setResponceHandler,
      closeAlert,
      headers
    );
    handleSettings();
  };

  const handleSubmit = async (e) => {
    let param;
    if (
      getError.phoneNo === "" &&
      getInput.phoneNo !== "" &&
      getInput.countryCode !== ""
    ) {
      param = {
        mail: getInput.emailID,
        number: getInput.countryCode.split("-")[0] + getInput.phoneNo,
        // usergroup: UserGroupId
      };
    } else {
      param = {
        mail: getInput.emailID,
        number: "",
        // usergroup: UserGroupId
      };
    }
    await getLoneWorkerAddSenderList(
      setLoading,
      param,
      setResponceHandler,
      closeAlert,
      setCountryRef,
      headers
    );
    handleSettings();
    setInput({ emailID: "", countryCode: "", phoneNo: "" });
    setError({ emailID: "", phoneNo: "" });
    const allElements = document.querySelectorAll(".customInput");
    allElements.forEach((element) => {
      element.classList.remove("valid");
    });
  };

  const closeAlert = () => {
    setTimeout(() => {
      setResponce({ status_code: "", message: "" });
    }, 2000);
  };

  useEffect(() => {
    let products = [];
    // Object.values(getLoneList['emailsId']).filter((i, k) => {
    for (let k = Object.keys(getLoneList["email"]).length - 1; k >= 0; k--) {
      products.push({
        Email_Id: getLoneList["email"][k],
        Phone_no: getLoneList["number"][k],
      });
    }
    const tableDOM = document.querySelector("#actions-example");
    const table = new Table(tableDOM, {
      data: products,
      columns: [
        {
          key: "Email_Id",
          title: "Email ID",
        },
        {
          key: "Phone_no",
          title: "Phone No.",
        },
        {
          key: "App_Notification",
          title: "App Notification",
          cellClass: "App_Notification",
          onCreatedCell: (td, data) => {
            td.innerHTML = `<input type="checkbox" checked disabled id="actions-example-master"><label class="checkbox select-row" for="actions-example-master"></label>`;
          },
        },
      ],
      maxHeight: "300px",
      actions: true,
      onCreatedActionsCell: (td, data) => {
        td.innerHTML = `<button class="btn-icon delete" data-email=${
          data.Email_Id
        } data-phone=${
          data.Phone_no === "" ? "" : data.Phone_no
        }><i class="icon icon-trashcan"></i></button>`;
        td.querySelector("button.delete").addEventListener("click", (evt) => {
          handleDelete(evt);
        });
      },
    });
    table.init();
  }, [getLoneList]);

  const countryCode = (e) => {
    setInput({ ...getInput, ["countryCode"]: e });
  };

  const countryCodeEdit = (e) => {
    setInput({ ...getInput, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!getCountryRef) {
      const selects = document.querySelectorAll(".countryCode");
      if (selects) {
        Array.from(selects).forEach((selectDOM) => {
          const select = new Select(selectDOM);
          select.init();
        });
      }
    }
  }, [getCountryRef]);

  return (
    <>
      <div
        className="dialog loneWorkerList show"
        data-trigger="#open-modalList"
        data-type="fullscreen"
      >
        <div className="content">
          <div className="top">
            <div className="title">Lone Workers Panic List</div>
            <div className="right">
              <button
                className="btn-icon"
                data-close="true"
                onClick={() => onCloseHandler()}
              >
                <i className="icon icon-cross"></i>
              </button>
            </div>
          </div>
          <div className="body">
            <div className="row">
              {errMsg !== "Success" && errMsg !== "" ? (
                <AlertEDS errMsg={errMsg} status_code="error" close={false} />
              ) : (
                <>
                  <div className="tile customRow">
                    <div className="header">
                      <div className="left">
                        <div className="title">Add Recipient</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="column sm-12 md-5 lg-5 xl-5">
                        <label htmlFor="input_email" className="required">
                          Enter Email ID
                        </label>
                        <input
                          id="input_email"
                          name="emailID"
                          value={getInput.emailID}
                          className="customInput validation"
                          type="text"
                          placeholder="Enter Email ID"
                          onChange={(e) => {
                            setInput({
                              ...getInput,
                              [e.target.name]: e.target.value,
                            });
                            if (e.target.value === "") {
                              setError({
                                ...getError,
                                [e.target.name]: "This field is required",
                              });
                              e.target.classList.add("invalid");
                              e.target.classList.remove("valid");
                            } else if (emailValidation(e)) {
                              setError({
                                ...getError,
                                [e.target.name]:
                                  "Please enter a valid Email ID",
                              });
                              e.target.classList.add("invalid");
                              e.target.classList.remove("valid");
                            } else {
                              setError({ ...getError, [e.target.name]: "" });
                              e.target.classList.remove("invalid");
                              e.target.classList.add("valid");
                            }
                          }}
                        />
                        <span className="validation-msg">
                          {getError.emailID !== "" && getError.emailID}
                        </span>
                      </div>

                      <div className="column sm-12 md-5 lg-5 xl-5">
                        <label htmlFor="input_phoneno" className="">
                          Enter Phone no.
                        </label>
                        <div className="telno">
                          {!getCountryRef ? (
                            <div
                              className="select countryCode"
                              data-type="input-single"
                            >
                              <div className="clickable current-options">
                                <input
                                  type="text"
                                  id="countryCD"
                                  name="countryCode"
                                  placeholder="Country Code"
                                  onChange={(e) => countryCodeEdit(e)}
                                />
                              </div>
                              <div className="options-list">
                                {CountryCode.map((i, k) => {
                                  return (
                                    <div
                                      className="item"
                                      onClick={() =>
                                        countryCode(i.CODE + "-" + i.NAME)
                                      }
                                      key={k}
                                    >
                                      {i.CODE + "-" + i.NAME}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            <input
                              type="text"
                              value={getInput.countryCode}
                              readOnly
                              className="dummayCountry"
                              name="countryCode"
                              placeholder="Country Code"
                            />
                          )}

                          <div className="phoneNoCont">
                            <input
                              id="input_phoneno"
                              disabled={
                                getInput.countryCode === "" ? true : false
                              }
                              name="phoneNo"
                              value={getInput.phoneNo}
                              className="customInput validation"
                              type="text"
                              placeholder="Enter Phone no."
                              onChange={(e) => {
                                setInput({
                                  ...getInput,
                                  [e.target.name]: e.target.value,
                                });
                                if (e.target.value === "") {
                                  setError({
                                    ...getError,
                                    [e.target.name]: "This field is required",
                                  });
                                  e.target.classList.add("invalid");
                                  e.target.classList.remove("valid");
                                } else if (phoneValidation(e)) {
                                  setError({
                                    ...getError,
                                    [e.target.name]:
                                      "Please enter a valid phone no.",
                                  });
                                  e.target.classList.add("invalid");
                                  e.target.classList.remove("valid");
                                } else {
                                  setError({
                                    ...getError,
                                    [e.target.name]: "",
                                  });
                                  e.target.classList.remove("invalid");
                                  e.target.classList.add("valid");
                                }
                              }}
                            />

                            <span className="validation-msg">
                              {getError.phoneNo !== "" && getError.phoneNo}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="column sm-12 md-2 lg-2 xl-2 customAlign">
                        <button
                          className="btn"
                          disabled={
                            getError.emailID !== "" || getInput.emailID === ""
                          }
                          onClick={(e) => handleSubmit(e)}
                        >
                          Submit
                        </button>
                      </div>
                      {getResponce.message !== "" && (
                        <div className="column sm-12">
                          <AlertEDS
                            errMsg={getResponce.message}
                            status_code={getResponce.status_code}
                            close={false}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {loadingVal ? (
                    <Loading />
                  ) : (
                    <div className="tile">
                      <div className="header">
                        <div className="left">
                          <div className="title">Loneworker List</div>
                        </div>
                      </div>
                      <div className="table-top">
                        <div className="table-top-left">
                          <div className="table-info"></div>
                        </div>
                      </div>
                      <table
                        id="actions-example"
                        className="table dashed"
                      ></table>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
