import { RangeSlider } from "@eds/vanilla";
import { useEffect } from "react";
export default function EDSMultiRangeSlider({
  id,
  settings,
  defaultValues,
  setValue,
}) {
  useEffect(() => {
    const updateValue = (event) => {
      setValue(event.target.values);
    };
    const rangeDOM = document.getElementById(id);
    const range = new RangeSlider(rangeDOM, { units: settings.units });
    rangeDOM.addEventListener("change", updateValue);
    range.init();
    return () => {
      rangeDOM.removeEventListener("change", updateValue);
      range.destroy();
    };
  }, [id, settings.min, settings.max, settings.step, settings.units]);

  return (
    <input
      id={id}
      className={settings.showThresholds ? "range thresholds" : "range"}
      type="range"
      min={settings.min}
      max={settings.max}
      step={settings.step}
      multiple
      values={defaultValues.join(",")}
    />
  );
}
