import { NavLink } from "react-router-dom";
export default function AppLinks() {
    return (
        <div className="row appLinks">
            <div className="tile sm-12">
                <div className="header">
                    <div className="left">
                        <div className="title">Install mobile application</div>
                        <p className="subTitle">Safe Worker AI is accessible on both Android and iOS platforms. Download the app to submit Safety Forms, or carry out Inspections on the go.</p>
                        </div>
                </div>
                <div className="content">
                    <NavLink to='https://play.google.com/store/apps/details?id=net.ericsson.safework&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" className="appLink">
                        <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' className="appImg" /></NavLink>

                    <NavLink className="appLink" to="https://apps.apple.com/us/app/ericsson-safe-worker/id6446954742?itsct=apps_box_badge&amp;itscg=30200" target="_blank" >
                        <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1685404800" alt="Download on the App Store" className="appImg iosApp"  /></NavLink>
                </div>
            </div>
        </div>
    )
}