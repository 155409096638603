import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { domain_url } from "../../utils/Constant";

export const AIinferenceApi = createApi({
  reducerPath: "AIinferenceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: domain_url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().envVariables.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    imageUrlListForm: builder.mutation({
      query: (body) => ({
        url: "/upload_multipleimg",
        method: "POST",
        body,
      }),
    }),
    getHazardAIData: builder.mutation({
      query: (payload) => ({
        url: "/get_hazard_ai_data",
        method: "POST",
        body: payload,
      }),
    }),
    getPpeAIData: builder.mutation({
      query: (payload) => ({
        url: "/get_all_ppe_ai_data",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useImageUrlListFormMutation,
  useGetHazardAIDataMutation,
  useGetPpeAIDataMutation,
} = AIinferenceApi;

export default AIinferenceApi;
