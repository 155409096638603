import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import EDSMultiRangeSlider from "../../components/EDSMultiRangeSlider/EDSMultiRangeSlider";
import { useWeatherConfiguration } from "../../api/Configuration.api";
import {
  convertToCelsius,
  convertToFahrenheit,
} from "../../utils/helperMethod";

export default function WeatherLimits() {
  const [reloadToogle, setReloadToggle] = useState(true);
  const [getTemperatureLimits, setTemperatureLimits] = useState([]);
  const [getWindLimits, setWindLimits] = useState([]);
  const [getTemperatureToggle, setTemperatureToggle] = useState(true);
  const [getWindToggle, setWindToggle] = useState(true);
  const [getLoading, setLoading] = useState({
    getCall: false,
    updateCall: false,
  });
  const [successMessage, setsuccessMessage] = useState({
    errMsg: "Success",
    status_code: 200,
  });
  const [WeatherConfigDetails] = useWeatherConfiguration();
  const { headersList } = useSelector((state) => state.envVariables);

  useEffect(() => {
    async function fetchData() {
      setLoading((val) => ({ ...val, getCall: true }));
      await WeatherConfigDetails().then((data) => {
        const temperature = data?.data?.weather_settings?.temperature || {
          max: 0,
          min: 0,
          unit: "C",
        };
        const wind_gust = data?.data?.weather_settings?.wind_gust || {
          max: 0,
          min: 0,
          unit: "mi/h",
        };
        setTemperatureLimits([temperature.min, temperature.max]);
        setWindLimits([wind_gust.min, wind_gust.max]);
        setTemperatureToggle(temperature.unit === "C");
        setWindToggle(wind_gust.unit === "mi/h");
        setLoading((val) => ({ ...val, getCall: false }));
        setsuccessMessage({
          errMsg:
            data.message === "Successfully fetch data"
              ? "Success"
              : data.message,
          status_code: data.status_code,
        });
      });
    }
    if (reloadToogle) {
      fetchData();
      setReloadToggle((val) => !val);
    }
  }, [reloadToogle]);

  const submitWeatherLimits = async () => {
    const payload = {
      weather_settings: {
        temperature: {
          min: getTemperatureLimits[0],
          max: getTemperatureLimits[1],
          unit: getTemperatureToggle ? "C" : "F",
        },
        wind_gust: {
          min: getWindLimits[0],
          max: getWindLimits[1],
          unit: getWindToggle ? "mi/h" : "km/h",
        },
      },
    };
    setLoading((val) => ({ ...val, updateCall: true }));
    const options = {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: headersList,
    };
    await WeatherConfigDetails(options).then((data) => {
      setLoading((val) => ({ ...val, updateCall: false }));
      setsuccessMessage({
        errMsg: data.message,
        status_code: data.status_code,
      });
      setTimeout(() => {
        setsuccessMessage({ errMsg: "Success", status_code: 200 });
      }, 5000);
    });
  };

  const handleTemperatureToggle = () => {
    if (getTemperatureToggle) {
      setTemperatureLimits((limits) =>
        limits.map((val) => convertToFahrenheit(val))
      );
    } else {
      setTemperatureLimits((limits) =>
        limits.map((val) => convertToCelsius(val))
      );
    }
    setTemperatureToggle((val) => !val);
  };

  const handleWindToggle = () => {
    if (getWindToggle) {
      setWindLimits((limit) => limit.map((val) => +(val * 1.60934).toFixed()));
    } else {
      setWindLimits((limit) => limit.map((val) => +(val / 1.60934).toFixed()));
    }
    setWindToggle((val) => !val);
  };

  return (
    <div className="tile sm-12">
      <div className="header">
        <div className="left">
          <div className="title">Weather Conditions</div>
          <div className="subtitle">
            Set the recommended weather parameters safe for workers to carryout
            their jobs
          </div>
        </div>
        <div className="right">
          <div
            className="action"
            title="Reload"
            onClick={() => setReloadToggle(true)}
          >
            <i className="icon icon-reload"></i>
          </div>
        </div>
      </div>
      <div className="content">
        {getLoading.getCall ? (
          <Loading />
        ) : (
          <div className="eds-form">
            <div className="form-body">
              <div className="form-group">
                <div className="form-element">
                  <div>
                    Temperature
                    <label className="switch ml-lg">
                      <input
                        type="checkbox"
                        checked={getTemperatureToggle}
                        onChange={handleTemperatureToggle}
                      />
                      <i className="ball"></i>
                      <span
                        data-enabled={"\xB0C"}
                        data-disabled={"\xB0F"}
                      ></span>
                    </label>
                  </div>
                  <EDSMultiRangeSlider
                    id="config-temperature"
                    settings={{
                      min: -40,
                      max: getTemperatureToggle ? 150 : 302,
                      step: 1,
                      units: getTemperatureToggle ? "\xB0C" : "\xB0F",
                    }}
                    defaultValues={getTemperatureLimits}
                    setValue={setTemperatureLimits}
                  />
                </div>
                <div className="form-element">
                  <div>
                    Wind Gust
                    <label className="switch ml-lg">
                      <input
                        type="checkbox"
                        checked={getWindToggle}
                        onChange={handleWindToggle}
                      />
                      <i className="ball"></i>
                      <span data-enabled={"mi/h"} data-disabled={"km/h"}></span>
                    </label>
                  </div>
                  <EDSMultiRangeSlider
                    id="config-wind"
                    settings={{
                      min: 0,
                      max: getWindToggle ? 150 : 241,
                      step: 1,
                      units: getWindToggle ? "mi/h" : "km/h",
                    }}
                    defaultValues={getWindLimits}
                    setValue={setWindLimits}
                  />
                </div>
              </div>
              {getLoading.updateCall ? (
                <Loading />
              ) : (
                successMessage.errMsg !== "Success" && (
                  <AlertEDS
                    errMsg={successMessage.errMsg}
                    status_code={successMessage.status_code}
                    close={false}
                  />
                )
              )}
            </div>
            <div className="form-footer">
              <button className="btn primary" onClick={submitWeatherLimits}>
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
