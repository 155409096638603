import { Table } from "@eds/vanilla";
import React, { useEffect, useState, useRef } from "react";
import { exportToCSVMethod } from "../../utils/helperMethod";
import _ from "lodash";
import { useSelector } from "react-redux";
export default function LoneWorkerTable({
  loneworkerData,
  callGetDeficiencies,
  handleSettings,
  handleManagerList,
}) {
  const [getData, setData] = useState();
  const [exportData, setExportData] = useState([]);
  const rolesMap = useSelector((state) => state.userAccess.roles);

  const progressData = (startTime, endTime, timer) => {
    let start_date = new Date(startTime).getTime() / (1000 * 60);
    let end_date = new Date(endTime).getTime() / (1000 * 60);
    let currDate = new Date().getTime() / (1000 * 60);

    let totalTime = end_date - start_date; // / 1000;
    let diff;

    if (currDate > end_date) {
      diff = end_date - start_date;
    } else {
      diff = currDate - start_date;
    }

    let finalTime = parseInt((diff / totalTime) * 100);

    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    const totalMinutes = Math.floor(seconds / 60);

    const sec = seconds % 60;
    const rhours = Math.floor(totalMinutes / 60);
    const fMin = totalMinutes % 60;

    let finalData = [
      finalTime > 100 ? 100 : finalTime,
      rhours + "h : " + fMin + "m : " + sec + "s",
    ];

    return finalData;
  };

  const alertData = (email, Notification, status, sms) => {
    let _email =
      status === "Panic-Alert" || status === "Overrun-Session"
        ? `<span class="iconDiv ml-sm mr-sm"><i class="icon icon-email color-red"></i></span>`
        : ``;
    let _Notification =
      status === "Panic-Alert" || status === "Overrun-Session"
        ? `<span class="iconDiv ml-sm mr-sm"><i class="icon icon-notification color-red"></i></span>`
        : ``;
    let _sms =
      sms === "Y" && (status === "Panic-Alert" || status === "Overrun-Session")
        ? `<span class="iconDiv ml-sm mr-sm"><i class="icon icon-message-contact-us color-red"></i></span>`
        : ``;

    return [_email + _Notification + _sms];
  };
  const _data = () => {
    let products = [];
    for (
      let tblData = Object.keys(loneworkerData["Email"]).length - 1;
      tblData >= 0;
      tblData--
    ) {
      products.push({
        "User Name": [
          loneworkerData["Fullname"][tblData],
          loneworkerData["Status"][tblData],
        ],
        "Email ID": loneworkerData["Email"][tblData],
        "Start Date/Time": loneworkerData["Session Start Time"][tblData],
        "Timer in-Min": loneworkerData["Timer"][tblData],
        Progress: loneworkerData["Status"][tblData],
        Status: loneworkerData["Status"][tblData],
        "Extended Time": loneworkerData["Extended Timer"][tblData],
        Alert: alertData(
          loneworkerData["Alertemail"][tblData],
          loneworkerData["AlertPushnotification"][tblData],
          loneworkerData["Status"][tblData],
          loneworkerData["Alertsms"][tblData]
        ),
        Updates:
          loneworkerData["Updates"][tblData] === ""
            ? ["Not Available"]
            : loneworkerData["Updates"][tblData],
        ProgressData: progressData(
          loneworkerData["Session Start Time"][tblData],
          loneworkerData["Session End Time"][tblData],
          loneworkerData["Timer"][tblData]
        ),
        Acknowledgement: loneworkerData["Acknowledge"][tblData],
      });
    }
    setExportData(products);
    return products;
  };

  useEffect(() => {
    if (!_.isEmpty(loneworkerData)) {
      const tableDOM = document.querySelector("#loneworkerTable");
      if (_.isEmpty(getData)) {
        const table = new Table(tableDOM, {
          data: _data(),
          columns: [
            {
              key: "User Name",
              title: "User Name",
              sort: "none",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="alertSpace">${data[1] === "Overrun-Session" || data[1] === "Panic-Alert"
                  ? `<i class="icon icon-alarm-level4 color-red"></i>`
                  : ""
                  }</span> ${data[0]}`;
              },
            },
            {
              key: "Start Date/Time",
              title: "Start Date/Time",
              sort: "none",
            },
            {
              key: "Timer in-Min",
              title: "Timer in-Min",
              cellStyle: "text-align: center",
            },
            {
              key: "ProgressData",
              title: "Progress",
              sort: "none",
              cellClass: "progressTD",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<div class="progress-bar thin ${data[0] === 100
                  ? "completeProgress"
                  : data[0] > 80
                    ? "eightyComplete red"
                    : data[0] > 60
                      ? "sixtyComplete"
                      : "inProcess"
                  }"><progress value=${data[0]} max="100"></progress><span>${data[1]
                  }</span></div>`;
              },
            },
            {
              key: "Status",
              title: "Status",
              sort: "none",
              cellStyle: "text-align: center",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<kbd class="tag ${data === "Completed-Session" ? "green" : data === "Panic-Alert" || data === "Overrun-Session" ? "red" : data === "Active-Session" ? "blue" : "orange"}">${data}</span>`;
              },
            },
            {
              key: "Extended Time",
              title: "Extended Time",
              cellStyle: "text-align: center",
            },
            {
              key: "Alert",
              title: "Alert",
              cellClass: "progressTD",
              cellStyle: "text-align: center",
              onCreatedCell: (td, data) => {
                td.innerHTML = data;
              },
            },
            {
              key: "Acknowledgement",
              title: "Ack",
              sort: "none",
              cellStyle: "text-align: center",
              onCreatedCell: (td, data) => {
                td.innerHTML =
                  data === "Y"
                    ? '<div><i class="icon icon-check" style="color: #37c508"></i></div>'
                    : "";
              },
            },
          ],
          sortable: true,
          expandable: true,
          onCreatedDetailsRow: (td, data) => {
            let hData = data.Updates.map(
              (i) =>
                `<div class="history"><div class="sub-History">${i}</div></div>`
            ).join("");
            td.innerHTML = hData;
          },
        });
        table.init();
        setData(table);
      } else {
        //setData(_data());
        getData.update(_data());
      }
    }
  }, [loneworkerData]);

  useEffect(() => {
    let callCheck = 1;
    const interval = setInterval(async () => {
      if (document.querySelector('#loneworkerTable .expanded') === null) {
        await callGetDeficiencies(1);
      }
      else {
        callCheck++;
        // Below 4 means (4 * 15000 time)
        if (callCheck > 8) {
          await callGetDeficiencies(1);
        }
      }
    }, 15000);

    return () => clearInterval(interval);
  }, [loneworkerData]);



  const callExport = () => {
    const csvData = [];
    exportData?.forEach((val) => {
      csvData.push({
        "User Name": val?.["User Name"]?.[0],
        "Start Date/Time": val?.["Start Date/Time"],
        "Timer in-Min": val?.["Timer in-Min"],
        Progress: val?.["ProgressData"][1],
        Status: val?.Status,
        "Extended Time": val?.["Extended Time"],
      });
    });
    exportToCSVMethod(csvData, "Lone Worker - Statistics");
  };

  useEffect(() => {
    let _tr = document.querySelector(".stickyHeader thead tr");
    let _top = document.querySelector(".filterDiv").offsetHeight;
    if (_tr) {
      _tr.style.top = _top + "px";
    }
  }, [loneworkerData]);

  return (
    <>
      <div className="table-top">
        <div className="table-top-left">
          <div className="table-info"></div>
        </div>
        <div className="table-top-right">
          {rolesMap?.some(
            (val) =>
              val === "CONSOLE_LONEWORKER_MANAGER" ||
              val === "CONSOLE_GLOBAL_ADMIN"
          ) && (
              <div className="table-actions">
                <button
                  className="btn icon-btn"
                  title="Manage Loneworker Manager's List"
                  id="open-modalManagerList"
                  onClick={() => handleManagerList()}
                >
                  <i className="icon icon-group"></i>
                </button>
                <button
                  className="btn icon-btn"
                  title="Manage Loneworker List"
                  id="open-modalList"
                  onClick={() => handleSettings()}
                >
                  <i className="icon icon-settings"></i>
                </button>
              </div>
            )}
          <div className="table-buttons">
            <button
              className="btn"
              id="exportData"
              onClick={() => callExport()}
            >
              Export
            </button>
            <button className="btn" onClick={() => callGetDeficiencies(0)}>
              Refresh
            </button>
          </div>
          <div className="table-search">
            <input
              type="text"
              className="with-icon"
              placeholder="Search for..."
            />
            <div className="suffix icon-inside">
              <i className="icon icon-search"></i>
            </div>
          </div>
        </div>
      </div>
      <table
        id="loneworkerTable"
        className="table dashed stickyHeader horizonatalScroll"
        style={{ tableLayout: `auto` }}
      ></table>
      <div className="table-bottom">
        <div className="pagination-group">
          <ul className="pagination"></ul>

          <div className="actions">
            <label className="left">Go to</label>
            <input type="number" min="1" />

            <label className="left">Show</label>
            <div className="select" data-type="single">
              <button className="btn current-options">5</button>

              <div className="options-list">
                <div className="item active">5</div>
                <div className="item">10</div>
                <div className="item">15</div>
                <div className="item">All</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
