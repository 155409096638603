import { URL } from "../utils/Constant";
import _ from "lodash";
export const getLoneWorkerData = async (
  param,
  setLoading,
  setErrMsg,
  setLoneData,
  isLodder,
  headers
) => {
  if (isLodder === 1) {
    setLoading({
      loneWorkerData: false,
      // isMap: true,
    });
  } else {
    setLoading({
      loneWorkerData: true,
      isMap: true,
    });
  }

  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }

  const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_DATA, {
    method: "POST",
    body: formData,
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();

    setLoneData(data.data);
    setErrMsg({
      loneWorkerData: data.message,
    });
    setLoading({
      loneWorkerData: false,
      isMap: false,
    });
  }
};

export const getLoneWorkerFilters = async (
  searchURL,
  setLoneFilterData,
  headers
) => {
  setLoneFilterData((val) => ({ ...val, loading: true }));
  try {
    const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_FILTERS + searchURL, {
      method: "GET",
      headers: headers,
    });
    const data = await resp.json();
    setLoneFilterData(data?.data || {});
    setLoneFilterData({
      loading: false,
      data: data?.data || {},
      message: data?.message || "Internal Server Error",
    });
  } catch {
    setLoneFilterData({
      loading: false,
      data: {},
      message: "Internal Server Error",
    });
  }
};

export const getLoneWorkerMetrics = async (
  param,
  getMetricsStatus,
  setLoading,
  setErrMsg,
  setLoneMetricsData,
  isLodder,
  headers
) => {
  if (isLodder === 1) {
    setLoading({
      loneWorkerMetrics: false,
    });
  } else {
    setLoading({
      loneWorkerMetrics: true,
    });
  }

  param.status = getMetricsStatus;
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }

  const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_METRICS, {
    method: "POST",
    body: formData,
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    setLoneMetricsData(data.data);
    setErrMsg({
      loneWorkerMetrics: data.message,
    });
    setLoading({
      loneWorkerMetrics: false,
    });
  }
};

export const getLoneWorkerSenderList = async (
  setLoading,
  setLoneList,
  setErrMsg,
  setModalShow,
  headers
) => {
  // const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_SENDER_LIST + "?usergroupId=" + UserGroupId, { method: 'GET', headers: headers });
  const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_SENDER_LIST, {
    method: "GET",
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    setLoneList(JSON.parse(data.data));
    setLoading({
      loneWorkerList: false,
    });
    setModalShow(true);
    setErrMsg({
      loneWorkerList: data.message,
    });
  }
};

export const getLoneWorkerAddSenderList = async (
  setLoading,
  param,
  setResponce,
  closeAlert,
  setCountryRef,
  headers
) => {
  setLoading(true);
  setCountryRef(true);
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_SENDER_LIST, {
    method: "POST",
    body: formData,
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    setResponce({
      status_code: data.status_code,
      message: data.message,
    });
    setLoading(false);
    closeAlert();
    setCountryRef(false);
  }
};

export const getLoneWorkerRemoveSenderList = async (
  setLoading,
  param,
  setResponce,
  closeAlert,
  headers
) => {
  setLoading(true);
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_SENDER_LIST, {
    method: "PUT",
    body: formData,
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    setResponce({
      status_code: data.status_code,
      message: data.message,
    });
    setLoading(false);
    closeAlert();
  }
};
