import { useEffect, useState } from "react";
import { Table } from "@eds/vanilla";
import { exportToCSVMethod } from "../../../utils/helperMethod";
export default function CrewSubmissionCountTable({ crewsubmsn_group_js }) {
  const [getData, setData] = useState();

  useEffect(() => {
    let product = [];
    for (
      let tblData = Object.keys(crewsubmsn_group_js["ID"]).length - 1;
      tblData >= 0;
      tblData--
    ) {
      // for (let tblData = 0 ; tblData <= Object.keys(loneworkerData['Email']).length - 1; tblData++) {
      product.push({
        ID: crewsubmsn_group_js["ID"][tblData],
        Name: crewsubmsn_group_js["Name"][tblData],
        Participation_Count:
          crewsubmsn_group_js["Participation count"][tblData],
      });
    }

    const tableDOM = document.querySelector("#Crew-Participation-Count");
    setData(product);
    const table = new Table(tableDOM, {
      data: product,
      columns: [
        {
          key: "ID",
          title: "ID",
          sort: "none",
        },
        {
          key: "Name",
          title: "Name",
          sort: "none",
        },
        {
          key: "Participation_Count",
          title: "Participation Count",
          sort: "none",
          cellStyle: "text-align: center",
        },
      ],
      sortable: true,
    });
    table.init();
  }, []);

  const callExport = () => {
    const csvData = [];
    getData?.forEach((val) => {
      csvData.push({
        ID: val?.["ID"],
        Name: val?.["Name"],
        "Participation Count": val?.["Participation_Count"],
      });
    });
    exportToCSVMethod(csvData, "Crew Participation Count");
  };

  useEffect(() => {
    let _tr = document.querySelector(
      ".JHA-Reports-Crew-Participation-Count.stickyHeader thead tr"
    );
    let _top = document.querySelector(".filterDiv").offsetHeight;
    if (_tr) {
      _tr.style.top = _top + "px";
    }
  }, [crewsubmsn_group_js]);

  return (
    <>
      <div className="table-top">
        <div className="table-top-left">
          <div className="table-info"></div>
        </div>
        <div className="table-top-right">
          <div className="table-buttons">
            <button
              className="btn"
              id="exportData-Crew-Participation-Count"
              onClick={() => callExport()}
            >
              Export
            </button>
          </div>
          <div className="table-search">
            <input
              type="text"
              className="with-icon"
              placeholder="Search for..."
            />
            <div className="suffix icon-inside">
              <i className="icon icon-search"></i>
            </div>
          </div>
        </div>
      </div>
      <table
        id="Crew-Participation-Count"
        className="table dashed JHA-Reports-Crew-Participation-Count stickyHeader"
      ></table>
      <div className="table-bottom">
        <div className="pagination-group">
          <ul className="pagination"></ul>

          <div className="actions">
            <label className="left">Go to</label>
            <input type="number" min="1" />

            <label className="left">Show</label>
            <div className="select" data-type="single">
              <button className="btn current-options">5</button>

              <div className="options-list">
                <div className="item active">5</div>
                <div className="item">10</div>
                <div className="item">15</div>
                <div className="item">All</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
