import { useSelector } from "react-redux";
import { URL } from "../utils/Constant";

export const useWeatherConfiguration = () => {
  const { headers } = useSelector((state) => state.envVariables);

  const WeatherConfigDetails = async (options = {}) => {
    const defaultOptions = {
      method: "GET",
      headers,
    };
    const requestOptions = { ...defaultOptions, ...options };
    return await fetch(URL.ERICSION.WEATHER_SETTINGS, requestOptions)
      .then(async (resp) => await resp.json())
      .catch(() => ({
        data: {},
        message: "Internal Server Error",
        status_code: 201,
      }));
  };

  return [WeatherConfigDetails];
};
