import React, { useEffect } from "react";
import { HierarchyChart } from '@eds/vanilla';
import FlowchartData from "./flowchartData.json";

export default function FlowChart() {

    useEffect(() => {
        const Data = FlowchartData;
        const chart = new HierarchyChart({
            element: document.querySelector('#horizontal-alerts-chart'),
            data: Data,
            alerts: false,
            horizontal: true
        });
        chart.init();
        return () => {
            chart.destroy();
        }
    })
    return (
        <div id="horizontal-alerts-chart" className="flowChart"></div>
    );
}