
import { Table } from '@eds/vanilla';
import React, { useEffect, useState } from 'react';
import _ from "lodash";
export default function LoneWorkerTable({ getLoneMetricsData }) {
  const [getData, setData] = useState();
  const _data = () => {
    let products = [];
    Object.values(getLoneMetricsData['status']).filter((i, k) => {
      products.push({
        "Status": getLoneMetricsData['status'][k],
        "Count": getLoneMetricsData['count'][k],
      });
    });
    return products;
  }
  useEffect(() => {
    if (!_.isEmpty(getLoneMetricsData)) {
      const tableDOM = document.querySelector('#tableMetrics');
      if (_.isEmpty(getData)) {
        const table = new Table(tableDOM, {
          data: _data(),
          columns: [
            {
              key: 'Status',
              title: 'Status',
            },
            {
              key: 'Count',
              title: 'Count',
            },
          ],
          sortable: true,
        });
        table.init();
        setData(table);
      }
      else {
        getData.update(_data());
      }
    }
  }, [getLoneMetricsData]);

  useEffect(() => {
    let _tr = document.querySelector('.stickyHeader thead tr');
    let _top = document.querySelector('.filterDiv').offsetHeight;
    if (_tr) {
      _tr.style.top = _top + 'px';
    }
  }, [getLoneMetricsData]);

  return (
    <>
      <table id="tableMetrics" className="table dashed stickyHeader"></table>
    </>
  );
}
