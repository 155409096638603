import { useEffect, useState } from "react";
import SafetyDialogueTopic from "./SafetyDialogueTopic/SafetyDialogueTopic";
import EmployeeList from "./EmployeesDetails/EmployeeList";
import { getSafetyDialogueTopics } from "../../api/Settings.api";
// import { UserContext } from "../../layouts/Sidebar/SideBar";
import _ from "lodash";
import { useSelector } from "react-redux";

export default function Settings() {
  const [loadingTable, setLoadingTable] = useState(false);
  const [errMsgTable, setErrMsgTable] = useState("");
  const [safetyTopicsData, setSafetyTopicsData] = useState({});
  const { headers } = useSelector((state) => state.envVariables);

  const getSafetyTopicsDetails = () => {
    getSafetyDialogueTopics(
      setLoadingTable,
      setErrMsgTable,
      setSafetyTopicsData,
      headers
    );
  };

  useEffect(() => {
      getSafetyTopicsDetails();
  }, []);

  return (
      <div className="row ">
            <SafetyDialogueTopic
              loadingTable={loadingTable}
              errMsgTable={errMsgTable}
              safetyTopicsData={safetyTopicsData}
              getSafetyTopicsDetails={getSafetyTopicsDetails}
            />
        <EmployeeList />
      </div>
  );
}
