import { URL } from "../utils/Constant";
import moment from "moment-timezone";
export const getReports = async (
  param,
  setLoading,
  setErrMsg,
  setData,
  headers
) => {
  setLoading({
    reportsTotalTime: true,
  });
  setErrMsg({
    reportsTotalTime: "",
  });
  setData({});
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  const url = URL.ERICSION.GET_REPORTS_DATA;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: formData,
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      if (data.status_code === 200) {
        setData({ ...data?.data });
      }
      setErrMsg({ reportsTotalTime: data.message });
      setLoading({ reportsTotalTime: false });
    } else {
      setErrMsg({ reportsTotalTime: "Internal Server Error" });
      setLoading({ reportsTotalTime: false });
    }
  } catch (err) {
    setErrMsg({ reportsTotalTime: "Internal Server Error" });
    setLoading({ reportsTotalTime: false });
  }
};
// export const getFormSubmissionData = async (
//   param,
//   setLoading,
//   setErrMsg,
//   setData,
//   currUserGroup
// ) => {
//   setLoading({ formSubmissionsGraph: true });
//   setErrMsg({ formSubmissionsGraph: "" });
//   setData({});
//   let formData = new FormData();
//   for (let i in param) {
//     formData.append(i, param[i]);
//   }
//   const url = URL.ERICSION?.GET_FORM_SUBMISSION_DATA;
//   const resp1 = await fetch(url, {
//     method: "POST",
//     body: formData,
//     headers: headers,
//   });
//   if (resp1.ok) {
//     const data = await resp1.json();
//     if (data.status_code === 200) {
//       setData({ ...data?.data });
//     }
//     setLoading({ formSubmissionsGraph: false });
//     setErrMsg({ formSubmissionsGraph: data.message });
//   } else {
//     setErrMsg({ formSubmissionsGraph: "Internal Server Error" });
//     setLoading({ formSubmissionsGraph: false });
//   }
// };
// export const getClimberData = async (
//   param,
//   setLoading,
//   setErrMsg,
//   setData,
//   currUserGroup
// ) => {
//   setLoading({ climberGraph: true });
//   setErrMsg({ climberGraph: "" });
//   setData({});
//   let formData = new FormData();
//   for (let i in param) {
//     formData.append(i, param[i]);
//   }
//   const url = URL.ERICSION.GET_CLIMBER_DATA;
//   const resp2 = await fetch(url, {
//     method: "POST",
//     body: formData,
//     headers: headers,
//   });
//   if (resp2.ok) {
//     const data = await resp2.json();
//     if (data.status_code === 200) {
//       setData({ climb_non_climb: data?.data });
//     }
//     setLoading({ climberGraph: false });
//     setErrMsg({ climberGraph: data.message });
//   } else {
//     setLoading({ climberGraph: false });
//     setErrMsg({ climberGraph: "Internal Server Error" });
//   }
// };
export const getCrewSubmissionCount = async (
  param,
  setLoading,
  setErrMsg,
  setData,
  headers
) => {
  setLoading({ crewSubmissionCount: true });
  setErrMsg({ crewSubmissionCount: "" });
  setData({});
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  const url = URL.ERICSION.GET_CREW_SUBMISSION_COUNT;
  try {
    const resp2 = await fetch(url, {
      method: "POST",
      body: formData,
      headers: headers,
    });
    if (resp2.ok) {
      const data = await resp2.json();
      if (data.status_code === 200) {
        setData({ ...data?.data });
      }
      setLoading({ crewSubmissionCount: false });
      setErrMsg({ crewSubmissionCount: data.message });
    } else {
      setLoading({ crewSubmissionCount: false });
      setErrMsg({ crewSubmissionCount: "Internal Server Error" });
    }
  } catch {
    setLoading({ crewSubmissionCount: false });
    setErrMsg({ crewSubmissionCount: "Internal Server Error" });
  }
};
export const getTotalTimeTakenStatics = async (
  param,
  setLoading,
  setErrMsg,
  setData,
  headers
) => {
  setLoading({ totalTimeStatistics: true });
  setErrMsg({ totalTimeStatistics: "" });
  setData({});
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  let url = URL.ERICSION.GET_TOTAL_TIME_COUNT;
  const resp2 = await fetch(url, {
    method: "POST",
    body: formData,
    headers: headers,
  });
  if (resp2.ok) {
    const data = await resp2.json();
    if (data.status_code === 200) {
      setData({ ...data?.data });
    }
    setLoading({ totalTimeStatistics: false });
    setErrMsg({ totalTimeStatistics: data.message });
  } else {
    setLoading({ totalTimeStatistics: false });
    setErrMsg({ totalTimeStatistics: "Internal Server Error" });
  }
};
//update approval and reject status
// for approve status id is 2 and reject it is 3,
export const updateStatusReportsApi = async (
  param,
  setLoading,
  setErrMsg,
  setShowMdlA,
  setStatusAR,
  headers
) => {
  setLoading(true);
  const formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  const url = URL.ERICSION.REPORTS_UPDATE_STATUS_URL;
  try {
    const resp = await fetch(url, {
      method: "PUT",
      body: formData,
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      setErrMsg(data.message);
      setLoading(false);
      setStatusAR(param.reportId);
      return data?.status_code;
    } else {
      setShowMdlA(false);
      return 201;
    }
  } catch (err) {
    setShowMdlA(false);
  }
};
export const getReportsByReportId = async (
  param,
  setStartDate,
  setEndDate,
  setFormIdData,
  setStatusOption,
  setLoading,
  setErrMsg,
  setData,
  headers
) => {
  setLoading({
    reportsTotalTime: true,
  });
  setErrMsg({
    reportsTotalTime: "",
  });
  setLoading({ reportsTotalTime: true });
  setErrMsg({ reportsTotalTime: "" });
  setData({});
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  const url = URL.ERICSION.GET_REPORTS_DATA;

  try {
    const resp = await fetch(url, {
      method: "POST",
      body: formData,
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      if (data.status_code === 200) {
        setData({ ...data?.data });
        setStartDate(
          new Date(moment(data?.data?.start_date).format("YYYY-MM-DD"))
        );
        setEndDate(new Date(moment(data?.data?.end_date).format("YYYY-MM-DD")));
        setFormIdData([data?.data?.formId]);
        setStatusOption(data?.data?.status_id);
      }
      setErrMsg({ reportsTotalTime: data.message });
      setLoading({
        reportsTotalTime: false,
      });
    } else {
      setLoading({ reportsTotalTime: false });
      setErrMsg({ reportsTotalTime: "Internal Server Error" });
    }
  } catch (err) {
    setLoading({ reportsTotalTime: false });
    setErrMsg({ reportsTotalTime: "Internal Server Error" });
  }
};
export const callGetApproveRejectOptions = async (
  param,
  setLoading,
  setErrMsg,
  headers
) => {
  setLoading(true);
  setErrMsg("");
  const url = URL.ERICSION.GET_APPROVAL_REJECTION_OPTION;
  try {
    const resp = await fetch(`${url}?status_id=${param?.status_id}`, {
      method: "GET",
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      setErrMsg(data.message);
      setLoading(false);
      return data?.data?.comments;
    } else {
      setErrMsg("Internal Server Error");
      setLoading(false);
      return [];
    }
  } catch (err) {
    setErrMsg("Internal Server Error");
    setLoading(false);
    return [];
  }
};

// export const getReportsFiltersData = async (
//   param,
//   setfiltersData,
//   setFiltersLoading,
//   setFiltersErrMsg,
//   headers
// ) => {
//   setFiltersLoading(true);
//   setFiltersErrMsg("");
//   let url = `${URL.ERICSION.GET_REPORTS_FILTERS}?reporttab=Y&ijhatab=${
//     param?.tabName === "JHA-reports" ? "Y" : "N"
//   }`;
//   try {
//     const resp = await fetch(url, {
//       method: "GET",
//       headers: headers,
//     });
//     if (resp.ok) {
//       const data = await resp.json();
//       setfiltersData(JSON.parse(data?.data || "{}"));
//       setFiltersErrMsg(data.message);
//       setFiltersLoading(false);
//     } else {
//       setFiltersErrMsg("Internal Server error");
//       setFiltersLoading(false);
//     }
//   } catch (err) {
//     setFiltersErrMsg("Internal Server error");
//     setFiltersLoading(false);
//   }
// };

export const getSubReports = async (
  param,
  setChildReportData,
  setLoading,
  setErrMsgPop,
  headers
) => {
  setLoading(true);
  setErrMsgPop("");
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  try {
    const resp = await fetch(URL.ERICSION.GET_REPORTS_DATA, {
      method: "POST",
      body: formData,
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      if (data.status_code === 200) {
        const _data = JSON.parse(data.data.totaltime_js);
        setChildReportData(_data);
      } else {
        setErrMsgPop(data.message);
      }
    } else {
      setErrMsgPop("Internal Server Error");
    }
  } catch (err) {
    setErrMsgPop("Internal Server Error");
  }
  setLoading(false);
};

export const callGenerateReport = async (reportURL, headersList) => {
  const resp = await fetch(reportURL, {
    method: "GET",
    headers: headersList,
  });
  const htmlContent = await resp.text();
  return htmlContent;
};
