import { useEffect } from "react";
import { Dialog } from "@eds/vanilla";
import { useUpdateFormReviewMutation } from "../../../redux/services/configurationApi";
import { useNotification } from "../../../CustomHooks/useNotificationTrigger";
import Loading from "../../../components/Loading/Loading";
import { useRef } from "react";

export default function DisclaimerDialog({ getDialog, setDialog }) {
  const dialogRef = useRef(null);
  const { isShow, value, checkbox } = getDialog;
  const customText = value?.ai_approval_required ? "Enable" : "Disable";
  const [updateFormReview, { isLoading }] = useUpdateFormReviewMutation();
  const [success, failed] = useNotification({ title: "Forms Configuration" });

  useEffect(() => {
    const dialogDOM = document.querySelector("#dislaimer-dialog");
    if (dialogRef.current === null && dialogDOM) {
      const dialog = new Dialog(dialogDOM);
      dialog.init();
      dialogRef.current = dialog;
    }
    if (dialogRef.current != null) {
      if (isShow) dialogRef.current.show();
      else dialogRef.current.hide();
    }
  }, [isShow]);

  const handleClose = () => {
    setDialog({ isShow: false, value: {} });
    checkbox.checked = !checkbox.checked;
  };

  const handleAISubmit = async () => {
    await updateFormReview(
      JSON.stringify({
        formId: value.formId,
        ai_approval_required: value.ai_approval_required,
      })
    )
      .unwrap()
      .then((payload) => {
        if (payload.status_code === 200) {
          success(`${value.form_name}'s AI Review is updated successfully.`);
        } else {
          failed(payload.message);
        }
      })
      .catch((error) => {
        failed("Internal Server Error. Please try again");
      });
    setDialog({ isShow: false, value: {} });
  };

  return (
    <div id="dislaimer-dialog" className="dialog" data-type="simple">
      <div className="content">
        <div className="top">
          <div className="title">{`${customText} AI Review?`}</div>
        </div>
        <div className="body">
          <p>{`Are you sure you want ${customText} AI Review?`} </p>
        </div>
        {isLoading && <Loading />}
        <div className="bottom">
          <button className="btn" data-close="true" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="btn warning"
            onClick={handleAISubmit}
          >{`AI Review ${customText}`}</button>
        </div>
      </div>
    </div>
  );
}
