import { Table } from "@eds/vanilla";
import { useEffect, useRef } from "react";
import { useGetFormReviewQuery } from "../../../redux/services/configurationApi";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import DisclaimerDialog from "./DisclaimerDialog";
import { useState } from "react";
import FlowChart from "./FlowChart";

export default function FormsConfiguration() {
  const tableRef = useRef(null);
  const {
    data: { data, message } = {},
    error,
    isLoading,
    refetch,
  } = useGetFormReviewQuery();

  const [getDialog, setDialog] = useState({ isShow: false, value: {} });

  useEffect(() => {
    if (!data?.form_data_list) {
      return;
    }
    const tableData =
      data?.form_data_list?.map((val) => ({
        ...val,
        review_status: { ...val },
        audit_history: {
          name: val.updateduser,
          date: val.updateddate,
        },
      })) || [];
    if (tableRef.current === null) {
      const tableDOM = document.getElementById("formReview-table");
      if (tableDOM) {
        const table = new Table(tableDOM, {
          data: tableData,
          columns: [
            {
              key: "form_name",
              title: "Form Name",
              sort: "asc",
            },
            {
              key: "review_status",
              title: "Review Required",
              onCreatedCell: (td, data, ind) => {
                td.innerHTML = `<input id="manual-review${ind}" type="checkbox"/>
              <label for="manual-review${ind}">Enabled</label>`;
                const checkbox = td.querySelector(`#manual-review${ind}`);
                checkbox.disabled = true;
                checkbox.checked = data.manual_approval_required;
              },
            },
            {
              key: "review_status",
              title: "AI Review",
              onCreatedCell: (td, data, ind) => {
                td.innerHTML = `<input id="ai-review${ind}" type="checkbox"/>
              <label for="ai-review${ind}">Enabled</label>`;
                const checkbox = td.querySelector(`#ai-review${ind}`);
                checkbox.disabled = !data.manual_approval_required;
                checkbox.checked = data.ai_approval_required;
                checkbox.addEventListener("click", () => {
                  setDialog({
                    isShow: true,
                    value: {
                      ...data,
                      ai_approval_required: checkbox.checked,
                    },
                    checkbox,
                  });
                });
              },
            },
            {
              key: "audit_history",
              title: "Audit History",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<div class="text-md">${data.name}</div><div class="text-sm">${data.date}</div>`;
              },
            },
          ],
          sortable: true,
        });
        table.init();
        tableRef.current = table;
      }
    } else {
      tableRef.current.update(tableData);
    }
  }, [data]);

  return (
    <div className="row">
      <div className="tile sm-12">
        <div className="header">
          <div className="left">
            <div className="title">Forms Configuration</div>
          </div>
          <div className="right">
            <div className="action" title="Reload" onClick={refetch}>
              <i className="icon icon-reload"></i>
            </div>
          </div>
        </div>
        <div className="content">
          <DisclaimerDialog getDialog={getDialog} setDialog={setDialog} />
          {isLoading && <Loading />}
          {(error || (message && message !== "Successfull")) && (
            <AlertEDS
              errMsg={error ? "Internal Server Error" : message}
              status_code={201}
              close={false}
            />
          )}
          {data?.form_data_list && (
            <>
              <div className="table-top">
                <div className="table-top-left">
                  <div className="table-info"></div>
                </div>
                <div className="table-top-right">
                  <div className="table-search">
                    <input
                      type="text"
                      className="with-icon"
                      placeholder="Search for..."
                    />
                    <div className="suffix icon-inside">
                      <i className="icon icon-search"></i>
                    </div>
                  </div>
                </div>
              </div>
              <table
                id="formReview-table"
                className="table dashed compact"
              ></table>
              <div className="table-bottom">
                <div className="pagination-group">
                  <ul className="pagination"></ul>
                  <div className="actions">
                    <label className="left">Go to</label>
                    <input type="number" min="1" />
                    <label className="left">Show</label>
                    <div className="select" data-type="single">
                      <button className="btn current-options">5</button>
                      <div className="options-list">
                        <div className="item active">5</div>
                        <div className="item">10</div>
                        <div className="item">15</div>
                        <div className="item">All</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="tile sm-12">
        <FlowChart />
      </div>
    </div>
  );
}
