import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import "./header.scss";
import logo from "../../asset/Icons/sw_logo.svg";
import packageJson from "../../../package.json";

export default function Header() {
  const {
    user: { firstName, lastName },
    isNewUser,
  } = useSelector((state) => state.loggedInUser);

  return (
    // <!-- System bar -->
    <>
      <header className="sysbar">
        <div className="items-container">
          <div className="item">
            <NavLink to="/home" className="homeLink">
              <img src={logo} alt="Safe Worker" width={32} height={32} />
              <span className="product">Safe Worker</span>
              <span className="acronym">SW</span>
            </NavLink>
          </div>
        </div>

        <div className="items-container">
          {isNewUser ? null : (
            <>
              <div className="item">v{packageJson.version}</div>
              <div id="global-nav-trigger" className="item hover">
                <i className="icon icon-app-launcher"></i>
              </div>
              <div className="item hover">
                <span className="tooltip pointer text-center">
                  <NavLink to="/Get-Support">
                    <i className="icon icon-message-double"></i>
                  </NavLink>
                  <span className="message bottom">Contact Support</span>
                </span>
              </div>
              <div className="item hover settings-trigger">
                <i className="icon icon-profile"></i>
                <span>{`${firstName} ${lastName}`}</span>
              </div>
            </>
          )}
        </div>
      </header>
    </>
  );
}
