import { Dialog } from "@eds/vanilla";
import { useEffect, useState } from "react";
export default function IncidentImageDialog({ setIsDialogOpen, imageData }) {
  useEffect(() => {
    const dialogs = document.getElementById("incidentImgDialog");
    if (dialogs) {
      const dialog = new Dialog(dialogs);
      dialog.init();
      dialog.show();
      return () => {
        dialog.destroy();
      };
    }
  }, []);

  const closeHandler = () => {
    setIsDialogOpen(false);
  };
  return (
    <div
      className="dialog "
      id="incidentImgDialog"
      data-type="simple"
    >
      <div className="content">
        <div className="top">
          <div className="title">Image Preview</div>
          <div className="right">
            <button className="btn-icon ">
              <i className="icon icon-cross" onClick={closeHandler}></i>
            </button>
          </div>
        </div>
        <div className="body">
          <div className="row">
            <img src={imageData} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
      </div>
    </div>
  );
}
