import React from "react";
import Card from "../../components/Card/Card";
import './safetyMetrics.scss';
export default function SafetyMetricsCard({getSafetyMetricsData}) {
    return (
        <>
            {getSafetyMetricsData?.map((i, k) => {
                return (
                    <Card
                        key={k}
                        title={i.title}
                        count={i.count}
                        // loadingVal={loadingVal.groupStats}
                        hoverText={i.title}
                        details={i.description}
                    // isHoverTextRequire={true}
                    />
                )
            })}</>
    );
}
