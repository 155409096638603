import React, { Suspense, useEffect, lazy } from "react";
import { Breadcrumb } from "@eds/vanilla";
import { Routes, useLocation, useNavigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
const InactivityPeriod = lazy(() => import("./InactivityPeriod"));
const WeatherLimits = lazy(() => import("./WeatherLimits"));
// const Suppliers = lazy(() => import("./SupplierConfiguration/Suppliers"));
const Notifications = lazy(() => import("./Notifications/Notifications"));
const FormsConfiguration = lazy(() =>
  import("./formsConfiguration/FormsConfiguration")
);

export default function Configuration() {
  const roles = useSelector((state) => state.userAccess.roles);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const cards = [
    {
      id: "1",
      title: "User Deactivation",
      path: "/user_deactivation",
    },
    {
      id: "2",
      title: "Weather Conditions",
      path: "/weather_conditions",
    },
    // {
    //   id: "3",
    //   title: "Manage Suppliers",
    //   path: "/supplier",
    // },
    {
      id: "3",
      title: "Notifications",
      path: "/notifications",
    },
    {
      id: "4",
      title: "Form Workflow",
      path: "/form-workflow",
    },
  ];

  useEffect(() => {
    const title =
      cards.find((val) => "/configuration" + val.path === pathname)?.title ||
      "";
    const element = document.getElementById("config-breadcrumb-action");
    if (element && title) {
      const breadcrumb = new Breadcrumb(element);
      breadcrumb.init([
        {
          title: "Configuration",
          action: () => {
            navigate("/configuration");
          },
        },
        {
          title: title,
        },
      ]);
      return () => {
        breadcrumb.destroy();
      };
    }
  }, [pathname]);

  const ConfigurationCards = () => {
    return (
      <>
        <div className="tile">
          <div className="eds-cards-list">
            {cards.map((card) => (
              <div
                className="card"
                key={card.id}
                onClick={() => {
                  navigate(`/configuration${card.path}`, {
                    state: { title: card.title },
                  });
                }}
              >
                <div className="header">
                  <div className="title">{card.title}</div>
                  <i className="icon icon-arrow-right"></i>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    roles?.some((val) => val === "CONSOLE_GLOBAL_ADMIN") && (
      <>
        <div>
          <div className="breadcrumb m-lg" id="config-breadcrumb-action"></div>
        </div>
        <Routes>
          <Route path="/" element={<ConfigurationCards />}></Route>
          <Route
            path="/user_deactivation"
            element={
              <Suspense fallback={<Loading />}>
                <InactivityPeriod />
              </Suspense>
            }
          />
          <Route
            path="/weather_conditions"
            element={
              <Suspense fallback={<Loading />}>
                <WeatherLimits />
              </Suspense>
            }
          />
          {/* <Route
            path="/supplier"
            element={
              <Suspense fallback={<Loading />}>
                <Suppliers />
              </Suspense>
            }
          /> */}
          <Route
            path="/notifications"
            element={
              <Suspense fallback={<Loading />}>
                <Notifications />
              </Suspense>
            }
          />
          <Route
            path="/form-workflow"
            element={
              <Suspense fallback={<Loading />}>
                <FormsConfiguration />
              </Suspense>
            }
          />
        </Routes>
      </>
    )
  );
}
