import React, { useState, useEffect, useContext } from "react";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import Loading from "../../components/Loading/Loading";
import _ from "lodash";
// import { UserContext } from "../../layouts/Sidebar/SideBar";
import { getUser } from "../../api/userManagement.api";
import UserManagementTable from "./UserManagementTable";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import { useSelector } from "react-redux";
import AddNewUser from "./AddNewUser";

export default function UserManagement() {
  // const { getUserAccess } = useContext(UserContext);
  const [loadingVal, setLoading] = useState(false);
  const [getUserData, setUserData] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [startDate, setStartDate] = useState("");
  // new Date(
  //   moment().local().subtract(1, "weeks").format("YYYY-MM-DD 00:00:00")
  // )
  const [endDate, setEndDate] = useState("");
  // new Date(moment().local().format("YYYY-MM-DD 23:59:59"))

  const [formIdData, setFormIdData] = useState(["Y", "N"]);
  const [dashboardAcessId, setDashboardAccessId] = useState(["Y", "N"]);
  const { headersList } = useSelector((state) => state.envVariables);
  // const [getTabAccess, setTabAccess] = useState([]);
  const [getUserRoles, setUserRoles] = useState({ isOpen: false, user: {} });
  const rolesMap = useSelector((state) => state.userAccess.roles);
 
  let datepickerData = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setFormIdData,
    formIdData,
    dashboardAcessId,
    setDashboardAccessId,
  };

  useEffect(() => {
      getUser(setLoading, setUserData, setErrMsg, datepickerData, headersList);
  }, []);

  const CallGetUser = () => {
    if (_.isEmpty(formIdData)) {
      setErrMsg("Please select atleast one status");
    } else if (_.isEmpty(dashboardAcessId)) {
      setErrMsg("Please select atleast one Dashboard Access");
    } else {
      getUser(setLoading, setUserData, setErrMsg, datepickerData, headersList);
    }
  };

  const handleUserRoles = (event, type = "Add", defaultUser = {}) => {
    setUserRoles((val) => ({
      ...val,
      isOpen: true,
      type,
      user: { ...defaultUser },
    }));
  };
  
  return (
    <>
      <div className="tile filterDiv">
        <CustomDatePickerForm
          formName="userManagement"
          datepickerData={datepickerData}
          filterData={{}}
          callApply={CallGetUser}
          disableButtton={loadingVal}
          filtersAccess={{}}
        />
      </div>
      <div className="tile sm-12 md-12 lg-12 xl-12">
        <div className="header">
          <div className="left">
            <div className="title">Registered Users</div>
          </div>
          <div className="right">
            {rolesMap?.some((val) => val === "CONSOLE_GLOBAL_ADMIN" || val === 'CONSOLE_USER_ADMIN') && (
              <div className="action" onClick={handleUserRoles}>
                <span className="tooltip pointer">
                  <i className="icon icon-plus text-xl"></i>
                  <span className="message left blue">Add New User</span>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="content">
          <AddNewUser
            getUserRoles={getUserRoles}
            setUserRoles={setUserRoles}
            callGetUsers={CallGetUser}
          />
          <div className="column">
            {loadingVal ? (
              <Loading />
            ) : (
              <>
                {errMsg !== "Success" ? (
                  <AlertEDS errMsg={errMsg} status_code={201} close={false} />
                ) : (
                  <>
                    {!_.isEmpty(getUserData) && (
                      <UserManagementTable
                        getUserData={getUserData}
                        handleUserRoles={handleUserRoles}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
