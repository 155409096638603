import React, { useEffect } from "react";
import moment from "moment";
import { ColorScale, StackedAreaChart } from "@eds/vanilla";
import _ from "lodash";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";

export default function ClimbNonClimbGraph({
  data: { data: { data, message } = {}, isLoading },
}) {
  const getChartData = (apiData) => {
    const dateGraph = apiData?.["Date"] || [];
    const countGraph = apiData?.["count"] || [];
    const statusGraph = apiData?.["Status"] || [];
    const formattedData = [];
    for (const property in dateGraph) {
      const index = formattedData.findIndex(
        (val) =>
          val.date ===
          moment(dateGraph[property], "DD/MM/YYYY").format("YYYY-MM-DD")
      );
      if (index > -1) {
        if (statusGraph[property]?.toLowerCase() === "climber") {
          formattedData[index].climber += countGraph[property];
        } else if (statusGraph[property]?.toLowerCase() === "non climber") {
          formattedData[index].nonClimber += countGraph[property];
        }
      } else {
        if (statusGraph[property]?.toLowerCase() === "climber") {
          formattedData.push({
            date: moment(dateGraph[property], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            climber: countGraph[property],
            nonClimber: 0,
          });
        } else if (statusGraph[property]?.toLowerCase() === "non climber") {
          formattedData.push({
            date: moment(dateGraph[property], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            climber: 0,
            nonClimber: countGraph[property],
          });
        } else {
          formattedData.push({
            date: moment(dateGraph[property], "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            ),
            climber: 0,
            nonClimber: 0,
          });
        }
      }
    }
    formattedData.sort((a, b) => {
      if (a.date > b.date) return 1;
      else if (a.date < b.date) return -1;
      else return 0;
    });
    const data = {
      common: formattedData?.map((data) => data?.date),
      series: [
        {
          name: "Climb",
          values: formattedData?.map((data) => data?.climber),
        },
        {
          name: "Non-Climb",
          values: formattedData?.map((data) => data?.nonClimber),
        },
      ],
    };
    return data;
  };
  useEffect(() => {
    const apiData = !_.isEmpty(data) && JSON.parse(data);
    if (apiData) {
      const myColors = new ColorScale({ offset: 1 });
      myColors.init();
      const Data = getChartData(apiData);
      const chart = new StackedAreaChart({
        element: document.getElementById("climbNonClimb-reportsGraph"),
        data: Data,
        y: { unit: "Count" },
        colorScale: myColors,
      });
      chart.init();
      return () => chart.destroy();
    }
  }, [data]);

  return (
    <div className="tile sm-12 md-12 lg-12 xl-12">
      <div className="header">
        <div className="left">
          <div className="title">Climbing Vs Non-Climbing Jobs</div>
        </div>
      </div>
      <div className="content">
        <div className="column">
          {isLoading && <Loading />}
          {message && message !== "Success" && (
            <AlertEDS errMsg={message} status_code={201} close={false} />
          )}
          {data && <div id="climbNonClimb-reportsGraph"></div>}
        </div>
      </div>
    </div>
  );
}
