import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChartStacked } from "@eds/vanilla";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";
export default function ActionsByOwnerGraph({ actionsByOwner }) {
  useEffect(() => {
    const data = {
      common: actionsByOwner?.map((obj) => obj?.Name),
      series: [
        { name: "Open", values: actionsByOwner?.map((obj) => obj?.OpenCount) },
        {
          name: "Closed",
          values: actionsByOwner?.map((obj) => obj?.CloseCount),
        },
      ],
    };
    const chartHeight = data?.common?.length * 40;
    const mycolorScale = new ColorScale({ reverse: true });
    const chart = new HorizontalBarChartStacked({
      element: document.getElementById("actionItemsByOwner"),
      data: data,
      x: { unit: "Count" },
      height: chartHeight,
      colorScale: mycolorScale,
    });
    mycolorScale.init();
    chart.init();
    return () => {
      chart.destroy();
      let element = document.getElementById("actionItemsByOwner");
      while (element?.firstChild) {
        element.removeChild(element.firstChild);
      }
    };
  }, [actionsByOwner]);

  return (
    <>
      <CustomGraphActions
        nodeId={"actionItemsByOwner"}
        fileName={"ActionItemsByOwner"}
        data={actionsByOwner}
      />
      <div id="actionItemsByOwner"></div>
    </>
  );
}
