import { URL } from "../utils/Constant";

export const getUploadFolder = async (headers, formData, getData, setIsLoading, setError, setCreateHazard) => {
    setIsLoading((val) => ({ ...val, folderUploadLoading: true }));

    try {
        const resp = await fetch(URL.ERICSION.POST_POST_HAZARD_IDENTIFICATION + "?site_id=" + getData.siteID + "&site_type=" + getData.siteType + "&customer=" + getData.customer, {
            method: "POST",
            body: formData,
            headers: headers,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (resp.status === 200) {
                setError((val) => ({ ...val, folderUploadLoading: "The site survey archive has been successfully uploaded. You will be notified once it is processed." }));
            } else {
                setError((val) => ({ ...val, folderUploadLoading: "Internal Server Error. Please try again" }));
            }
        } else {

            setError((val) => ({
                ...val,
                folderUploadLoading: "Internal Server Error. Please try again",
            }));
        }
    } catch {
        setError((val) => ({
            ...val,
            folderUploadLoading: "Internal Server Error. Please try again",
        }));
    }
    setIsLoading((val) => ({ ...val, folderUploadLoading: false }));
    setCreateHazard(false);
};

export const getcreateHazardIdentification = async (headersList, setIsLoading, setError, request_id, setHazardIdenti) => {
    setIsLoading((val) => ({ ...val, getHazardIdentification: true }));
    try {
        const resp = await fetch(URL.ERICSION.POST_POST_HAZARD_IDENTIFICATION + "/" + request_id, {
            method: "GET",
            headers: headersList,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (resp.status === 200) {
                setHazardIdenti(data);
                setError((val) => ({ ...val, getHazardIdentification: "Success" }));
            } else {
                setError((val) => ({ ...val, getHazardIdentification: data.message }));
                setHazardIdenti([]);
            }
        }
    } catch {
        setHazardIdenti([]);
        setError((val) => ({ ...val, getHazardIdentification: "Internal Server Error. Please try again" }));
    }
    setIsLoading((val) => ({ ...val, getHazardIdentification: false }));
};



export const getListHazardIdentification = async (headersList, param, setIsLoading, setError, setListHazardIdenti) => {
    setIsLoading((val) => ({ ...val, getListHazardIdentification: true }));
    try {
        // const interval = setInterval(async () => {
        const resp = await fetch(URL.ERICSION.GET_HAZARD_IDENTIFICATION_LIST + param, {
            method: "GET",
            headers: headersList,
        });

        const data = await resp.json();
        if (resp.status === 200) {
            // const listHazards = data2.filter(i => i.id === "REQ-HID-20241107-7d2d40b4")[0];
            setListHazardIdenti(data);
            setError((val) => ({ ...val, getListHazardIdentification: "Success" }));
            // if (listHazards.status === "BATCH_COMPLETED" || listHazards.status === "BATCH_FAILED") {
            //     clearInterval(interval);
            // }
        }
        else {
            setError((val) => ({ ...val, getListHazardIdentification: "Internal Server Error. Please try again" }));
            setListHazardIdenti([]);
        }
        // }, 10000);
    } catch {
        setListHazardIdenti([]);
        setError((val) => ({ ...val, getListHazardIdentification: "Internal Server Error. Please try again" }));
    }
    setIsLoading((val) => ({ ...val, getListHazardIdentification: false }));
};