import { useEffect, useState } from "react";
import { Table } from "@eds/vanilla";
import { exportToCSVMethod } from "../../../utils/helperMethod";
export default function TotalTimeTakenStatisticsTable({ totaltime_stats_js }) {
  const [getData, setData] = useState();

  useEffect(() => {
    let product = [];
    for (
      let tblData = Object.keys(totaltime_stats_js["Metric"]).length - 1;
      tblData >= 0;
      tblData--
    ) {
      // for (let tblData = 0 ; tblData <= Object.keys(loneworkerData['Email']).length - 1; tblData++) {
      product.push({
        Metric: totaltime_stats_js["Metric"][tblData],
        Value: totaltime_stats_js["Value"][tblData],
      });
    }
    const tableDOM = document.querySelector("#Total-Time-Taken");
    setData(product);
    const table = new Table(tableDOM, {
      data: product,
      columns: [
        {
          key: "Metric",
          title: "Metric",
          sort: "desc",
        },
        {
          key: "Value",
          title: "Value",
          sort: "none",
        },
      ],
      sortable: true,
    });
    table.init();
  }, []);

  const callExport = () => {
    const csvData = [];
    getData?.forEach((val) => {
      csvData.push({
        Metric: val?.["Metric"],
        Value: val?.["Value"],
      });
    });
    exportToCSVMethod(csvData, "Total Time Taken - Statistics");
  };

  useEffect(() => {
    let _tr = document.querySelector(
      ".JHA-Reports-Total-Time-Taken.stickyHeader thead tr"
    );
    let _top = document.querySelector(".filterDiv").offsetHeight;
    if (_tr) {
      _tr.style.top = _top + "px";
    }
  }, [totaltime_stats_js]);

  return (
    <>
      <div className="table-top">
        <div className="table-top-left">
          <div className="table-info"></div>
        </div>
        <div className="table-top-right">
          <div className="table-buttons">
            <button
              className="btn"
              id="exportData-Reports-Total-Time-Taken"
              onClick={() => callExport()}
            >
              Export
            </button>
          </div>
          <div className="table-search">
            <input
              type="text"
              className="with-icon"
              placeholder="Search for..."
            />
            <div className="suffix icon-inside">
              <i className="icon icon-search"></i>
            </div>
          </div>
        </div>
      </div>
      <table
        id="Total-Time-Taken"
        className="table dashed JHA-Reports-Total-Time-Taken stickyHeader"
      ></table>
      {/* <div className="table-bottom">
        <div className="pagination-group">
          <ul className="pagination"></ul>

          <div className="actions">
            <label className="left">Go to</label>
            <input type="number" min="1" />

            <label className="left">Show</label>
            <div className="select" data-type="single">
              <button className="btn current-options">5</button>

              <div className="options-list">
                <div className="item active">5</div>
                <div className="item ">10</div>
                <div className="item ">15</div>
                <div className="item ">All</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
