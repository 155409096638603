import React, { useRef } from "react";

export default function DrawCanvas({ _key, ppedetected, uploadedImage, setIsDialogOpen, setDialogImgCount }) {
    const canvasRef = useRef(null);

    const drawBoundingBoxes = (data, image) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        const detectionColors = ["#DC2D37"];
        let colorIndex = 0;
        Object.entries(data).forEach(([key, value]) => {
            if (value.boundingBox && value.boundingBox.length > 0) {
                const box = value.boundingBox[0];
                if (
                    typeof box.left !== "undefined" &&
                    typeof box.top !== "undefined" &&
                    typeof box.width !== "undefined" &&
                    typeof box.height !== "undefined"
                ) {
                    const boxColor = detectionColors[colorIndex % detectionColors.length];
                    context.beginPath();
                    context.rect(
                        box.left * canvas.width,
                        box.top * canvas.height,
                        box.width * canvas.width,
                        box.height * canvas.height
                    );
                    context.lineWidth = 3;
                    context.strokeStyle = boxColor;
                    context.stroke();
                    const fontSize =
                        Math.min(box.width * canvas.width, box.height * canvas.height) *
                        0.1;
                    context.font = `bold ${fontSize}px Arial`;
                    const textWidth = context.measureText(key).width;
                    const textHeight = fontSize + 4;
                    context.fillStyle = boxColor;
                    context.fillRect(
                        box.left * canvas.width - 1,
                        box.top * canvas.height - textHeight,
                        textWidth + 10,
                        textHeight
                    );
                    context.fillStyle = "#FFF";
                    const textX = box.left * canvas.width + 5;
                    const textY = box.top * canvas.height - 5;
                    context.fillText(key, textX, textY);
                    colorIndex++;
                }
            }
        });
    };

    if (ppedetected && uploadedImage) {
        const image = new Image();
        image.src = uploadedImage;
        image.onload = () => drawBoundingBoxes(ppedetected, image);
    }

    const renderHazard = (key, value) => {
        if (Array.isArray(value)) {
            return value.map((item, index) => (
                <div key={`${key}_${index}`}>
                    {item.hazard_identified === "Y" ? (
                        <>
                            <span style={{ color: "green" }}>✔</span>
                            <span style={{ color: "green", marginLeft: "3px" }}>{item.hazard}</span>
                        </>
                    ) : (
                        <>
                            <i className="icon icon-triangle-warning" style={{ color: "red" }}></i>
                            <span style={{ color: "red", marginLeft: "1.5px" }}>{item.hazard}</span>
                        </>
                    )}
                </div>
            ));
        } else {
            return (
                <div key={key}>
                    {value.hazard_identified === "Y" ? (
                        <>
                            <span style={{ color: "green" }}>✔</span>
                            <span style={{ color: "green", marginLeft: "3px" }}>{value.hazard}</span>
                        </>
                    ) : (
                        <>
                            <i className="icon icon-triangle-warning" style={{ color: "red" }}></i>
                            <span style={{ color: "red", marginLeft: "1.5px" }}>{value.hazard}</span>
                        </>
                    )}
                </div>
            );
        }
    };

    return (
        <div className="card md-12 sm-12 lg-6">
            <div className="row">
            <div className="md-12 sm-12 lg-6">
                <div className="header">
                    <div className="left">
                        <div className="title">Uploaded Image</div>
                    </div>
                </div>
                <div className="content ">
                    <div className="column">
                        <canvas
                            ref={canvasRef}
                            style={{
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                            }}
                            onClick={() => {setIsDialogOpen(true); setDialogImgCount(_key)}}
                        ></canvas>
                    </div>
                </div>
            </div>

            <div className="md-12 sm-12 lg-6">
                <div className="header">
                    <div className="left">
                        <div className="title">AI Insights</div>
                    </div>
                </div>

                <div className="content">
                    <div className="column">
                        {Object.entries(ppedetected)?.map(([key, value]) =>
                            renderHazard(key, value)
                        )}
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}