import { Table } from "@eds/vanilla";
import React, { useEffect, useState } from "react";
import _ from "lodash";
export default function SafetyDialogueTopicTable({
  topicsData,
  getSafetyTopicsDetails,
}) {
  const [topicTable, setTopicTable] = useState({});
  const getProducts = () => {
    const products = [];
    for (let tblData in topicsData?.["topic"]) {
      products.push({
        Topic: topicsData?.["topic"]?.[tblData],
        Month: topicsData?.["created"]?.[tblData],
        userCount: topicsData?.["count"]?.[tblData],
        attachment: topicsData?.["attachment"]?.[tblData],
      });
    }
    return products;
  };

  useEffect(() => {
    if (_.isEmpty(topicTable)) {
      const tableDOM = document.querySelector("#safetydialogueTopics-table");
      const table = new Table(tableDOM, {
        data: getProducts(),
        columns: [
          {
            key: "Month",
            title: "Month",
            sort: "none",
            headerStyle: "text-align:center",
            cellStyle: "text-align:center",
          },
          {
            key: "Topic",
            title: "Topic",
            sort: "none",
            headerStyle: "text-align:center",
            cellStyle: "text-align:center",
          },
          {
            key: "userCount",
            title: "Number of Users",
            sort: "none",
            headerStyle: "text-align:center",
            cellStyle: "text-align:center",
          },
          {
            key: "attachment",
            title: "Attachment",
            headerStyle: "text-align:center",
            cellStyle: "text-align:center",
            onCreatedCell: (td, data) => {
              td.innerHTML = `<a class="btn icon-btn" target="_blank" title="Click here to Download" href="${data}"><span><i class="icon icon-download-save"></i></span></a>`;
            },
          },
        ],

        // scroll: true,
        sortable: true,
      });
      table.init();
      // Export button
      document
        .querySelector("#export-safetydialogueTopics-table")
        .addEventListener("click", () => {
          exportToCSVHandler(table.data);
        });
      setTopicTable(table);
    } else {
      topicTable.update(getProducts());
    }
  }, []);

  const exportToCSVHandler = (data) => {
    const csvData = [
      ["Month", "Topic", "Number of Users", "Attachment"],
      ...data?.map((i) => [
        i?.["Month"],
        i?.["Topic"],
        i?.["userCount"],
        i?.["attachment"],
      ]),
    ];
    let csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Safety Dialogue Topics table.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  };

  return (
    <div>
        <div className="table-top">
          <div className="table-top-left">
            <div className="table-info"></div>
          </div>
          <div className="table-top-right">
            <div className="table-buttons">
              <button
                className="btn"
                onClick={() => {
                  getSafetyTopicsDetails();
                }}
              >
                Refresh
              </button>
              <button className="btn" id="export-safetydialogueTopics-table">
                Export
              </button>
            </div>
            <div className="table-search">
              <input
                type="text"
                className="with-icon"
                placeholder="Search for..."
              />
              <div className="suffix icon-inside">
                <i className="icon icon-search"></i>
              </div>
            </div>
          </div>
        </div>
        <table id="safetydialogueTopics-table" className="table dashed stickyHeader horizonatalScroll"></table>

        <div className="table-bottom">
          <div className="pagination-group">
            <ul className="pagination"></ul>

            <div className="actions">
              <label className="left">Go to</label>
              <input type="number" min="1" />

              <label className="left">Show</label>
              <div className="select" data-type="single">
                <button className="btn current-options">5</button>

                <div className="options-list">
                  <div className="item active">5</div>
                  <div className="item">10</div>
                  <div className="item">15</div>
                  <div className="item">All</div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}
