import { createSlice } from "@reduxjs/toolkit";
import authApi from "../services/authApi";

const initialState = {
  roles: [],
  forms: {},
  features: [],
  access: [],
  favourites: [],
  filter_dates: {},
};

export const userAccessSlice = createSlice({
  name: "userAccess",
  initialState,
  reducers: {
    updateRoles: (state = initialState, action) => {
      state.roles = [...action.payload];
    },
    updateForms: (state = initialState, action) => {
      state.forms = { ...action.payload };
    },
    updateFeatures: (state = initialState, action) => {
      state.features = [...action.payload];
    },
    updateUserAccess: (state = initialState, action) => {
      state.access = [...action.payload];
    },
    updateFavourites: (state = initialState, action) => {
      state.favourites = [...action.payload];
    },
    updateFilterDates: (state = initialState, action) => {
      state.filter_dates = { ...action.payload };
    },
    resetUserAccess: (state = initialState, action) => {
      state.roles = [];
      state.forms = {};
      state.features = [];
      state.access = [];
      state.favourites = [];
      state.filter_dates = {};
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.roles.matchFulfilled,
      (state, { payload }) => {
        const { data = {}, status_code } = payload;
        if (status_code === 200) {
          const {
            features,
            filter_date_details,
            forms,
            roles: tempRoles = [],
          } = data;
          const roles = [...tempRoles];

          if (forms?.safety_forms?.length) {
            roles.some((role) => role === "CONSOLE_SAFETYFORM_VIEWER") &&
              roles.push("CONSOLE_SAFETY_FORM_VIEWER");

            roles.some((role) =>
              ["CONSOLE_GLOBAL_ADMIN", "CONSOLE_SAFETYFORM_APPROVALS"].includes(
                role
              )
            ) && roles.push("CONSOLE_SAFETY_FORM_APPROVALS");
          }

          if (forms?.audit_forms?.length) {
            roles.some((role) => role === "CONSOLE_SAFETYFORM_VIEWER") &&
              roles.push("CONSOLE_SITE_SAFETYFORM_VIEWER");

            roles.some((role) =>
              ["CONSOLE_GLOBAL_ADMIN", "CONSOLE_SAFETYFORM_APPROVALS"].includes(
                role
              )
            ) && roles.push("CONSOLE_SITE_SAFETYFORM_APPROVALS");
          }

          features?.some(
            (feature) => feature.name === "SMARTFACTORY" && feature.value
          ) && roles.push("CONSOLE_SMARTFACTORY_SETTING");
          state.roles = roles;
          state.forms = forms || [];
          state.features = features || [];
          state.filter_dates = filter_date_details || {};
        }
      }
    );

    builder.addMatcher(
      authApi.endpoints.favorites.matchFulfilled,
      (state, { payload }) => {
        const { data = {}, status_code } = payload;
        if (status_code === 200) {
          state.favourites = data || [];
        }
      }
    );
  },
});

export const {
  updateRoles,
  updateForms,
  updateFeatures,
  updateUserAccess,
  updateFavourites,
  updateFilterDates,
  resetUserAccess,
} = userAccessSlice.actions;

export default userAccessSlice.reducer;
