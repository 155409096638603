import { useEffect, useState } from "react";
import MultiselectDropDown from "../../../components/CustomMultiSelectDropDown/MultiSelectDropDown";
import moment from "moment-timezone";
import Datepicker from "../../../components/Datepicker/Datepicker";
import { updateSiteWalkGoals } from "../../../api/SitewalkGoals.api";
import Loading from "../../../components/Loading/Loading";
import { isNumber } from "lodash";
import { useSelector } from "react-redux";
export default function SiteWalkGoalsModel({
  getAuditorsList,
  selectedAuditorGoal,
  setSelectedAuditorGoal,
  setIsAutoCall,
}) {
  const [getAllAuditors, setAllAuditors] = useState([]);
  const [selectedAuditors, setSelectedAuditors] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(
    new Date(selectedAuditorGoal?.Date || moment().local().format("YYYY-MM"))
  );
  const [selectedGoalsCount, setSelectedGoalsCount] = useState(
    selectedAuditorGoal?.["Stretch Target"] || 0
  );
  const [loadingVal, setLoadingVal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { headersList } = useSelector((state) => state.envVariables);

  const closeHandler = () => {
    setSelectedAuditorGoal({ isOpen: false });
  };

  useEffect(() => {
    if (selectedAuditorGoal?.type === "Add") {
      setAllAuditors(
        getAuditorsList?.map((val) => {
          const temp = Object.entries(val);
          return { formId: temp?.[0]?.[0], form_name: temp?.[0]?.[1] };
        }) || []
      );
    } else {
      setAllAuditors([
        {
          formId: selectedAuditorGoal?.Auditor?.id,
          form_name: selectedAuditorGoal?.Auditor?.name,
        },
      ]);
      setSelectedAuditors([selectedAuditorGoal?.Auditor?.id]);
    }
  }, [selectedAuditorGoal, getAuditorsList]);

  const onActionHandler = () => {
    let status_code;
    if (selectedAuditorGoal?.type === "Delete") {
      status_code = updateSiteWalkGoals(
        {
          auditor: selectedAuditors?.[0],
          date: moment(selectedMonth).format("YYYY-MM-01"),
        },
        setLoadingVal,
        setErrorMsg,
        "Delete",
        headersList
      );
    } else if (selectedAuditorGoal?.type === "Update") {
      const auditorsPayload = {
        Month: moment(selectedMonth).format("YYYY-MM-01"),
        "Target Count": selectedGoalsCount,
      };
      const selectedauditorslist = getAllAuditors?.reduce(
        (acc, val) =>
          selectedAuditors.includes(val?.formId)
            ? acc.concat({ id: val?.formId, name: val?.form_name })
            : acc,
        []
      );
      auditorsPayload.Auditor = selectedauditorslist;
      status_code = updateSiteWalkGoals(
        { data: [auditorsPayload] },
        setLoadingVal,
        setErrorMsg,
        "PUT",
        headersList
      );
    } else if (selectedAuditorGoal?.type === "Add") {
      const auditorsPayload = {
        Month: moment(selectedMonth).format("YYYY-MM-01"),
        "Target Count": selectedGoalsCount,
      };
      const selectedauditorslist = getAllAuditors?.reduce(
        (acc, val) =>
          selectedAuditors.includes(val?.formId)
            ? acc.concat({ id: val?.formId, name: val?.form_name })
            : acc,
        []
      );
      auditorsPayload.Auditor = selectedauditorslist;
      status_code = updateSiteWalkGoals(
        { data: [auditorsPayload] },
        setLoadingVal,
        setErrorMsg,
        "POST",
        headersList
      );
    } else {
    }
    status_code.then((val) => {
      if (val === 200) {
        closeHandler();
        setIsAutoCall(true);
      }
    });
  };

  return (
    <div className="dialog show" data-type="fullscreen">
      <div className="content">
        <div className="eds-form">
          <div className="form-header">
            <div className="title">{selectedAuditorGoal?.type} Goal</div>
          </div>
          <div className="form-body">
            {selectedAuditorGoal?.type === "Delete" ? (
              <p>
                {`Are you sure that you want to delete
                ${selectedAuditorGoal?.Auditor?.name}'s goal? `}
              </p>
            ) : (
              <div className="form-group">
                <div className="form-element">
                  <label className="required">Auditors</label>
                  <MultiselectDropDown
                    setStatusOption={setSelectedAuditors}
                    formDetailsUG={getAllAuditors}
                    defaultValues={selectedAuditors}
                    isDisabled={
                      selectedAuditorGoal?.type === "Update" ? true : false
                    }
                  />
                </div>
                <div className="form-element">
                  <label className="required">Month</label>
                  <Datepicker
                    selectedDate={selectedMonth}
                    setDate={setSelectedMonth}
                    isMonthyearPicker={true}
                    isDisable={selectedAuditorGoal?.type === "Update"}
                    minDate={new Date(moment().local().format("YYYY-MM"))}
                  />
                </div>
                <div className="form-element">
                  <label htmlFor="goalCount" className="required">
                    Stretch Target
                  </label>
                  <input
                    type="number"
                    className={`left-aligned validation`}
                    style={{ width: "100%" }}
                    id="goalCount"
                    required
                    min={0}
                    value={selectedGoalsCount}
                    onChange={(event) => {
                      setSelectedGoalsCount(event.target.value);
                    }}
                  />
                  <span className="validation-msg">
                    Please enter valid number
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="form-group">
            <div className="form-element">
              {loadingVal ? (
                <Loading />
              ) : (
                errorMsg && <p className="color-red">{errorMsg}</p>
              )}
            </div>
          </div>
          <div className="form-footer">
            <button
              className="btn ghost"
              onClick={() => {
                closeHandler();
              }}
            >
              Cancel
            </button>
            <button
              className={`btn ${
                selectedAuditorGoal?.type === "Delete" ? "warning" : "primary"
              }`}
              disabled={
                selectedAuditors.length < 1 ||
                isNumber(selectedAuditorGoal) ||
                selectedMonth === null ||
                (selectedAuditorGoal?.type !== "Delete" &&
                  selectedGoalsCount < 1)
              }
              onClick={onActionHandler}
            >
              {selectedAuditorGoal?.type}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
