// import { useEffect } from "react";
// import { Notification } from '@eds/vanilla';

export default function AlertEDS({ errMsg, status_code, close, closeAlert }) {
    let class_color = "";
    let class_icon = "";
    switch (status_code) {
        case 201:
            class_color = "stripe-orange";
            class_icon = "icon-triangle-warning";
            break;

        case "error":
            class_color = "stripe-red";
            class_icon = "icon-fault";
            break;

        case 200:
            class_color = "stripe-green";
            class_icon = "icon-check";
            break;
    }
    // useEffect(() => {
    //     const notification = new Notification({
    //         title: 'Critical banner message placeholder',
    //         icon: 'icon-fault',
    //         stripeColor: 'red',
    //         isBanner: true,
    //         action: () => { }
    //     })

    //     notification.init();
    // }, [])

    // const closeAlert = () => {
    //     setResponce({ status_code: "", message: "" });
    // }

    return (
        <div className={`notification banner mb-lg ${class_color}`}>
            <div className="top-row">
                <div className="title"><i className={`icon ${class_icon}`}></i> {errMsg}</div>
                {close && <i className="icon icon-cross close" onClick={()=> closeAlert()}></i>}
            </div>
        </div>
    )
}
