import React, { useEffect } from "react";

import { ColorScale, VerticalBarChartStacked } from "@eds/vanilla";
export default function LicenceUsageGraph({ graphData }) {

  const getChartData = (data1) => {
    data1.series.forEach((i, k) => {
      if (i.name === 'FTE') {
        i.name = 'Full Time Employees'
      }
    });
    return data1;
  };

  const data1 = getChartData(graphData);

  useEffect(() => {
    const myColorScale = new ColorScale({ reverse: true });
    myColorScale.init();
    const data = data1;
    const chart = new VerticalBarChartStacked({
      element: document.getElementById("licenceUsageGraph"),
      data: data,
      y: { unit: "Count" },
      colorScale: myColorScale,
    });
    chart.init();
    return () => {
      chart.destroy();
    };
  }, []);

  return <div id="licenceUsageGraph"></div>;
}
