import { URL } from "../utils/Constant";
export const getUserManagementExpiry = async (
  setusergroupExpiry,
  param,
  setLoading,
  setErrMsg,
  headersList
) => {
  setLoading(true);
  const resp = await fetch(URL.ERICSION.GET_USER_MANAGEMENT_EXPIRY, {
    method: "GET",
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      setusergroupExpiry(data.data.validationperiod);
      setLoading(false);
    } else {
      setLoading(false);
      setErrMsg(data.message);
    }
  }
};

export const setUserManagementExpiry = async (
  param,
  setLoading,
  setErrMsg,
  headersList
) => {
  setLoading(true);
  let jsonData = JSON.stringify(param);
  const resp = await fetch(URL.ERICSION.GET_USER_MANAGEMENT_EXPIRY, {
    method: "PUT",
    body: jsonData,
    headers: headersList,
  });
  if (resp.status === 200) {
    const data = await resp.json();
    setLoading(false);
  } else {
    const errorData = await resp.json();
    setLoading(false);
    setErrMsg(errorData.message);
  }
};
