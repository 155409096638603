import { useEffect, useMemo, useState } from "react";
import { Dialog } from "@eds/vanilla";
import { DeleteSupplierEntry } from "../../api/Validomain.api";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import Loading from "../../components/Loading/Loading";

export default function DeleteSupplier({
  setdeleteSupplier,
  deleteSupplier,
  setconfirmationMessage,
  setLoading,
  setErrMsg,
  headersList,
  confirmationMessage,
  loadingVal,
  ValidsupplierList,
}) {
  useEffect(() => {
    const dialogDOM = document.getElementById("deleteUser-dialog");
    const dialog = new Dialog(dialogDOM);
    dialog.init();
    dialog.show();
    return () => {
      dialog.destroy();
    };
  }, []);

  const onCancel = () => {
    setdeleteSupplier({ isOpen: false });
  };
  const handleDelete = async () => {
    await DeleteSupplierEntry(
      setconfirmationMessage,
      {
        internal_supplier_id: deleteSupplier.Supplier.id,
      },
      setLoading,
      setErrMsg,
      headersList
    );
    setTimeout(() => {
      setconfirmationMessage({ errorMsg: "", statusCode: "" });
      onCancel();
      ValidsupplierList();
    }, 2000);
  };

  return (
    <div id="deleteUser-dialog" className="dialog sm show" data-type="simple">
      <div className="content ">
        <div className="top">
          <div className="title">{"Confirmation"}</div>
          <div className="right">
            <button className="btn-icon">
              <i className="icon icon-cross" onClick={() => onCancel()}></i>
            </button>
          </div>
        </div>
        {loadingVal ? (
          <Loading />
        ) : confirmationMessage.errorMsg !== "" ? (
          <div className="tile sm-12">
            <AlertEDS
              errMsg={confirmationMessage.errorMsg}
              status_code={
                confirmationMessage.statusCode === 200 ? 200 : "error"
              }
              close={false}
            />
          </div>
        ) : (
          <>
            <br />
            <div className="header">
              <div className="left">
                <div className="title">
                  Are you sure you want to delete {deleteSupplier.Supplier.name}{" "}
                  supplier?
                </div>
              </div>
            </div>
            <div className="bottom">
              <button className="btn " onClick={() => onCancel()}>
                Cancel
              </button>
              <button className="btn warning" onClick={() => handleDelete()}>
                Delete
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
