import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import environmentReducer from "./slices/envVariablesSlice";
import userAccessReducer from "./slices/accessSlice";
import supportApi from "./services/supportApi";
import AIinferenceApi from "./services/AIinferenceApi";
import safetyFormsApi from "./services/safetyFormsApi";
import sitewalkAnalyticsApi from "./services/sitewalksAnalyticsApi";
import loneworkerApi from "./services/loneworkerApi";
import authApi from "./services/authApi";
import safetyActionsApi from "./services/safetyActionsApi";
import configuartionApi from "./services/configurationApi";
import incidentsApi from "./services/incidentsApi";
export const appReducer = combineReducers({
  loggedInUser: userReducer,
  envVariables: environmentReducer,
  userAccess: userAccessReducer,
  [supportApi.reducerPath]: supportApi.reducer,
  [AIinferenceApi.reducerPath]: AIinferenceApi.reducer,
  [safetyFormsApi.reducerPath]: safetyFormsApi.reducer,
  [sitewalkAnalyticsApi.reducerPath]: sitewalkAnalyticsApi.reducer,
  [loneworkerApi.reducerPath]: loneworkerApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [safetyActionsApi.reducerPath]: safetyActionsApi.reducer,
  [configuartionApi.reducerPath]: configuartionApi.reducer,
  [incidentsApi.reducerPath]: incidentsApi.reducer,
});

export const allMiddlewares = [
  supportApi.middleware,
  AIinferenceApi.middleware,
  safetyFormsApi.middleware,
  sitewalkAnalyticsApi.middleware,
  loneworkerApi.middleware,
  authApi.middleware,
  safetyActionsApi.middleware,
  configuartionApi.middleware,
  incidentsApi.middleware,
];

export const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
