import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL } from "../../utils/Constant";

export const safetyActionsApi = createApi({
  reducerPath: "safetyActionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().envVariables.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSafetyActionsFilters: builder.query({
      query: (params) => ({
        url: URL.ERICSION.GET_ACTIONS_FILTERS,
        params,
      }),
    }),
    getDeficiencies: builder.mutation({
      query: (body) => ({
        url: URL?.ERICSION?.DEFICIENCIES_URL,
        method: "POST",
        body,
        headers: {
          "content-type": "application/json",
        },
      }),
    }),
    updateDeficiency: builder.mutation({
      query: (body) => ({
        url: URL.ERICSION.DEFICIENCIES_UPDATE_URL,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetSafetyActionsFiltersQuery,
  useGetDeficienciesMutation,
  useUpdateDeficiencyMutation,
} = safetyActionsApi;

export default safetyActionsApi;
