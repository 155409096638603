import React, { useEffect } from "react";
import { VerticalBarChartStacked } from "@eds/vanilla";
import moment from "moment";
export default function FormSubmissionsGraph({ approvereject_js }) {
  const dateGraph = approvereject_js["Date"];
  const countGraph = approvereject_js["count"];
  const statusGraph = approvereject_js["status"];
  const createData = () => {
    const commonData = [];
    const submittedValues = [];
    const approvedValues = [];
    const rejectedValues = [];
    const aiApprovedValues = [];

    for (const property in dateGraph) {
      const index = commonData.indexOf(
        moment(dateGraph[property], "DD/MM/YYYY").format("YYYY-MM-DD")
      );
      if (index > -1) {
        if (statusGraph[property] === "Submitted") {
          submittedValues[index] = countGraph[property];
        } else if (statusGraph[property] === "Approved") {
          approvedValues[index] = countGraph[property];
        } else if (statusGraph[property] === "Rejected") {
          rejectedValues[index] = countGraph[property];
        } else if (statusGraph[property] === "AI Approved") {
          aiApprovedValues[index] = countGraph[property];
        } else {
        }
        console.log("Values :::>>>", dateGraph[property]);
      } else {
        commonData.push(
          moment(dateGraph[property], "DD/MM/YYYY").format("YYYY-MM-DD")
        );
        if (statusGraph[property] === "Submitted") {
          submittedValues.push(countGraph[property]);
          approvedValues.push(0);
          rejectedValues.push(0);
          aiApprovedValues.push(0);
        } else if (statusGraph[property] === "Approved") {
          submittedValues.push(0);
          approvedValues.push(countGraph[property]);
          rejectedValues.push(0);
          aiApprovedValues.push(0);
        } else if (statusGraph[property] === "Rejected") {
          submittedValues.push(0);
          approvedValues.push(0);
          rejectedValues.push(countGraph[property]);
          aiApprovedValues.push(0);
        } else if (statusGraph[property] === "AI Approved") {
          submittedValues.push(0);
          approvedValues.push(0);
          rejectedValues.push(0);
          aiApprovedValues.push(countGraph[property]);
        } else {
          submittedValues.push(0);
          approvedValues.push(0);
          rejectedValues.push(0);
          aiApprovedValues.push(0);
        }
      }
    }
    return {
      common: commonData,
      series: [
        { name: "Submitted", values: submittedValues },
        { name: "Approved", values: approvedValues },
        { name: "Rejected", values: rejectedValues },
        { name: "AI Approved", values: aiApprovedValues },
      ],
    };
  };
  useEffect(() => {
    const Data = createData();
    const chart = new VerticalBarChartStacked({
      element: document.getElementById("formSubmissionsGraph-reports"),
      data: Data,
      y: { unit: "Count " },
      x: { unit: "Date", scaleType: "time", showLabels: true },
    });

    chart.init();
  }, []);

  return <div id="formSubmissionsGraph-reports"></div>;
}
