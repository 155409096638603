import React, { useState } from "react";
import { useRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { EDSTable } from "../../components/EDSTable/EDSTable";
import { exportToCSVMethod } from "../../utils/helperMethod";
import moment from "moment-timezone";
import { useIncidentListMutation } from "../../redux/services/incidentsApi";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import _ from "lodash";
import Loading from "../../components/Loading/Loading";
import IncidentImageDialog from "./IncidentImageDialog";

export default function Incidents() {
  const [incidentList, { data: IncidentsData, isFetching = false }] =
    useIncidentListMutation();
  const [startDate, setStartDate] = useState(
    new Date(
      moment().local().subtract(1, "weeks").format("YYYY-MM-DD 00:00:00")
    )
  );
  const [endDate, setEndDate] = useState(
    new Date(moment().local().format("YYYY-MM-DD 23:59:59"))
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const datepickerData = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  };
  useEffect(() => {
    const formData = new FormData();
    formData.append("from_date", moment(startDate).format("YYYY-MM-DD"));
    formData.append("to_date", moment(endDate).format("YYYY-MM-DD"));
    incidentList(formData);
  }, [incidentList, isRefresh]);

  const tableRef = useRef(null);
  const tableOptions = useMemo(() => {
    return {
      data: [],
      columns: [
        {
          key: "Created_By",
          title: "Created By",
          sort: "none",
          width: "200px",
        },
        {
          key: "Incident_Date_Time",
          title: "Incident Date-Time",
          sort: "none",
          width: "200px",
        },
        {
          key: "attachment",
          title: "Attachment",
          width: "150px",
          onCreatedCell: (td, data) => {
            if (!_.isEmpty(data)) {
              td.innerHTML = `<img style="cursor:pointer;" class="incident-Img" src="${data}" alt="Incident-Image" />`;
              td.addEventListener("click", (event) => {
                setIsDialogOpen(true);
                setImageData(data);
              });
            } else {
              td.innerHTML = ""
            }
          }
        },
        {
          key: "Summary",
          title: "Summary",
          sort: "none"
        },
        {
          key: "Channel",
          title: "Channel",
          sort: "none",
          width: "150px",
          cellClass: "text-wrap",
          onCreatedCell: (td, data) => {
            td.innerHTML = data === "mobileapp" ? "Mobile App" : "Web App";
          }
        },
      ],
      sortable: true,
      actions: true,
      expandable: true,
      scroll: true,
      onCreatedDetailsRow: (td, data) => {
        td.innerHTML = data.recipients.map(i => `<div class="RecipientID">${i}</div>`).join("");
      },
    };
  }, []);

  const incidents = useMemo(() => {
    const products = [];
    if (
      IncidentsData === undefined ||
      _.isEmpty(IncidentsData) ||
      _.isEmpty(IncidentsData.data)
    ) {
      return [];
    }

    const jsonData = JSON.parse(IncidentsData.data);
    const { date, user, description, source, recipients, attachments } = jsonData;
    for (let i = 0; i < Object.keys(user).length; i++) {
      products.push({
        Created_By: user[i],
        Incident_Date_Time: date[i],
        Summary: description[i],
        Channel: source[i],
        recipients: recipients[i],
        attachment: attachments[i]
      });
    }

    return products;
  }, [IncidentsData, isRefresh]);

  const exportToCSVHandler = (data) => {
    if (data.length > 0) {
      const csvData = [];
      data?.forEach((val) => {
        csvData.push({
          "Created By": val?.Created_By,
          "Incident Date-Time": val?.Incident_Date_Time,
          Summary: val?.Summary,
          Channel: val?.Channel === "mobileapp" ? "Mobile App" : "Web App",
          "Incident Report recipients": val?.recipients?.join(' ,')
        });
      });
      exportToCSVMethod(csvData, "Incidents");
    }
  };

  const callIncidentsApi = () => {
    const formData = new FormData();
    formData.append("from_date", moment(startDate).format("YYYY-MM-DD"));
    formData.append("to_date", moment(endDate).format("YYYY-MM-DD"));
    incidentList(formData);
  };

  useEffect(() => {
    tableRef.current.update(incidents);
  }, [IncidentsData]);

  return (
    <>
      <div className="row">
        <div className="tile filterDiv">
          <CustomDatePickerForm
            formName="Incidents"
            datepickerData={datepickerData}
            filterData={{}}
            callApply={callIncidentsApi}
            disableButtton={false}
            filtersAccess={{}}
          />
        </div>
        <div className="tile sm-12 lg-12">
          <div className="content">
            {isFetching ? (
              <Loading />
            ) : (
              <EDSTable
                classType="dashed"
                exportToCSV={exportToCSVHandler}
                id="incidents"
                options={tableOptions}
                refresh={() => {
                  setIsRefresh((val) => !val);
                }}
                tableRef={tableRef}
              />
            )}
          </div>
        </div>
      </div>
      {isDialogOpen && (
        <IncidentImageDialog
          setIsDialogOpen={setIsDialogOpen}
          imageData={imageData}
        ></IncidentImageDialog>
      )}
    </>
  );
}
