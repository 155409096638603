import { useEffect } from "react";
import { useState } from "react";
import CheckboxGroup from "react-checkbox-group";
import "./CustomCheckboxGroup.css";
export default function CustomCheckboxGroup({
  reasonsList,
  setReasonsList,
  defaultValues,
}) {
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(reasonsList);
    setSelected(defaultValues);
  }, [reasonsList, defaultValues]);
  const onReasonsChange = (values) => {
    setReasonsList(values);
    setSelected(values);
  };
  return (
    <div className="custom-checkbox">
      <CheckboxGroup
        label="Custom Checkbox Group"
        value={selected}
        onChange={(values) => onReasonsChange(values)}
      >
        {(Checkbox) => (
          <>
            {options?.map((val, index) => (
              <div key={index}>
                <Checkbox value={val} id={index} />
                <label htmlFor={index} key={index}>{val}</label>
              </div>
            ))}
          </>
        )}
      </CheckboxGroup>
    </div>
  );
}
