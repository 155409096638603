import { URL } from "../utils/Constant";
const getRequestBody = (params) => {
  let formData = new FormData();
  for (let i in params) {
    formData.append(i, params[i]);
  }
  return formData;
};
const getUrlByQueryParams = (url, params) => {
  let queryString = "";
  Object.entries(params).forEach(([key, value], index) => {
    const formattedValue = value.replace("&", "%26");
    queryString += `${index === 0 ? "?" : "&"}${key}=${formattedValue}`;
  });
  return url + queryString;
};
export const getCustomerDetails = async (headers) => {
  const resp = await fetch(URL.INCIDENT_MANAGEMENT.CUSTOMER_DETAILS, {
    method: "GET",
    headers: headers,
  });

  if (resp.ok) {
    const data = await resp.json();
    return data;
    // setData(data.data)
    // setErrMsg(data.message);
    // setLoading(false);
  }
};
export const getCustomerRegion = async (payload, headers) => {
  const requestUrl = getUrlByQueryParams(
    URL.INCIDENT_MANAGEMENT.CUSTOMER_REGION,
    payload
  );
  const resp = await fetch(requestUrl, { method: "GET", headers: headers });
  if (resp.ok) {
    const data = await resp.json();
    return data;
  }
};
export const getCustomerMarket = async (payload, headers) => {
  const requestUrl = getUrlByQueryParams(
    URL.INCIDENT_MANAGEMENT.CUSTOMER_MARKET,
    payload
  );

  const resp = await fetch(requestUrl, { method: "GET", headers: headers });
  if (resp.ok) {
    const data = await resp.json();
    return data;
  }
};
export const generateTicket = async (
  payload,
  setIsLoading,
  setErrMsg,
  headers
) => {
  setIsLoading(true);
  setErrMsg("");
  try {
    const resp = await fetch(URL.INCIDENT_MANAGEMENT.GENERATE_TICKET, {
      method: "POST",
      body: getRequestBody(payload),
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      setIsLoading(false);
      setErrMsg(data.message);
      return data;
    }
  } catch {}
  setIsLoading(false);
  setErrMsg("Internal Server Error. Please try again later !!!");
  return {};
};

export const getCustomerTicketDetails = async (payload, headers) => {
  const requestUrl = getUrlByQueryParams(
    URL.INCIDENT_MANAGEMENT.GENERATE_TICKET_DETAILS,
    payload
  );

  const resp = await fetch(requestUrl, { method: "GET", headers: headers });
  if (resp.ok) {
    const data = await resp.json();
    return data;
  }
};
