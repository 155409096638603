import { URL } from "../utils/Constant";

export const getFormDetails = async (setData, headers) => {
  const defaultResp = { loading: false, data: {}, message: "" };
  setData({ ...defaultResp, loading: true });
  let url = `${URL.ERICSION.GET_REPORTS_FILTERS}?reporttab=Y&ijhatab=Y`;

  await fetch(url, {
    method: "GET",
    headers: headers,
  })
    .then(async (data) => await data.json())
    .then((data) => {
      defaultResp.data = JSON.parse(data?.data || "{}");
      defaultResp.message = data?.message || "Internal Server Error";
    })
    .catch((error) => {
      defaultResp.data = { form: {} };
      defaultResp.message = "Internal Server Error";
    });
  setData(defaultResp);
};
