import { useNavigate } from "react-router-dom";

export const AccessDenied = () => {
  const navigate = useNavigate();

  return (
    <div className="empty-state">
      <div className="message">
        <p className="text-xl color-red">Access Denied</p>
        <button
          className="btn"
          onClick={() => {
            navigate("/home");
          }}
        >
          Go to home
        </button>
      </div>
    </div>
  );
};

export default AccessDenied;
