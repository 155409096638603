import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { domain_url } from "../../utils/Constant";

export const IncidentsList = createApi({
  reducerPath: "IncidentsList",
  baseQuery: fetchBaseQuery({
    baseUrl: domain_url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().envVariables.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    incidentList: builder.mutation({
      query: (payload) => ({
        url: "/incident_report_data",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useIncidentListMutation } = IncidentsList;
export default IncidentsList;
