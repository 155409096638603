import { Dialog, Select } from "@eds/vanilla";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { emailValidation } from "../../../../utils/helperMethod";
import {
  addNewData,
  removeFormDataFormTable,
  updateDistributionList,
} from "../../../../api/Notification.api";

export default function NotificationDialog({
  actionPopUp,
  setActionPopUp,
  distributionData,
  getTableData,
  setTableData,
  headers,
  setTableActionHappend,
}) {
  const [countCCOption, setCountCCOption] = useState(0);
  // const [recipentListState, setRecipentListState] = useState("");
  const [newEmailFlag, setNewEmailFlag] = useState(0);
  const updateCCCount = (length) => {
    if (length === 0) {
      setCountCCOption(0);
    } else {
      setCountCCOption(1);
    }
  };
  const [newEmailValue, setNewEmailValue] = useState({
    emailError: "",
    emailId: "",
    isEmailValid: false,
  });
  useEffect(() => {
    const selectDOM = document.getElementById("formList");
    if (selectDOM) {
      const select = new Select(selectDOM);
      select.init();
      selectDOM.addEventListener("selectOption", () => {
        const value = document.getElementById("formListValue")?.value;
        if (value !== undefined) {
          distributionData.setFormOption(value);
        }
      });
    }
  }, []);
  useEffect(() => {
    const selectDOM = document.getElementById("recipientList");
    if (selectDOM) {
      const select = new Select(selectDOM);
      select.init();
      if (newEmailFlag > 0) {
        var optionsContainer = document.querySelector(".options_container");
        optionsContainer.remove();
        select.value = distributionData.getCCOption;
      }
      if (actionPopUp.type === "Edit" && newEmailFlag === 0) {
        const param = distributionData.newEmailIds.map((i) => i.emailId);

        if (Array.isArray(param) && param.length > 0) {
          select.value = param;
          distributionData.setCCOption(select.value);
          updateCCCount(select.value.length);
          var optionsContainer = document.querySelector(".options_container");
          var spanElements = optionsContainer.querySelectorAll("span");
          param.forEach(function (item, index) {
            if (index < spanElements.length) {
              spanElements[index].textContent = item;
            }
          });
        }
      }
      selectDOM.addEventListener("selectOption", () => {
        if (Array.isArray(select.value) && select.value.length > 0) {
          if (select.value.includes("others")) {
            distributionData.setIsOthers(true);
          } else {
            distributionData.setIsOthers(false);
          }
          distributionData.setCCOption(select.value);
        }
        updateCCCount(select.value.length);
      });
    }
  }, [distributionData.getCCList]);

  useEffect(() => {
    const dialogs = document.getElementById("Notification-dialog");
    if (dialogs && actionPopUp.isOpen) {
      const dialog = new Dialog(dialogs);
      dialog.init();
      dialog.show();
      return () => {
        dialog.destroy();
      };
    }
  }, [actionPopUp.isOpen]);

  const createData = () => {
    const param = [];
    // creating actual data
    if (actionPopUp.type === "Add") {
      distributionData.getFormsList.map((item) => {
        param.push({
          formId: item.formId,
          "cc-list": distributionData.getCCOption,
        });
      });
    } else if (actionPopUp.type === "Edit") {
      param.push({
        formId: actionPopUp.form_Id,
        "cc-list": distributionData.getCCOption,
      });
    }
    return param;
  };

  const handleAdd = async () => {
    const param = createData();
    if (param.length !== 0) {
      await addNewData(
        param,
        setTableData,
        getTableData,
        headers,
        setTableActionHappend,
        setActionPopUp,
        distributionData.setFormsList,
        distributionData.setErrMsg,
        distributionData.setLoading
      );
      setTimeout(() => {
        distributionData.setErrMsg({ msg: "", status_code: "" });
      }, 4000);
      closeHandler();
    }
  };
  // Closing the Popup
  const closeHandler = () => {
    distributionData.setFormOption([]);
    distributionData.setNewEmailIds([]);
    setActionPopUp({ isOpen: false });
    distributionData.setIsOthers(false);
  };

  const handleDelete = () => {
    distributionData.setIsdeletionCompleted(true);
    const param = {
      formId: actionPopUp.formId,
    };
    removeFormDataFormTable(
      param,
      setTableData,
      headers,
      distributionData.setTableActionHappend,
      distributionData.setNewEmailIds,
      distributionData.setCCOption,
      distributionData.setFormsList,
      distributionData.setErrMsg,
      setActionPopUp,
      distributionData.setLoading
    );
    setTimeout(() => {
      distributionData.setErrMsg({ msg: "", status_code: "" });
      distributionData.setIsdeletionCompleted(false);
    }, 4000);
  };
  const handleEdit = () => {
    const param = createData();
    updateDistributionList(
      param,
      setTableActionHappend,
      setTableData,
      headers,
      setActionPopUp,
      distributionData.setErrMsg,
      distributionData.setLoading
    );
    distributionData.setNewEmailIds([]);
    setTimeout(() => {
      distributionData.setErrMsg({ msg: "", status_code: "" });
    }, 4000);
  };

  const addNewEmail = () => {
    const inputEle = document.getElementById("newEmail");
    var optionsContainer = document.querySelector(".options_container");
    var spanElements = optionsContainer.querySelectorAll("span");
    spanElements.forEach(function (item, index) {
      if (item.textContent === "others") {
        distributionData.setCCList([
          ...distributionData.getCCList,
          {
            formId: distributionData.getCCList.length,
            form_name: inputEle.value,
          },
        ]);
        distributionData.setNewEmailIds([
          ...distributionData.newEmailIds,
          {
            index: distributionData.newEmailIds.length,
            emailId: inputEle.value,
            emailError: "",
          },
        ]);
        setNewEmailFlag(1);
        const temp = distributionData.getCCOption;
        const index = temp.findIndex((item) => item === "others");
        temp[index] = inputEle.value;
        distributionData.setCCOption(temp);
        distributionData.setIsOthers(false);
        setNewEmailValue({
          emailError: "",
          emailId: "",
          isEmailValid: false,
        });
      }
    });
  };

  // Validation for other email ids and enabling disabling Add new Details button
  const newEmailHandler = (e) => {
    const { value } = e.target;
    if (value === "") {
      setNewEmailValue({
        emailError: "This field is required",
        emailId: value,
        isEmailValid: false,
      });
    } else if (emailValidation(e)) {
      setNewEmailValue({
        emailError: "Please enter a valid Email ID",
        emailId: value,
        isEmailValid: false,
      });

      e.target.classList.add("invalid");
      e.target.classList.remove("valid");
    } else {
      setNewEmailValue({ emailError: "", emailId: value, isEmailValid: true });

      e.target.classList.remove("invalid");
      e.target.classList.add("valid");
    }
  };
  return (
    <div
      className="dialog "
      data-trigger="#open-simple"
      data-type="simple"
      id="Notification-dialog"
    >
      <div className="content Notification1">
        <form className="eds-form">
          {_.isEmpty(distributionData.getFormsList) &&
          actionPopUp.type === "Add" ? (
            <>
              <div className="form-body ">
                <p className="title"> No Form Available</p>
                <div className="form-footer">
                  <button
                    className="btn "
                    onClick={() => setActionPopUp({ isOpen: false })}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="form-header">
                <div className="title">{actionPopUp?.type} Form Details</div>
              </div>
              <div className="form-body ">
                {actionPopUp.type === "Delete" ? (
                  <>
                    <p>
                      {`Are you sure that you want to delete
                ${actionPopUp.formName}'s Details? `}
                    </p>

                    <div className="form-footer">
                      <button
                        className="btn"
                        onClick={() => setActionPopUp({ isOpen: false })}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn warning"
                        type="button"
                        onClick={() => handleDelete()}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="form-group">
                    <div className="form-element">
                      <label htmlFor="formList">Form List*</label>
                      {actionPopUp.type === "Add" ? (
                        <div
                          className="select"
                          id="formList"
                          data-type="input-single"
                        >
                          <div className="clickable current-options">
                            <input
                              type="text"
                              placeholder="Select option"
                              id="formListValue"
                            />
                          </div>

                          <div className="options-list">
                            {distributionData.getFormsList.map((i, j) => {
                              return (
                                <div className="item" key={i.formId}>
                                  {i.form_name}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <input
                          className="inputField"
                          value={actionPopUp.form_Name}
                          id="FormDetail_input"
                          disabled
                        ></input>
                      )}
                    </div>
                    <div className="form-element">
                      <label htmlFor="recipientList">Recipient List*</label>
                      <div
                        className="select "
                        data-type="input-multi"
                        id="recipientList"
                      >
                        <div className="clickable current-options">
                          {actionPopUp.type === "Add" && (
                            <input
                              type="text"
                              placeholder="Select option"
                              id="recipentlist"
                              disabled={
                                distributionData.getFormOption.length === 0
                                  ? true
                                  : false
                              }
                            />
                          )}
                          {actionPopUp.type === "Edit" && (
                            <input
                              type="text"
                              placeholder="Select option"
                              id="recipentlist"
                            />
                          )}
                        </div>
                        <div className="options-list">
                          {distributionData.getCCList.map((i, j) => {
                            return (
                              <div className="item" key={i.formId}>
                                {i.form_name}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {distributionData.isOthers && (
                      <>
                        <div className="form-element">
                          <input
                            className="validation"
                            type="text"
                            placeholder="Enter Email ID"
                            id="newEmail"
                            onChange={(e) => newEmailHandler(e)}
                            value={newEmailValue.emailId}
                          />
                          {newEmailValue.emailError !== "" && (
                            <span className="validation-msg">
                              {newEmailValue.emailError}
                            </span>
                          )}
                        </div>
                        <div className="form-element">
                          <button
                            type="button"
                            className="btn primary"
                            onClick={addNewEmail}
                            disabled={!newEmailValue.isEmailValid}
                          >
                            Add Email
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              {actionPopUp.type === "Add" && (
                <div className="form-footer">
                  <button className="btn ghost" onClick={() => closeHandler()}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn primary"
                    onClick={handleAdd}
                    disabled={
                      distributionData.getFormOption.length === 0 ||
                      countCCOption === 0 ||
                      distributionData.isOthers
                    }
                  >
                    Add
                  </button>
                </div>
              )}
              {actionPopUp.type === "Edit" && (
                <div className="form-footer">
                  <button
                    className="btn ghost"
                    onClick={() => {
                      setActionPopUp({ isOpen: false });
                      distributionData.setIsOthers(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn primary"
                    onClick={handleEdit}
                    disabled={countCCOption === 0 || distributionData.isOthers}
                  >
                    Save
                  </button>
                </div>
              )}
            </>
          )}
        </form>
      </div>
    </div>
  );
}
