import { Dialog } from "@eds/vanilla";
import { useEffect } from "react";
import { useUserLastLoginQuery } from "../../redux/services/authApi";
import _, { split } from "lodash";
import { useSelector } from "react-redux";
export const LastLoginTime = () => {
  //const { data: lastLoginData = {}, isFetching } = useUserLastLoginQuery();
  const lastLoginData = useSelector(
    (state) => state.loggedInUser.lastLoginDate
  );
  useEffect(() => {
    if (lastLoginData) {
      const dialogDOM = document.getElementById("Last-Login-dialog");
      const dialog = new Dialog(dialogDOM);
      dialog.init();
      dialog.show();
      return () => {
        dialog.destroy();
      };
    }
  }, [lastLoginData]);

  return (
    <>
      {lastLoginData ? (
        <div id="Last-Login-dialog" className="dialog" data-type="simple">
          <div className="content">
            <div className="top">
              <div className="title">Last Login Detail</div>
              <div className="right">
                <button
                  className="btn-icon"
                  data-close="true"
                  onClick={() =>
                    sessionStorage.setItem("isLastLoginAPICalled", true)
                  }
                >
                  <i className="icon icon-cross"></i>
                </button>
              </div>
            </div>
            <div className="body">
              <p>
                The last time you logged in was on {lastLoginData.split("T")[0]}{" "}
                at {lastLoginData.split("T")[1]}
                {/* {lastLoginData.data.lastSignInDateTime.split("T")[0]} at{" "}
                {lastLoginData.data.lastSignInDateTime.split("T")[1]} */}
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
