import { URL } from "../utils/Constant";
import _ from "lodash";
export const getUserGraph = async (
  param,
  setLoading,
  setErrMsg,
  setData,
  headers
) => {
  setLoading((val) => ({ ...val, userStats: true }));
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  const url = URL?.ERICSION?.USER_STATS_URL || "";
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: formData,
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      var _data = _.cloneDeep(data.data);
      setData(_data);
      if (
        Object.values(
          JSON.parse(
            _.isEmpty(_data?.userlogingraph_data)
              ? "{}"
              : _data.userlogingraph_data
          )?.Date || {}
        ).length
      ) {
        setErrMsg((val) => ({ ...val, userStats: data.message }));
      } else {
        setErrMsg((val) => ({
          ...val,
          userStats: "No data available in this date range",
        }));
      }
      setLoading((val) => ({ ...val, userStats: false }));
    } else {
      setLoading((val) => ({ ...val, userStats: false }));
      setErrMsg((val) => ({ ...val, userStats: "Internal Server Error" }));
    }
  } catch (err) {
    setLoading((val) => ({ ...val, userStats: false }));
    setErrMsg((val) => ({ ...val, userStats: "Internal Server Error" }));
  }
};

export const getAIConversionDetails = async (
  param,
  setLoading,
  setErrMsg,
  setData,
  headers
) => {
  setLoading((val) => ({ ...val, aiData: true }));
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  const url = URL?.ERICSION?.AI_CONVERSION_DETAILS || "";
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: formData,
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      var _data = _.cloneDeep(data.data);
      setData(_data);
      setErrMsg((val) => ({ ...val, aiData: data.message }));
      setLoading((val) => ({ ...val, aiData: false }));
    } else {
      setLoading((val) => ({ ...val, aiData: false }));
      setErrMsg((val) => ({ ...val, aiData: "Internal Server Error" }));
    }
  } catch (err) {
    setLoading((val) => ({ ...val, aiData: false }));
    setErrMsg((val) => ({ ...val, aiData: "Internal Server Error" }));
  }
};
export const getUserCountDetails = async (
  param,
  setLoading,
  setErrMsg,
  setData,
  headers
) => {
  setLoading((val) => ({ ...val, userCount: true }));
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  let url = URL?.ERICSION?.USER_COUNT_URL || "";
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: formData,
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      //
      if (data.message === "Success") {
        const data1 = JSON.parse(data?.data || "{}");
        setData(data1);
        setErrMsg((val) => ({ ...val, userCount: data.message }));
      } else if (data.message === "No data available in this date range") {
        setData({});
        setErrMsg((val) => ({
          ...val,
          userCount: data.message,
        }));
      }
      setLoading((val) => ({ ...val, userCount: false }));
    } else {
      setLoading((val) => ({ ...val, userCount: false }));
      setErrMsg((val) => ({
        ...val,
        userCount: "No data available in this date range",
      }));
    }
  } catch (err) {
    setLoading((val) => ({ ...val, userCount: false }));
    setErrMsg((val) => ({ ...val, userCount: "Internal Server Error" }));
  }
};
export const getGroupStatsDetails = async (
  param,
  setLoading,
  setErrMsg,
  setData,
  headers
) => {
  setLoading((val) => ({ ...val, groupStats: true }));
  const url = `${URL?.ERICSION?.GROUP_STATS_URL}?usergroup=${param?.usergroup}`;
  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      var _data = _.cloneDeep(data.data);
      setData(_data);
      setErrMsg((val) => ({ ...val, groupStats: data.message }));
      setLoading((val) => ({ ...val, groupStats: false }));
    } else {
      setLoading((val) => ({ ...val, groupStats: false }));
      setErrMsg((val) => ({ ...val, groupStats: "Internal Server Error" }));
    }
  } catch (err) {
    setLoading((val) => ({ ...val, groupStats: false }));
    setErrMsg((val) => ({ ...val, groupStats: "Internal Server Error" }));
  }
};

export const getgroupStatsDetailsNew = async (
  param,
  setLoading,
  setErrMsg,
  setData,
  headers
) => {
  setLoading((val) => ({ ...val, groupStats: true }));
  const url = `${URL?.ERICSION?.GET_USER_METRIC}`;
  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      var _data = _.cloneDeep(data.data);
      setData(_data);
      setErrMsg((val) => ({ ...val, groupStats: data.message }));
      setLoading((val) => ({ ...val, groupStats: false }));
    } else {
      setLoading((val) => ({ ...val, groupStats: false }));
      setErrMsg((val) => ({ ...val, groupStats: "Internal Server Error" }));
    }
  } catch (err) {
    setLoading((val) => ({ ...val, groupStats: false }));
    setErrMsg((val) => ({ ...val, groupStats: "Internal Server Error" }));
  }
};

export const getRegisteredUserMonthlyGraphData = async (
  param,
  setLoading,
  setErrMsg,
  setRegisteredUserMonthly,
  headers
) => {
  setLoading((val) => ({ ...val, monthlyRegisteredUser: true }));
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  const url = `${URL?.ERICSION?.GET_MONTHLY_REGISTERD_USER_COUNT}`;
  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();

      var _data = _.cloneDeep(data.data);
      if (_data?.counts) {
        setRegisteredUserMonthly(_data?.counts);
        setErrMsg((val) => ({ ...val, monthlyRegisteredUser: data.message }));
      } else {
        setRegisteredUserMonthly({});
        setErrMsg((val) => ({
          ...val,
          monthlyRegisteredUser: "No data available in this date range",
        }));
      }
    } else {
      setRegisteredUserMonthly({});
      setErrMsg((val) => ({
        ...val,
        monthlyRegisteredUser: "Internal Server Error",
      }));
    }
    setLoading((val) => ({ ...val, monthlyRegisteredUser: false }));
  } catch (err) {
    setRegisteredUserMonthly({});
    setLoading((val) => ({ ...val, monthlyRegisteredUser: false }));
    setErrMsg((val) => ({
      ...val,
      monthlyRegisteredUser: "Internal Server Error",
    }));
  }
};

export const getActiveUserMonthlyGraphData = async (
  param,
  setLoading,
  setErrMsg,
  setActiveUserMonthly,
  headers
) => {
  setLoading((val) => ({ ...val, monthlyActiveUser: true }));
  const url = `${URL?.ERICSION?.GET_MONTHLY_ACTIVE_USER_COUNT}`;
  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();

      var _data = _.cloneDeep(data.data);
      if (_data?.counts) {
        setActiveUserMonthly(_data?.counts);
        setErrMsg((val) => ({ ...val, monthlyActiveUser: data.message }));
      } else {
        setActiveUserMonthly({});
        setErrMsg((val) => ({
          ...val,
          monthlyActiveUser: "No data available in this date range",
        }));
      }
    } else {
      setActiveUserMonthly({});
      setErrMsg((val) => ({
        ...val,
        monthlyActiveUser: "Internal Server Error",
      }));
    }
    setLoading((val) => ({ ...val, monthlyActiveUser: false }));
  } catch (err) {
    setActiveUserMonthly({});
    setLoading((val) => ({ ...val, monthlyActiveUser: false }));
    setErrMsg((val) => ({
      ...val,
      monthlyActiveUser: "Internal Server Error",
    }));
  }
};

export const getDateWiseRegisteredAndActveUsers = async (
  param,
  setLoading,
  setErrMsg,
  setDateWiseUsers,
  headers
) => {
  setLoading((val) => ({ ...val, userDateWiseStats: true }));
  const url = `${URL?.ERICSION?.GET_DATE_WISE_REGISTERED_AND_ACTIVE_USER}?start_date=${param?.start_date}&end_date=${param?.end_date}`;
  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();

      var _data = _.cloneDeep(data.data);
      if (_data?.counts) {
        setDateWiseUsers(_data?.counts);
        setErrMsg((val) => ({ ...val, userDateWiseStats: data.message }));
      } else {
        setDateWiseUsers({});
        setErrMsg((val) => ({
          ...val,
          userDateWiseStats: "No data available in this date range",
        }));
      }
    } else {
      setErrMsg((val) => ({
        ...val,
        userDateWiseStats: "Internal Server Error",
      }));
    }
    setLoading((val) => ({ ...val, userDateWiseStats: false }));
  } catch (err) {
    setDateWiseUsers({});
    setLoading((val) => ({ ...val, userDateWiseStats: false }));
    setErrMsg((val) => ({
      ...val,
      userDateWiseStats: "Internal Server Error",
    }));
  }
};

export const getLicenceUsageGraphData = async (
  param,
  setLoading,
  setErrMsg,
  setLicenceGraph,
  headersList
) => {
  setLoading((val) => ({ ...val, licenceUsageGraph: true }));
  const url = URL?.ERICSION?.GET_MONTHLY_LICENSE
  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      var _data = _.cloneDeep(data.data);
      if (_data) {
        setLicenceGraph(_data);
        setErrMsg((val) => ({ ...val, licenceUsageGraph: data.message }));
      } else {
        setLicenceGraph({});
        setErrMsg((val) => ({
          ...val,
          licenceUsageGraph: "No data available in this date range",
        }));
      }
    } else {
      setErrMsg((val) => ({
        ...val,
        licenceUsageGraph: "Internal Server Error",
      }));
    }
    setLoading((val) => ({ ...val, licenceUsageGraph: false }));
  } catch (err) {
    setLicenceGraph({});
    setLoading((val) => ({ ...val, licenceUsageGraph: false }));
    setErrMsg((val) => ({
      ...val,
      licenceUsageGraph: "Internal Server Error",
    }));
  }
};