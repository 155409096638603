import FormNotifications from "./FormNotifications/FormNotifications";
import IncidentManagement from "./IncidentManagement";

export default function Notifications() {
  return (
    <div className="row">
      <FormNotifications />
      <IncidentManagement />
    </div>
  );
}
