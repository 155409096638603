import { URL } from "../utils/Constant";

// API to provide certificate Type filter data
export const getCertigicateTypeList = async (
  setCertificateType,
  setCertificateIds,
  headers
) => {
  try {
    const resp = await fetch(URL.ERICSION.GET_CERTIFICATE_LIST, {
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      setCertificateType(data.data);
      setCertificateIds(data.data?.map((val) => val.certificateId));
    } else {
    }
  } catch (ex) {}
};

export const getCertificationExpiredOrExpiringWithin30Days = async (
  certificateData,
  headers,
  param
) => {
  try {
    const jsonData = JSON.stringify(param);
    certificateData.setLoadingValCertificatesTable(true);
    const resp = await fetch(URL.ERICSION.GET_CERTIFICATE_EXPIRY, {
      method: "POST",
      body: jsonData,
      headers: headers,
    });
    if (resp.ok) {
      const data = await resp.json();
      certificateData.setCertificateExpiredAndExpireSoon(
        data.data.certificate_data_Expiry
      );
      if (data.data.certificate_data_Expiry.length > 0) {
        certificateData.setCertificatesTableMsg({
          msg: "",
          status_code: data.status_code,
        });
      } else {
        certificateData.setCertificatesTableMsg({
          msg: "No data available for these values",
          status_code: data.status_code,
        });
      }
      certificateData.setLoadingValCertificatesTable(false);
    } else {
      certificateData.setCertificatesTableMsg({
        msg: "Internal Server Error",
        status_code: "error",
      });
      certificateData.setLoadingValCertificatesTable(false);
    }
  } catch (ex) {
    certificateData.setCertificatesTableMsg({
      msg: "Internal Server Error",
      status_code: "error",
    });
    certificateData.setLoadingValCertificatesTable(false);
  }
};

export const getCertificateDetails = async (certificateData, data, headers) => {
  const param = {
    certificateId: data.certificateIds,
    status: data.getStatusOption,
  };
  try {
    getCertificationExpiredOrExpiringWithin30Days(
      certificateData,
      headers,
      param
    );
  } catch (ex) {
    console.log("Inertnal server Error");
  }
};
