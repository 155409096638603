import { useEffect, useRef } from "react";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import Loading from "../../components/Loading/Loading";
import { EDSDialog } from "../../components/EDSDialog/EDSDialog";

export default function ModelUpdateStatus({
  showMdl,
  setShowMdl,
  dataMdl,
  setDataMdl,
  getLoading,
  setStatusComments,
  getStatusComments,
  onStatusUpdateApi,
  getErrMsg,

  setAttachmentData,
}) {
  const dialogRef = useRef(null);

  useEffect(() => {
    if (showMdl) dialogRef.current.show();
    else dialogRef.current.hide();
  }, [showMdl]);

  const onCloseHandler = () => {
    setStatusComments("");
    setShowMdl(false);
    setDataMdl({
      heading: "",
      body: "",
      showClose: false,
      showSave: false,
      reportID: "",
      questionId: "",
      saveBtnName: "",
      statusId: "",
      pastComments: [],
    });
    dialogRef.current.hide();
  };

  const dialogTitle = <div className="title">{dataMdl.heading}</div>;

  const dialogBody = (
    <div className="row">
      <div className="tile sm-12 md-12 lg-12 xl-12">
        {dataMdl?.heading === "Comment" ? null : (
          <label htmlFor="statusComments">
            {dataMdl.saveBtnName} reason (Optional)
          </label>
        )}
        <textarea
          placeholder="Enter comments"
          id="statusComments"
          className="commentBox w-100"
          rows={4}
          value={getStatusComments}
          onChange={(e) => {
            setStatusComments(e.target.value);
          }}
        ></textarea>
      </div>

      {dataMdl?.heading !== "Comment" && (
        <div className="tile sm-12 md-12 lg-12 xl-12">
          <label> Attachment (Optional)</label>
          <div className="fileDiv">
            <input
              id="fileUploademp"
              type="file"
              onChange={(e) => {
                setAttachmentData(e.target.files[0]);
              }}
            />
            <label htmlFor="fileUploademp" className="fileUpload"></label>
          </div>
        </div>
      )}

      {dataMdl?.heading === "Comment" && (
        <>
          <div className="tile sm-12 md-12 lg-12 xl-12">
            <div className="text-end ">
              <button
                className="btn primary"
                disabled={getLoading || !getStatusComments.length}
                onClick={() =>
                  onStatusUpdateApi(
                    dataMdl.reportID,
                    dataMdl.questionId,
                    dataMdl.statusId
                  )
                }
              >
                Add Comment
              </button>
            </div>
          </div>

          {dataMdl?.pastComments.length > 0 && (
            <div className="tile sm-12 md-12 lg-12 xl-12">
              <p className="">Previous Comments :</p>
              <ul className="list-group">
                {dataMdl?.pastComments.map((val, i) => (
                  <li key={i} className="list-group-item">
                    <p key={i}>
                      {val?.datestamp +
                        " - " +
                        val?.username +
                        " : " +
                        val?.comment}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}

      {getLoading ? (
        <Loading />
      ) : getErrMsg && getErrMsg !== "Status updated successfully" ? (
        <div className="tile sm-12 md-12 lg-12 xl-12">
          <AlertEDS errMsg={getErrMsg} status_code={201} close={false} />
        </div>
      ) : null}

      <div className="tile sm-12 md-12 lg-12 xl-12">
        <div className="text-end">
          {dataMdl.showClose && (
            <button className="btn" onClick={onCloseHandler}>
              Cancel
            </button>
          )}
          {dataMdl.showSave && dataMdl?.heading !== "Comment" && (
            <button
              className="btn warning "
              disabled={getLoading}
              onClick={() =>
                onStatusUpdateApi(
                  dataMdl.reportID,
                  dataMdl.questionId,
                  dataMdl.statusId
                )
              }
            >
              {dataMdl.saveBtnName}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <EDSDialog
      id="deficiency-update"
      dataType="simple"
      dialogRef={dialogRef}
      dialogTitle={dialogTitle}
      dialogBody={dialogBody}
    />
  );
}
