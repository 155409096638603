import { useSelector } from "react-redux";
import AccessDenied from "./layouts/DefaultPages/AccessDenied";

const PrivateRoute = ({ children, roles = [] }) => {
  const rolesMap = useSelector((state) => state.userAccess.roles);
  const userHasRequiredRole = rolesMap.some((role) => roles.includes(role));

  if (!userHasRequiredRole) {
    return <AccessDenied />;
  }

  return children;
};

export default PrivateRoute;
