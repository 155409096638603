import React, { useState } from "react";
import _ from "lodash";
// import { useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import { exportToCSVMethod } from "../../utils/helperMethod";
import LargeImgPopup from "./LargeImgPopup";

export default function HazardIDIdentification({ setHazardIdentiPopUp, getHazardIdenti, setHazardIdenti, isLoading, errMsg, setError }) {
    // const ImageToken = useSelector((state) => state.loggedInUser.user.profilePicture);
    // const SasToken = ImageToken.indexOf("?");
    // const querystring = ImageToken.substring(SasToken);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [getImgURL, setImgURL] = useState("");

    const onCloseHandler = () => {
        setHazardIdenti([]);
        setError((val) => ({ ...val, getListHazardIdentification: "" }));
        setHazardIdentiPopUp(false);
    };

    const _getDateTime = (date_time) => {
        let d = new Date(date_time),
            dformat = [d.getMonth() + 1,
            d.getDate(),
            d.getFullYear()].join('/') + ' ' +
                [d.getHours(),
                d.getMinutes(),
                d.getSeconds()].join(':');
        return dformat;
    }

    const callExport = () => {
        const csvData = [];
        getHazardIdenti?.archive?.files?.forEach((i) => {
            const HazardMsg = getHazardIdenti?.result?.output?.map(j => j.custom_id === i.id && j.message_content).filter(Boolean).toString();
            csvData.push({
                "Request ID": getHazardIdenti.id,
                "Image URL": i.url,
                "Hazard Identified": HazardMsg,
                "Customer": getHazardIdenti.customer,
                "Date": _getDateTime(getHazardIdenti.created),
                "Survey Archive": getHazardIdenti.archive.filename,
                "Site Type": getHazardIdenti.site_type
            });
        });
        exportToCSVMethod(csvData, "User List");
    }

    return (
        <>
            <div className="dialog HazardIDIdentiffication show modal-md" data-trigger="#HazardIDIdentiffication" data-type="simple">
                <div className="content">
                    <div className="top">
                        <div className="title">{getHazardIdenti.id} </div>
                        <div className="right">
                            <button className="btn-icon" data-close="true" onClick={() => onCloseHandler()}>
                                <i className="icon icon-cross"></i>
                            </button>
                        </div>
                    </div>
                    <div className="body">
                        <div className="tile sm-12 md-12 lg-12 xl-12 createHazardEle">
                            {isLoading.getHazardIdentification ? (
                                <div className="centerLoader">
                                    <Loading />
                                </div>
                            ) :
                                (<> {errMsg.getHazardIdentification !== "Success" ?
                                    (<>
                                        <AlertEDS errMsg={errMsg.getHazardIdentification} status_code={201} close={false} />
                                    </>)
                                    :
                                    (<>
                                        {!_.isEmpty(getHazardIdenti) && (
                                            <>
                                                <div className="table-top">
                                                    <div className="table-top-right">
                                                        <div className="table-buttons">
                                                            <button className="btn" id="exportData" onClick={() => callExport()}>
                                                                Export
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <table className="table dashed hazardsImtigationTable">
                                                    <thead>
                                                        <tr>
                                                            <th>Uploaded Images</th>
                                                            <th>Hazard Identified</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {getHazardIdenti?.archive?.files.map((i, k) => {
                                                            return (
                                                                <tr key={k} style={{ verticalAlign: 'top' }}>
                                                                    <td><img src={i.url} onClick={() => { setImgURL(i.url); setIsDialogOpen(true); }} alt="hazards images" width="150" style={{cursor:'pointer'}} /> </td>
                                                                    <td>{getHazardIdenti?.result?.output.map((j, idx) => {
                                                                        if (j.custom_id === i.id) {
                                                                            return (
                                                                                <span key={idx}>{j.message_content}</span>
                                                                            )
                                                                        }
                                                                    })}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <tr></tr>
                                                    </tbody>
                                                </table>
                                            </>
                                        )}
                                    </>
                                    )}
                                </>
                                )}
                        </div>
                    </div>
                </div>
            </div>

            {isDialogOpen &&
                <LargeImgPopup
                    setIsDialogOpen={setIsDialogOpen}
                    getImgURL={getImgURL}
                />
            }
        </>
    )
}