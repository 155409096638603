import { useEffect } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Table } from "@eds/vanilla";
import roleData from "./rolesData.json"
export default function Roles() {
  const navigate = useNavigate();
  const rolesMap = useSelector((state) => state.userAccess.roles);

  useEffect(() => {
    const tableDOM = document.querySelector("#rolesTable");
    const table = new Table(tableDOM, {
      data: roleData,
      columns: [
        {
          key: "roleName",
          title: "Roles",
          sort: "none",
        },
        {
          key: "description",
          title: "Description",
        },
      ],
      sortable: true,
    });
    table.init();
  }, []);

  return (
    <div className="row">
      <div className="tile sm-12 lg-12">
        <div className="table-top">
          <div className="table-top-right">
            <div className="table-search">
              <input type="text" className="with-icon" placeholder="Search for..." />
              <div className="suffix icon-inside">
                <i className="icon icon-search"></i>
              </div>
            </div>
          </div>
        </div>
        <table id="rolesTable" className="table dashed"></table>
        <div className="table-bottom">
          <div className="pagination-group">
            <ul className="pagination"></ul>
            <div className="actions">
              <label className="left">Go to</label>
              <input type="number" min="1" />
              <label className="left">Show</label>
              <div className="select" data-type="single">
                <button className="btn current-options">5</button>
                <div className="options-list">
                  <div className="item active">10</div>
                  <div className="item">20</div>
                  <div className="item">30</div>
                  <div className="item">All</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}