import { useEffect,useState,useRef   } from "react";
import { ColorScale, Donut } from "@eds/vanilla";
export default function AverageTimePerSection({ avg_time_js }) {
  const [isPercentageMode, setIsPercentageMode] = useState(false);
  const chartRef = useRef(null);

  const getChartData = () => {
    const avg_time_in_min = {};
    Object.keys(avg_time_js["Average Time"]).forEach(key=>{
      const time_in_min = avg_time_js["Average Time"][key]/60;
      avg_time_in_min[key] = time_in_min;

    })
    const formattedData = [];
    for (let pieData in avg_time_js.Section) {
      formattedData.push({
        values: [avg_time_in_min[pieData]],
        name: avg_time_js["Section"][pieData],
      });
    }
    return formattedData;
  };

  useEffect(() => {
    const data = getChartData();
    const myColors = new ColorScale({ offset: 1 });
    myColors.init();
    const chart = new Donut({
      element: document.getElementById("averageTimePerSectionGraph"),
      data: { series: data },
      legend: {
        isHidden: true,
      },
      showValue: true,
      showAbsoluteValue: !isPercentageMode,
      unit: "Average Time",
      format: ",.2f",
      colorScale: myColors,
    });
    chart.init();
    chartRef.current = chart;
    // return () => chart.destroy();
  }, [avg_time_js]);


  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.showAbsoluteValue = !isPercentageMode;
      chartRef.current.unit =  "Average Time";
      chartRef.current.draw();
    }
  }, [isPercentageMode]);

  const handleToggleSwitch = () => {
    setIsPercentageMode(!isPercentageMode);
  };

  return <div id="averageTimePerSectionGraph">
     <div>
        <label className="switch">
          <input
            type="checkbox"
            onChange={handleToggleSwitch}
            checked={isPercentageMode}
          />
          <i className="ball"></i>
          <span
            data-enabled="Percentage"
            data-disabled="Count"
            onClick={handleToggleSwitch}
          ></span>
        </label>
      </div>
  </div>;
}
