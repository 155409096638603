import React, { useEffect } from "react";
import { Dialog } from "@eds/vanilla";

export default function AlertWarnEDS({
  errTitle,
  errMsg,
  btnText,
  handleDeleteConfirm,
  setDelete,
}) {
  const confirm = async () => {
    await handleDeleteConfirm();

    setDelete(false);
  };

  const close = () => {
    setDelete(false);
  };

  useEffect(() => {
    const dialogDOM = document.getElementById("dialog-alert-warn");
    if (dialogDOM) {
      const dialog = new Dialog(dialogDOM);
      dialog.init();
      dialog.show();
      return () => {
        dialog.destroy();
      };
    }
  }, []);

  return (
    <div id="dialog-alert-warn" className="dialog" data-type="simple">
      <div className="content">
        <div className="top">
          <div className="title">{errTitle}</div>
        </div>
        <div className="body">{errMsg}</div>
        <div className="bottom">
          <button className="btn" data-close="true" onClick={close}>
            Cancel
          </button>
          <button className="btn warning" onClick={confirm}>
            {btnText}
          </button>
        </div>
      </div>
    </div>
  );
}
