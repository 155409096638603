import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Resizer from "react-image-file-resizer";
import { useNavigate } from "react-router-dom";
// import {
//   useImageUrlListFormMutation,
//   useGetHazardAIDataMutation,
//   useGetPpeAIDataMutation,
// } from "../../redux/services/AIinferenceApi";
import _ from "lodash";
import ExampleOfAPersonWearingAllPPE from "../../../asset/Icons/ExampleOfAPersonWearingAllPPE.JPEG";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../../components/Loading/Loading";
import AIImageDialog from "./AIImageDialog";
import { getUploadImages, getHazardAIData } from "../../../api/AiServices.api";
import DrawCanvas from "./DrawCanvas";
import AlertEDSPopup from "../../../components/AlertWarn/AlertWarnEDS";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";

export default function AIImageUpload({ commonCardData, pathname }) {
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const ImageToken = useSelector((state) => state.loggedInUser.user.profilePicture);
  const SasToken = ImageToken.indexOf("?");
  const querystring = ImageToken.substring(SasToken);
  const [hazardsdetected, sethazardsdetected] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isImageUpload, setIsImageUpload] = useState(true);
  const [isVideoUpload, setIsVideoUpload] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [uploadedImage, setuploadedImage] = useState([]);
  const { headers, headersList } = useSelector((state) => state.envVariables);
  const [isLoading, setIsLoading] = useState({
    imageLoading: false,
    ppehazardLoading: false,
  });
  const [errMsg, setError] = useState({
    imageError: "",
    ppehazardLoading: "",
    maxUploadImg: ""
  });
  const [dialogImgCount, setDialogImgCount] = useState(null);
  const rolesMap = useSelector((state) => state.userAccess.roles);

  let imgPath = [];
  let _hazardsdetected = [];

  const findTitleByPathname = (commonCardData, pathname) => {
    const matchedCard = commonCardData.find((card) =>
      pathname.endsWith(card.path)
    );
    return matchedCard ? matchedCard.title : null;
  };

  const fileUpload = async (fileInput) => {
    const maxSize = 4 * 1024 * 1024;
    if (fileInput.size > maxSize) {
      try {
        Resizer.imageFileResizer(
          fileInput,
          1200,
          1200,
          "JPEG",
          100,
          0,
          (resizedImage) => {
            const resizedFile = new File([resizedImage], fileInput.name);
            const formData = new FormData();
            formData.append("files", resizedFile);
            formData.append("section_names", "AI_Tab_PPE");
            formData.append("upload_type", "JHA");
            formData.append("reportId", uuidv4());
            // submitUrlFromList(formData);
            getUploadImages(headers, formData, setIsLoading, setError, getAIdata)
          },
          "file",
          800,
          800
        );
      } catch (error) {
        console.error("Error resizing image:", error);
      }
    } else {
      const formData = new FormData();
      formData.append("files", fileInput);
      formData.append("section_names", "AI_Tab_PPE");
      formData.append("upload_type", "JHA");
      formData.append("reportId", uuidv4());
      // await submitUrlFromList(formData);
      await getUploadImages(headers, formData, setIsLoading, setError, getAIdata)
    }
  }

  const handleFileUpload = (event) => {
    const fileInput = event[0];
    if (!fileInput) {
      return;
    }
    Object.values(event).forEach((i, k) => {
      if (k < 3) {
        fileUpload(i);
      } else {
        setError((val) => ({ ...val, maxUploadImg: "Please note maximum 3 images are accepted at a time." }));
        setTimeout(() => {
          setError((val) => ({ ...val, maxUploadImg: "" }));
        }, 4000);
        return;
      }
    })
  };

  const getAIdata = async (imgURL) => {
    imgPath.push(imgURL + querystring);
    setuploadedImage(imgPath);
    await getHazardAIData(headersList, imgURL, setIsLoading, setError, _hazardsdetected);
    sethazardsdetected(_hazardsdetected);

  }

  const clearImages = () => {
    imgPath = [];
    _hazardsdetected = [];
    setuploadedImage([]);
    sethazardsdetected([]);
  }

  const clearHandler = () => {
    setError((val) => ({ ...val, maxUploadImg: "" }));
  }

  return (
    <>
      <div className="row ai-imagesUpload">
        <div className="tile md-12 sm-12 lg-12 ">
          <div className="header">
            <div className="left">
              <div className="title">
                {findTitleByPathname(commonCardData, pathname)}
              </div>
            </div>
            <div className="right">
              <div className="btn-group horizontal" id="btnGroupH">
                <button className={isImageUpload ? "btn icon-btn active" : "btn icon-btn"} onClick={() => { setIsImageUpload(true); setIsVideoUpload(false); setIsBulkUpload(false); }}>
                  <i className="icon icon-image"></i>
                  <i style={{ marginLeft: "2px" }}>Image</i>
                </button>

                <button className={isVideoUpload ? "btn icon-btn active" : "btn icon-btn"} onClick={() => { setIsVideoUpload(true); setIsImageUpload(false); setIsBulkUpload(false); setuploadedImage([]); imgPath = [] }}>
                  <i className="icon icon-video-play"></i>
                  <i style={{ marginLeft: "2px" }}>Video</i>
                </button>
                
                {rolesMap.some((role) => ["CONSOLE_HAZARD_ID_USER"].includes(role)) && (
                  <button className={isBulkUpload ? "btn icon-btn active" : "btn icon-btn"} onClick={() => { setuploadedImage([]); imgPath = []; navigate("/site-hazards"); }}>
                    <i className="icon icon-folder"></i>
                    <i style={{ marginLeft: "2px" }}>Bulk</i>
                  </button>)}

              </div>
            </div>
          </div>
          {isImageUpload ? (
            <>
              <div className="content">
                <div className="column">
                  <div className="card drag-and-drop">
                    <div className="header">
                      <div className="left">Choose file(s) to upload</div>
                      <div className="right">
                        <i className="icon icon-robot-head" style={{ marginRight: "4px" }}></i>
                        Powered by AI
                      </div>
                    </div>
                    <div className="content">
                      {/* <p>Choose file(s) to upload</p> */}
                      <form className="drag-and-drop-area"
                        onDragOver={(event) => { event.preventDefault(); }}
                        onDrop={(event) => {
                          event.preventDefault(); handleFileUpload(event.dataTransfer.files); event.target.value = null;
                        }}>
                        <i className="icon icon-upload"></i>
                        <p style={{ marginTop: "-13px" }}>
                          Drag & drop your file(s) here or{" "}
                          <label>
                            select to browse
                            <input
                              type="file"
                              multiple
                              accept="image/png, image/gif, image/jpeg"
                              onChange={(event) => {
                                handleFileUpload(event.target.files); event.target.value = null;
                              }}
                            />
                          </label>
                        </p>
                        <p>Supported formats: png, jpeg<br /></p>
                      </form>
                      <div className="header header-files hidden">
                        <div className="left">Uploaded files</div>
                      </div>
                      <div className="content content-files">
                        <div className="file-pills"></div>
                        <table className="upload-table"></table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="header">
                  <div className="left">
                    <div>
                      <span className="disclaimer-text" style={{ whiteSpace: "nowrap", marginLeft: "8px" }}>Disclaimer:</span>
                      <span style={{ color: "#888", alignItems: "center" }}>
                        {" "}
                        This feature is offered for experimentation purposes only. It
                        should not be used to draw decisive conclusions about the
                        environment.
                      </span>
                    </div>
                  </div>
                  <div className="right">
                    {findTitleByPathname(commonCardData, pathname) ===
                      "PPE Detection" ? (
                      <a href={ExampleOfAPersonWearingAllPPE} target="_blank" rel="noreferrer" style={{ marginLeft: 'auto', marginRight: '8px' }}>
                        Example Image
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="content featureCome">
              <i className="icon icon-time"></i> <p>Feature is Coming Soon.
              </p>
            </div>
          )}

          {isLoading.ppehazardLoading || isLoading.imageLoading ? (
            <div className="SiderBarLoader">
              <Loading />
            </div>
          ) : (
            <>
              {!_.isEmpty(errMsg.ppehazardLoading) ?
                (<>
                  <AlertEDS
                    errMsg={errMsg.ppehazardLoading}
                    status_code={201}
                    close={false}
                  />
                </>)
                :
                (
                  <>
                    {!_.isEmpty(uploadedImage) && !_.isEmpty(hazardsdetected) && (
                      <div className="tile uploadedImages">
                        <div className="right" style={{ marginLeft: 'auto' }}>
                          <button className="btn" onClick={() => clearImages()}>Clear </button>
                        </div>
                        <div className="tableContainer">
                          <table className="table dashed hazardsImtigationTable">
                            <thead>
                              <tr>
                                <th>Uploaded Image</th>
                                <th width='34%'>Hazard Identified</th>
                                <th>Mitigation Measures</th>
                              </tr>
                            </thead>
                            <tbody>
                              {hazardsdetected.map((i, k) => {
                                return (
                                  <DrawCanvas
                                    key={k}
                                    _key={k}
                                    hazardsdetected={i}
                                    uploadedImage={uploadedImage[k]}
                                    setIsDialogOpen={setIsDialogOpen}
                                    setDialogImgCount={setDialogImgCount}
                                  />
                                )
                              })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </>
                )
              }
            </>
          )
          }
        </div>
      </div>

      {!_.isEmpty(errMsg.maxUploadImg) && (
        <AlertEDSPopup
          id="maxUploadImg"
          errTitle={"Please note!"}
          errMsg={errMsg.maxUploadImg}
          btnText={"Ok"}
          handleDeleteConfirm={''}
          setDelete={() => {
            clearHandler();
          }}
        />
      )}

      {isDialogOpen && (
        <AIImageDialog
          setIsDialogOpen={setIsDialogOpen}
          uploadedImage={uploadedImage[dialogImgCount]}
          canvasRef={canvasRef}
          hazardsdetected={hazardsdetected[dialogImgCount]}
        />
      )}
    </>
  );
}
