import React, { useEffect } from "react";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import "./App.scss";
import Layout from "./layouts/Layout";
import Login from "./layouts/Main/Login";
// import { EventType } from "@azure/msal-browser";
// import { b2cPolicies } from "./authConfig";

function App({ instance }) {
  // const [getForgotPasswordClick, SetForgotPasswordClick] = useState(false);
  useEffect(() => {
    // instance.addEventCallback((message) => {
    //   if (message.eventType === EventType.LOGIN_FAILURE && message.error.errorMessage.includes("AADB2C90118")) {
    //     SetForgotPasswordClick(true);
    //     instance.loginRedirect(b2cPolicies.authorities.changePassword);
    //   }
    // });

    if (localStorage.getItem("theme") === null) {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        document.body.classList.add("dark");
        localStorage.setItem("theme", "dark");
      } else {
        document.body.classList.add("light");
        localStorage.setItem("theme", "light");
      }
    } else {
      document.body.removeAttribute("class");
      document.body.classList.add(localStorage.getItem("theme"));
    }
  }, []);

  return (
    <MsalProvider instance={instance}>
      <AuthenticatedTemplate>
        <Layout />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;
