import { URL } from "../utils/Constant";
export const setPolicyStatus = async (headersList) => {
  try {
    const resp = await fetch(URL.ERICSION.POLICY_STATUS_UPDATE, {
      method: "POST",
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
      return data?.status_code;
    }
  } catch {}
  return 201;
};