import React, { useState, useEffect, useMemo, useCallback } from "react";
import moment from "moment-timezone";
import _ from "lodash";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import { getAllMonthsBetweenDates } from "../../utils/helperMethod";
import AuditorsMonthlyGoalsSummary from "./Graphs/AuditorsMonthlyGoalsSummart";
import {
  getSitewalkGoalsByAuditors,
  getSitewalkGoalsFilterDetails,
} from "../../api/SitewalkGoals.api";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import MultiselectDropDown from "../../components/CustomMultiSelectDropDown/MultiSelectDropDown";
import { useSelector } from "react-redux";

export default function GoalsAnalytics() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    moment().local().format("YYYY-MM-01")
  );
  const [endDate, setEndDate] = useState(moment().local().format("YYYY-MM-DD"));
  const [getUsersOption, setUsersOption] = useState([]);
  const [currentDay, setCurrentDay] = useState(
    moment().local().format("YYYY-MM-DD")
  );
  const [getGoalsAnalytics, setGoalsAnalytics] = useState({});
  // const [filtersAccess, setFiltersAccess] = useState([]);
  const [getFilterData, setFilterData] = useState({});
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filtersErrMsg, setFiltersErrMsg] = useState("");
  const [loadingVal, setLoadingVal] = useState({
    goalsByAuditor: false,
    goalsByRegion: false,
  });
  const [errorMsg, setErrorMsg] = useState({
    goalsByAuditor: "",
    goalsByRegion: "",
  });
  const [isAutoCall, setIsAutoCall] = useState(false);
  const { headers, headersList } = useSelector((state) => state.envVariables);
  const rolesMap = useSelector((state) => state.userAccess.roles);

  const auditorsList = useMemo(() => {
    return (
      getFilterData?.reporters?.map((val) => {
        const temp = Object.entries(val);
        return { formId: temp?.[0]?.[0], form_name: temp?.[0]?.[1] };
      }) || []
    );
  }, [getFilterData]);

  const datepickerData = useMemo(
    () => ({
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      getUsersOption,
      setUsersOption,
    }),
    [startDate, endDate, getUsersOption]
  );

  const getAllAnalyticsData = () => {
    const param = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      reporter: getUsersOption,
      timeZone: moment.tz.guess(true),
    };
    setGoalsAnalytics((val) =>
      Object.keys(val).reduce((acc, key) => {
        acc[key] = [];
        return acc;
      }, {})
    );
    if (param.start_date.length < 1) {
      setErrorMsg((val) =>
        Object.keys(val).reduce((acc, key) => {
          acc[key] = "Please select Start Date";
          return acc;
        }, {})
      );
    } else if (param.end_date.length < 1) {
      setErrorMsg((val) =>
        Object.keys(val).reduce((acc, key) => {
          acc[key] = "Please select End Date";
          return acc;
        }, {})
      );
    } else {
      // if (
      //   !_.isEmpty(
      //     getGoalsAccess?.find(
      //       (val) =>
      //         val?.Action === "Auditors Summary by month" &&
      //         val?.UserAccess === "Y"
      //     )
      //   )
      // ) {
      getSitewalkGoalsByAuditors(
        param,
        setLoadingVal,
        setErrorMsg,
        setGoalsAnalytics,
        "goalsAnalytics",
        headersList
      );
      // }
      // if (
      //   !_.isEmpty(
      //     getGoalsAccess?.find(
      //       (val) =>
      //         val?.Action === "Region Leader Summary" && val?.UserAccess === "Y"
      //     )
      //   )
      // ) {
      //   getSitewalkGoalsByRegions(
      //     { usergroupid: UserGroupId },
      //     setLoadingVal,
      //     setErrorMsg,
      //     setGoalsAnalytics
      //   );
      // }
    }
  };

  const getAuditorsSummary = useCallback(
    _.debounce((param) => {
      getSitewalkGoalsByAuditors(
        param,
        setLoadingVal,
        setErrorMsg,
        setGoalsAnalytics,
        "goalsAnalytics",
        headersList
      );
    }, 4000),
    []
  );

  const callAllMonthsBetweenDates = useMemo(
    () => getAllMonthsBetweenDates(startDate, endDate),
    [isAutoCall]
  );

  useEffect(() => {
    // const userAccess = getUserAccess?.find(
    //   (val) => val?.Screen === "Site Walk Goal Analytics"
    // );
    if (
      rolesMap?.some(
        (val) =>
          val === "CONSOLE_GOALS_VIEWER" ||
          val === "CONSOLE_GOALS_EDITOR" ||
          val === "CONSOLE_GLOBAL_ADMIN"
      )
    ) {
      // setGoalsAccess(userAccess?.Subcomponent || []);
      // setFiltersAccess(userAccess?.filterComponent || []);
      getSitewalkGoalsFilterDetails(
        setFiltersLoading,
        setFiltersErrMsg,
        setFilterData,
        headers
      );
    } else {
      if (!_.isEmpty(rolesMap)) {
        alert("Access denied and you will be navigated to Welcome page");
        navigate("/home");
      }
    }
  }, [rolesMap]);

  useEffect(() => {
    if (rolesMap.length) {
      const intervalId = setInterval(() => {
        const newDay = moment().local().format("YYYY-MM-DD");
        if (currentDay !== newDay) {
          setCurrentDay(newDay);
          setStartDate(
            new Date(
              moment(currentDay, "YYYY-MM-DD").format("YYYY-MM-DD 00:00:00")
            )
          );
          setEndDate(
            new Date(moment(newDay, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59"))
          );
          setIsAutoCall(true);
        }
      }, 60000);
      return () => clearInterval(intervalId);
    }
  }, [rolesMap, currentDay]);

  useEffect(() => {
    if (!_.isEmpty(getFilterData)) {
      setUsersOption(
        getFilterData?.reporters?.map((val) => {
          return Object.entries(val)?.[0]?.[0];
        })
      );
      setIsAutoCall(true);
    }
  }, [getFilterData]);

  useEffect(() => {
    if (isAutoCall) {
      getAllAnalyticsData();
      setIsAutoCall(false);
    }
  }, [isAutoCall]);

  useEffect(() => {
    if (!isAutoCall && !_.isEmpty(getFilterData)) {
      const param = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        reporter: getUsersOption,
        timeZone: moment.tz.guess(true),
      };
      getAuditorsSummary(param);
    }
  }, [getUsersOption]);

  return (
    rolesMap.length && (
      <div className="row">
        <div className="tile filterDiv">
          <CustomDatePickerForm
            formName="siteWalkGoals"
            datepickerData={datepickerData}
            filterData={getFilterData}
            callApply={() => {
              setIsAutoCall((val) => !val);
            }}
            disableButtton={Object.values(loadingVal).indexOf(true) > -1}
            // filtersAccess={filtersAccess}
          />
        </div>
        {filtersLoading ? (
          <Loading />
        ) : (
          <>
            {filtersErrMsg !== "Successfull" ? (
              <div className="tile">
                <AlertEDS
                  errMsg={filtersErrMsg}
                  status_code={201}
                  close={false}
                />
              </div>
            ) : (
              <>
                {/* {!_.isEmpty(
                  getGoalsAccess?.find(
                    (val) =>
                      val?.Action === "Auditors Summary by month" &&
                      val?.UserAccess === "Y"
                  )
                ) && ( */}
                <div className="tile sm-12">
                  <div className="header">
                    <div className="left">
                      <div className="title">
                        <span>Auditors Monthly Goals Summary</span>
                        <span className="tooltip ml-sm">
                          <i className="icon icon-info-solid"></i>
                          <span
                            className="message"
                            style={{ whiteSpace: "normal" }}
                          >
                            Below Graph is showing the labels in the <br />
                            "Auditor Name (Total Walks Completed)/(Stretch
                            Target)&nbsp;Total Walks Completed percentage by
                            Auditor" format.
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="right">
                      <div className="action">
                        <label htmlFor="auditors-dropdown">Auditors</label>
                      </div>
                      <div className="action" style={{ width: "150px" }}>
                        <MultiselectDropDown
                          id="auditors-dropdown"
                          formDetailsUG={auditorsList}
                          setStatusOption={(statusOptions) =>
                            setUsersOption(statusOptions)
                          }
                          defaultValues={getUsersOption}
                          formName="Action-repoters"
                          filterName="Reporter"
                          dataType="input-multi"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    {loadingVal?.goalsByAuditor ? (
                      <Loading />
                    ) : (
                      <>
                        {errorMsg?.goalsByAuditor !== "Successfull" ? (
                          <div className="tile">
                            <AlertEDS
                              errMsg={errorMsg?.goalsByAuditor}
                              status_code={201}
                              close={false}
                            />
                          </div>
                        ) : (
                          <AuditorsMonthlyGoalsSummary
                            auditorsGoalsSumamry={
                              getGoalsAnalytics?.goalsByAuditor || []
                            }
                            getMonths={callAllMonthsBetweenDates}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/*  )} */}
                {/* {!_.isEmpty(
                  getGoalsAccess?.find(
                    (val) =>
                      val?.Action === "Region Leader Summary" &&
                      val?.UserAccess === "Y"
                  )
                ) && (
                  <div className="tile sm-12">
                    <div className="header">
                      <div className="left">
                        <div className="title">Region Leader Summary</div>
                      </div>
                      <div className="right">
                        <button
                          title="Export to CSV"
                          className="btn"
                          disabled={_.isEmpty(
                            getGoalsAnalytics?.goalsByRegions
                          )}
                          onClick={() => {
                            exportToCSVMethod(
                              getGoalsAnalytics?.goalsByRegions,
                              "Region Leader Summary"
                            );
                          }}
                        >
                          Export
                        </button>
                      </div>
                    </div>
                    <div className="content">
                      {loadingVal?.goalsByRegion ? (
                        <Loading />
                      ) : (
                        <>
                          {errorMsg?.goalsByRegion !== "Successfull" ? (
                            <div className="tile">
                              <AlertEDS
                                errMsg={errorMsg?.goalsByRegion}
                                status_code={201}
                                close={false}
                              />
                            </div>
                          ) : (
                            <RegionLeaderSummary
                              regionLeadersData={
                                getGoalsAnalytics?.goalsByRegions || []
                              }
                              getMonths={callAllMonthsBetweenDates}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )} */}
              </>
            )}
          </>
        )}
      </div>
    )
  );
}
