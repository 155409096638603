import { Select } from "@eds/vanilla";
import { useEffect } from "react";
import _ from "lodash";
export default function EDSSingleSelect({
  id,
  options,
  setStatusOption,
  defaultValues,
  filterName,
}) {
  useEffect(() => {
    const selectDOM = document.getElementById(id);
    const select = new Select(selectDOM);
    select.init();
    select.value = defaultValues;
    setStatusOption(defaultValues);

    const handleChange = (event) => {
      event.preventDefault();
      setStatusOption(select.value);
      event.stopPropagation();
    };
    selectDOM.addEventListener("selectOption", handleChange);

    return () => {
      selectDOM?.removeEventListener("selectOption", handleChange);
      select.destroy();
    };
  }, [options]);

  return (
    <>
      <div className="column sm-6 md-4 lg-2 md-mt-bs">
        {filterName}:
        <div
          className="select"
          style={{ maxWidth: "100% " }}
          data-type="input-single"
          id="formId"
        >
          <div className="clickable current-options">
            <input id="formInputId" type="text" placeholder="Select option" />
          </div>
          <div className="options-list">
            {options?.map((option, index) => (
              <div
                className={"item"}
                key={option?.formId}
                data-value={option?.formId}
              >
                {option?.form_name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
