import { useState } from "react";
import _ from "lodash";
import Loading from "../../../components/Loading/Loading";
import { uploadEmployeeList } from "../../../api/Settings.api";
import { exportToCSVMethod } from "../../../utils/helperMethod";
import { useSelector } from "react-redux";

export default function EmployeeList() {
  const [employeeFile, setEmployeeFile] = useState({});
  const [loadingVal, setLoadingVal] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const allowedExtensions = ["text/csv"];
  const { headers } = useSelector((state) => state.envVariables);

  const handleFileChange = (file) => {
    if (_.isEmpty(file?.type)) {
      setErrMsg("");
      setEmployeeFile({});
    } else if (!allowedExtensions.includes(file?.type)) {
      setErrMsg("Only CSV file is allowed.");
      setEmployeeFile({});
    } else {
      setErrMsg("");
      setEmployeeFile(file);
    }
  };

  const callUploadEmployeeList = () => {
    const param = { doc_file: employeeFile };
    uploadEmployeeList(param, setLoadingVal, setErrMsg, headers);
  };

  const handleRefresh = () => {
    setEmployeeFile({});
    setErrMsg("");
  };

  return (
    <div className="tile column sm-12">
      <div className="header">
        <div className="left">
          <div className="title">Employee List</div>
        </div>
      </div>
      <div className="content">
        <div className="eds-form">
          <div className="row form-body">
            <div className="column sm-12 md-12 lg-6">
              <div className="form-group">
                <div className="form-element">
                  <label className="required has-tooltip">
                    <span>Employee List</span>
                    <span className="tooltip">
                      <i
                        className="icon icon-info-solid"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          exportToCSVMethod(
                            [
                              { names: "Vivek Gnanavelu" },
                              { names: "Ali Awais" },
                              { names: "Serdar Sahin" },
                              { names: "Jyoti Marwah" },
                            ],
                            "Employee_List_Sample"
                          );
                        }}
                      ></i>
                      <span className="message top">
                        Click here to download the Sample File
                      </span>
                    </span>
                  </label>
                  <label>
                    <span className="btn">
                      <input
                        type="file"
                        onChange={(e) => {
                          handleFileChange(e.target.files[0]);
                        }}
                      />
                      <i className="icon icon-upload"></i>
                      Choose file
                    </span>
                    <span className="ml-sm">{employeeFile?.name || ""}</span>
                  </label>
                </div>
              </div>
              {loadingVal ? (
                <Loading />
              ) : (
                errMsg !== "" && (
                  <div className="success-message">
                    {errMsg === "Name List Updated" ? (
                      <span className="color-green text-lg">
                        <i className="icon icon-success mr-sm"></i>
                        Name List Updated Successsfully !
                      </span>
                    ) : (
                      <span className="color-red text-lg">
                        <i className="icon icon-failed mr-sm"></i>
                        {errMsg}
                      </span>
                    )}
                  </div>
                )
              )}
              <div className="form-footer">
                <button className="btn" onClick={handleRefresh}>
                  Clear
                </button>
                <button
                  className="btn primary"
                  disabled={employeeFile?.type !== "text/csv"}
                  onClick={callUploadEmployeeList}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
