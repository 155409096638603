import * as htmlToImage from "html-to-image";
import download from "downloadjs";
export default function useHtmlToImage(props) {
  const takeScreenShot = () => {
    const node = document.getElementById(props.nodeId);
    const options = { backgroundColor: "#fafafa" };
    const theme = document.getElementById("myDiv")?.classList;
    if (theme.contains("dark")) {
      options.backgroundColor = "#242424";
    }
    htmlToImage
      .toPng(node, options)
      .then(function (dataUrl) {
        download(dataUrl, `${props.fileName}.png`);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  return [takeScreenShot];
}
