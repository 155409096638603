import { ExportToCsv } from "export-to-csv";
import moment from "moment-timezone";
import _ from "lodash";

export function emailValidation(e) {
  var regex_email =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var email = e.target.value;
  if (!regex_email.test(String(email).toLowerCase())) {
    return true;
  } else {
    return false;
  }
}

export const validateEmail = (email) => {
  var regex_email =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return String(email).toLowerCase().match(regex_email);
};

export function phoneValidation(e) {
  var regex_phone = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
  var phone = e.target.value;
  if (!regex_phone.test(String(phone))) {
    return true;
  } else {
    return false;
  }
}

// Without it, your current regex only matches that you have 6 to 16 valid characters, it doesn't validate that it has at least a number, and at least a special character. That's what the lookahead above is for.
// (?=.*[0-9]) - Assert a string has at least one number;
// (?=.*[!@#$%^&*]) - Assert a string has at least one special character.
export function passwordValidation(e) {
  var regex_pass =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  var pass = e.target.value;
  if (!regex_pass.test(String(pass))) {
    return true;
  } else {
    return false;
  }
}

export function colorsGenerator(n) {
  let markerColorsList = [
    "#DCAF00",
    "#E66E19",
    "#8E45B0",
    "#288964",
    "#0077DB",
  ];
  for (let i = 0; i < n / 5; i++) {
    markerColorsList.push(
      "#DCAF00",
      "#E66E19",
      "#8E45B0",
      "#288964",
      "#0077DB"
    );
  }
  return markerColorsList;
}

export function exportToCSVMethod(csvData, csvfileName) {
  // From https://www.npmjs.com/package/export-to-csv Library
  const options = {
    fieldSeparator: ",",
    filename: csvfileName,
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(csvData);
}

//Get All months between two Dates
export const getAllMonthsBetweenDates = (startDate, endDate) => {
  const start = moment(startDate).startOf("month");
  const end = moment(endDate).startOf("month");
  const stepType =
    Math.abs(end.diff(start, "months", true)) >= 12 ? "year" : "month";
  const months = [];
  while (start.isSameOrBefore(end)) {
    if (stepType === "year") {
      months.push(start.format("YYYY"));
      start.add(1, "year");
    } else {
      months.push(start.format("YYYY-MM"));
      start.add(1, "month");
    }
  }
  return { type: stepType, data: months };
};

export const convertToCelsius = (temperature, number = 0) => {
  return +(((temperature - 32) * 5) / 9).toFixed(number);
};

export const convertToFahrenheit = (temperature, number = 0) => {
  return +((temperature * 9) / 5 + 32).toFixed(number);
};

export const parseReduxAPIResponse = ({ data, error, isLoading }) => ({
  data: {
    ...data,
    data: _.isEmpty(data?.data) ? {} : JSON.parse(data.data),
  },
  error,
  isLoading,
});
