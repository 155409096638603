import React, { useState, useContext, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment-timezone";
import ModelUpdateStatus from "./ModelUpdateStatus";
import ModalPreview from "./ModalPreview";
import { domain_url } from "../../utils/Constant";
import { exportToCSVMethod } from "../../utils/helperMethod";
import { useUpdateDeficiencyMutation } from "../../redux/services/safetyActionsApi";
import { EDSTable } from "../../components/EDSTable/EDSTable";
import { useRef } from "react";

export default function DeficienciesActionTable({ totaltimeData, refetch }) {
  const [totaltime_js, setTotaltimeJS] = useState(totaltimeData);
  const [getStatusComments, setStatusComments] = useState("");
  const [getShowMdlStatus, setShowMdlStatus] = useState(false);
  const [getDataMdlStatus, setDataMdlStatus] = useState({
    heading: "",
    body: "",
    showClose: false,
    showSave: false,
    reportID: "",
    questionId: "",
    saveBtnName: "",
    statusId: "",
    pastComments: [],
  });
  const [getShowMdlAttachmet, setShowMdlAttachment] = useState(false);
  const [getDataMdlAttachment, setDataMdlAttachment] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "",
  });
  const [getAttachmentData, setAttachmentData] = useState("");
  const navigate = useNavigate();
  const rolesMap = useSelector((state) => state.userAccess.roles);
  const features = useSelector((state) => state.userAccess.features);
  const tableRef = useRef(null);

  const [
    updateDeficiency,
    { data: updateData, isLoading: updateIsLoading, isError: updateIsError },
  ] = useUpdateDeficiencyMutation();

  const onStatusHandler = (reportId, questionId, status, pastComments) => {
    let status_id = "";
    let statusName = "";
    let buttonName = "";
    let pastCommentsData = [...pastComments].sort(
      (a, b) => new Date(b?.datestamp) - new Date(a?.datestamp)
    );
    if (status.toLowerCase() === "open") {
      statusName = "Close";
      status_id = "0";
      buttonName = "Close";
    } else if (status.toLowerCase() === "close") {
      statusName = "Reopen";
      status_id = "1";
      buttonName = "Reopen";
    } else {
      statusName = "Comment";
      status_id = "2";
      buttonName = "Add";
    }
    setDataMdlStatus({
      heading: statusName,
      body: "",
      showClose: true,
      showSave: true,
      reportID: reportId,
      questionId: questionId,
      saveBtnName: buttonName,
      statusId: status_id,
      pastComments: pastCommentsData,
    });
    setShowMdlStatus(true);
  };

  const callUpdateTotaltimeJS = (
    reportID,
    questionId,
    status_id,
    attachment
  ) => {
    let reportIndex = Object.values(totaltime_js["uniquekeydata"]).indexOf(
      reportID + questionId
    );
    const tempUpdateObj = {};
    if (getStatusComments.length > 0) {
      const commentsData = { ...totaltime_js?.Comments };
      const newCommentObj = {
        datestamp: attachment?.comment?.datestamp || "",
        username: attachment?.comment?.username || "",
        comment: attachment?.comment?.comment || "",
      };
      commentsData[reportIndex] = [
        ...commentsData[reportIndex],
        { ...newCommentObj },
      ];
      tempUpdateObj["Comments"] = commentsData;
      setDataMdlStatus({
        ...getDataMdlStatus,
        pastComments: [{ ...newCommentObj }, ...getDataMdlStatus?.pastComments],
      });
    }
    if (status_id !== "2") {
      const dataStatus = { ...totaltime_js?.status };
      const dataCompleted = { ...totaltime_js["Completion Date"] };
      const dataUpdatedAttachments = { ...totaltime_js["updatedAttachment"] };
      const dataUpdateAttachNames = {
        ...totaltime_js?.["updatedAttachmentName"],
      };
      const dataUpdateAttachDates = {
        ...totaltime_js?.["updatedAttachmentDate"],
      };
      if (status_id === "1") {
        dataStatus[reportIndex] = "open";
        dataCompleted[reportIndex] = "";
      } else {
        dataStatus[reportIndex] = "close";
        const date = String(
          moment(new Date()).tz("America/Chicago").format("YYYY/MM/DD HH:mm:ss")
        );
        dataCompleted[reportIndex] = date;
      }
      if (!_.isEmpty(attachment?.newLink)) {
        if (_.isEmpty(dataUpdatedAttachments[reportIndex])) {
          dataUpdatedAttachments[reportIndex] = [attachment?.newLink || ""];
          dataUpdateAttachNames[reportIndex] = [attachment?.name || ""];
          dataUpdateAttachDates[reportIndex] = [attachment?.date || ""];
        } else {
          dataUpdatedAttachments[reportIndex] = [
            ...dataUpdatedAttachments[reportIndex],
            attachment?.newLink,
          ];
          dataUpdateAttachNames[reportIndex] = [
            ...dataUpdateAttachNames[reportIndex],
            attachment?.name,
          ];
          dataUpdateAttachDates[reportIndex] = [
            ...dataUpdateAttachDates[reportIndex],
            attachment?.date,
          ];
        }
      }
      tempUpdateObj["status"] = dataStatus;
      tempUpdateObj["Completion Date"] = dataCompleted;
      tempUpdateObj["updatedAttachment"] = dataUpdatedAttachments;
      tempUpdateObj["updatedAttachmentName"] = dataUpdateAttachNames;
      tempUpdateObj["updatedAttachmentDate"] = dataUpdateAttachDates;
    }
    setTotaltimeJS({ ...totaltime_js, ...tempUpdateObj });
  };

  const onStatusUpdateApi = async (reportId, questionId, statusId) => {
    const body = {
      reportId: reportId,
      status_id: statusId,
      remarks: getStatusComments,
      questionId: questionId,
      attachment: getAttachmentData,
    };

    const formData = new FormData();
    for (let i in body) {
      formData.append(i, body[i]);
    }

    await updateDeficiency(formData)
      .unwrap()
      .then(async (resp) => {
        if (resp.status_code === 200) {
          await callUpdateTotaltimeJS(
            reportId,
            questionId,
            statusId,
            resp?.data?.[0]
          );
          if (statusId !== "2") {
            setShowMdlStatus(false);
          }
        }
      });

    setStatusComments("");

    setAttachmentData("");
  };

  const modalPreview = (
    attachments,
    attachmentsDate,
    attachNames,
    updatedAttachment,
    updatedAttachmentDate,
    updatedAttachNames
  ) => {
    let dataMdl = {
      showPreviewCarousal: true,
      heading: "Preview Attachments",
      body: "",
      showClose: true,
      saveBtnName: "",
      //certificateSeqId: _certificateSeqId,
      picUrl: {
        url: _.isEmpty(attachments) ? [] : attachments,
        name: _.isEmpty(attachNames) ? [] : attachNames,
        date: _.isEmpty(attachmentsDate) ? [] : attachmentsDate,
      },
      updatedPicUrl: {
        url: _.isEmpty(updatedAttachment) ? [] : updatedAttachment,
        name: _.isEmpty(updatedAttachNames) ? [] : updatedAttachNames,
        date: _.isEmpty(updatedAttachmentDate) ? [] : updatedAttachmentDate,
      },
    };
    setDataMdlAttachment(dataMdl);
    setShowMdlAttachment(true);
  };

  const exportToCSVHandler = (data) => {
    const csvData = [];
    data?.forEach((val) => {
      csvData.push({
        Id: val.id,
        "Site ID": val?.SiteID,
        Auditor: val?.Reporter,
        Question: val?.Question,
        "Non - Compliance": val?.NonCompliance,
        Mitigation: val?.Mitigation,
        Owner: val?.Owner,
        Created: val?.["Created Date"],
        Completed: val?.Completed,
        Status: val?.status,
        "Report Link": val?.reportURL,
        ...(features.find((val) => val.name === "COUNTRY").value === "IN"
          ? { Circle: val?.["circle"] }
          : { Region: val?.["region"] }),
        State: val?.state,
        Supplier: val?.supplier,
        ...(features?.some((val) => val.name === "MARKET" && val.value)
          ? { Market: val?.market }
          : {}),

        ...(features?.some((val) => val.name === "CUSTOMER" && val.value)
          ? { Customer: val?.customer }
          : {}),
      });
    });
    exportToCSVMethod(csvData, "Action items");
  };

  const deficiencies = useMemo(() => {
    const products = [];
    for (let tblData in totaltime_js["reportId"]) {
      products.push({
        id: totaltime_js["EswUniqueId"]?.[tblData],
        UniqueKey:
          totaltime_js["reportId"][tblData] +
          totaltime_js["questionId"][tblData],
        reportId: totaltime_js["reportId"][tblData] || "",
        NonCompliance: totaltime_js["Describe The Deficiency"][tblData] || "",
        Mitigation: totaltime_js["Course Of Action"][tblData] || "",
        Owner: totaltime_js["Owner Of Deficiency"][tblData] || "",
        "Created Date": totaltime_js["createdDate"][tblData] || "",
        Completed:
          totaltime_js["status"][tblData].toLowerCase() === "open"
            ? ""
            : totaltime_js["Completion Date"][tblData],
        status:
          totaltime_js["status"][tblData].toLowerCase() === "open"
            ? "Open"
            : "Closed",
        Action: [
          totaltime_js?.["status"]?.[tblData],
          totaltime_js?.["reportId"]?.[tblData],
          totaltime_js?.["questionId"]?.[tblData],
          totaltime_js?.["Comments"]?.[tblData] || [],
          [
            totaltime_js?.["Attachment"]?.[tblData],
            totaltime_js?.["AttachmentDate"]?.[tblData],
            totaltime_js?.["AttachmentName"]?.[tblData],
            totaltime_js?.["updatedAttachment"]?.[tblData],
            totaltime_js?.["updatedAttachmentDate"]?.[tblData],
            totaltime_js?.["updatedAttachmentName"]?.[tblData],
          ],
        ],
        attachemnts: [
          totaltime_js?.["Attachment"]?.[tblData],
          totaltime_js?.["AttachmentDate"]?.[tblData],
          totaltime_js?.["AttachmentName"]?.[tblData],
          totaltime_js?.["updatedAttachment"]?.[tblData],
          totaltime_js?.["updatedAttachmentDate"]?.[tblData],
          totaltime_js?.["updatedAttachmentName"]?.[tblData],
        ],
        comments: [
          totaltime_js?.["reportId"]?.[tblData],
          totaltime_js?.["questionId"]?.[tblData],
          totaltime_js?.["Comments"]?.[tblData] || [],
        ],
        reportLink: totaltime_js["reportId"][tblData] || "",
        SiteID: totaltime_js["Siteid"][tblData] || "",
        Reporter: totaltime_js["Reporter"][tblData] || "",
        Question: totaltime_js["non-compliance"][tblData] || "",
        circle: totaltime_js["Circle"]?.[tblData] || "",
        region: totaltime_js?.["Region"]?.[tblData] || "",
        market: totaltime_js?.["Market"]?.[tblData] || "",
        customer: totaltime_js?.["Customer"]?.[tblData] || "",
        supplier: totaltime_js?.["Supplier"]?.[tblData],
        state: totaltime_js?.["state"]?.[tblData] || "",
        business_unit: totaltime_js?.["business_unit"]?.[tblData] || "",
        form_name: totaltime_js?.["form_name"]?.[tblData] || "",
        reportURL:
          domain_url +
            "/show_report/" +
            totaltime_js?.["reportId"]?.[tblData] || "",
      });
    }
    return products;
  }, [totaltime_js]);

  const tableOptions = useMemo(() => {
    return {
      data: [],
      columns: [
        {
          key: "id",
          title: "Id",
          sort: "none",
        },
        {
          key: "Reporter",
          title: "Auditor",
          sort: "none",
        },
        {
          key: "NonCompliance",
          title: "Non - Compliance",
          sort: "none",
          width: "200px",
        },
        {
          key: "Owner",
          title: "Owner",
          sort: "none",
          cellClass: "text-wrap",
        },
        {
          key: "state",
          title: "State",
          sort: "none",
          hidden: true,
        },
        {
          key: "Created Date",
          title: "Created",
          sort: "desc",
        },
        {
          key: "status",
          title: "Status",
          sort: "none",
        },
        {
          key: "Action",
          title: "Action",
          action: true,
          onCreatedCell: (td, data) => {
            td.innerHTML = `${
              data[0].toLowerCase() === "open"
                ? `<button class="btn statusHandler warning" id="updateAction" title="Click here to Close" >Close</button>`
                : `<button class="btn statusHandler green" id="updateAction" title="Click here to Reopen" > Reopen</button>`
            }`;
            td.querySelector("button.statusHandler").addEventListener(
              "click",
              () => {
                onStatusHandler(data[1], data[2], data[0], []);
              }
            );
          },
        },
      ],
      sortable: true,
      actions: true,
      expandable: true,
      scroll: true,
      height: "300px",
      onCreatedDetailsRow: (td, data) => {
        td.innerHTML = `<div class="mb-bs">Site Id : ${
          data?.["SiteID"]
        }</div><div class="mb-bs">Question : ${
          data?.["Question"]
        }</div><div class="mb-bs">Mitigation : ${
          data?.["Mitigation"]
        }</div><div class="mb-bs">Completed : ${data?.["Completed"]}</div>${
          features.find((val) => val.name === "COUNTRY").value === "IN"
            ? ` <div class="mb-bs">Circle : ${data?.["circle"]}</div>`
            : ` <div class="mb-bs">Region : ${data?.["region"]}</div>`
        }

            ${
              features?.some((val) => val.name === "MARKET" && val.value)
                ? `<div class="mb-bs">Market : ${data?.["market"]}</div>`
                : ""
            }

            ${
              features?.some((val) => val.name === "CUSTOMER" && val.value)
                ? `<div class="mb-bs">Customer : ${data?.["customer"]}</div>`
                : ""
            }

        <div class="mb-bs">Supplier : ${data?.["supplier"]}</div>
      <div class="mb-bs">
      ${
        rolesMap.some((role) =>
          ["CONSOLE_SAFETYACTION_GLOBAL", "CONSOLE_GLOBAL_ADMIN"].includes(role)
        )
          ? `<button
            class="btn reportsLinkHandler"
            title="Click here to Open Report"
          >
            <span>
              <i class="icon icon-link"></i>
            </span>
          </button>`
          : ""
      }
      
      <button id="updateComment" class="btn addComments" title="Add Comments"><span><i class="icon icon-message-single"></i></span></button><button  class="btn showAttachments" title="Show Attachments" ${
        _.isEmpty(data?.attachemnts?.[0]) &&
        _.isEmpty(data?.attachemnts?.[3]) &&
        `disabled`
      }
><span><i class="icon icon-attach"></i></span></button></div>`;
        td.querySelector("button.addComments").addEventListener("click", () => {
          onStatusHandler(
            data?.comments?.[0],
            data?.comments?.[1],
            "Comment",
            data?.comments?.[2]
          );
        });
        td.querySelector("button.showAttachments").addEventListener(
          "click",
          () => {
            modalPreview(...data?.attachemnts);
          }
        );
        td.querySelector("button.reportsLinkHandler")?.addEventListener(
          "click",
          () => {
            navigate(`/Sitewalk-Reports/${data?.reportId}`);
          }
        );
      },
    };
  }, [navigate, rolesMap, features]);

  useEffect(() => {
    tableRef.current.update(deficiencies);
  }, [deficiencies]);

  return (
    <div className="row">
      <div className="column sm-12">
        {getShowMdlAttachmet && (
          <ModalPreview
            showMdl={getShowMdlAttachmet}
            setShowMdl={setShowMdlAttachment}
            dataMdl={getDataMdlAttachment}
            setDataMdl={setDataMdlAttachment}
          />
        )}

        <ModelUpdateStatus
          showMdl={getShowMdlStatus}
          setShowMdl={setShowMdlStatus}
          dataMdl={getDataMdlStatus}
          setDataMdl={setDataMdlStatus}
          getLoading={updateIsLoading}
          setStatusComments={setStatusComments}
          getStatusComments={getStatusComments}
          onStatusUpdateApi={onStatusUpdateApi}
          getErrMsg={
            updateIsError ? "Internal Server Error" : updateData?.message
          }
          setAttachmentData={setAttachmentData}
        />

        <EDSTable
          classType="dashed"
          exportToCSV={exportToCSVHandler}
          id="deficiencies"
          options={tableOptions}
          refresh={refetch}
          tableRef={tableRef}
        />
      </div>
    </div>
  );
}
