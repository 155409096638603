import { useMsal } from "@azure/msal-react";

export const useLogout = () => {
  const { instance } = useMsal();

  const callLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    instance.logoutRedirect();
  };

  return [callLogout];
};
