import { Table } from "@eds/vanilla";
import { exportToCSVMethod } from "../../../utils/helperMethod";
import ModalApprovalReject from "../../../components/Modal/ModalApprovalReject";
import ModalDownLoadPopup from "../../../components/Modal/ModalDownLoadPopup";
import { useState, useEffect } from "react";
import {
  updateStatusReportsApi,
  callGetApproveRejectOptions,
  callGenerateReport,
} from "../../../api/reports.api";
import IncidentPopup from "../IncidentPopup/IncidentPopup";
import { domain_url } from "../../../utils/Constant";
import _ from "lodash";
import { getSubReports } from "../../../api/reports.api";
import ChildReportPopup from "./ChildReportPopup";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import { useSelector } from "react-redux";

export default function ReportWithTotalTimeTakenTable({
  totaltimeData,
  setStatusAR,
  setReportsData,
  callGetReports,
  getParam,
  formList,
}) {
  const [getLoadingM, setLoadingM] = useState(false);
  const [getErrMsgM, setErrMsgM] = useState("");
  const [totaltime_js, setTotaltimeJS] = useState({});
  const [IncidentPopupReportId, setIncidentPopupReportId] = useState("");
  const [incidentDataIndex, setIncidentDataIndex] = useState("");
  const [showIncidentPopup, setShowIncidentPopup] = useState(false);
  const [getChildReportPopData, setChildReportPopData] = useState({});
  const [showSubReportModal, setShowSubReportModal] = useState(false);
  const [loadingVal, setLoadingPop] = useState(false);
  const [errMsgPop, setErrMsgPop] = useState("");
  const { headers, headersList } = useSelector((state) => state.envVariables);
  const rolesMap = useSelector((state) => state.userAccess.roles);
  const [ReportsDataformatted, setReportsDataformatted] = useState("");

  useEffect(() => {
    setTotaltimeJS(_.cloneDeep(totaltimeData));
  }, []);

  const [getDataMdlA, setDataMdlA] = useState({
    heading: "",
    body: "",
    showClose: false,
    showSave: false,
    saveBtnName: "",
    status_id: "",
    status: "",
    reasonsOptions: [],
    crewLeads: [],
  });
  const [getShowMdlA, setShowMdlA] = useState(false);
  const [getData, setData] = useState();
  const [Showpopupdownload, setShowpopupdownload] = useState({
    isOpen: false,
    Index: null,
    Value: null,
  });

  const reason = (reason) => {
    let _reason = "";
    if (reason) {
      reason.split("\n").map((i, k) => {
        if (!i.includes("Comments:") || i.length > 12) {
          _reason = _reason + `<div class="text-start">${k + 1}. ${i} </div>`;
        }
      });
    } else {
      _reason = "Reason";
    }
    return `<span class="tooltip pointer" style="text-align: center"><div class="border"><i class="icon icon-info" style="font-size:20px"></i></div><span class="message left">${_reason}</span></span>`;
  };
  const handleCloseIncidentPopup = () => {
    setIncidentDataIndex("");
    setIncidentPopupReportId("");
    setShowIncidentPopup(false);
  };

  const handleShowIncidentPop = (evt) => {
    const idx = evt;
    setIncidentDataIndex(idx);
    setIncidentPopupReportId(totaltime_js["ReportID"][idx]);
    setShowIncidentPopup(true);
    setShowpopupdownload({ isOpen: false });
  };
  const _data = () => {
    let product = [];
    for (
      let tblData = Object.keys(totaltime_js["ReportID"]).length - 1;
      tblData >= 0;
      tblData--
    ) {
      // for (let tblData = 0 ; tblData <= Object.keys(loneworkerData['Email']).length - 1; tblData++) {
      product.push({
        ReportVersion: [
          totaltime_js["ReportID"][tblData],
          totaltime_js["parent_reportId"][tblData],
          totaltime_js["count"][tblData],
          tblData,
        ],
        Esw_unique_id: totaltime_js["esw_unique_id"][tblData],
        "Report Type": totaltime_js["App Type"][tblData],
        "Project ID": totaltime_js["Unique ID"][tblData],
        "Crew Lead": totaltime_js["Crew Lead"][tblData],
        "Additional Crew": totaltime_js["Crew Names"][tblData]?.join(", "),
        "Submitted Datetime (CST)": totaltime_js["Submitted Datetime"][tblData],
        "SNTP Datetime (CST)": totaltime_js["SNTP Datetime (CST)"][tblData],
        "Total time (mins)": totaltime_js["Total time"][tblData],
        Download: [
          totaltime_js["Submitted Datetime"][tblData],
          totaltime_js["Status"][tblData],
          totaltime_js["ReportID"][tblData],
          tblData,
          totaltime_js["imtIds"][tblData],
          totaltime_js["App Type"][tblData],
          totaltime_js["require_review"][tblData]
        ],
        Status: [totaltime_js["Status"][tblData], totaltime_js["require_review"][tblData]],
        Reason: reason(totaltime_js["Reason"][tblData]),
        "Site ID": totaltime_js["Site_ID"][tblData],
        Customer: totaltime_js["customer"][tblData],
        "Crew Lead Email": totaltime_js["email"][tblData],
        "Crew Lead Company Name": totaltime_js["company"][tblData],
        "AI Status": totaltime_js["ai_status"][tblData],
        "Climber Data": totaltime_js["climber_data"][tblData],
        State: totaltime_js["State"][tblData],
        count: totaltime_js["count"][tblData],
        parent_reportId: totaltime_js["parent_reportId"][tblData],
        require_review: totaltime_js["require_review"][tblData]
      });
    }
    return product;
  };

  const viewSubReportPopup = async (selectedReportID, parentReportID, idx) => {
    let _getParam = _.cloneDeep(getParam);
    ["usergroup", "response_type"].forEach((i) => delete _getParam[i]);
    const param = {
      ..._getParam,
      childdata: "Y",
      parent_report_Id: parentReportID,
      selected_report_Id: selectedReportID,
    };
    await getSubReports(
      param,
      setChildReportPopData,
      setLoadingPop,
      setErrMsgPop,
      headers
    );
    setShowSubReportModal(true);
  };

  useEffect(() => {
    if (!_.isEmpty(totaltime_js)) {
      const tableDOM = document.querySelector("#JHA-Reports");
      if (_.isEmpty(getData)) {
        const table = new Table(tableDOM, {
          data: _data(),
          columns: [
            {
              key: "ReportVersion",
              title: "",
              cellClass: "checkVersionTD",
              width: "40px",
              onCreatedCell: (td, data) => {
                td.innerHTML =
                  data[2] !== 1
                    ? '<span class="tooltip pointer text-center"><button class="viewSubReport btn" id="open-subReports"><i class="icon icon-chevron-right text-lg"></i></button><span class="message right">Click here to check report version</span></span>'
                    : '';
                data[2] !== 1 &&
                  td
                    .querySelector("button.viewSubReport")
                    .addEventListener("click", () => {
                      viewSubReportPopup(data[0], data[1], data[3]);
                    });
              },
            },
            {
              key: "Esw_unique_id",
              title: "Report ID",
              sort: "none",
              cellClass: "text-wrap",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message right">${data}</span></span>`;
              },
            },
            {
              key: "Report Type",
              title: "Report Type",
              sort: "none",
              cellClass: "text-wrap",
            },
            {
              key: "require_review",
              title: "Requires Review",
              sort: "none",
              cellClass: "text-wrap",
            },
            {
              key: "Status",
              title: "Status",
              sort: "none",
              cellStyle: "text-align: center",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<kbd class="tag ${data[0] === "Approved" ? "green" : data[0] === "Rejected" ? "red" : data[0] === "Submitted" && data[1] === "No"  ? "green": "orange"}">${data[0]}</span>`;
              },
            },
            {
              key: "Project ID",
              title: "Project ID",
              sort: "none",
              cellClass: "text-wrap",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message right">${data}</span></span>`;
              },
            },
            {
              key: "Crew Lead",
              title: "Crew Lead",
              sort: "none",
              cellClass: "text-wrap",
            },
            {
              key: "Additional Crew",
              title: "Additional Crew",
              sort: "none",
              cellClass: "text-wrap",
            },
            {
              key: "Submitted Datetime (CST)",
              title: "Submitted Datetime",
              sort: "desc",
              cellClass: "text-wrap",
            },
            {
              key: "SNTP Datetime (CST)",
              title: "SNTP Datetime",
              sort: "none",
              cellClass: "text-wrap",
            },
            {
              key: "Total time (mins)",
              title: "Total time (mins)",
              sort: "none",
            },
            {
              key: "Download",
              title: "View Details",
              cellClass: "download",
              cellStyle: "text-align: center",
              onCreatedCell: (td, data, key) => {
                td.innerHTML = `<span class="tooltip pointer text-center"><button class="btn icon-btn viewReport primary" index="${data[3]}" ><i class="icon icon-launch"></i></button><span class="message left">Review detailed report</span></span>`;
                td.querySelector("button.viewReport").addEventListener(
                  "click",
                  async (evt) => {       
                    setShowpopupdownload({
                      isOpen: true,
                      Index: data[3],
                      Value: data,
                    });
                    setReportsDataformatted(
                      await callGenerateReport(
                        `${domain_url}/show_report/${data[2]}`,
                        headersList
                      )
                    );
                  }
                );
              },
            },
          ],
          sortable: true,
          expandable: false,
        });
        table.init();
        setData(table);
      } else {
        getData.update(_data());
      }
    }
    handleResize();
  }, [totaltime_js]);

  const callUpdateTotaltimeJS = (reportID, status_id, reason) => {
    const dataStatus = { ...totaltime_js?.Status };
    const dataReason = { ...totaltime_js?.Reason };
    let reportIndex = Object.values(totaltime_js["ReportID"]).indexOf(reportID);
    dataStatus[reportIndex] = status_id === 2 ? "Approved" : "Rejected";
    let reasonStr =
      reason?.crew_reasons
        ?.map((crew) => crew?.crew_name + crew.reasons?.join(", "))
        .join("\n") +
      "\n Comments:  " +
      reason?.comments;
    dataReason[reportIndex] = reasonStr;

    setTotaltimeJS({ ...totaltime_js, Status: dataStatus, Reason: dataReason });
  };

  const onStatusUpdateApi = async (reportID, status_id, reason) => {
    const respData = await updateStatusReportsApi(
      {
        reportId: [reportID],
        status_id: status_id,
        update_reason: JSON.stringify(reason),
      },
      setLoadingM,
      setErrMsgM,
      setShowMdlA,
      setStatusAR,
      headers
    );
    // const respData = 200;
    if (respData === 200) {
      setShowMdlA(false);
      callUpdateTotaltimeJS(reportID, status_id, reason);
    }
  };

  const callExport = () => {
    const csvData = [];
    const data = _data();
    data?.forEach((val) => {
      csvData.push({
        "Submitted Datetime": val?.["Submitted Datetime (CST)"],
        Version:
          val?.["ReportVersion"][2] !== 1 ? val["ReportVersion"][2] - 1 : "",
        "Report Type": val?.["Report Type"],
        "Project ID": val?.["Project ID"],
        "Requires Review": val?.["require_review"],
        "Site ID": val?.["Site ID"],
        Customer: val?.["Customer"],
        "Crew Lead": val?.["Crew Lead"],
        "Crew Lead Email": val?.["Crew Lead Email"],
        "Crew Lead Company Name": val?.["Crew Lead Company Name"],
        "Additional Crew": !_.isEmpty(val?.["Additional Crew"])
          ? val["Additional Crew"]
          : "",
        "SNTP Datetime": val?.["SNTP Datetime (CST)"],
        "Total time (mins)": val?.["Total time (mins)"],
        "Link to item": domain_url + "/show_report/" + val?.["Download"],
        Status: val?.["Status"],
        "AI Status": val?.["AI Status"],
        "Climber Data": val?.["Climber Data"],
        State: val?.["State"],
        "Report ID": val?.["Esw_unique_id"],
      });
    });
    exportToCSVMethod(csvData, "Reports with total time taken");
  };

  const handleResize = () => {
    let _tr = document.querySelector(".JHA-Reports.stickyHeader thead tr");
    let _top = document.querySelector(".filterDiv").offsetHeight;
    if (_tr) {
      _tr.style.top = _top + "px";
    }
  };

  const closeAlert = () => {
    setErrMsgPop("");
  };

  return (
    <>
      {loadingVal && (
        <div className="SiderBarLoader">
          <Loading />
        </div>
      )}
      {showSubReportModal && !_.isEmpty(getChildReportPopData) && (
        <ChildReportPopup
          getChildReportData={getChildReportPopData}
          setChildReportPopData={setChildReportPopData}
          setShowSubReportModal={setShowSubReportModal}
          setStatusAR={setStatusAR}
          setReportsData={setReportsData}
          headersList={headersList}
          callGenerateReport={callGenerateReport}
        />
      )}
      {errMsgPop !== "" && (
        <AlertEDS
          errMsg={errMsgPop}
          status_code={201}
          close={true}
          closeAlert={closeAlert}
        />
      )}
      <div className="table-top">
        <div className="table-top-left">
          <div className="table-info"></div>
        </div>
        <div className="table-top-right">
          <div className="table-buttons">
            <button
              className="btn"
              id="exportDataJHA-Reports"
              onClick={() => callExport()}
            >
              Export
            </button>
            <button className="btn" onClick={() => callGetReports()}>
              Refresh
            </button>
          </div>
          <div className="table-search">
            <input
              type="text"
              className="with-icon"
              placeholder="Search for..."
            />
            <div className="suffix icon-inside">
              <i className="icon icon-search"></i>
            </div>
          </div>
        </div>
      </div>
      <table
        id="JHA-Reports"
        className="table dashed stickyHeader JHA-Reports horizonatalScroll compact"
      ></table>
      <div className="table-bottom">
        <div className="pagination-group">
          <ul className="pagination"></ul>

          <div className="actions">
            <label className="left">Go to</label>
            <input type="number" min="1" />

            <label className="left">Show</label>
            <div className="select" data-type="single">
              <button className="btn current-options">5</button>

              <div className="options-list">
                <div className="item active">5</div>
                <div className="item">10</div>
                <div className="item">15</div>
                <div className="item">All</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Showpopupdownload.isOpen && (
        <ModalDownLoadPopup
          Showpopupdownload={Showpopupdownload}
          ReportsDataformatted={ReportsDataformatted}
          setReportsDataformatted={setReportsDataformatted}
          setShowpopupdownload={setShowpopupdownload}
          totaltime_js={totaltime_js}
          setShowMdlA={setShowMdlA}
          callGetApproveRejectOptions={callGetApproveRejectOptions}
          setLoadingM={setLoadingM}
          setErrMsgM={setErrMsgM}
          headers={headers}
          setDataMdlA={setDataMdlA}
          handleShowIncidentPop={handleShowIncidentPop}
          formList={formList}
        />
      )}

      {getShowMdlA && (
        <ModalApprovalReject
          Showpopupdownload={Showpopupdownload}
          setShowpopupdownload={setShowpopupdownload}
          showMdl={getShowMdlA}
          setShowMdl={setShowMdlA}
          dataMdl={getDataMdlA}
          getLoading={getLoadingM}
          setErrMsgM={setErrMsgM}
          getErrMsgM={getErrMsgM}
          onStatusUpdateApi={onStatusUpdateApi}
          setDataMdlA={setDataMdlA}
          ReportsDataformatted={ReportsDataformatted}
        />
      )}

      {showIncidentPopup &&
      !_.isEmpty(
        rolesMap?.find(
          (val) =>
            val === "CONSOLE_GLOBAL_ADMIN" ||
            val === "CONSOLE_SAFETY_FORM_APPROVALS"
        )
      ) ? (
        <IncidentPopup
          handleCloseIncidentPopup={handleCloseIncidentPopup}
          reportId={IncidentPopupReportId}
          dataIndex={incidentDataIndex}
          setReportsData={setReportsData}
        />
      ) : null}
    </>
  );
}
