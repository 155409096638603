import Loading from "../components/Loading/Loading";
export const EnvironmentVariables = async () => {
  try {
    const response = await fetch("/public/settings-dashboard");
    const data = await response.json();
    return {
      redirect_URI: data.data.Redirect_URL,
      domain_url: data.data.Endpoint,
      client_ID: data.data.client_ID,
      tenantID: data.data.tenantID,
      tenantName: data.data.tenantName,
      tenantURI: data.data.tenantURI,
      appURL: data.data.appURL,
    };
  } catch (error) {
    return (
      <>
        <Loading width={"28"} height={"28"} />
      </>
    );
  }
};
