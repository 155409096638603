import { useEffect } from "react";
import { Dialog } from "@eds/vanilla";
import { useLogout } from "../../api/login.api";

export const SessionExpired = () => {
  const [callLogout] = useLogout();

  useEffect(() => {
    const dialogDOM = document.getElementById("session-expired-dialog");
    const dialog = new Dialog(dialogDOM);
    dialog.init();

    let idleTimer;
    const resetTimer = () => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(logout, 1800000);
    };

    const logout = () => {
      dialog.show();
      sessionStorage.setItem("sessionExpired", true);
    };

    resetTimer();

    const sessionExpired = sessionStorage.getItem("sessionExpired");
    if (sessionExpired) {
      logout();
    }

    const events = ["mousemove", "keydown", "mousedown", "touchstart"];
    const onActivity = () => resetTimer();

    events.forEach((event) => {
      document.addEventListener(event, onActivity);
    });

    return () => {
      dialog.destroy();
      clearInterval(idleTimer);
      events.forEach((event) => {
        document.removeEventListener(event, onActivity);
      });
    };
  }, []);

  return (
    <div id="session-expired-dialog" className="dialog" data-type="simple">
      <div className="content">
        <div className="top">
          <div className="title">Session Expired</div>
        </div>
        <div className="body">
          <p>
            You session has expired because of inactivity. Please login again.
          </p>
        </div>

        <div className="bottom">
          <button id="btnSubmit" className="btn warning" onClick={callLogout}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
