import React, { useState, useEffect } from "react";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import Loading from "../../components/Loading/Loading";
import moment from "moment-timezone";
import _ from "lodash";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import { useSelector } from "react-redux";
import {
  getLoneWorkerData,
  getLoneWorkerFilters,
  getLoneWorkerMetrics,
  getLoneWorkerSenderList,
} from "../../api/loneWorker.api";
import LoneWorkerTable from "./LoneWorkerTable";
import LoneWorkerMap from "./LoneWorkerMap";
import LoneWorkerMetrics from "./LoneWorkerMetrics";
import ModalList from "./ModalList";
import ModalManagerList from "./ModalManagerList";

export default function LoneWorker() {
  const [startDate, setStartDate] = useState(
    new Date(
      moment().local().subtract(1, "weeks").format("YYYY-MM-DD 00:00:00")
    )
  );
  const [endDate, setEndDate] = useState(
    new Date(
      moment()
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("YYYY-MM-DD 23:59:59")
    )
  );
  const [loadingVal, setLoading] = useState({
    loneWorkerData: true,
    loneWorkerMetrics: true,
    loneWorkerList: false,
    loneWorkerManagerList: false,
    loneWorkerSettings: false,
    loneWorkerUserList: false,
    isMap: true,
  });
  const [getStatusOption, setStatusOption] = useState([]);
  const [getMetricsStatus, setMetricsStatus] = useState([]);
  const [getLocations, setLocations] = useState([]);
  const [errMsg, setErrMsg] = useState({
    loneWorkerData: "",
    loneWorkerMetrics: "",
    loneWorkerList: "",
    loneWorkerFilterData: "",
    loneWorkerUserList: "",
    loneWorkerManagerList: "",
  });
  // const { getUserAccess } = useContext(UserContext);
  const [getLoneData, setLoneData] = useState([]);
  const [getLoneFilterData, setLoneFilterData] = useState({
    loading: false,
    data: {},
    message: "",
  });
  const [getLoneMetricsData, setLoneMetricsData] = useState([]);
  const [getLoneList, setLoneList] = useState([]);
  const [getModalShow, setModalShow] = useState(false);
  const [getManagerModalShow, setManagerModalShow] = useState(false);
  const [isAutoCall, setIsAutoCall] = useState(false);
  const { headers } = useSelector((state) => state.envVariables);

  let datepickerData = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    getStatusOption,
    setStatusOption,
    getLocations,
    setLocations,
    getLoneFilterData,
  };

  const callGetDeficiencies = (isLodder) => {
    let param = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      status: getStatusOption,
      state: getLocations,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (
      !_.isNull(startDate) &&
      !_.isNull(endDate) &&
      getStatusOption.length &&
      getLocations.length
    ) {
      getLoneWorkerData(
        param,
        setLoadingHandler,
        setErrMsgHandler,
        setLoneData,
        isLodder,
        headers
      );
      getLoneWorkerMetrics(
        param,
        getMetricsStatus,
        setLoadingHandler,
        setErrMsgHandler,
        setLoneMetricsData,
        isLodder,
        headers
      );
    } else {
      setLoneData({});
      let error = "";
      if (!getStatusOption.length) {
        error = "Please select at least one Status";
      } else if (
        !getLocations.length &&
        !getLoneFilterData.data?.locations.length
      ) {
        error = "No data found under this date range";
      } else if (!getLocations.length) {
        error = "Please select at least one Circle";
      }
      setErrMsg((val) => ({
        ...val,
        loneWorkerData: error,
        loneWorkerMetrics: error,
      }));
      setLoading((val) => ({
        ...val,
        loneWorkerData: false,
        loneWorkerMetrics: false,
        isMap: false,
      }));
    }
  };

  const setLoadingHandler = (val) => {
    setLoading((prevState) => ({
      ...prevState,
      [Object.keys(val)[0]]: Object.values(val)[0],
      [Object.keys(val)[1]]: Object.values(val)[1],
    }));
  };

  const setErrMsgHandler = (val) => {
    setErrMsg((prevState) => ({
      ...prevState,
      [Object.keys(val)[0]]: Object.values(val)[0],
    }));
  };

  useEffect(() => {
      const param = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const searchURL = `?start_date=${param?.start_date}&end_date=${param?.end_date}&timeZone=${param?.timeZone}`;
      getLoneWorkerFilters(searchURL, setLoneFilterData, headers);
  }, [ startDate, endDate]);

  useEffect(() => {
    if (!_.isEmpty(getLoneFilterData.data)) {
      setStatusOption(getLoneFilterData.data?.status.map((val) => val.id));
      setLocations(getLoneFilterData.data?.locations || []);
      setMetricsStatus(getLoneFilterData.data?.status.map((val) => val.id));
      setIsAutoCall(true);
    }
  }, [getLoneFilterData.data]);

  useEffect(() => {
    if (isAutoCall) {
      callGetDeficiencies(0);
      setIsAutoCall(false);
    }
  }, [isAutoCall]);

  const handleSettings = () => {
    setLoading({
      ...loadingVal,
      loneWorkerList: true,
    });
    getLoneWorkerSenderList(
      setLoadingHandler,
      setLoneList,
      setErrMsgHandler,
      setModalShow,
      //loadingVal,
      headers
    );
  };

  const handleManagerList = () => {
    setManagerModalShow(true);
  };

  const closeAlert = () => {
    setErrMsg({
      ...errMsg,
      loneWorkerUserList: "",
      loneWorkerManagerList: "",
    });
    setManagerModalShow(false);
  };

  const disableButtton =
    loadingVal.loneWorkerData ||
    _.isNull(startDate) ||
    _.isNull(endDate) ||
    !getStatusOption.length ||
    !getLocations.length;

  return (
      <div className="row">
        <div className="tile filterDiv">
          <CustomDatePickerForm
            formName="lone-worker"
            datepickerData={datepickerData}
            filterData={getLoneFilterData.data}
            callApply={() => {
              setIsAutoCall(true);
            }}
            disableButtton={disableButtton}
            // filtersAccess={filtersAccess}
          />
        </div>
        {getLoneFilterData.loading ? (
          <Loading />
        ) : getLoneFilterData.message !== "Success" ? (
          <AlertEDS
            errMsg={getLoneFilterData.message}
            status_code={201}
            close={true}
            closeAlert={closeAlert}
          />
        ) : (
          <>
            {getModalShow && !_.isEmpty(getLoneList) && (
              <ModalList
                getLoneList={getLoneList}
                setModalShow={setModalShow}
                getModalShow={getModalShow}
                loadingVal={loadingVal.loneWorkerList}
                errMsg={errMsg.loneWorkerList}
                handleSettings={handleSettings}
              />
            )}
            {getManagerModalShow && (
              <ModalManagerList setManagerModalShow={setManagerModalShow} />
            )}

            <div className="tile sm-12 md-12 lg-12 xl-12">
              <div className="header">
                <div className="left">
                  <div className="title">Lone Worker Sessions</div>
                </div>
              </div>
              <div className="content">
                <div className="column">
                  {loadingVal?.loneWorkerData ? (
                    <Loading />
                  ) : (
                    <>
                      {errMsg.loneWorkerData !== "Success" &&
                      errMsg.loneWorkerData !==
                        "No data available in this date range" ? (
                        <AlertEDS
                          errMsg={errMsg.loneWorkerData}
                          status_code={201}
                          close={false}
                        />
                      ) : (
                        <>
                          {!_.isEmpty(getLoneData) && (
                            <LoneWorkerTable
                              loneworkerData={JSON.parse(
                                getLoneData.loneworker_data
                              )}
                              callGetDeficiencies={callGetDeficiencies}
                              handleSettings={handleSettings}
                              handleManagerList={handleManagerList}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="tile sm-12 md-12 lg-8 xl-8">
              <div className="header">
                <div className="left">
                  <div className="title">Real-time Location Tracker</div>
                </div>
              </div>
              <div className="content">
                <div className="column">
                  {loadingVal?.isMap ? (
                    <Loading />
                  ) : (
                    <>
                      {errMsg.loneWorkerData !== "Success" &&
                      errMsg.loneWorkerData !==
                        "No data available in this date range" ? (
                        <AlertEDS
                          errMsg={errMsg.loneWorkerData}
                          status_code={201}
                          close={false}
                        />
                      ) : (
                        <>
                          {!_.isEmpty(getLoneData) && (
                            <>
                              <LoneWorkerMap
                                loneWorkerusergroup_location={
                                  getLoneData.usergroup_location
                                }
                                loneworkerData={JSON.parse(
                                  getLoneData.loneworker_data
                                )}
                                getLoneData={getLoneData}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="tile column sm-12 md-12 lg-4 xl-4">
              <div className="header">
                <div className="left">
                  <div className="title">Lone Worker Metrics</div>
                </div>
              </div>
              <div className="content">
                <div className="column">
                  {loadingVal?.loneWorkerMetrics ? (
                    <Loading />
                  ) : (
                    <>
                      {errMsg.loneWorkerMetrics !== "Success" ? (
                        <AlertEDS
                          errMsg={errMsg.loneWorkerMetrics}
                          status_code={201}
                          close={false}
                        />
                      ) : (
                        <>
                          {!_.isEmpty(getLoneMetricsData) && (
                            <LoneWorkerMetrics
                              getLoneMetricsData={JSON.parse(
                                getLoneMetricsData
                              )}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
  );
}
