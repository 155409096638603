import { useEffect, useRef } from "react";
import { Carousel } from "@eds/vanilla";
import { EDSDialog } from "../../components/EDSDialog/EDSDialog";

const ShowCertficates = ({ showCertficates, setShowCertificates }) => {
  const dialogRef = useRef(null);

  const carsouselRef = useRef(null);

  useEffect(() => {
    if (showCertficates.length) {
      const carousel = new Carousel(carsouselRef.current, {});
      carousel.init();
      dialogRef.current.show();

      return () => {
        carousel.destroy();
        const pagination = carousel.dom.pagination;

        while (pagination.firstChild) {
          pagination.removeChild(pagination.firstChild);
        }
      };
    } else {
      dialogRef.current.hide();
    }
  }, [showCertficates]);

  const dialogBody = (
    <div className="carousel" ref={carsouselRef}>
      <div className="carousel-container">
        <div className="carousel-wrapper">
          {showCertficates.map((imgUrl, ind) => (
            <div key={ind} className="carousel-slide">
              <a href={imgUrl} target="_blank">
                <img className="cs-content" src={imgUrl} alt="No image"></img>
              </a>
            </div>
          ))}
        </div>
        <div className="carousel-counter"></div>
        <div className="btn carousel-button-prev">
          <i className="icon icon-chevron-left"></i>
        </div>
        <div className="btn carousel-button-next">
          <i className="icon icon-chevron-right"></i>
        </div>
        <div className="carousel-pagination"></div>
      </div>
    </div>
  );

  return (
    <EDSDialog
      cancelHandler={() => {
        setShowCertificates([]);
      }}
      dataType="simple"
      dialogBody={dialogBody}
      dialogRef={dialogRef}
      dialogTitle={<></>}
      id="certificates"
    />
  );
};

export default ShowCertficates;
