import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { domain_url } from "../../utils/Constant";

export const supportApi = createApi({
  reducerPath: "supportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: domain_url,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().envVariables.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getHaloFeaturesList: builder.query({
      query: () => "/get_halo_featurelist",
    }),
    submitSupportForm: builder.mutation({
      query: (body) => ({
        url: "/create_ticket_halo",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetHaloFeaturesListQuery, useSubmitSupportFormMutation } =
  supportApi;

export default supportApi;
