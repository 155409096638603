import { Dialog } from "@eds/vanilla";
import { Table } from "@eds/vanilla";
import { exportToCSVMethod } from "../../../utils/helperMethod";
import { useState, useEffect } from "react";
import { callGenerateReport } from "../../../api/reports.api";
import { domain_url } from "../../../utils/Constant";
import _ from "lodash";
import { useSelector } from "react-redux";
import ModelReportPreview from "../../../components/Modal/ModelReportPreview";
export default function ChildReportPopup({
  getChildReportPopData,
  setChildReportPopData,
  setShowSubReportModal,
}) {
  const [totaltime_js, setTotaltimeJS] = useState(getChildReportPopData);
  const [getData, setData] = useState();
  const { headersList } = useSelector((state) => state.envVariables);
  const [Childreportdata, setChildreportdata] = useState("");
  const [previewpopup, setpreviewpopup] = useState({
    isOpen: false,
  });
  const onCloseHandler = () => {
    setShowSubReportModal(false);
    setChildReportPopData({});
  };

  useEffect(() => {
    const dialogs = document.querySelectorAll(".loneWorkerManagerList");
    if (dialogs) {
      Array.from(dialogs).forEach((dialogDOM) => {
        const dialog = new Dialog(dialogDOM);
        dialog.init();
      });
    }
  }, []);

  const getProducts = () => {
    const products = [];
    for (let tblData in totaltime_js?.["ReportID"]) {
      products.push({
        "Project ID": totaltime_js?.["Unique ID"]?.[tblData] || "",
        "Report Type": totaltime_js?.["App Type"]?.[tblData] || "",
        "Crew Lead": totaltime_js?.["Crew Lead"]?.[tblData] || "",
        "Additional Crew":
          totaltime_js?.["Crew Names"]?.[tblData].join(", ") || "",
        "Submitted Datetime (CST)":
          totaltime_js?.["Submitted Datetime"]?.[tblData] || "",
        "SNTP Datetime (CST)":
          totaltime_js?.["SNTP Datetime (CST)"]?.[tblData] || "",
        "Total time (mins)": totaltime_js?.["Total time"]?.[tblData] || "",
        key: totaltime_js?.["ReportID"]?.[tblData] || "",
        ReportID: totaltime_js?.["ReportID"]?.[tblData] || "",
        "Site ID": totaltime_js?.["Site_ID"]?.[tblData] || "",
        Customer: totaltime_js?.["customer"]?.[tblData] || "",
        "Crew Lead Email": totaltime_js?.["email"]?.[tblData] || "",
        "Crew Lead Company Name": totaltime_js?.["company"]?.[tblData] || "",
        "Link to Item":
          domain_url +
            "/show_report/" +
            totaltime_js?.["ReportID"]?.[tblData] || "",
        "OHS Status": totaltime_js?.["Ohs_Status"]?.[tblData] || "",
        Market: totaltime_js?.["Market"]?.[tblData] || "",
        Region: totaltime_js?.["Region"]?.[tblData] || "",
        Supplier: totaltime_js?.["Supplier"]?.[tblData] || "",
        State: totaltime_js?.["State"]?.[tblData] || "",
      });
    }
    console.log("products", products);
    return products;
  };

  useEffect(() => {
    let tableDOM = null;
    if (!_.isEmpty(totaltime_js)) {
      tableDOM = document.getElementById("subReports");

      if (_.isEmpty(getData)) {
        const table = new Table(tableDOM, {
          data: getProducts(),
          columns: [
            {
              key: "Esw_unique_id",
              title: "Report ID",
              sort: "none",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<span class="tooltip pointer text-center" style="width:100%;"><div class="ellipsis">${data}</div><span class="message right">${data}</span></span>`;
              },
            },
            {
              key: "Project ID",
              title: "Project ID",
              sort: "none",
              // headerClass: "custom-table-header-1",
              cellClass: "custom-table-body-1",
            },
            {
              key: "State",
              title: "State",
              sort: "none",
              // headerClass: "custom-table-header-1",
              cellClass: "custom-table-body-1",
            },
            {
              key: "Crew Lead",
              title: "Auditor",
              sort: "none",
              // headerClass: "custom-table-header-1",
              cellClass: "custom-table-body-1",
            },
            {
              key: "Additional Crew",
              title: "Crew",
              sort: "none",
              // headerClass: "custom-table-header-1",
              cellClass: "custom-table-body-1",
            },

            {
              key: "Submitted Datetime (CST)",
              title: "Submitted Datetime",
              sort: "desc",
              cellClass: "custom-table-body-1",
            },
            {
              key: "Total time (mins)",
              title: "Total time (mins)",
              sort: "none",
              cellClass: "custom-table-body-1",
            },

            {
              key: "Link to Item",
              title: "Details",
              headerClass: "text-center",
              cellStyle: "text-align: center",
              onCreatedCell: (td, data) => {
                td.innerHTML = `<button class="btn icon-btn viewReport" title="Click here to Open"><span><i class="icon icon-link"></i></span></button>`;
                td.querySelector("button.viewReport").addEventListener(
                  "click",
                  async (evt) => {
                    setpreviewpopup({ isOpen: true });
                    setChildreportdata(
                      await callGenerateReport(data, headersList)
                    );
                  }
                );
              },
            },
            {
              key: "Region",
              title: "Region",
              sort: "none",
              // headerClass: "custom-table-header-1",
              cellClass: "custom-table-body-1",
            },
            {
              key: "Customer",
              title: "Customer",
              sort: "none",
              // headerClass: "custom-table-header-1",
              cellClass: "custom-table-body-1",
            },
            {
              key: "Supplier",
              title: "Supplier",
              sort: "none",
              // headerClass: "custom-table-header-1",
              cellClass: "custom-table-body-1",
            },
            {
              key: "Market",
              title: "Market",
              sort: "none",
              // headerClass: "custom-table-header-1",
              cellClass: "custom-table-body-1",
            },
          ],

          sortable: true,
        });
        table.init();
        document
          .querySelector("#exportDataSub-Reports")
          .addEventListener("click", () => {
            exportToCSVHandler(table.data);
          });
        setData(table);
      } else {
        getData.update(getProducts());
      }
      //   handleResize();
    }
  }, [totaltime_js]);

  const exportToCSVHandler = (data) => {
    const csvData = [];
    data?.forEach((val) => {
      csvData.push({
        "Project ID": val?.["Project ID"],
        "Site ID": val?.["Site ID"],
        State: val?.State,
        Region: val?.Region,
        Market: val?.Market,
        Customer: val?.Customer,
        Supplier: val?.Supplier,
        Auditor: val?.["Crew Lead"],
        "Auditor Email": val?.["Crew Lead Email"],
        "Auditor Company Name": val?.["Crew Lead Company Name"],
        "Additional Crew": val?.["Additional Crew"],
        "Submitted Datetime": val?.["Submitted Datetime (CST)"],
        "Total time (mins)": val?.["Total time (mins)"],
        Details: val?.["Link to Item"],
        "Report ID": val?.["Esw_unique_id"],
      });
    });
    exportToCSVMethod(
      csvData,
      "EHS Site Walk sub Reports with total time taken"
    );
  };

  return (
    <>
      {previewpopup.isOpen ? (
        <ModelReportPreview
          setpreviewpopup={setpreviewpopup}
          Childreportdata={Childreportdata}
          setChildreportdata={setChildreportdata}
          setShowSubReportModal={setShowSubReportModal}
        />
      ) : (
        <div
          className="dialog subReportsPopup fullscreen show"
          data-trigger="#open-subReports"
          data-type="fullscreen"
        >
          <div className="content">
            <div className="top">
              <div className="title">Available Version</div>
              <div className="right">
                <button
                  className="btn-icon"
                  data-close="true"
                  onClick={() => onCloseHandler()}
                >
                  <i className="icon icon-cross"></i>
                </button>
              </div>
            </div>
            <div className="body">
              <div className="table-top">
                <div className="table-top-left">
                  <div className="table-info"></div>
                </div>
                <div className="table-top-right">
                  <div className="table-buttons">
                    <button className="btn" id="exportDataSub-Reports">
                      Export
                    </button>
                  </div>
                  <div className="table-search">
                    <input
                      type="text"
                      className="with-icon"
                      placeholder="Search for..."
                    />
                    <div className="suffix icon-inside">
                      <i className="icon icon-search"></i>
                    </div>
                  </div>
                </div>
              </div>
              <table
                id="subReports"
                className="table dashed stickyHeader horizonatalScroll"
              ></table>
              <div className="table-bottom">
                <div className="pagination-group">
                  <ul className="pagination"></ul>
                  <div className="actions">
                    <label className="left">Go to</label>
                    <input type="number" min="1" />
                    <label className="left">Show</label>
                    <div className="select" data-type="single">
                      <button className="btn current-options">5</button>
                      <div className="options-list">
                        <div className="item active">5</div>
                        <div className="item">10</div>
                        <div className="item">15</div>
                        <div className="item">All</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
