import React, { useEffect } from "react";
import { Donut } from '@eds/vanilla';
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";

export default function UnsafeResponsePieChart({ UnsafeResponseData }) {
    useEffect(() => {
        const data = {
            series: UnsafeResponseData.map(i => ({ name: i.Category, values: [i.UnsafeCount] }))
        };
        const chart = new Donut({
            element: document.getElementById('UnsafeResponsesGraph'),
            data: data,
            showValue: true,
            showAbsoluteValue: true,
            unit: 'Unsafe Responses'
        });
        chart.init();
        return () => {
            chart.destroy();
            let element = document.getElementById("UnsafeResponsesGraph");
            while (element?.firstChild) {
                element.removeChild(element.firstChild);
            }
        };
    }, [UnsafeResponseData]);

    return (
        <>
            <CustomGraphActions
                nodeId={"UnsafeResponsesGraph"}
                fileName={"SafeResponses_UnsafeResponses"}
                data={UnsafeResponseData}
            />
            <div id="UnsafeResponsesGraph"></div>
        </>
    );
}
