import { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import "./MultiselectDropDown.scss";
export default function MultiselectDropDown({
  formName,
  setStatusOption,
  formDetailsUG,
  defaultValues,
  defaultWidth = "",
  isDisabled = false,
}) {
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);

  // const debouncedFilter = useCallback(
  //   _.debounce((filterData) => {
  //     setStatusOption(filterData);
  //   }, 5000),
  //   []
  // );

  useEffect(() => {
    let filteredObject = formDetailsUG?.filter((form) =>
      defaultValues.includes(form.formId)
    );
    formDetailsUG.sort((form1, form2) => {
      if (
        form1?.form_name?.trim()?.toUpperCase() >
        form2?.form_name?.trim()?.toUpperCase()
      )
        return 1;
      else if (
        form1?.form_name?.trim()?.toUpperCase() <
        form2?.form_name?.trim()?.toUpperCase()
      )
        return -1;
      else return 0;
    });
    setSelected(
      filteredObject?.map((item) => ({
        label: item.form_name.trim().length ? item.form_name : "N/A",
        value: item.formId,
      }))
    );

    setOptions(
      formDetailsUG.map((item) => ({
        label: item.form_name.trim().length ? item.form_name : "N/A",
        value: item.formId,
      }))
    );
  }, [formDetailsUG, defaultValues]);

  const overrideStrings = {
    allItemsAreSelected: options.length === 1 ? options[0].label : "All",
    noOptions: "Please select",
  };

  const onChange = (values) => {
    const filterData = values.map((i) => i.value);
    //debouncedFilter(filterData);
    setSelected(values);
    setStatusOption(filterData);
  };

  const customValueRenderer = () => {
    if (isDisabled && selected?.length === 1) {
      return selected?.[0]?.form_name;
    } else {
      return selected?.length
        ? selected?.length + " options selected"
        : "No Items Selected";
    }
  };

  return (
    <div className="multiSelectCheckBox">
      <MultiSelect
        className={`multiSelectDropDown ${defaultWidth}`}
        options={options}
        value={selected}
        onChange={(values) => onChange(values)}
        labelledBy="Please Select"
        disableSearch={options.length < 10}
        hasSelectAll={options.length > 1 && formName !== "Action-Users"}
        overrideStrings={overrideStrings}
        disabled={isDisabled}
        valueRenderer={() => customValueRenderer()}
      />
    </div>
  );
}
