import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment-timezone";
import {
  getReports,
  getTotalTimeTakenStatics,
  getReportsFiltersData,
  getReportsByReportId,
} from "../../api/EHSReports.api";
import ReportWithTotalTimeTakenTable from "./Tables/ReportWithTotalTimeTakenTable";
import TotalTimeTakenStatisticsTable from "./Tables/TotalTimeTakenStatisticsTable";
import Loading from "../../components/Loading/Loading";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import AlertEDS from "../../components/AlertWarn/AlertEDS";

export default function EHSReports() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loadingVal, setLoading] = useState({
    reportsTotalTime: false,
    totalTimeStatistics: false,
  });
  const [reportsData, setReportsData] = useState({});
  const [totalTimeStatisticsData, setTotalTimeStatisticsData] = useState({});
  const [formIdData, setFormIdData] = useState([]);
  const [getStateDetails, setStateDetails] = useState([]);
  const [errMsg, setErrMsg] = useState({
    reportsTotalTime: "",
    totalTimeStatistics: "",
  });
  const [getUsersOption, setUsersOption] = useState([]);
  const [getMarketDetails, setMarketDetails] = useState([]);
  const [getCustomerDetails, setCustomerDetails] = useState([]);
  const [getRegionDetails, setRegionDetails] = useState([]);
  const [getSupplierDetails, setSupplierDetails] = useState([]);
  const [filterData, setfiltersData] = useState({});
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filtersErrMsg, setFiltersErrMsg] = useState("");
  const [getStatusAR, setStatusAR] = useState("");
  const [currentDay, setCurrentDay] = useState(
    moment().local().format("YYYY-MM-DD")
  );
  const [isAutoCall, setIsAutoCall] = useState(false);
  const [selectedBusinessUnit, setSelecetedBusinessUnit] = useState([]);
  const { reportId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [toggleReportId, setToggleReportId] = useState(false);
  const { employeeId } = useSelector((state) => state.loggedInUser.user);
  const { headers, headersList } = useSelector((state) => state.envVariables);
  const [getParam, setParam] = useState({});
  const datepickerData = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setFormIdData,
    formIdData,
    getStateDetails,
    setStateDetails,
    getUsersOption,
    setUsersOption,
    getMarketDetails,
    setMarketDetails,
    getCustomerDetails,
    setCustomerDetails,
    getRegionDetails,
    setRegionDetails,
    getSupplierDetails,
    setSupplierDetails,
    filterData,
    setfiltersData,
    selectedBusinessUnit,
    setSelecetedBusinessUnit,
  };

  const setErrMsgHandler = (val) => {
    setErrMsg((prevState) => ({
      ...prevState,
      [Object.keys(val)[0]]: Object.values(val)[0],
    }));
  };
  const setLoadingHandler = (val) => {
    setLoading((prevState) => ({
      ...prevState,
      [Object.keys(val)[0]]: Object.values(val)[0],
    }));
  };

  const callGetReports = async (formId = []) => {
    if (typeof reportId !== "undefined") {
      const currentPath = location.pathname.split("/");
      navigate(`/${currentPath?.[1] || ""}`);
      await setToggleReportId(false);
    }
    const param = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      formId: formIdData,
      response_type: "2",
      state: getStateDetails,
      timeZone: moment.tz.guess(true),
      markets: getMarketDetails,
      customers: getCustomerDetails,
      locations: getRegionDetails,
      suppliers: getSupplierDetails,
      reporter: getUsersOption,
      business_unit: selectedBusinessUnit,
    };
    if (
      !_.isNull(startDate) &&
      !_.isNull(endDate) &&
      !_.isEmpty(param.formId) &&
      !_.isEmpty(param.state) &&
      !_.isEmpty(getUsersOption) &&
      !_.isEmpty(getRegionDetails) &&
      !_.isEmpty(getSupplierDetails) &&
      !_.isEmpty(getMarketDetails) &&
      !_.isEmpty(getCustomerDetails) &&
      !_.isEmpty(selectedBusinessUnit)
    ) {
      getReports(
        param,
        setLoadingHandler,
        setErrMsgHandler,
        setReportsData,
        headersList
      );

      getTotalTimeTakenStatics(
        param,
        setLoadingHandler,
        setErrMsgHandler,
        setTotalTimeStatisticsData,
        headersList
      );
    } else {
      if (_.isNull(startDate)) {
        setErrMsg({
          reportsTotalTime: "Please select Start Date",
          totalTimeStatistics: "Please select Start Date",
        });
      } else if (_.isNull(endDate)) {
        setErrMsg({
          reportsTotalTime: "Please select End Date",
          totalTimeStatistics: "Please select End Date",
        });
      } else if (_.isEmpty(param.formId)) {
        setErrMsg({
          reportsTotalTime: "Please select at least one Form",
          totalTimeStatistics: "Please select at least one Form",
        });
      } else if (_.isEmpty(param.state)) {
        setErrMsg({
          reportsTotalTime: "Please select at least one State",
          totalTimeStatistics: "Please select at least one State",
        });
      } else if (_.isEmpty(getUsersOption)) {
        setErrMsg({
          reportsTotalTime: "Please select at least one Auditor",
          totalTimeStatistics: "Please select at least one Auditor",
        });
      } else if (_.isEmpty(getRegionDetails)) {
        setErrMsg({
          reportsTotalTime: "Please select at least one Region",
          totalTimeStatistics: "Please select at least one Region",
        });
      } else if (_.isEmpty(getSupplierDetails)) {
        setErrMsg({
          reportsTotalTime: "Please select at least one Supplier",
          totalTimeStatistics: "Please select at least one Supplier",
        });
      } else if (_.isEmpty(getMarketDetails)) {
        setErrMsg({
          reportsTotalTime: "Please select at least one Market",
          totalTimeStatistics: "Please select at least one Market",
        });
      } else if (_.isEmpty(getCustomerDetails)) {
        setErrMsg({
          reportsTotalTime: "Please select at least one Customer",
          totalTimeStatistics: "Please select at least one Customer",
        });
      } else if (_.isEmpty(selectedBusinessUnit)) {
        setErrMsg({
          reportsTotalTime: "Please select at least one Business Unit",
          totalTimeStatistics: "Please select at least one Business Unit",
        });
      }
      setLoading({
        reportsTotalTime: false,
        totalTimeStatistics: false,
      });
      setReportsData({});
      setTotalTimeStatisticsData({});
    }
    setParam(param);
  };

  useEffect(() => {
    if ( startDate && endDate) {
      const param = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        timeZone: moment.tz.guess(true),
      };
      getReportsFiltersData(
        param,
        setfiltersData,
        setFiltersLoading,
        setFiltersErrMsg,
        headers
      );
    }
  }, [startDate, endDate, headers]);

  useEffect(() => {
      updateFiltersDefaultValuesHandler();
      if (typeof reportId !== "undefined" && !toggleReportId) {
        callReportsByReportId();
      } else if (typeof reportId === "undefined") {
        setIsAutoCall(true);
      }
  }, [filterData]);

  useEffect(() => {
      const intervalId = setInterval(() => {
        const newDay = moment().local().format("YYYY-MM-DD");
        if (currentDay !== newDay) {
          setCurrentDay(newDay);
          setStartDate(
            new Date(
              moment(currentDay, "YYYY-MM-DD").format("YYYY-MM-DD 00:00:00")
            )
          );
          setEndDate(
            new Date(moment(newDay, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59"))
          );
          setIsAutoCall(true);
        }
      }, 60000);
      return () => clearInterval(intervalId);
    
  }, [ currentDay]);

  useEffect(() => {
    if (isAutoCall) {
      callGetReports();
      setIsAutoCall(false);
    }
  }, [isAutoCall]);

  const callReportsByReportId = async () => {
    await getReportsByReportId(
      {
        report_id: reportId,
      },
      datepickerData,
      setLoadingHandler,
      setErrMsgHandler,
      setReportsData,
      headersList
    );
    await setToggleReportId(true);
  };

  const updateFiltersDefaultValuesHandler = () => {
    if (typeof reportId !== "undefined") {
      const reportData = JSON.parse(reportsData?.totaltime_js || "{}");
      setStateDetails([reportData?.State?.[0] || ""]);
      setCustomerDetails([reportData?.customer?.[0] || ""]);
      setMarketDetails([reportData?.Market?.[0] || ""]);
      setSupplierDetails([reportData?.Supplier?.[0] || ""]);
      const defaultReporter = Object.entries(
        filterData?.reporters?.find(
          (val) =>
            Object.entries(val)?.[0]?.[1]?.[0] ===
            reportData?.["Crew Lead"]?.[0]
        ) || []
      )?.[0]?.[0];
      setUsersOption(_.isEmpty(defaultReporter) ? [] : [defaultReporter]);
      setFormIdData([reportsData?.formId || ""]);
      setRegionDetails([reportData?.Region?.[0] || ""]);
      //setSelecetedBusinessUnit([reportData?.Buss])
    } else {
      setStateDetails(filterData?.state || []);
      setCustomerDetails(filterData?.customers || []);
      setMarketDetails(filterData?.markets || []);
      setSupplierDetails(filterData?.suppliers || []);
      const defaultReporter = Object.entries(
        filterData?.reporters?.find(
          (val) => Object.entries(val)?.[0]?.[0] === employeeId
        ) || []
      )?.[0]?.[0];
      setUsersOption(_.isEmpty(defaultReporter) ? [] : [defaultReporter]);
      setFormIdData(filterData?.form?.map((form) => form.formId) || []);
      setRegionDetails(filterData?.locations || []);
      setSelecetedBusinessUnit(filterData?.business_unit || []);
    }
  };

  return (
      <div className="row">
        <div className="tile filterDiv">
          <CustomDatePickerForm
            formName="ehsReports"
            datepickerData={datepickerData}
            filterData={filterData}
            callApply={callGetReports}
            disableButtton={
              filtersLoading ||
              loadingVal?.reportsTotalTime ||
              loadingVal?.totalTimeStatistics
            }
          />
        </div>

        <>
          {filtersLoading && typeof reportId === "undefined" ? (
            <Loading />
          ) : (
            <>
              {filtersErrMsg !== "Success" &&
              typeof reportId === "undefined" ? (
                <div className="tile">
                  <AlertEDS
                    errMsg={filtersErrMsg}
                    status_code={201}
                    close={false}
                  />
                </div>
              ) : (
                <>
                  <div className="tile sm-12 md-12 lg-12">
                    <div className="header">
                      <div className="left">
                        <div className="title">Reports</div>
                      </div>
                    </div>
                    <div className="content">
                      <div className="column">
                        {loadingVal?.reportsTotalTime ? (
                          <Loading />
                        ) : (
                          <>
                            {errMsg?.reportsTotalTime !== "Success" ? (
                              <AlertEDS
                                errMsg={errMsg?.reportsTotalTime}
                                status_code={201}
                                close={false}
                              />
                            ) : (
                              !_.isEmpty(reportsData?.totaltime_js) && (
                                <ReportWithTotalTimeTakenTable
                                  totaltimeData={
                                    reportsData?.totaltime_js !== "undefined" &&
                                    JSON.parse(reportsData.totaltime_js)
                                  }
                                  setStatusAR={setStatusAR}
                                  setReportsData={setReportsData}
                                  callGetReports={callGetReports}
                                  // reportAccessDetails={reportsAccess}
                                  getParam={getParam}
                                />
                              )
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {typeof reportId === "undefined" && (
                    <>
                      <div className="tile sm-12 md-12 lg-12">
                        <div className="header">
                          <div className="left">
                            <div className="title">
                              Total Time Taken - Statistics (minutes)
                            </div>
                          </div>
                        </div>
                        <div className="content">
                          <div className="column">
                            {loadingVal?.totalTimeStatistics ? (
                              <Loading />
                            ) : (
                              <>
                                {errMsg?.totalTimeStatistics !== "Success" ? (
                                  <AlertEDS
                                    errMsg={errMsg?.totalTimeStatistics}
                                    status_code={201}
                                    close={false}
                                  />
                                ) : (
                                  <TotalTimeTakenStatisticsTable
                                    totaltime_stats_js={
                                      typeof totalTimeStatisticsData.totaltime_stats_js !=
                                        "undefined" &&
                                      JSON.parse(
                                        totalTimeStatisticsData.totaltime_stats_js
                                      )
                                    }
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      </div>
  );
}
