import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function Datepicker({
  selectedDate,
  setDate,
  isMonthyearPicker = false,
  isDisable = false,
  minDate = undefined,
}) {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date) => setDate(date)}
      showMonthDropdown
      showYearDropdown
      dateFormat={isMonthyearPicker ? "MM/yyyy" : "MM/dd/yyyy"}
      dropdownMode="select"
      todayButton="Today"
      className="w-100"
      showMonthYearPicker={isMonthyearPicker}
      disabled={isDisable}
      minDate={minDate}
    />
  );
}
