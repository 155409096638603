import React, { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import "./Login.scss";
import Loading from "../../components/Loading/Loading";
import { loginRequest } from "../../authConfig";
import Footer from "../Footer/Footer";
import logo from "../../asset/Icons/sw_logo.svg";

export default function Login() {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
    
    if (!isAuthenticated) {
      instance.loginRedirect(loginRequest).catch((error) => {
        console.log(error);
        setError("Unable to redirect to a login page");
      });
    } else {
      sessionStorage.setItem("isLastLoginAPICalled", false); // to show last login date time popup on first time login
    } 
  }, [instance, isAuthenticated]);

  return (
    <div className="signin">
      <div className="watermark">
        <div className="logoDiv">
          <img src={logo} alt="Safe Worker" className="siteLogo" />
          <div className="product">
            <span className="firstTxt">Safe Worker</span>
          </div>
        </div>
      </div>
      <div className="waiting">
        {error ? (
          <div className="message color-red">{error}</div>
        ) : (
          <>
            <div className="message">Please wait... Loading.</div>
            <Loading />
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
