import React, { useEffect } from "react";
import moment from "moment";
import { ColorScale, VerticalBarChartStacked } from "@eds/vanilla";
export default function UserCountGraph({ graphData }) {
  const getChartData = (data1) => {
    const date = [];
    const UserNames = [
      "Team Lead",
      "Approver",
      "actionmoduleuser",
      "Crew Member",
    ];
    const series = [];
    const mapName = (name) => {
      if (name === "actionmoduleuser") {
        return "Action Module User";
      } else if (name === "Approver") {
        return "Reviewer";
      }
      return name;
    };
    data1.forEach((item) => {
      if (!date.includes(item.date)) {
        date.push(item.date);
      }
      UserNames.forEach((name) => {
        const updatedName = mapName(name);
        const value = item.count[name] || 0;
        const existingUser = series.find((s) => s.name === updatedName);
        if (existingUser) {
          existingUser.values.push(value);
        } else {
          const values = new Array(date.length).fill(0);
          values[date.indexOf(item.date)] = value;

          series.push({ name: updatedName, values });
        }
      });
    });
    return { common: date, series };
  };

  const data1 = getChartData(graphData);
  // const getChartData = () => {
  //   // const dateGraph = graphData["date"];
  //   // const countGraph = graphData["count"];
  //   const formattedData = [];
  //   for (const property in dateGraph) {
  //     formattedData.push({
  //       date: moment(dateGraph[property], "DD/MM/YYYY").format("YYYY-MM-DD"),
  //       count: countGraph[property],
  //     });
  //   }
  //   formattedData.sort((a, b) => {
  //     if (a.date > b.date) return 1;
  //     else if (a.date < b.date) return -1;
  //     else return 0;
  //   });
  //   return formattedData;
  // };

  useEffect(() => {
    const myColorScale = new ColorScale({ reverse: true });
    myColorScale.init();
    const data = data1;
    const chart = new VerticalBarChartStacked({
      element: document.getElementById("userCountGraph"),
      data: data,
      y: { unit: "Count" },
      colorScale: myColorScale,
    });
    chart.init();
    return () => {
      chart.destroy();
    };
  }, []);

  return <div id="userCountGraph"></div>;
}
