import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChartStacked } from "@eds/vanilla";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import Loading from "../../../components/Loading/Loading";
import { useAuditsByBusinessMutation } from "../../../redux/services/sitewalksAnalyticsApi";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";

export const AuditsByBusiness = ({
  param,
  isAutoCall,
  getAnalyticsGraphsToggle,
  setAnalyticsGraphsToggle,
  validationMsg,
  validateFilterValues,
}) => {
  const [auditsByBusiness, { data: { data, message } = {}, isLoading, error }] =
    useAuditsByBusinessMutation();

  const errorMessage =
    validationMsg ||
    (message !== "Success" && message) ||
    (error && "Internal Server Error. Please try again");

  const handleIsOpen = (event) => {
    event.preventDefault();

    const isValid = validateFilterValues("audit_count_by_bu");

    isValid &&
      !getAnalyticsGraphsToggle.auditsByBusiness &&
      auditsByBusiness(JSON.stringify(param));

    setAnalyticsGraphsToggle((val) => ({
      ...val,
      audit_count_by_bu: !val.audit_count_by_bu,
    }));
  };

  useEffect(() => {
    const isValid = validateFilterValues("audit_count_by_bu");

    isValid &&
      isAutoCall &&
      getAnalyticsGraphsToggle.audit_count_by_bu &&
      auditsByBusiness(JSON.stringify(param));
  }, [isAutoCall]);

  useEffect(() => {
    if (data?.length) {
      const chartData = (() => {
        const locations = param.region || [];
        const groupByType = Object.groupBy(
          data,
          ({ bussiness_unit }) => bussiness_unit
        );

        const formatedData = {
          common: locations,
          series: Object.entries(groupByType).map(([name, val]) => {
            const values = locations.map(
              (location) =>
                val.find((obj) => obj.region === location)?.count || 0
            );
            return {
              name,
              values,
            };
          }),
        };

        return formatedData;
      })();

      const chartHeight = chartData.common.length * 60;
      const mycolorScale = new ColorScale({ reverse: true });

      const chart = new HorizontalBarChartStacked({
        element: document.getElementById("auditsByBusiness-graph"),
        data: chartData,
        x: { unit: "Count" },
        height: chartHeight,
        colorScale: mycolorScale,
      });

      mycolorScale.init();

      chart.init();

      return () => {
        chart.destroy();
      };
    }
  }, [data]);

  return (
    <li
      tabIndex={6}
      className={`tile mb-sm accordion-item ${
        getAnalyticsGraphsToggle?.audit_count_by_bu ? "opened" : ""
      }`}
    >
      <div className="title stickyTileHeader" onClick={handleIsOpen}>
        <div className="header p-sm">
          <div className="left">Audit Count by Business Unit</div>
        </div>
      </div>
      <div className="content">
        {isLoading && <Loading />}

        {errorMessage && (
          <AlertEDS errMsg={errorMessage} status_code={201} close={false} />
        )}

        {data?.length && !errorMessage ? (
          <>
            <CustomGraphActions
              nodeId={"auditsByBusiness-graph"}
              fileName={"AuditsByBusinessUnit-Graph"}
              data={data || []}
            />
            <div id="auditsByBusiness-graph"></div>
          </>
        ) : null}
      </div>
    </li>
  );
};
