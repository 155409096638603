import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "@eds/vanilla";
import { emailValidation, validateEmail } from "../../utils/helperMethod";
import Loading from "../../components/Loading/Loading";
import CountryCode from "./countryCode.json";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import AlertWarnEDS from "../../components/AlertWarn/AlertWarnEDS";
import LoneWorkerListTable from "./LoneWorkerListTable";
import EDSCustomMultiselect from "../../components/EDSCustomMultiselect/EDSCustomMultiselect";
import {
  useGetLoneworkerManagerDetailsQuery,
  useAddLoneWorkersMutation,
  useUpdateLoneWorkersMutation,
  useDeleteLoneWorkersMutation,
} from "../../redux/services/loneworkerApi";

let othersNextId = 0;

export default function ModalManagerList({ setManagerModalShow }) {
  const { data: { loneworkers = [], managers = [] } = {}, isLoading } =
    useGetLoneworkerManagerDetailsQuery();
  const [addLoneWorkers, { isLoading: addLoading }] =
    useAddLoneWorkersMutation();
  const [updateLoneWorkers, { isLoading: updateLoading }] =
    useUpdateLoneWorkersMutation();
  const [deleteLoneWorkers, { isLoading: deleteLoading }] =
    useDeleteLoneWorkersMutation();

  const [actionType, setActionType] = useState("Submit");
  const [selectedLoneworker, setSelectedLoneworker] = useState({
    email: "",
    employeeId: "",
  });

  const [selectedManager, setSelectedManager] = useState({
    email: "",
    phone: "",
  });
  const [getOthersData, setOthersData] = useState([
    { id: ++othersNextId, email: "", code: "", phone: "" },
  ]);
  const loneWorkerRef = useRef(null);
  const managerRef = useRef(null);
  const [getResponseStatus, setResponseStatus] = useState({
    status_code: "",
    message: "",
  });

  useEffect(() => {
    const dialogDOM = document.getElementById("loneWorkerManagerList");
    if (dialogDOM) {
      const dialog = new Dialog(dialogDOM);
      dialog.init();
      dialog.show();
      return () => {
        dialog.destroy();
      };
    }
  }, []);

  const removeWorker = (id) => {
    setOthersData((state) => state.filter((val) => val.id !== id));
  };

  const onChangeHandler = (event, index) => {
    const { name, value } = event.target;
    if (name === "email") {
      if (event.target.value === "") {
        event.target.classList.add("invalid");
        event.target.classList.remove("valid");
      } else if (emailValidation(event)) {
        event.target.classList.add("invalid");
        event.target.classList.remove("valid");
      } else {
        event.target.classList.remove("invalid");
      }
    }

    if (name === "phone") {
      if (isNaN(value) || value.length > 15) return;
    }

    setOthersData((state) =>
      state.map((val, i) => (i === index ? { ...val, [name]: value } : val))
    );
  };

  const submitHandler = async (event) => {
    const others = [...getOthersData];
    const body = {
      employeeId: selectedLoneworker.employeeId,
      lone_worker_email: selectedLoneworker.email,
      manager_email_address: selectedManager.email,
      manager_phone_number: selectedManager.phone,
      other_email_address: others.map((val) => val.email).toString(),
      other_phone_number: others
        .map((val) =>
          val.code && val.phone ? val.code + "-" + val.phone : [""]
        )
        .toString(),
    };
    if (actionType === "Submit") {
      await addLoneWorkers(body)
        .unwrap()
        .then((data) => {
          setResponseStatus({
            status_code: data.status_code,
            message: data.message,
          });
          data.status_code === 200 && clearHandler();
        })
        .catch((error) => {
          setResponseStatus({
            status_code: 201,
            message: "Internal server Error",
          });
        });
    } else if (actionType === "Update") {
      await updateLoneWorkers(body)
        .unwrap()
        .then((data) => {
          setResponseStatus({
            status_code: data.status_code,
            message: data.message,
          });
          data.status_code === 200 && clearHandler();
        })
        .catch((error) => {
          setResponseStatus({
            status_code: 201,
            message: "Internal server Error",
          });
        });
    } else if (actionType === "Delete") {
      await deleteLoneWorkers({ lone_worker_email: body.lone_worker_email })
        .unwrap()
        .then((data) => {
          setResponseStatus({
            status_code: data.status_code,
            message: data.message,
          });
          data.status_code === 200 && clearHandler();
        })
        .catch(() => {
          setResponseStatus({
            status_code: 201,
            message: "Internal server Error",
          });
        });
    }
    closeAlert();
  };

  const closeAlert = () => {
    setTimeout(() => {
      setResponseStatus({ status_code: 0, message: "" });
    }, 3000);
  };

  const updateWorkersList = (type, value) => {
    const othersList = value.other_email_address_list.map((val, ind) => {
      const [code = "", phone = ""] =
        value.other_phone_number_list[ind]?.split("-") || [];
      return { id: ++othersNextId, email: val, code, phone };
    });

    setActionType(type);

    setOthersData([...othersList]);

    loneWorkerRef.current.value = [
      `${value.employeeId},${value.lone_worker_email}`,
    ];
    managerRef.current.value = [
      `${value.manager_phone_number},${value.manager_email_address}`,
    ];
    const dom = document.querySelector("#loneWorkerManagerList .body");
    dom.scrollTop = 0;
  };

  const clearHandler = () => {
    loneWorkerRef.current.listeners.resetForm();
    managerRef.current.listeners.resetForm();
    setActionType("Submit");
    setSelectedLoneworker({
      email: "",
      employeeId: "",
    });
    setSelectedManager({
      email: "",
      phone: "",
    });
    setOthersData([{ id: ++othersNextId, email: "", code: "", phone: "" }]);
  };

  const enableAction =
    selectedLoneworker.email &&
    selectedManager.email &&
    getOthersData.every(({ email }) => email.length && validateEmail(email));

  return (
    <>
      <div
        className="dialog fullscreen"
        data-type="fullscreen"
        id="loneWorkerManagerList"
      >
        <div className="content">
          <div className="top">
            <div className="title">Lone Workers Manager List</div>
            <div className="right">
              <button
                className="btn-icon"
                data-close="true"
                onClick={() => {
                  setManagerModalShow(false);
                }}
              >
                <i className="icon icon-cross"></i>
              </button>
            </div>
          </div>
          <div className="body">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <div className="tile">
                  <div className="content">
                    <div className="eds-form horizontal">
                      <div className="form-body">
                        <div className="form-group">
                          <div className="form-element">
                            <label
                              htmlFor="LoneWorkerName"
                              className="required"
                            >
                              Lone Worker
                            </label>
                            <EDSCustomMultiselect
                              setStatusOption={([value]) => {
                                let split = value?.split(",");
                                setSelectedLoneworker({
                                  email: split?.[1] || "",
                                  employeeId: split?.[0] || "",
                                });
                              }}
                              formDetailsUG={loneworkers.map((worker) => ({
                                form_name:
                                  worker.Name + " <" + worker.Email + ">",
                                formId: `${worker.employeeId},${worker.Email}`,
                              }))}
                              defaultValues={[]}
                              filterName="LoneWorkerName"
                              dataType="input-single"
                              disabled={actionType !== "Submit"}
                              selectRef={loneWorkerRef}
                            />
                          </div>
                          <div className="form-element mr-0">
                            <label htmlFor="managerName" className="required">
                              Manager
                            </label>
                            <EDSCustomMultiselect
                              setStatusOption={([value]) => {
                                let split = value?.split(",");
                                setSelectedManager({
                                  email: split?.[1] || "",
                                  phone: split?.[0] || "",
                                });
                              }}
                              formDetailsUG={managers.map((worker) => ({
                                form_name:
                                  worker.Name + " <" + worker.Email + ">",
                                formId: `${worker.Phone},${worker.Email}`,
                              }))}
                              defaultValues={[]}
                              filterName="managerName"
                              dataType="input-single"
                              disabled={!selectedLoneworker.employeeId}
                              selectRef={managerRef}
                            />
                          </div>
                        </div>
                        {selectedManager.email && !selectedManager.phone && (
                          <p>
                            Please note that SMS alerts will not be sent to the
                            manager until the phone number is added to their
                            profile.
                          </p>
                        )}
                        {actionType !== "Delete" &&
                          getOthersData.map((worker, ind) => (
                            <div key={worker.id} className="form-group">
                              <div className="form-element">
                                <label htmlFor="email" className="required">
                                  Others Email ID
                                </label>
                                <input
                                  id="email"
                                  className="validation"
                                  type="email"
                                  name="email"
                                  placeholder="Enter email address"
                                  value={worker.email}
                                  onChange={(event) => {
                                    onChangeHandler(event, ind);
                                  }}
                                  disabled={!selectedManager.email}
                                />
                                <span className="validation-msg">
                                  Enter valid email
                                </span>
                              </div>
                              <div
                                className="form-element phonenumber"
                                id={"phonenumber" + worker.id}
                              >
                                <label
                                  htmlFor={"phone" + worker.id}
                                  className=""
                                >
                                  Others Phone no.
                                </label>
                                <span className="phonenumber">
                                  <EDSCustomMultiselect
                                    className="countryCode"
                                    setStatusOption={([value]) => {
                                      onChangeHandler(
                                        {
                                          target: {
                                            name: "code",
                                            value: value || "",
                                          },
                                        },
                                        ind
                                      );
                                    }}
                                    formDetailsUG={CountryCode.map(
                                      (country) => ({
                                        form_name: `${country.CODE}-${country.NAME}-${country.CODE2}`,
                                        formId: country.CODE,
                                      })
                                    )}
                                    defaultValues={
                                      worker.code ? [worker.code] : []
                                    }
                                    filterName={`phone-country-code-select-${worker.id}`}
                                    dataType="input-single"
                                    disabled={!worker.email}
                                  />
                                  <input
                                    id={"phone" + worker.id}
                                    className="phone"
                                    type="text"
                                    inputMode="numeric"
                                    name="phone"
                                    placeholder="Enter Phone number"
                                    value={worker.phone}
                                    onChange={(event) => {
                                      onChangeHandler(event, ind);
                                      event.stopPropagation();
                                    }}
                                    disabled={!worker.code}
                                  />
                                </span>
                              </div>
                              {getOthersData.length - 1 > ind ? (
                                <button
                                  className="btn icon-btn mt-xl"
                                  title="Remove"
                                  onClick={(event) => {
                                    removeWorker(worker.id);
                                    event.stopPropagation();
                                  }}
                                >
                                  <i className="icon icon-trashcan"></i>
                                </button>
                              ) : (
                                <button
                                  className="btn icon-btn mt-xl"
                                  title="Add More"
                                  disabled={
                                    !worker.email ||
                                    (worker.phone.length && isNaN(worker.phone))
                                  }
                                  onClick={(event) => {
                                    setOthersData((val) => [
                                      ...val,
                                      {
                                        id: ++othersNextId,
                                        email: "",
                                        code: "",
                                        phone: "",
                                      },
                                    ]);
                                    event.stopPropagation();
                                  }}
                                >
                                  <i className="icon icon-plus"></i>
                                </button>
                              )}
                            </div>
                          ))}
                        {(addLoading || updateLoading) && <Loading />}
                        {getResponseStatus.status_code ? (
                          <AlertEDS
                            errMsg={getResponseStatus.message}
                            status_code={getResponseStatus.status_code}
                            close={false}
                          />
                        ) : null}
                      </div>
                      <div className="form-footer mr-0">
                        <button className="btn" onClick={clearHandler}>
                          Clear
                        </button>
                        <button
                          className={
                            actionType === "Delete"
                              ? "btn warning"
                              : "btn primary"
                          }
                          disabled={!enableAction}
                          onClick={submitHandler}
                        >
                          {actionType}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="page-hr" />
                <div className="tile">
                  <div className="header">
                    <div className="left">
                      <div className="title">Manager and Loneworker List</div>
                    </div>
                  </div>
                  <div className="content">
                    <LoneWorkerListTable
                      updateWorkersList={updateWorkersList}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {actionType === "Delete" && (
        <AlertWarnEDS
          id="loneworker-delete"
          errTitle={"Please Confirm!"}
          errMsg={
            <>
              Are you sure you want to delete lone worker <br />
              {selectedLoneworker.email}?
            </>
          }
          btnText={"Delete"}
          handleDeleteConfirm={submitHandler}
          setDelete={() => {
            clearHandler();
          }}
        />
      )}
    </>
  );
}
