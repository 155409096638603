import { useEffect, useState } from "react";
import { Table } from "@eds/vanilla";
import _ from "lodash";
import moment from "moment-timezone";
import { exportToCSVMethod } from "../../../utils/helperMethod";
import { useSelector } from "react-redux";
export default function SitewalkAuditorsGoalsTable({
  // goalsAccess,
  auditorsGoals,
  setSelectedAuditorGoal,
  setIsAutoCall,
}) {
  const [getTable, setTable] = useState({});
  const rolesMap = useSelector((state) => state.userAccess.roles);

  const getColumnsDetails = () => {
    const columns = [
      {
        key: "Auditor",
        title: "Auditor",
        sort: "none",
      },
      {
        key: "Date",
        title: "Month & Year",
        sort: "asc",
        onCreatedCell: (td, data) => {
          td.innerHTML = moment(data, "YYYY-MM").format("MMMM YYYY");
        },
      },
      {
        key: "Total Walks Completed",
        title: "Total Walks Completed",
        sort: "none",
      },
      {
        key: "Stretch Target",
        title: "Stretch Target",
        sort: "none",
      },
    ];
    return columns;
  };

  const getTableDetails = () => {
    const data = auditorsGoals.map((val) => ({
      ...val,
      Auditor: val?.Auditor?.name,
      AuditorId: val?.Auditor?.id,
    }));
    return data;
  };

  useEffect(() => {
    const tempDom = document.getElementById("sitewalkAuditorsGoalsTable");

    if (_.isEmpty(getTable) && !_.isEmpty(rolesMap)) {
      const tableTemp = new Table(tempDom, {
        data: getTableDetails(),
        columns: getColumnsDetails(),
        sortable: true,
        scroll: false,
        actions: rolesMap?.some((val) => val === "CONSOLE_GLOBAL_ADMIN" || val === "CONSOLE_GOALS_EDITOR"),
        onCreatedActionsCell: (td, data) => {
          td.innerHTML = `${
            rolesMap?.some((val) => val === "CONSOLE_GLOBAL_ADMIN" || val === "CONSOLE_GOALS_EDITOR")
              ? `<div><button class="btn-icon edit mr-xl" data-index=${data.indexOriginal} ><i class="icon icon-edit"></i></button>`
              : ``
          }
                  ${rolesMap?.some((val) => val === "CONSOLE_GLOBAL_ADMIN" || val === "CONSOLE_GOALS_EDITOR")
                      ? `<button class="btn-icon delete" data-index=${data.indexOriginal} ><i class="icon icon-trashcan"></i></button><div>`
                      : ``
                  }`;
          if (rolesMap?.some((val) => val === "CONSOLE_GLOBAL_ADMIN" || val === "CONSOLE_GOALS_EDITOR")) {
            td.querySelector("button.edit").addEventListener("click", () => {
              setSelectedAuditorGoal({
                type: "Update",
                isOpen: true,
                ...data,
                Auditor: { id: data?.AuditorId, name: data?.Auditor },
              });
            });
          }
          if (rolesMap?.some((val) => val === "CONSOLE_GLOBAL_ADMIN" || val === "CONSOLE_GOALS_EDITOR")) {
            td.querySelector("button.delete").addEventListener("click", () => {
              setSelectedAuditorGoal({
                type: "Delete",
                isOpen: true,
                ...data,
                Auditor: { id: data?.AuditorId, name: data?.Auditor },
              });
            });
          }
        },
      });
      tableTemp.init();
      setTable(tableTemp);
    } else {
      getTable.update(auditorsGoals);
    }
    let _tr = document.querySelector(".stickyHeader thead tr");
    let _top = document.querySelector(".filterDiv").offsetHeight;
    if (_tr) {
      _tr.style.top = _top + "px";
    }
  }, [auditorsGoals]);

  const exportData = () => {
    const data = auditorsGoals?.map((val) => ({
      ...val,
      Auditor: val?.Auditor?.name,
    }));
    exportToCSVMethod(data, "Auditor Goals Details");
  };

  return (
    <div>
      <div className="table-top">
        <div className="table-top-left">
          <div className="table-info"></div>
        </div>
        <div className="table-top-right">
          <div className="table-buttons">
            <button
              className="btn"
              onClick={() => {
                setIsAutoCall(true);
              }}
            >
              Refresh
            </button>
            <button
              className="btn"
              id="exportData"
              onClick={() => {
                exportData();
              }}
            >
              Export
            </button>
          </div>
          <div className="table-search">
            <input
              type="text"
              className="with-icon"
              placeholder="Search for..."
            />
            <div className="suffix icon-inside">
              <i className="icon icon-search"></i>
            </div>
          </div>
        </div>
      </div>
      <table
        id="sitewalkAuditorsGoalsTable"
        className="table dashed stickyHeader"
        style={{ tableLayout: `auto` }}
      ></table>
      <div className="table-bottom">
        <div className="pagination-group">
          <ul className="pagination"></ul>
          <div className="actions">
            <label className="left">Go to</label>
            <input type="number" min="1" />

            <label className="left">Show</label>
            <div className="select" data-type="single">
              <button className="btn current-options">5</button>
              <div className="options-list">
                <div className="item active">5</div>
                <div className="item">10</div>
                <div className="item">15</div>
                <div className="item">All</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
