import React, { useContext, useEffect, useState } from "react";
import "./usersStats.scss";
import UserStatsStackGraph from "./UserStatsStackGraph";
import LicenceUsageGraph from "./LicenceUsageGraph";
import UserCountGraph from "./UserCountGraph";
import UserRegisteredStackGraph from "./UserRegisteredStackGraph";
import UserActiveStackGraph from "./UserActiveStackGraph";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import Loading from "../../components/Loading/Loading";
import Card from "../../components/Card/Card";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import {
  getUserGraph,
  getAIConversionDetails,
  getUserCountDetails,
  getgroupStatsDetailsNew,
  getRegisteredUserMonthlyGraphData,
  getActiveUserMonthlyGraphData,
  getDateWiseRegisteredAndActveUsers,
  getLicenceUsageGraphData
} from "../../api/userStats.api";
import moment from "moment-timezone";
import _ from "lodash";
import { UserContext } from "../../layouts/Sidebar/SideBar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function UserStats() {
  const [startDate, setStartDate] = useState(
    new Date(
      moment().local().subtract(1, "weeks").format("YYYY-MM-DD 00:00:00")
    )
  );
  const [endDate, setEndDate] = useState(
    new Date(moment().local().format("YYYY-MM-DD 23:59:59"))
  );
  const [getUsersData, setUsersData] = useState({});
  const [loadingVal, setLoading] = useState({
    userStats: false,
    aiData: false,
    userCount: false,
    groupStats: false,
    monthlyRegisteredUser: false,
    monthlyActiveUser: false,
    userDateWiseStats: false,
    licenceUsageGraph: false
  });
  const [errMsg, setErrMsg] = useState({
    userStats: "",
    aiData: "",
    userCount: "",
    groupStats: "",
    monthlyRegisteredUser: "",
    monthlyActiveUser: "",
    userDateWiseStats: "",
    licenceUsageGraph: ""
  });
  const [getAIData, setAIData] = useState({});
  const [userCountData, setUserCountData] = useState({});
  const [groupStatsData, setGroupStatsData] = useState({});
  // const { getUserAccess } = useContext(UserContext);
  // const [metricAccess, setMetricAcess] = useState([]);
  const navigate = useNavigate();
  const [currentDay, setCurrentDay] = useState(
    moment().local().format("YYYY-MM-DD")
  );
  const [isAutoCall, setIsAutoCall] = useState(false);
  // const [filtersAccess, setFiltersAccess] = useState([]);
  const [getRegisteredUserMonthly, setRegisteredUserMonthly] = useState([]);
  const [getActiveUserMonthly, setActiveUserMonthly] = useState([]);
  const [getDateWiseUsers, setDateWiseUsers] = useState([]);
  const [getLicenceGraph, setLicenceGraph] = useState([]);
  const { headers, headersList } = useSelector((state) => state.envVariables);
  // const rolesMap = useSelector((state) => state.userAccess.roles);

  useEffect(() => {
      const intervalId = setInterval(() => {
        const newDay = moment().local().format("YYYY-MM-DD");
        if (currentDay !== newDay) {
          setCurrentDay(newDay);
          setStartDate(
            new Date(
              moment(currentDay, "YYYY-MM-DD").format("YYYY-MM-DD 00:00:00")
            )
          );
          setEndDate(
            new Date(moment(newDay, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59"))
          );
          setIsAutoCall(true);
        }
      }, 60000);
      return () => clearInterval(intervalId);

  }, [currentDay]);
  useEffect(() => {
    if (isAutoCall) {
      callgetUserGraph();
      setIsAutoCall(false);
    }
  }, [isAutoCall]);

  useEffect(() => {
      callgetUserGraph();
      callGroupStatsDetails();
  }, []);

  //console.log(param, UserGroupId, "*******************param**********************");
  const datepickerData = { startDate, setStartDate, endDate, setEndDate };
  const callgetUserGraph = () => {
    const param = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      response_type: "2",
      timeZone: moment.tz.guess(true),
    };
    if (!_.isNull(startDate) && !_.isNull(endDate)) {
      getDateWiseRegisteredAndActveUsers(
        param,
        setLoading,
        setErrMsg,
        setDateWiseUsers,
        headers
      );
      //getUserGraph(param, setLoading, setErrMsg, setUsersData, headers);
      getUserCountDetails(
        param,
        setLoading,
        setErrMsg,
        setUserCountData,
        headers
      );

        let param2 = { ...param };
        getAIConversionDetails(
          param2,
          setLoading,
          setErrMsg,
          setAIData,
          headers
        );

    } else {
      setLoading((msg) => ({
        ...msg,
        userStats: false,
        aiData: false,
        userCount: false,
        groupStats: false,
        monthlyRegisteredUser: false,
        monthlyActiveUser: false,
        userDateWiseStats: false,
      }));
      setErrMsg((msg) => ({
        ...msg,
        userStats: "Incorrect date range",
        aiData: "Incorrect date range",
        userCount: "Incorrect date range",
        groupStats: "Incorrect date range",
        monthlyRegisteredUser: "Incorrect date range",
        monthlyActiveUser: "Incorrect date range",
        userDateWiseStats: "Incorrect date range",
      }));
    }
  };
  const callGroupStatsDetails = () => {
    const param = {
      timeZone: moment.tz.guess(true),
    };
    getgroupStatsDetailsNew(
      param,
      setLoading,
      setErrMsg,
      setGroupStatsData,
      headers
    );
    getRegisteredUserMonthlyGraphData(
      param,
      setLoading,
      setErrMsg,
      setRegisteredUserMonthly,
      headers
    );
    getActiveUserMonthlyGraphData(
      param,
      setLoading,
      setErrMsg,
      setActiveUserMonthly,
      headers
    );

    getLicenceUsageGraphData(
      param,
      setLoading,
      setErrMsg,
      setLicenceGraph,
      headersList
    );
    //getGroupStatsDetails(param, setLoading, setErrMsg, setGroupStatsData);
  };
  return (
    // !_.isEmpty(
    //   getUserAccess.find(
    //     (val) =>
    //       val?.Action === "View" &&
    //       val?.Screen === "Metrics" &&
    //       val?.UserAccess === "Y"
    //   )
    // ) && (
    <div className="row UserStats">
      <div className="tile sm-12">
        <div className="row customRow">
          {/* {!_.isEmpty(
              metricAccess.find(
                (val) => val?.Action === "Users" && val?.UserAccess === "Y"
              )
            ) && ( */}
          <Card
            title={"Registered Users"}
            count={groupStatsData?.counts?.Registered || 0}
            loadingVal={loadingVal.groupStats}
            hoverText={"Total users in the system"}
            isHoverTextRequire={true}
          />
          {/* )} */}

          <Card
            title={"Active Users"}
            count={groupStatsData?.counts?.Active || 0}
            loadingVal={loadingVal.groupStats}
            hoverText={"Active users in the system"}
            isHoverTextRequire={true}
          />

          {/* {!_.isEmpty(
              metricAccess.find(
                (val) => val?.Action === "Approvers" && val?.UserAccess === "Y"
              )
            ) && ( */}
          <Card
            title={"Reviewers"}
            count={groupStatsData?.counts?.Reviewers || 0}
            loadingVal={loadingVal.groupStats}
            hoverText={"Users with access to all reports"}
            isHoverTextRequire={true}
          />
          {/*  )} */}

          <Card
            title={"Admins"}
            count={groupStatsData?.counts?.Admins || 0}
            loadingVal={loadingVal.groupStats}
            hoverText={"Users with complete access to the system"}
            isHoverTextRequire={true}
          />

          <Card
            title={"Domains"}
            count={groupStatsData?.counts?.["Total Domains"] || 0}
            loadingVal={loadingVal.groupStats}
            hoverText={"Total number of unique email domains in the system"}
            isHoverTextRequire={true}
          />

          {/* {!_.isEmpty(
              metricAccess.find(
                (val) =>
                  val?.Action === "Contracting" && val?.UserAccess === "Y"
              )
            ) && (
              <Card
                title={"Contracting Companies"}
                count={groupStatsData?.contracting_companies || 0}
                loadingVal={loadingVal.groupStats}
              />
            )}*/}
          {/* {!_.isEmpty(
              metricAccess.find(
                (val) =>
                  val?.Action === "Object Type" && val?.UserAccess === "Y"
              )
            ) && ( */}
          <Card
            title={"AI Objects"}
            count={groupStatsData?.counts?.["AI Objects"] || 0}
            loadingVal={loadingVal.groupStats}
            hoverText={"Safety object types detected by AI"}
            isHoverTextRequire={true}
          />
          {/* )} */}
        </div>
      </div>

      <div className="tile sm-12 md-12 lg-12 xl-12">
        <div className="header">
          <div className="left">
            <div className="title">Licence Usage Graph </div>
          </div>
        </div>
        <div className="content">
          <div className="column">
            {loadingVal.licenceUsageGraph ? (
              <Loading />
            ) : (
              <>
                {errMsg.licenceUsageGraph !== "Successful" ? (
                  <AlertEDS
                    errMsg={errMsg.licenceUsageGraph}
                    status_code={201}
                    close={false}
                  />
                ) : (
                  <LicenceUsageGraph
                    graphData={
                      !_.isEmpty(getLicenceGraph) &&
                      getLicenceGraph
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="tile sm-12 md-12 lg-12 xl-12">
        <div className="header">
          <div className="left">
            <div className="title">New Registered Users per Month </div>
          </div>
        </div>
        <div className="content">
          <div className="column">
            {loadingVal.monthlyRegisteredUser ? (
              <Loading />
            ) : (
              <>
                {errMsg.monthlyRegisteredUser !== "Success" ? (
                  <AlertEDS
                    errMsg={errMsg.monthlyRegisteredUser}
                    status_code={201}
                    close={false}
                  />
                ) : (
                  <UserRegisteredStackGraph
                    graphData={
                      !_.isEmpty(getRegisteredUserMonthly) &&
                      getRegisteredUserMonthly
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="tile sm-12 md-12 lg-12 xl-12">
        <div className="header">
          <div className="left">
            <div className="title">New Active Users per Month </div>
          </div>
        </div>
        <div className="content">
          <div className="column">
            {loadingVal.monthlyActiveUser ? (
              <Loading />
            ) : (
              <>
                {errMsg.monthlyActiveUser !== "Success" ? (
                  <AlertEDS
                    errMsg={errMsg.monthlyActiveUser}
                    status_code={201}
                    close={false}
                  />
                ) : (
                  <UserActiveStackGraph
                    graphData={
                      !_.isEmpty(getActiveUserMonthly) && getActiveUserMonthly
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="tile filterDiv">
        <CustomDatePickerForm
          formName="user-stats"
          datepickerData={datepickerData}
          filterData={{}}
          callApply={callgetUserGraph}
          disableButtton={Object.values(loadingVal).includes(true)}
        // filtersAccess={filtersAccess}
        />
      </div>

      <div
        className={
          getDateWiseUsers.registered_ericsson !== 0 ||
            getDateWiseUsers.registered_non_ericsson !== 0 ||
            getDateWiseUsers.active_ericsson !== 0 ||
            getDateWiseUsers.active_non_ericsson !== 0
            ? "tile sm-12"
            : "sm-12"
        }
      >
        <div className="row customRow">
          {getDateWiseUsers.registered_ericsson !== 0 && (
            <Card
              title={"Registered Users - Ericsson"}
              count={getDateWiseUsers.registered_ericsson}
              loadingVal={loadingVal.userDateWiseStats}
            />
          )}

          {getDateWiseUsers.registered_non_ericsson !== 0 && (
            <Card
              title={"Registered Users - Others"}
              count={getDateWiseUsers.registered_non_ericsson}
              loadingVal={loadingVal.userDateWiseStats}
            />
          )}

          {getDateWiseUsers.active_ericsson !== 0 && (
            <Card
              title={"Active Users - Ericsson"}
              count={getDateWiseUsers?.active_ericsson}
              loadingVal={loadingVal.userDateWiseStats}
            />
          )}

          {getDateWiseUsers.active_non_ericsson !== 0 && (
            <Card
              title={"Active Users - Others"}
              count={getDateWiseUsers?.active_non_ericsson}
              loadingVal={loadingVal.userDateWiseStats}
            />
          )}
        </div>
      </div>
      <div className="tile sm-12">
        <div className="row customRow">
          <Card
            title={"AI Assisted Approvals"}
            count={getAIData?.ai_approved_to_approved_count || 0}
            loadingVal={loadingVal.aiData}
            manualApprovalCount={getAIData?.approval_percentage || 0}
            hoverText={
              "Proportion of manually approved forms that were originally verified by AI"
            }
            isHoverTextRequire={true}
          />
        </div>
      </div>


      <div className="tile sm-12 md-12 lg-12 xl-12">
        <div className="header">
          <div className="left">
            <div className="title">New User Count</div>
          </div>
        </div>
        <div className="content">
          <div className="column">
            {loadingVal.userCount ? (
              <Loading />
            ) : (
              <>
                {errMsg.userCount !== "Success" ? (
                  <AlertEDS
                    errMsg={errMsg.userCount}
                    status_code={201}
                    close={false}
                  />
                ) : (
                  <UserCountGraph
                    graphData={!_.isEmpty(userCountData) && userCountData}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
    // )
  );
}
