import { useEffect } from "react";
import { Dialog } from "@eds/vanilla";
import { useLogout } from "../../api/login.api";

export const ForgetPasswordClick = ({getForgetPassClick}) => {
  const [callLogout] = useLogout();

  useEffect(() => {
    const dialogDOM = document.getElementById("forgetPassword-dialog");
    const dialog = new Dialog(dialogDOM);
    dialog.init();

    if (getForgetPassClick) {
        dialog.show();
      }


    return () => {
      dialog.destroy();
    };
  }, [getForgetPassClick]);

  return (
    <div id="forgetPassword-dialog" className="dialog" data-type="simple">
      <div className="content">
        <div className="top">
          <div className="title">Password Updated.</div>
        </div>
        <div className="body">
          <p>
            You password has been Updated successfully. Please login again.
          </p>
        </div>

        <div className="bottom">
          <button id="btnSubmit" className="btn warning" onClick={callLogout}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};
