import { useEffect } from "react";
import { useSelector } from "react-redux";
import { GeographicalViewChart } from "@eds/vanilla";
import { domain_url } from "../../../utils/Constant";

export default function StateWiseSubmissions({
  zonereport_countjs,
  usergroup_location,
}) {
  const { headersList } = useSelector((state) => state.envVariables);

  const reportLinkOnClick = async (reportId, e) => {
    e.preventDefault();
    e.target.style.color = "#DC2D37";
    const reportURL = domain_url + "/show_report/" + reportId;

    await fetch(reportURL, { method: "GET", headers: headersList })
      .then(async (response) => await response.text())
      .then((data) => {
        const reportWindow = window.open("", "_blank");
        reportWindow.document.write(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AlertData = (alert) => {
    let data =
      alert === "AI Approved"
        ? "Major"
        : alert === "Approved"
        ? "Minor"
        : alert === "Rejected" && "Critical";

    return [{ name: data, value: alert }];
  };
  const setValue = (status) => {
    if (status === "AI Verified") {
      return true;
    } else return false;
  };
  const getChartData = () => {
    const data = zonereport_countjs?.map((state) => {
      // const stateCode = state?.state_code.split("-");
      // const iconName = stateCode?.[stateCode.length - 1];
      const ReportId = state.reportId;
      const project_Id = state?.project_id.split(" ");
      let projectId = "";
      if (project_Id.length > 0) {
        if (project_Id.length === 1) {
          projectId = project_Id[0];
        } else if (project_Id.length === 2) {
          projectId = project_Id[0] + " " + project_Id[1];
        } else {
          projectId = project_Id[0] + " " + project_Id[1] + "...";
        }
      }

      const reportLink =
        projectId.length > 0
          ? `<a style="color:#0077DB"  id="reportLink_${ReportId}" >${projectId}</a>`
          : `<a style="color:#0077DB"  id="reportLink_${ReportId}" >NA</a>`;
      const retObj = {};
      return {
        key: ``,
        name: `${reportLink}`,
        reportId: ReportId,
        x: state?.longitude,
        y: state?.latitude,
        icon: "icon-profile",
        maintenance: setValue(state?.status),
        alerts: AlertData(state?.status),
      };
    });

    return {
      alerts: [{ name: "Critical" }, { name: "Major" }, { name: "Minor" }],
      nodes: data,
    };
  };

  const parseGeoCoordinates = (geoCoordinatesStr) => {
    try {
      return JSON.parse(geoCoordinatesStr);
    } catch (error) {
      return null;
    }
  };
  const { geo_coordinates, zoomlevel } = usergroup_location || {};
  const parsedGeoCoordinates = parseGeoCoordinates(geo_coordinates);

  useEffect(() => {
    const data = getChartData();
    const defaultNodes = [data?.nodes?.[0]?.y || 0, data?.nodes?.[0]?.x || 0];
    const map = new GeographicalViewChart({
      element: document.getElementById("stateWiseSubmissionsGraph"),
      showScrollLock: true,
      extent: 512,
    });
    map.init();
    map?.setView(
      parsedGeoCoordinates || [0, 0],
      zoomlevel ? parseFloat(zoomlevel) : 2
    );
    map.draw(data);
    document
      .getElementById("stateWiseSubmissionsGraph")
      .addEventListener("click", (event) => {
        const targetId = event.target;
        if (targetId.matches('a[id^="reportLink_"]')) {
          const reportId = targetId.id.split("_")[1];
          reportLinkOnClick(reportId, event);
        }
      });
    return () => {
      map.destroy();
    };
  }, []);

  return <div id="stateWiseSubmissionsGraph"></div>;
}
