import { HorizontalBarChart, ColorScale } from "@eds/vanilla";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";
export default function AuditorsMonthlyGoalsSummary({
  auditorsGoalsSumamry,
  getMonths,
}) {
  const [selectedMonth, setSelectedMonth] = useState(getMonths?.data?.[0]);

  const getChartData = () => {
    const databyMonth = auditorsGoalsSumamry?.filter((val) =>
      moment(val?.Date).isSame(selectedMonth)
    );
    const data = {
      common: databyMonth?.map(
        (val) =>
          `${val?.Auditor?.name} ${val?.["Total Walks Completed"]}/${val?.["Stretch Target"]}`
      ),
      series: [
        {
          name: "Auditors Monthly Goals Summary",
          values: databyMonth?.map((val) => {
            if (val?.["Total Walks Completed"] && val?.["Stretch Target"]) {
              return (
                (val?.["Total Walks Completed"] * 100) /
                val?.["Stretch Target"]
              ).toFixed(0);
            } else {
              return 0;
            }
          }),
        },
      ],
    };
    return data;
  };

  const getCSVData = () => {
    const data =
      auditorsGoalsSumamry?.map((val) => ({
        ...val,
        Auditor: val?.Auditor?.name,
      })) || [];
    return data;
  };

  useEffect(() => {
    const data = getChartData();
    const mycolorScale = new ColorScale({
      colors: ["color-data-6"],
    });
    mycolorScale.init();
    const chartHeight = data?.common?.length * 40;
    const chart = new HorizontalBarChart({
      element: document.getElementById("auditorsMonthlyGoalsSummary-graph"),
      data: data,
      legend: {
        isHidden: true,
      },
      thresholds: [
        {
          moreThan: 85,
          color: "green",
        },
      ],
      x: {
        unit: "Completed %",
      },
      height: chartHeight,
      colorScale: mycolorScale,
    });
    chart.init();
    return () => {
      chart.destroy();
      let element = document.getElementById(
        "auditorsMonthlyGoalsSummary-graph"
      );
      while (element?.firstChild) {
        element.removeChild(element.firstChild);
      }
    };
  }, [auditorsGoalsSumamry, selectedMonth]);

  return (
    <>
      <div id="radio-pills">
        {getMonths?.data?.map((val, ind) => (
          <span
            key={ind}
            className={`pill toggle ${
              val !== selectedMonth ? "unselected" : ""
            }`}
            onClick={() => {
              setSelectedMonth(val);
            }}
          >
            {moment(val).format(getMonths?.type === "year" ? "YYYY" : "MMM YY")}
          </span>
        ))}
      </div>
      <CustomGraphActions
        nodeId={"auditorsMonthlyGoalsSummary-graph"}
        fileName={"Auditors Monthly Goals Summary"}
        data={getCSVData()}
      />
      <div id="auditorsMonthlyGoalsSummary-graph"></div>
    </>
  );
}
