import { Dialog } from "@eds/vanilla";
import { useEffect } from "react";
export default function AIImageDialog({ setIsDialogOpen, uploadedImage, }) {
  useEffect(() => {
    const dialogs = document.getElementById("AIImagePreview");
    if (dialogs) {
      Array.from(dialogs).forEach((dialogDOM) => {
        const dialog = new Dialog(dialogDOM);
        dialog.init();
      });
    }
  }, []);

  const closeHandler = () => {
    setIsDialogOpen(false);
  };

  return (
    <div
      id="AIImagePreview"
      className="dialog show fullscreen"
      data-trigger="#open-modalList"
      data-type="fullscreen"
    >
      <div className="content">
        <div className="top">
          <div className="right">
            <button className="btn-icon ">
              <i className="icon icon-cross" onClick={closeHandler}></i>
            </button>
          </div>
        </div>
        <div className="body" style={{ marginTop: "1px" }}>
          <div className="row">
            <img src={uploadedImage} alt="Hazard Identification" style={{width:'100%'}} />
          </div>
        </div>
      </div>
    </div>
  );
}
