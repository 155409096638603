import { useState } from "react";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import Loading from "../../../components/Loading/Loading";
import { TaskItem } from "../../../components/TaskItem/TaskItem";
import { useNotification } from "../../../CustomHooks/useNotificationTrigger";
import {
  useAddIncidentRecipentsMutation,
  useDeleteIncidentRecipentsMutation,
  useIncidentRecipentsQuery,
} from "../../../redux/services/configurationApi";
import { validateEmail } from "../../../utils/helperMethod";

export default function IncidentManagement() {
  const [getRecipient, setRecipient] = useState("");
  const [success, failed] = useNotification({ title: "Incident Management" });

  const {
    data: { data, message: getMessage } = {},
    isFetching: getFetching,
    error: getError,
    refetch,
  } = useIncidentRecipentsQuery();

  const [addIncidentRecipents, { isLoading: addLoading }] =
    useAddIncidentRecipentsMutation();

  const [deleteIncidentRecipents, { isLoading: deleteLoading }] =
    useDeleteIncidentRecipentsMutation();

  const handleAction = async (type, receipient) => {
    let message = "",
      status_code = 0;

    if (type === "Add") {
      await addIncidentRecipents(JSON.stringify({ receipient }))
        .unwrap()
        .then((response) => {
          message = response.message;
          status_code = response.status_code;
          setRecipient("");
        })
        .catch(() => {
          message = "Internal Server Error";
          status_code = 500;
        });
    } else if (type === "Delete") {
      await deleteIncidentRecipents(JSON.stringify({ receipient }))
        .unwrap()
        .then((response) => {
          message = response.message;
          status_code = response.status_code;
        })
        .catch(() => {
          message = "Internal Server Error";
          status_code = 500;
        });
    }

    if (status_code === 200) {
      success(message);
    } else {
      failed(message);
    }
  };

  const loading = getFetching || addLoading || deleteLoading;

  const message =
    (getMessage && getMessage !== "Successfull") ||
    (getError && "Internal Server Error") ||
    (getMessage && !data.length && "No data found");

  return (
    <div className="tile sm-12 md-12 lg-6">
      <div className="header">
        <div className="left">
          <div className="title">Incident notifications</div>
        </div>
        <div className="right">
          <div className="action" title="Reload" onClick={refetch}>
            <i className="icon icon-reload"></i>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="eds-form">
          <div className="form-body">
            <div className="form-group">
              <p>
                Share incident recipient email to keep assigned with the latest
                incidents.
              </p>
              <div className="input-action">
                <input
                  type="email"
                  className="validation"
                  placeholder="example@email.com"
                  pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                  value={getRecipient || ""}
                  onChange={(event) => setRecipient(event.target.value)}
                />
                <div className="suffix icon-inside">
                  <i className="icon icon-email"></i>
                </div>
                <button
                  className="btn primary"
                  disabled={!validateEmail(getRecipient)}
                  onClick={() => handleAction("Add", getRecipient)}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="form-group">
              {loading && <Loading />}

              {message && !Loading && (
                <AlertEDS errMsg={message} status_code={201} />
              )}

              {!(message || loading) && (
                <div className="task-group">
                  {data?.map(({ receipient }, ind) => (
                    <TaskItem
                      key={`${ind}-${receipient}`}
                      task={{ id: receipient, text: receipient }}
                      deleteTask={(id) => {
                        handleAction("Delete", id);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
