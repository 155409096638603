import { Table } from "@eds/vanilla";
import { useEffect } from "react";
export default function CrewSubmissionCountTable({ crewsubmsn_group_js }) {
  const getProducts = () => {
    const products = [];
    for (let tblData in crewsubmsn_group_js["ID"]) {
      products.push({
        ID: crewsubmsn_group_js?.["ID"]?.[tblData],
        Name: crewsubmsn_group_js?.["Name"]?.[tblData],
        "Participation count":
          crewsubmsn_group_js?.["Participation count"]?.[tblData],
      });
    }
    return products;
  };

  useEffect(() => {
    const tableDOM = document.querySelector("#crewsubmissioncount-table");
    const table = new Table(tableDOM, {
      data: getProducts(),
      columns: [
        {
          key: "ID",
          title: "ID",
          sort: "none",
          headerClass: "custom-table-header-1",
          cellClass: "custom-table-body-1",
        },
        {
          key: "Name",
          title: "Name",
          sort: "none",
          headerClass: "custom-table-header-1",
          cellClass: "custom-table-body-1",
        },
        {
          key: "Participation count",
          title: "Participation count",
          sort: "desc",
          headerClass: "custom-table-header-1",
          cellClass: "custom-table-body-1",
        },
      ],

      // scroll: true,
      sortable: true,
    });
    table.init();
    // Export button
    document
      .querySelector("#export-crewsubmission-count")
      .addEventListener("click", () => {
        exportToCSVHandler(table.data);
      });
  }, []);

  const exportToCSVHandler = (data) => {
    const csvData = [
      ["ID", "Name", "Participation count"],
      ...data?.map((i) => [i?.["ID"], i?.["Name"], i?.["Participation count"]]),
    ];
    let csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((e) => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Crew Participation Count.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  };

  useEffect(() => {
    let _tr = document.querySelector('.stickyHeader thead tr');
    let _top = document.querySelector('.filterDiv').offsetHeight;
    if(_tr){
      _tr.style.top = _top+'px';
    }
  },[crewsubmsn_group_js]);

  return (
    <div>
      <div className="table-top">
        <div className="table-top-left">
          <div className="table-info"></div>
        </div>
        <div className="table-top-right">
          <div className="table-buttons">
            <button className="btn" id="export-crewsubmission-count">
              Export
            </button>
          </div>
          <div className="table-search">
            <input
              type="text"
              className="with-icon"
              placeholder="Search for..."
            />
            <div className="suffix icon-inside">
              <i className="icon icon-search"></i>
            </div>
          </div>
        </div>
      </div>
      {/* <div> */}
      <table
        id="crewsubmissioncount-table"
        className="table dashed stickyHeader"
      ></table>
      <div className="table-bottom">
        <div className="pagination-group">
          <ul className="pagination"></ul>

          <div className="actions">
            <label className="left">Go to</label>
            <input type="number" min="1" />

            <label className="left">Show</label>
            <div className="select" data-type="single">
              <button className="btn current-options">5</button>

              <div className="options-list">
                <div className="item active">5</div>
                <div className="item">10</div>
                <div className="item">15</div>
                <div className="item">All</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
