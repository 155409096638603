import { URL } from "../utils/Constant";

export const getFormList = async (setFormsList, headers, setErrMsg) => {
  try {
    const resp = await fetch(URL.ERICSION.GET_FORM_LIST, {
      method: "GET",
      headers: headers,
    });
    const data = await resp.json();
    if (resp.ok) {
      const data1 = data.data.map((i) => ({
        Formid: i.formId,
        Form_name: data.data.form_name,
      }));
      setFormsList(data.data);
    } else {
      console.log("GetForm List Error >>>> ", data.status_code);
      setErrMsg({ msg: data.message, status_code: data.status_code });
    }
  } catch (ex) {
    console.log("GetForm List Error >>>> Internal Server Error");
    setErrMsg({ msg: "Internal Server Error", status_code: "error" });
  }
};
export const getFormWiseCCList = async (setCCList, headers) => {
  try {
    const resp = await fetch(URL.ERICSION.GET_MAIL_USER_LIST, {
      method: "GET",
      headers: headers,
    });
    const data = await resp.json();
    if (resp.ok) {
      const data1 = [];
      data.data.map((i, j) => {
        data1.push({ formId: j, form_name: i.email });
      });
      data1.push({ formId: data1.length, form_name: "others" });
      setCCList(data1);
      return true;
    } else {
      console.log("Get distribution List Error >>>> ", data.status_code);
      return false;
    }
  } catch (ex) {
    console.log("Get distribution List Error >>>> Internal Server Error");
    return false;
  }
};
export const addNewData = async (
  param,
  setTableData,
  getTableData,
  headers,
  setTableActionHappend,
  setActionPopUp,
  setFormsList,
  setErrMsg,
  setLoading
) => {
  let dataJson = JSON.stringify(param[0]);
  try {
    const resp = await fetch(URL.ERICSION.EMAIL_DISTRIBUTION_DETAILS, {
      method: "POST",
      headers: headers,
      body: dataJson,
    });
    const data = await resp.json();
    if (resp.ok) {
      getFormWiseData(
        setTableData,
        setTableActionHappend,
        setErrMsg,
        setLoading,
        headers
      );
      setActionPopUp({ isOpen: false });
      getFormList(setFormsList, headers);
      setErrMsg({ msg: data.message, status_code: data.status_code });
    } else {
      setErrMsg({ msg: data.message, status_code: data.status_code });
    }
  } catch (ex) {
    setErrMsg({ msg: "Internal Server Error", status_code: "error" });
  }
};

export const getFormWiseData = async (
  setTableData,
  setTableActionHappend,
  setErrMsg,
  setLoading,
  headers
) => {
  try {
    const resp = await fetch(URL.ERICSION.EMAIL_DISTRIBUTION_DETAILS, {
      method: "GET",
      headers: headers,
    });

    const data = await resp.json();
    if (data.status_code === 200) {
      setTableData(data.data);
      setTableActionHappend(true);
    } else {
      setErrMsg({ msg: data.message, status_code: data.status_code });
    }
    setLoading(false);
  } catch (ex) {
    setErrMsg({ msg: "Internal Server Error", status_code: "error" });
    setLoading(false);
  }
};

export const removeFormDataFormTable = async (
  param,
  setTableData,
  headers,
  setTableActionHappend,
  setNewEmailIds,
  setCCOption,
  setFormsList,
  setErrMsg,
  setActionPopUp,
  setLoading
) => {
  let jsonData = JSON.stringify(param);
  try {
    const resp = await fetch(URL.ERICSION.EMAIL_DISTRIBUTION_DETAILS, {
      method: "DELETE",
      body: jsonData,
      headers: headers,
    });
    const data = await resp.json();
    if (resp.ok) {
      getFormList(setFormsList, headers);
      getFormWiseData(
        setTableData,
        setTableActionHappend,
        setErrMsg,
        setLoading,
        headers
      );
      setCCOption([]);
      setNewEmailIds([]);
      setErrMsg({ msg: data.message, status_code: data.status_code });
      setActionPopUp({ isOpen: false });
    } else {
      setErrMsg({ msg: data.message, status_code: data.status_code });
      setActionPopUp({ isOpen: false });
    }
  } catch (ex) {
    setErrMsg({ msg: "Internal Server Error", status_code: "error" });
    setActionPopUp({ isOpen: false });
  }
};

export const updateDistributionList = async (
  param,
  setTableActionHappend,
  setTableData,
  headers,
  setActionPopUp,
  setErrMsg,
  setLoading
) => {
  try {
    let jsonData = JSON.stringify(param[0]);
    const resp = await fetch(URL.ERICSION.EMAIL_DISTRIBUTION_DETAILS, {
      method: "PUT",
      body: jsonData,
      headers: headers,
    });
    const data = await resp.json();
    if (resp.ok) {
      getFormWiseData(
        setTableData,
        setTableActionHappend,
        setErrMsg,
        setLoading,
        headers
      );
      setActionPopUp({ isOpen: false });
      setErrMsg({ msg: data.message, status_code: data.status_code });
    } else {
      setErrMsg({ msg: data.message, status_code: data.status_code });
    }
  } catch (ex) {
    setErrMsg({ msg: "Internal Server Error", status_code: "error" });
  }
};

export const updateActionPopUp = async (setActionPopUp, data) => {
  setActionPopUp({
    isOpen: true,
    type: "Edit",
    form_Name: data.Form_Name,
    form_Id: data.Formid,
  });
};

export const updateCCList = (getCCList, setCCList, FormId, form_name) => {
  setCCList([...getCCList, { formId: FormId, form_name: form_name }]);
};
