import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Header from "./Header/Header";
import SideBar from "./Sidebar/SideBar";
import Footer from "./Footer/Footer";
import Loading from "../components/Loading/Loading";
import { loginRequest } from "../authConfig";
import { updateTokenDetails } from "../redux/slices/envVariablesSlice";
import {
  useFavoritesQuery,
  useRolesQuery,
  useUserDetailsQuery,
  useUserLastLoginQuery,
} from "../redux/services/authApi";
// import { SessionExpired } from "./Main/SessionExpired";
// import { ForgetPasswordClick } from "./Main/ForgetPasswordClick";
import { InactiveUserDialog } from "./Main/InactiveUserDialog";

function Layout() {
  const [refreshToggle, setRefreshToogle] = useState(true);
  // const [getForgetPassClick, setForgetPassClick] = useState(false);
  const { instance, accounts } = useMsal();

  const accessToken = useSelector((state) => state.envVariables.accessToken);
  const dispatch = useDispatch();

  const { isLoading: lastLoginLoading } = useUserLastLoginQuery(undefined, {
    skip:
      sessionStorage.getItem("isLastLoginAPICalled") === "true" || !accessToken,
  });

  const { isLoading: userDetailsLoading } = useUserDetailsQuery(undefined, {
    skip: !accessToken,
  });

  const { isLoading: rolesLoading } = useRolesQuery(undefined, {
    skip: !accessToken,
  });

  const { isLoading: favoritesLoading } = useFavoritesQuery(undefined, {
    skip: !accessToken,
  });

  const isLoading =
    !accessToken ||
    lastLoginLoading ||
    userDetailsLoading ||
    rolesLoading ||
    favoritesLoading;

  useEffect(() => {
    if (refreshToggle) {
      (async () => {
        const accessTokenRequest = {
          ...loginRequest,
          account: accounts[0],
        };
        await instance
          .acquireTokenSilent(accessTokenRequest)
          .then(({ accessToken, expiresOn }) => {
            if (accessToken && expiresOn) {
              const refreshTime = moment
                .duration(moment(expiresOn).diff(moment().local()))
                .asMilliseconds();

              dispatch(updateTokenDetails(accessToken));

              setTimeout(() => {
                setRefreshToogle(true);
              }, refreshTime);
            }
          })
          .catch((error) => {
            console.log(error);
            // setForgetPassClick(true);
          });
      })();
      setRefreshToogle(false);
    }
  }, [instance, accounts, refreshToggle]);

  return (
    <>
      {isLoading && (
        <div className="SiderBarLoader">
          <Loading />
        </div>
      )}

     {/* {getForgetPassClick && (
        <ForgetPasswordClick getForgetPassClick={getForgetPassClick} />
      )}*/}

      {accessToken && !isLoading && (
        <>
          <Header />
          <SideBar />
          {/* <SessionExpired /> */}
          <InactiveUserDialog />
          <Footer />
        </>
      )}
    </>
  );
}

export default Layout;