import React, { useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { ColorScale, StackedAreaChart } from "@eds/vanilla";
export default function UserRegisteredStackGraph({ graphData }) {


  function sortByMonth(arr) {
    const sortedMonths = Object.entries(arr).map(([month,values])=>({
      [month]:values
    }));

    const month =["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    const monthName = sortedMonths.sort((a,b)=>
      month.indexOf(Object.keys(a)[0])-month.indexOf(Object.keys(b)[0])

    )

    return monthName;
  }
  const getChartData = () => {
    const year = new Date().getFullYear();
    const formattedData = sortByMonth(graphData);
    const common=[''+year+'-01-01', ''+year+'-02-01', ''+year+'-03-01', ''+year+'-04-01', ''+year+'-05-01', ''+year+'-06-01', ''+year+'-07-01', ''+year+'-08-01', ''+year+'-09-01', ''+year+'-10-01', ''+year+'-11-01', ''+year+'-12-01'];
    const series= [{
      "name": "Full Time Employee",
      "values": formattedData.map((a)=>a[Object.keys(a)[0]].registered_ericsson
      )
    },
    {
      "name": "Others",
      "values": formattedData.map((a)=>a[Object.keys(a)[0]].registered_non_ericsson)
    }
    ]
    const data = {
      common,series
    }

    return data;
  };
  useEffect(() => {
      const myColorScale = new ColorScale({ offset: 1 });
      myColorScale.init();
      const data = getChartData();
      const chart = new StackedAreaChart({
        element: document.getElementById("userRegisteredStackGraph"),
        data:data,
        y: { unit: "Count" },
        colorScale: myColorScale,
      });
      chart.init();
      return () => {
        chart.destroy();
      };
  }, []);

  return <div id="userRegisteredStackGraph"></div>;
}
