import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChart } from "@eds/vanilla";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";

export default function PPEConfidence({ confidencegrp_js }) {
  const getChartData = () => {
    const ppeGraph = confidencegrp_js["ppe"];
    const confidenceGraph = confidencegrp_js["confidence"];
    const formattedData = [];
    for (const property in ppeGraph) {
      formattedData.push({
        name: ppeGraph[property],
        confidence: confidenceGraph[property],
      });
    }
    return formattedData;
  };

  useEffect(() => {
    if (Object.values(confidencegrp_js?.ppe || {}).length) {
      const myColors = new ColorScale({ offset: 1 });
      myColors.init();
      const data = getChartData();
      const chart = new HorizontalBarChart({
        element: document.getElementById("PPEConfidenceGraph"),
        data: {
          common: data?.map((val) => val?.name),
          series: [
            { name: "PPE", values: data?.map((val) => val?.confidence) },
          ],
        },
        x: {
          unit: "Confidence %",
        },
        colorScale: myColors,
      });
      chart.init();
      return () => chart.destroy();
    }
  }, []);
  return (
    <div>
      {Object.values(confidencegrp_js?.ppe || {}).length ? (
        <div id="PPEConfidenceGraph"> </div>
      ) : (
        <AlertEDS errMsg={"No Data Found"} status_code={201} close={false} />
      )}
    </div>
  );
}
