import { useState, useEffect } from "react";
import CustomCheckboxGroup from "../CustomCheckboxGroup/CustomCheckboxGroup";
import MultiselectDropDown from "../CustomMultiSelectDropDown/MultiSelectDropDown";
import Loading from "../Loading/Loading";
import "./ModalApprovalReject.css";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import { Dialog } from "@eds/vanilla";
import { useSelector } from "react-redux";

export default function ModalApprovalReject({
  Showpopupdownload,
  setShowpopupdownload,
  showMdl,
  setShowMdl,
  dataMdl,
  getLoading,
  setErrMsgM,
  getErrMsgM,
  onStatusUpdateApi,
  setDataMdlA,
}) {
  const [getReasons, setReasons] = useState([]);
  const [getCrewNames, setCrewNames] = useState([]);
  const [getCrewDetails, setCrewDetails] = useState([]);
  const [getComment, setComment] = useState("");
  const { usergroupId } = useSelector((state) => state.loggedInUser.user);
  const [toggleSubmit, setToggleSubmit] = useState(false);
  useEffect(() => {
    const dialogs = document.getElementById("approvalReject ");
    if (dialogs) {
      Array.from(dialogs).forEach((dialogDOM) => {
        const dialog = new Dialog(dialogDOM);
        dialog.init();
      });
    }
  }, []);
  const onBackHandler = () => {
    setShowpopupdownload({
      isOpen: true,
      Index: Showpopupdownload.Index,
      Value: Showpopupdownload.Value,
    });
    setErrMsgM("");
    setShowMdl(false);
    setReasons([]);
    setCrewNames([]);
    setCrewDetails([]);
    setComment("");
    setDataMdlA({
      heading: "",
      body: "",
      showClose: false,
      showSave: false,
      saveBtnName: "",
      status_id: "",
      status: "",
      reasonsOptions: [],
      crewLeads: [],
    });
  };
  const onCloseHandler = () => {
    setErrMsgM("");
    setShowMdl(false);
    setReasons([]);
    setCrewNames([]);
    setCrewDetails([]);
    setComment("");
    setDataMdlA({
      heading: "",
      body: "",
      showClose: false,
      showSave: false,
      saveBtnName: "",
      status_id: "",
      status: "",
      reasonsOptions: [],
      crewLeads: [],
    });
  };
  const onAddReasonHandler = () => {
    let crewDetails = getCrewDetails.filter(
      (crew) => !getCrewNames.includes(crew?.crew_name)
    );
    crewDetails.push(
      ...getCrewNames.map((crew) => ({ crew_name: crew, reasons: getReasons }))
    );
    setCrewDetails(crewDetails);
    setReasons([]);
    setCrewNames([]);
  };
  const onApproveRejectHandler = () => {
    let reasonsObj = {
      crew_reasons: getCrewDetails,
      comments: getComment,
    };
    onStatusUpdateApi(dataMdl.reportID, dataMdl.status_id, reasonsObj);
    setReasons([]);
    setCrewNames([]);
    setCrewDetails([]);
    setComment("");
  };
  return (
    <div
      id="approvalReject"
      className="dialog show"
      data-trigger="#open-modalList"
      data-type="fullscreen"
    >
      <div className="content">
        <div className="top">
          <div className="title">{dataMdl.heading}</div>
          <div className="right">
            <button
              className="btn-icon"
              data-close="true"
              onClick={() => onCloseHandler()}
            >
              <i className="icon icon-cross"></i>
            </button>
          </div>
        </div>
        <div className="body">
          {getLoading ? (
            <Loading width="32" />
          ) : dataMdl.reasonsOptions?.length < 1 ? (
            getErrMsgM !== "Success" && (
              <AlertEDS errMsg={getErrMsgM} status_code={201} close={false} />
            )
          ) : (
            <div className="row">
              <div className="tile sm-12 md-12 lg-12 xl-12 ">
                <MultiselectDropDown
                  formName={"ModalApprovalReject"}
                  setStatusOption={setCrewNames}
                  formDetailsUG={dataMdl?.crewLeads}
                  defaultValues={getCrewNames}
                  defaultWidth="w-100"
                />
              </div>
              <div className="tile sm-12 md-12 lg-12 xl-12 ">
                <CustomCheckboxGroup
                  reasonsList={dataMdl.reasonsOptions}
                  setReasonsList={setReasons}
                  defaultValues={getReasons}
                />
              </div>
              <div className="tile sm-12 md-12 lg-12 xl-12 text-right">
                <div className="text-end">
                  <button
                    className="btn "
                    disabled={
                      getLoading ||
                      getReasons.length < 1 ||
                      getCrewNames.length < 1
                    }
                    onClick={() => onAddReasonHandler()}
                  >
                    Add Reason
                  </button>
                </div>
              </div>

              {getCrewDetails.length > 0 && (
                <div className="tilesm-12 md-12 lg-12 xl-12 ">
                  <ol>
                    {getCrewDetails?.map((crew, index) => (
                      <li key={index}>
                        <strong style={{ fontSize: "14px" }}>
                          {crew?.crew_name + " :"}
                        </strong>
                        <span style={{ fontSize: "12px" }}>
                          {crew?.reasons.join(", ")}
                        </span>
                      </li>
                    ))}
                  </ol>
                </div>
              )}
              <div className="tile sm-12 md-12 lg-12 xl-12 ">
                <label htmlFor="Comments">Comments (Optional)</label>
                <textarea
                  id="Comments"
                  rows="4"
                  className="commentBox"
                  placeholder={`Enter ${dataMdl.heading} reason`}
                  value={getComment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </div>
              {getErrMsgM !== "Success" && (
                <AlertEDS errMsg={getErrMsgM} status_code={201} close={false} />
              )}
              <div className="tile sm-12 md-12 lg-12 xl-12 ">
                <input
                  type="checkbox"
                  id="indeterminate-cb"
                  onChange={(e) => setToggleSubmit(e.target.checked)}
                ></input>
                <label for="indeterminate-cb">
                  I have reviewed the form carefully before submitting the
                  review
                </label>
              </div>
              {dataMdl?.saveBtnName && (
                <div className="tile sm-12 md-12 lg-12 xl-12">
                  <div className="buttom-alignment_1">
                    <button className="btn" onClick={() => onBackHandler()}>
                      Back
                    </button>
                    <div className="buttom-alignment_2">
                      <button className="btn" onClick={() => onCloseHandler()}>
                        Cancel
                      </button>
                      <button
                        className={
                          dataMdl.saveBtnName === "Approve"
                            ? "btn primary"
                            : "btn warning"
                        }
                        disabled={
                          getLoading ||
                          (!getCrewDetails.length && usergroupId !== "7") ||
                          !toggleSubmit
                        }
                        onClick={() => onApproveRejectHandler()}
                      >
                        {dataMdl.saveBtnName}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
