import { useState, useEffect } from "react";
import { Datepicker } from "@eds/vanilla";
import moment from "moment";
import _ from "lodash";
import "./DatePicker.scss";
export default function EDSDatePicker({
  selectedDate,
  setDate,
  filterName,
  disableDatepicker = false,
  maxDate = moment().local().format("YYYY-MM-DD"),
  setApplyFlag,
  applyFlag,
}) {
  useEffect(() => {
    const datepickerDOM = document.getElementById(
      "custom-datepicker" + filterName
    );
    const datepickerTemp = new Datepicker(datepickerDOM);
    datepickerTemp.init();
    setDate(selectedDate);

    const onChangeHandler = () => {
      if (!_.isEmpty(datepickerTemp?.selectedDate)) {
        const selectedDateTemp =
          datepickerTemp?.selectedDate?.year +
          "-" +
          datepickerTemp?.selectedDate?.month +
          "-" +
          datepickerTemp?.selectedDate?.day;
        setDate(moment(selectedDateTemp).format("YYYY-MM-DD"));
      }
    };

    datepickerTemp.dom.input.addEventListener("change", onChangeHandler);

    return () => {
      datepickerTemp.dom.input.removeEventListener("change", onChangeHandler);
    };
  }, []);

  return (
    <div className="edsDatePicker">
      <div id={"custom-datepicker" + filterName} className="datepicker">
        <input
          type="text"
          placeholder="YYYY-MM-DD"
          name="datepicker"
          maxLength="10"
          max={maxDate}
          className="with-icon"
          defaultValue={moment(selectedDate).format("YYYY-MM-DD")}
          disabled={disableDatepicker}
        />
        <div className="suffix icon-inside">
          <i className="icon icon-calendar"></i>
        </div>
        <div className="calendar closed">
          <div className="head">
            <i className="icon icon-chevron-left"></i>
            <span className="month"></span>
            <i className="icon icon-chevron-right"></i>

            <i className="icon icon-chevron-left"></i>
            <span className="year"></span>
            <i className="icon icon-chevron-right"></i>
          </div>
          <table className="body"></table>
          {/* <div className="calendar-footer">
            <div className="btn set-current-day">Today</div>
          </div> */}
        </div>
        <input
          type="hidden"
          name="selectedDay"
          id="selectedDayID"
          value={moment(selectedDate).format("YYYY-MM-DD")}
        />
      </div>
    </div>
  );
}
