import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChart } from "@eds/vanilla";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import _ from "lodash";
export default function SiteWalkGraph2({ site_walk_name, getAnalyticsGraphsToggle, getSiteWalkDataByAnalyticsToggle, loadingVal, errMsg }) {
  useEffect(() => {
    if (!_.isEmpty(site_walk_name) && !loadingVal.completion_count_by_auditor) {
      const data = {
        common: site_walk_name?.map((obj) => obj?.Auditor),
        series: [
          {
            name: `Count by Auditor`,
            values: site_walk_name?.map((obj) => obj?.Count),
          },
        ],
      };
      const chartHeight = data?.common?.length * 40;
      const mycolorScale = new ColorScale({ reverse: true });
      const chart = new HorizontalBarChart({
        element: document.getElementById("totalSiteWalkCompletionCountByName"),
        data: data,
        x: { unit: "Count" },
        height: chartHeight,
        colorScale: mycolorScale,
      });
      mycolorScale.init();
      chart.init();
      return () => {
        chart.destroy();
        let element = document.getElementById(
          "totalSiteWalkCompletionCountByName"
        );
        while (element?.firstChild) {
          element.removeChild(element.firstChild);
        }
      };
    }
  }, [site_walk_name, loadingVal.completion_count_by_auditor]);

  return (
    <>

      <li
        tabIndex={1}
        className={`tile mb-sm accordion-item ${getAnalyticsGraphsToggle.completion_count_by_auditor ? "opened" : ""
          }`}
      >
        <div
          className="title stickyTileHeader"
          onClick={() => {
            getSiteWalkDataByAnalyticsToggle("completion_count_by_auditor");
          }}
        >
          <div className="header p-sm">
            <div className="left">
              Total Site Walk Completion Count – By Auditor
            </div>
          </div>
        </div>
        <div className="content">
          {loadingVal.completion_count_by_auditor ? (
            <Loading />
          ) : (
            <>
              {errMsg.completion_count_by_auditor !== "Success" ? (
                <AlertEDS
                  errMsg={errMsg.completion_count_by_auditor}
                  status_code={201}
                  close={false}
                />
              ) : (
                <>
                  <CustomGraphActions
                    nodeId={"totalSiteWalkCompletionCountByName"}
                    fileName={"Total_SiteWalk_Completion_CountByAuditor"}
                    data={site_walk_name}
                  />
                  <div id="totalSiteWalkCompletionCountByName"></div>
                </>
              )}
            </>
          )}
        </div>
      </li>


    </>
  );
}
