import { URL } from "../utils/Constant";

export const getFavorites = async (param, headersList) => {
  let dataJSON = JSON.stringify(param);
  let url = URL.ERICSION.UPDATE_FAVORITES_DATA;
  try {
    const resp = await fetch(url, {
      method: "POST",
      body: dataJSON,
      headers: headersList,
    });
    if (resp.ok) {
      const data = await resp.json();
    } else {
      console.log("API Error");
    }
  } catch {
    // setErrMsg("Internal Server error. Please try again later....");
    // setLoading(false);
  }
};
