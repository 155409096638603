import { Table } from "@eds/vanilla";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import RolePopupDashboard from "./RolePopupDashboard";

export default function RoleDashboardTable({ getRolesDashboardData }) {
    const [getTableData, setTableData] = useState(getRolesDashboardData);
    const [getData, setData] = useState();
    const [getRolePopUp, setRolePopUp] = useState(false);
    const [getRolePopData, setRolePopData] = useState([]);

    const _getData = (item, k, role) => {
        let arr = []
        item.map((j, idx) => {
            if (!_.isEmpty(j)) {
                if (j.UserAccess === 'Y') {
                    arr.push(`<input type="checkbox" class="checkInput" id="role${role + j.Action + idx + k}" checked disabled><label for="role${role + j.Action + idx + k}">${j.Action}</label>`)
                } else {
                    arr.push(`<input type="checkbox" class="checkInput" id="role${role + j.Action + idx + k}" disabled><label for="role${role + j.Action + idx + k}">${j.Action}</label>`)
                }
            }

        });
        return arr;
    }

    const handleTableData = (item, role) => {
        let products = [];
        item.access.map((i, k) => {
            products.push({
                "roles": i.Screen,
                "forms": _getData(i.Subcomponent, k, role),
                "edit": k,
            });
        })
        return products;
    }

    const checkClick = (e) => {
        const firstIdx = parseInt(e.target.getAttribute('data-index').split('_')[0]);
        const SecondIdx = parseInt(e.target.getAttribute('data-index').split('_')[1]);
        setRolePopData(getTableData[firstIdx].access[SecondIdx])
        setRolePopUp(true)
    }

    useEffect(() => {
        getTableData.map((item, key) => {
            const tableDOM = document.querySelector("#displayDashboardRole" + key);
            const table = new Table(tableDOM, {
                data: handleTableData(item, item.role),
                columns: [
                    {
                        key: "roles",
                        title: "Role",
                    },
                    {
                        key: "role",
                        title: "User Count",
                        sort: "none",

                    },
                    {
                        key: "edit",
                        title: "Edit",
                        onCreatedCell: (td, data) => {
                            td.innerHTML = `<button class="btn btnEdit" id="roleTag" data-index=${key + '_' + data}>Edit</button>`;
                            td.querySelector('button.btnEdit').addEventListener('click', (e) => {
                                checkClick(e);
                            });
                        },

                    },
                ],
                sortable: true,
                expandable: true,
                actions: true,
                onCreatedDetailsRow: (td, data) => {
                    let assignRole = data.forms.map((i) => `<div class="sub-History mr-xl">&nbsp;${i}</div>`).join("");
                    td.innerHTML = `<div class="history">${assignRole}</div>`;
                },
            });
            table.init();

        });
    }, [getTableData]);

    return (
        <div>
            {getRolePopUp && !_.isEmpty(getRolePopData) &&
                <RolePopupDashboard
                    getRolePopData={getRolePopData}
                    setRolePopUp={setRolePopUp}
                />
            }
            {getTableData.map((item, key) => {
                return (
                    <div key={key} className="mb-xl">
                        <div className="header">
                            <div className="left">
                                <div className="title">{item.role}</div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="column">
                                <div className="table-top">
                                    <div className="table-top-left">
                                        <div className="table-info"></div>
                                    </div>
                                </div>
                                <table id={"displayDashboardRole" + key} className="roleTable table dashed stickyHeader horizonatalScroll"></table>
                                <div className="table-bottom">
                                    <div className="pagination-group">
                                        <ul className="pagination"></ul>
                                        <div className="actions">
                                            <label className="left">Go to</label>
                                            <input type="number" min="1" />
                                            <label className="left">Show</label>
                                            <div className="select" data-type="single">
                                                <button className="btn current-options">5</button>
                                                <div className="options-list">
                                                    <div className="item active">5</div>
                                                    <div className="item">10</div>
                                                    <div className="item">15</div>
                                                    <div className="item">All</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            {/* <div className="table-top">
                <div className="table-top-left">
                    <div className="table-info"></div>
                </div>
            </div> */}
            {/* <table id="displayDashboardRole" className="table dashed stickyHeader horizonatalScroll" style={{ tableLayout: `auto` }}></table> */}

        </div>
    )
}