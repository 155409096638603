import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL } from "../../utils/Constant";

export const loneworkerApi = createApi({
  reducerPath: "loneworkerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().envVariables.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["loneWorkersList"],
  endpoints: (builder) => ({
    getLoneworkerManagerDetails: builder.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const result = await Promise.all([
          fetchWithBQ(`${URL.ERICSION.GET_LONE_WORKER_USER_LIST}?loneworker=Y`),
          fetchWithBQ(URL.ERICSION.GET_LONE_WORKER_USER_LIST),
        ]);
        if (result?.[0]?.error || result?.[1]?.error) {
          return { error: result.error };
        } else {
          const [loneworkers, managers] = result.map(
            (val) =>
              val?.data?.data?.employeelist?.map((obj) => obj?.[0] || {}) || []
          );
          return { data: { loneworkers, managers } };
        }
      },
    }),
    getLoneworkerSettings: builder.query({
      query: (body) => ({
        url: URL.ERICSION.GET_LONE_WORKER_SETTINGS,
        method: "GET",
        body,
      }),
      providesTags: ["loneWorkersList"],
    }),
    addLoneWorkers: builder.mutation({
      query: (body) => ({
        url: URL.ERICSION.ADD_LONE_WORKER_SETTINGS,
        method: "POST",
        body,
      }),
      invalidatesTags: ["loneWorkersList"],
    }),
    updateLoneWorkers: builder.mutation({
      query: (body) => ({
        url: URL.ERICSION.EDIT_LONE_WORKER_SETTINGS,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["loneWorkersList"],
    }),
    deleteLoneWorkers: builder.mutation({
      query: (body) => ({
        url: URL.ERICSION.EDIT_LONE_WORKER_SETTINGS,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["loneWorkersList"],
    }),
  }),
});

export const {
  useGetLoneworkerSettingsQuery,
  useGetLoneworkerManagerDetailsQuery,
  useAddLoneWorkersMutation,
  useUpdateLoneWorkersMutation,
  useDeleteLoneWorkersMutation,
} = loneworkerApi;

export default loneworkerApi;
