import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Select, Table } from "@eds/vanilla";
import Loading from "../../../../components/Loading/Loading";
import { exportToCSVMethod } from "../../../../utils/helperMethod";
import { updateActionPopUp } from "../../../../api/Notification.api";
export default function NotificationTable({
  setActionPopUp,
  getTableData,
  distributionData,
}) {
  const [getTable, setTable] = useState([]);
  const [exportData, setExportData] = useState([]);

  const handleEdit = (data) => {
    updateActionPopUp(setActionPopUp, data);

    const CC_List = data.CC_List.split("\n");
    let otherEmailIds = CC_List;

    CC_List.map((item) => {
      const matchingArray = distributionData.getCCList.filter(
        (obj) => obj.form_name === item
      );
      if (matchingArray !== undefined) {
        otherEmailIds = CC_List.filter((i) => i !== matchingArray.form_name);
      }
    });

    let param = [];
    if (otherEmailIds.length > 0) {
      param = otherEmailIds.map((i, j) => ({
        index: j,
        emailId: i,
        emailError: "",
      }));
      if (otherEmailIds) {
        const ret = distributionData.getCCList.concat(
          otherEmailIds
            .filter(
              (item) =>
                !distributionData.getCCList.some(
                  (val) => val.form_name === item
                )
            )
            .map((val, i) => ({
              formId: distributionData.getCCList.length + i,
              form_name: val,
            }))
        );
        distributionData.setCCList(ret);
      }
      distributionData.setNewEmailIds(param);
    }
  };

  // Data table record deletion
  const deleteConfirmation = (data) => {
    if (getTableData.length > 0) {
      setActionPopUp({
        type: "Delete",
        isOpen: true,
        formName: data.Form_Name,
        formId: data.Formid,
      });
    }
  };
  const callExport = () => {
    const csvData = [];
    exportData?.forEach((val) => {
      csvData.push({
        "Form Name": val?.["Form_Name"],
        "Notification Recipient": val?.["CC_List"],
      });
    });
    exportToCSVMethod(csvData, "Formwise Distribution Details");
  };

  // Creating Table Data
  const getTableRecodes = () => {
    const items = getTableData;
    let products = [];
    items.map((i, j) => {
      let ccList = "";
      i["cc-list"].map((item, j) => {
        if (j !== i["cc-list"].length - 1) ccList += item + "\n";
        else ccList += item;
      });

      products.push({
        Formid: i.formId,
        Form_Name: i.form_name,
        CC_List: ccList,
        history: i.history,
      });
    });
    setExportData(products);
    return products;
  };

  useEffect(() => {
    distributionData.setLoading(true);
    const tableDOM = document.querySelector("#Notification-table");
    if (_.isEmpty(getTable)) {
      const table = new Table(tableDOM, {
        data: getTableRecodes(),
        columns: [
          {
            key: "Form_Name",
            title: "Form Name",
            sort: "none",
          },
          {
            key: "CC_List",
            title: "Notification Recipient",
            sort: "none",
          },
        ],
        sortable: true,
        maxHeight: "300px",
        actions: true,
        onCreatedActionsCell: (td, data) => {
          td.innerHTML = `<button class="btn-icon edit mr-xl"  data-index=${data.indexOriginal}><i class="icon icon-edit"></i></button> <button class="btn-icon delete" id="open-warning" data-index=${data.indexOriginal}><i class="icon icon-trashcan"></i></button>`;
          td.querySelector("button.edit").addEventListener("click", (evt) => {
            handleEdit(data);
          });
          td.querySelector("button.delete").addEventListener("click", (evt) => {
            deleteConfirmation(data);
          });
        },
      });

      table.init();
      setTable(table);
    } else {
      getTable.update(getTableRecodes());
    }
    distributionData.setLoading(false);
  }, [getTableData]);
  return (
    <>
      {distributionData.loading ? (
        <Loading />
      ) : (
        <div>
          <div className="table-top">
            <div className="table-top-left">
              <div className="table-info"></div>
            </div>
            <div className="table-top-right">
              <div className="table-buttons">
                <button
                  className="btn hidden"
                  id="open-simple"
                  onClick={() => {
                    setActionPopUp({
                      type: "Add",
                      isOpen: true,
                    });
                  }}
                >
                  + New
                </button>
                <button
                  className="btn"
                  id="exportData"
                  onClick={() => callExport()}
                >
                  Export
                </button>
              </div>
              <div className="table-search">
                <input
                  type="text"
                  className="with-icon"
                  placeholder="Search for..."
                />
                <div className="suffix icon-inside">
                  <i className="icon icon-search"></i>
                </div>
              </div>
            </div>
          </div>
          <table
            id="Notification-table"
            className="table stickyHeader horizonatalScroll"
            style={{ tableLayout: "auto" }}
          ></table>
          <div className="table-bottom">
            <div className="pagination-group">
              <ul className="pagination"></ul>

              <div className="actions">
                <label className="left">Go to</label>
                <input type="number" min="1" />

                <label className="left">Show</label>
                <div className="select" data-type="single" id="showId">
                  <button className="btn current-options">5</button>

                  <div className="options-list">
                    <div className="item active">5</div>
                    <div className="item">10</div>
                    <div className="item">15</div>
                    <div className="item">All</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
