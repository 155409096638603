import { Table } from "@eds/vanilla";
import { useEffect, useState } from "react";
import _ from "lodash";
import { exportToCSVMethod } from "../../../utils/helperMethod";
export default function TotalTimeTakenStatisticsTable({ totaltime_stats_js }) {
  const [tableData, setTableData] = useState({});

  const getProducts = () => {
    const products = [];
    for (let tblData in totaltime_stats_js["Metric"]) {
      products.push({
        Metric: totaltime_stats_js?.["Metric"]?.[tblData],
        Value: totaltime_stats_js?.["Value"]?.[tblData],
      });
    }
    return products;
  };

  useEffect(() => {
    if (_.isEmpty(tableData)) {
      const tableDOM = document.querySelector(
        "#totaltimetakenstatistics-table"
      );
      const table = new Table(tableDOM, {
        data: getProducts(),
        columns: [
          {
            key: "Metric",
            title: "Metric",
            sort: "desc",
            headerClass: "custom-table-header-1",
            cellClass: "custom-table-body-1",
          },
          {
            key: "Value",
            title: "Value",
            sort: "none",
            headerClass: "custom-table-header-1",
            cellClass: "custom-table-body-1",
          },
        ],
        sortable: true,
      });
      table.init();
      document
        .querySelector("#export-totaltimetaken-statistics")
        .addEventListener("click", () => {
          exportToCSVHandler(table.data);
        });
      setTableData(table);
    } else {
      tableData.update(getProducts());
    }
    handleResize();
  }, [totaltime_stats_js]);

  const exportToCSVHandler = (data) => {
    const csvData = [];
    data?.forEach((val) => {
      csvData.push({
        Metric: val?.Metric,
        Value: val?.Value,
      });
    });
    exportToCSVMethod(csvData, "EHS Site Walk Total Time Taken - Statistics");
  };

  const handleResize = () => {
    let _tr = document.querySelector(
      "#totaltimetakenstatistics-table.stickyHeader thead tr"
    );
    let _top = document.querySelector(".filterDiv").offsetHeight;
    if (_tr) {
      _tr.style.top = _top + "px";
    }
  };

  return (
    <div>
      <div className="table-top">
        <div className="table-top-left">
          <div className="table-info"></div>
        </div>
        <div className="table-top-right">
          <div className="table-buttons">
            <button className="btn" id="export-totaltimetaken-statistics">
              Export
            </button>
          </div>
          <div className="table-search">
            <input
              type="text"
              className="with-icon"
              placeholder="Search for..."
            />
            <div className="suffix icon-inside">
              <i className="icon icon-search"></i>
            </div>
          </div>
        </div>
      </div>
      <table
        id="totaltimetakenstatistics-table"
        className="table dashed stickyHeader"
      ></table>
      {/* <div className="table-bottom">
        <div className="pagination-group">
          <ul className="pagination"></ul>

          <div className="actions">
            <label className="left">Go to</label>
            <input type="number" min="1" />

            <label className="left">Show</label>
            <div className="select" data-type="single">
              <button className="btn current-options">5</button>

              <div className="options-list">
                <div className="item active">5</div>
                <div className="item">10</div>
                <div className="item">15</div>
                <div className="item">All</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
