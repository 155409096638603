import React, { useEffect, useState } from "react";
import { getChangePassword } from "../../api/ChangePassword.api";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import { useSelector } from "react-redux";
import { useLogout } from "../../api/login.api";

export default function ChangePassword() {
  const headersList = useSelector((state) => state.envVariables.headersList);
  const emailId = useSelector((state) => state.loggedInUser.user.email);
  const isNewUser = useSelector((state) => state.loggedInUser.isNewUser);
  const [getEmail, setEmail] = useState(emailId || "");
  const [getNewPassword, setNewPassword] = useState("");
  const [getOldPassword, setOldPassword] = useState("");
  const [getConfrimPassword, setConfrimPassword] = useState("");
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [getMsg, setMsg] = useState("");
  const [getLoading, setLoading] = useState(false);
  const [callLogout] = useLogout();
  let dataJSON = {
    emailId: getEmail,
    oldPassword: getOldPassword,
    newPassword: getNewPassword,
  };
  useEffect(() => {
    if (
      getEmail !== "" &&
      getNewPassword !== "" &&
      getNewPassword === getConfrimPassword
    ) {
      setLoginDisabled(false);
    } else {
      setLoginDisabled(true);
    }
    // console.log(dataJSON, getConfrimPassword);
  }, [getEmail, getNewPassword, getConfrimPassword, getOldPassword]);

  useEffect(() => {
    if (isNewUser) {
      document.querySelector("body").classList.add("dark");
      return () => {
        document.querySelector("body").classList.remove("dark");
      };
    }
  }, [isNewUser]);

  return (
    <div className="row">
      <div className="tile">
        {getLoading ? (
          <div className="SiderBarLoader">
            <Loading />
          </div>
        ) : (
          <>
            {getMsg && (
              <AlertEDS
                errMsg={getMsg}
                status_code={
                  getMsg === "Password changed successfully" ? 200 : 201
                }
                close={false}
              />
            )}
          </>
        )}
        <form className="eds-form">
          <div className="row form-body">
            <div className="column sm-12 md-12 lg-6">
              <div className="form-group">
                <div className="form-element">
                  <label htmlFor="input-1" className="required has-tooltip">
                    <span>Email address </span>
                    <span className="tooltip">
                      <i className="icon icon-info-solid"></i>
                      <span className="message right">
                        We'll never share your email with anyone else.
                      </span>
                    </span>
                  </label>
                  <input
                    type="text"
                    id="input-1"
                    placeholder="Email address"
                    value={getEmail}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="form-element">
                  <label htmlFor="input-2" className="required">
                    Old Password
                  </label>
                  <input
                    type="password"
                    id="input-2"
                    placeholder="Old Password"
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </div>

                <div className="form-element">
                  <label htmlFor="input-3" className="required">
                    New Password
                  </label>
                  <input
                    id="input-3"
                    type="password"
                    placeholder="New Password"
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>

                <div className="form-element">
                  <label htmlFor="input-4" className="required">
                    Confirm Password
                  </label>
                  <input
                    id="input-4"
                    type="password"
                    placeholder="Confirm Password"
                    onChange={(e) => setConfrimPassword(e.target.value)}
                  />
                </div>

                <div className="form-element">
                  <button
                    type="button"
                    className="btn primary"
                    onClick={() =>
                      getChangePassword(
                        dataJSON,
                        setLoading,
                        setMsg,
                        headersList,
                        callLogout
                      )
                    }
                    disabled={loginDisabled}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
