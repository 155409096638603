import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL } from "../../utils/Constant";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().envVariables.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    userDetails: builder.query({
      query: (body) => URL.Profile,
    }),

    roles: builder.query({
      query: () => URL.GET_ROLE_MAPPING,
    }),

    switchOrganization: builder.mutation({
      query: (body) => ({
        url: URL.ERICSION.SWITCH_USER_GROUP,
        method: "POST",
        body,
        headers: {
          "content-type": "application/json",
        },
      }),
    }),

    userLastLogin: builder.query({
      query: () => URL.GET_USER_LAST_LOGIN_DATE_TIME,
    }),

    favorites: builder.query({
      query: () => URL.ERICSION.UPDATE_FAVORITES_DATA,
    }),

    addFavorites: builder.mutation({
      query: (body) => ({
        url: URL.ERICSION.UPDATE_FAVORITES_DATA,
        method: "POST",
        body,
        headers: {
          "content-type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useUserDetailsQuery,
  useRolesQuery,
  useSwitchOrganizationMutation,
  useUserLastLoginQuery,
  useFavoritesQuery,
  useAddFavoritesMutation,
} = authApi;

export default authApi;
