import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePolicyStatus, updateEndUserStatement } from "../../redux/slices/userSlice";
import { setPolicyStatus } from "../../api/PolicyStatusUpdate.API";
import { Link } from "react-router-dom";

export const PrivacyPolicy = () => {
  const [toggleSubmit, setToggleSubmit] = useState(false);
  const [toggleSubmitEndUser, setToggleSubmitEndUser] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const policyStatus = useSelector((state) => state.loggedInUser.policyStatus);
  const enduserStatement = useSelector((state) => state.loggedInUser.enduserStatement);
  const endUserStatementURL = useSelector((state) => state.loggedInUser.user.end_user_statement);
  const hyperlink = useSelector((state) => state.loggedInUser.user.hyperlink);
  const { headersList } = useSelector((state) => state.envVariables);
  const dispatch = useDispatch();

  useEffect(() => {
    setToggleSubmit(policyStatus);
    setToggleSubmitEndUser(enduserStatement);
  },[]);

  const setPrivacyPolicyStatus = async () => {
    const response = await setPolicyStatus(headersList);
    if (response === 200) {
      dispatch(updatePolicyStatus(true));
      dispatch(updateEndUserStatement(true));
    } else {
      setErrMsg("Please try again !!!");
    }
  };

  return (
    <div className="dialog show" data-trigger="#open-simple" data-type="simple">
      <div className="content">
        <div className="top">
          <div className="title">PRIVACY POLICY</div>
        </div>
        <div className="body">
          <p>
            Please read this{" "}
            <a href={hyperlink} target="_blank" rel="noreferrer">
              Privacy Policy{" "}
            </a>
            carefully. <br></br>If you do not agree with the terms of Privacy
            Policy,<br></br> please do not access the application.
          </p>
          <input
            type="checkbox"
            id="cb2"
            onChange={(e) => setToggleSubmit(e.target.checked)}
            checked={toggleSubmit}
          ></input>
          <label htmlFor="cb2">
            I acknowledge that I have read the privacy policy and I accept it.
          </label>

          <br />

          <input
            type="checkbox"
            id="cb3"
            onChange={(e) => setToggleSubmitEndUser(e.target.checked)}
            checked={toggleSubmitEndUser}
          ></input>
          <label htmlFor="cb3">
          I accept the <Link className="footerLink" target="_blank" to={endUserStatementURL !== undefined ? endUserStatementURL : "https://steswprodeastus-chanbah3hbhkfdc5.z01.azurefd.net/public/ESW%20End%20User%20Statement.pdf"}>End-User statement</Link>.
          </label>

        </div>
        {errMsg.length ? <p className="color-red">{errMsg}</p> : null}
        <div className="bottom">
          <button
            className="btn primary"
            disabled={!toggleSubmit || !toggleSubmitEndUser}
            onClick={setPrivacyPolicyStatus}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
