import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../layouts/Sidebar/SideBar";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment-timezone";
import SitewalkAuditorsGoalsTable from "./Tables/SitewalkAuditorsGoalsTable";
import SiteWalkGoalsModel from "./Model/SiteWalkGoalsModel";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import {
  getSitewalkGoalsByAuditors,
  getSitewalkGoalsFilterDetails,
} from "../../api/SitewalkGoals.api";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import { useSelector } from "react-redux";

export default function SiteWalkGoalsManagement() {
  // const { getUserAccess } = useContext(UserContext);
  const navigate = useNavigate();
  // const [getGoalsAccess, setGoalsAccess] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(moment().local().format("YYYY-MM-01 00:00:00"))
  );
  const [endDate, setEndDate] = useState(
    new Date(moment().local().format("YYYY-MM-DD 23:59:59"))
  );
  const [getUsersOption, setUsersOption] = useState([]);
  const [currentDay, setCurrentDay] = useState(
    moment().local().format("YYYY-MM-DD")
  );
  const [getAuditorsGoalsData, setAuditorsGoalsData] = useState({});
  // const [filtersAccess, setFiltersAccess] = useState([]);
  const [getFilterData, setFilterData] = useState({});
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filtersErrMsg, setFiltersErrMsg] = useState("");
  const [loadingVal, setLoadingVal] = useState({
    goalsByAuditor: false,
  });
  const [errorMsg, setErrorMsg] = useState({ goalsByAuditor: "" });
  const [isAutoCall, setIsAutoCall] = useState(false);

  const [selectedAuditorGoal, setSelectedAuditorGoal] = useState({
    isOpen: false,
  });
  const { headers, headersList } = useSelector((state) => state.envVariables);
  const rolesMap = useSelector((state) => state.userAccess.roles);

  const datepickerData = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    getUsersOption,
    setUsersOption,
  };

  useEffect(() => {
    // let accessTemp = getUserAccess?.find(
    //   (val) => val?.Screen === "Site Walk Goal Settings"
    // );


      // setGoalsAccess(accessTemp?.Subcomponent || []);
      // setFiltersAccess(accessTemp?.filterComponent || []);
      getSitewalkGoalsFilterDetails(
        setFiltersLoading,
        setFiltersErrMsg,
        setFilterData,
        headers
      );
 
  }, []);

  useEffect(() => {

      const intervalId = setInterval(() => {
        const newDay = moment().local().format("YYYY-MM-DD");
        if (currentDay !== newDay) {
          setCurrentDay(newDay);
          setStartDate(
            new Date(
              moment(currentDay, "YYYY-MM-DD").format("YYYY-MM-DD 00:00:00")
            )
          );
          setEndDate(
            new Date(moment(newDay, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59"))
          );
          setIsAutoCall(true);
        }
      }, 60000);
      return () => clearInterval(intervalId);
    
  }, [currentDay]);

  useEffect(() => {
    if (!_.isEmpty(getFilterData)) {
      setUsersOption(
        getFilterData?.reporters?.map((val) => {
          return Object.entries(val)?.[0]?.[0];
        })
      );
      setIsAutoCall(true);
    }
  }, [getFilterData]);

  useEffect(() => {
    if (isAutoCall) {
      const param = {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        reporter: getUsersOption,
        timeZone: moment.tz.guess(true),
      };
      getSitewalkGoalsByAuditors(
        param,
        setLoadingVal,
        setErrorMsg,
        setAuditorsGoalsData,
        "settargets",
        headersList
      );
      setIsAutoCall(false);
    }
  }, [isAutoCall]);

  return (
      <div className="row">
        <div className="tile filterDiv">
          <CustomDatePickerForm
            formName="siteWalkGoals"
            datepickerData={datepickerData}
            filterData={getFilterData}
            callApply={() => {
              setIsAutoCall((val) => !val);
            }}
            disableButtton={false}
            // filtersAccess={filtersAccess}
          />
        </div>
        {filtersLoading ? (
          <Loading />
        ) : (
          <>
            {filtersErrMsg !== "Successfull" ? (
              <div className="tile">
                <AlertEDS
                  errMsg={filtersErrMsg}
                  status_code={201}
                  close={false}
                />
              </div>
            ) : (
              <div className="tile sm-12">
                <div className="header">
                  <div className="left">
                    <div className="title">Auditor Goals Details</div>
                  </div>
                  <div className="right">
                    {rolesMap?.some((val) => val === "CONSOLE_GLOBAL_ADMIN" || val === "CONSOLE_GOALS_EDITOR") && (
                        <div
                          className="action"
                          onClick={() => {
                            setSelectedAuditorGoal({
                              type: "Add",
                              isOpen: true,
                            });
                          }}
                        >
                          <span className="tooltip pointer">
                            <i className="icon icon-plus text-xl"></i>
                            <span className="message left blue">
                              Add new goals
                            </span>
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="content">
                  {selectedAuditorGoal?.isOpen && (
                    <SiteWalkGoalsModel
                      getAuditorsList={getFilterData?.reporters || []}
                      selectedAuditorGoal={selectedAuditorGoal}
                      setSelectedAuditorGoal={setSelectedAuditorGoal}
                      setIsAutoCall={setIsAutoCall}
                    />
                  )}
                  {(loadingVal?.goalsByAuditor ? (
                      <Loading />
                    ) : (
                      <>
                        {errorMsg?.goalsByAuditor !== "Successfull" ? (
                          <div className="tile">
                            <AlertEDS
                              errMsg={errorMsg?.goalsByAuditor}
                              status_code={201}
                              close={false}
                            />
                          </div>
                        ) : (
                          <SitewalkAuditorsGoalsTable
                            // goalsAccess={getGoalsAccess}
                            auditorsGoals={
                              getAuditorsGoalsData?.goalsByAuditor || []
                            }
                            setSelectedAuditorGoal={setSelectedAuditorGoal}
                            setIsAutoCall={setIsAutoCall}
                          />
                        )}{" "}
                      </>
                    ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
  );
}
