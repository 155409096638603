import React, {useEffect, useState, lazy } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Table } from "@eds/vanilla";
import _ from "lodash";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import { getValidSupplier } from "../../api/Validomain.api";
import AddUpdateSupplier from "./AddUpdateSupplier";
import { exportToCSVMethod } from "../../utils/helperMethod";
import { getFormWiseCCList } from "../../api/Notification.api";
const ViewMoreInfo = lazy(() => import("./ViewMoreInfo"));

export default function Suppliers() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const roles = useSelector((state) => state.userAccess.roles);
  const [getvalidsupplierlist, setvalidsupplierlist] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [loadingVal, setLoading] = useState(false);
  const [addnewsupplier, setaddnewsupplier] = useState({ isOpen: false, type: "", data: {} });
  const [confirmationMessage, setconfirmationMessage] = useState({
    errorMsg: "",
    statusCode: "",
  });

  const [getCCList, setCCList] = useState([]);
  const [getAdministrators, setAdministrators] = useState([]);
  const [getRecipients, setRecipients] = useState([]);
  const { headersList, headers } = useSelector((state) => state.envVariables);

  let distributionData = {
    getCCList,
    setCCList,
  }

  useEffect(() => {
    getValidSupplier(
      setvalidsupplierlist,
      {},
      setLoading,
      setErrMsg,
      headersList
    );
    getFormWiseCCList(distributionData.setCCList, headers);
  }, []);

  const ValidsupplierList = async () => {
    await getValidSupplier(
      setvalidsupplierlist,
      {},
      setLoading,
      setErrMsg,
      headersList
    );
  };

  const dataformatted = () => {
    const data = [];
    getvalidsupplierlist.filter((item, k) => {
      if (item.default !== "Y") {
        data.push({
          name: Array.isArray(item.allowed_domain) ? item.allowed_domain.join(", ") : item.allowed_domain,
          date: item.supplier_created_date,
          supplier: item.supplier_name,
          id: item.supplier_id,
          internal_supplier_id: item.internal_supplier_id,
          UsersCount: item.total_count,
          supplier_administrators: Array.isArray(item.supplier_administrators) ? item.supplier_administrators.join(", ") : item.supplier_administrators,
          notification_recipients: Array.isArray(item.notification_recipients) ? item.notification_recipients.join(", ") : item.notification_recipients,
          action: { index: k, "name": Array.isArray(item.allowed_domain) ? item.allowed_domain.join(", ") : item.allowed_domain, "supplier": item.supplier_name, "id": item.supplier_id, "internal_supplier_id": item.internal_supplier_id, "supplier_administrators": Array.isArray(item.supplier_administrators) ? item.supplier_administrators.join(", ") : item.supplier_administrators, "notification_recipients": Array.isArray(item.notification_recipients) ? item.notification_recipients.join(", ") : item.notification_recipients },
          view: item,
          verified: item.verified
        })
      }
    });
    data.reverse()
    return data;
  };

  const removedItems = () => {
    const data = [];
    getvalidsupplierlist.filter(item => {
      if (item.default === "Y") {
        data.push({
          Organization: item.supplier_name,
          "Default Domain": item.allowed_domain.join(","),
          Users: item.total_count
        })
      }
    });
    data.reverse()
    return data;
  };
  useEffect(() => {
    if (!_.isEmpty(getvalidsupplierlist)) {
      const tableDOM = document.querySelector("#SupplierList");
      const table = new Table(tableDOM, {
        data: dataformatted(),
        columns: [

          {
            key: "supplier",
            title: "Supplier",
            sort: "none",
          },
          {
            key: "verified",
            title: "Verified",
            sort: "none",
            onCreatedCell: (td, data) => {
              td.innerHTML = `<kbd class="tag ${data ? "green" : "orange"}">${data ? "Verified" : "Unverified"}</kbd>`
            }
          },         
          {
            key: "id",
            title: "Supplier ID",
            sort: "none",
          },
          {
            key: "name",
            title: "Domain",
            sort: "none",
          },
          {
            key: "date",
            title: "Created Date",
            sort: 'none',
            // sort: 'desc'
          },
          {
            key: "UsersCount",
            title: "Users",
            sort: "none"
          },
          {
            key: "view",
            title: "Action",
            onCreatedCell: (td, data) => {
              td.innerHTML = `<button class="btn primary icon-btn viewInfo" title="View More">
              <i class="icon icon-eye"></i></button>`;
              td.querySelector("button.viewInfo").addEventListener(
                "click",
                async (evt) => {
                  handleViewInfocall(data);
                }
              );
            }
          }
        ],
        sortable: true,
      });

      table.init();
    }
  }, [getvalidsupplierlist]);

  const handleAddDomain = () => {
    setRecipients([])
    setAdministrators([]);
    setaddnewsupplier({
      isOpen: true,
      type: "Add",
      data: {}
    });
  };

  const handleViewInfocall = (val) => {
    navigate(`supplierID=${val.internal_supplier_id}`, {
      state: { title: "ViewMore", data: val },
    });
  }

  const callExport = () => {
    const csvData = [];
    const dataArray = Object.values(getvalidsupplierlist);
    dataArray?.forEach((val) => {
      csvData.push({
        Supplier: val?.supplier_name,
        Domain: Array.isArray(val?.allowed_domain) ? val?.allowed_domain.join(", ") : val?.allowed_domain[0],
        "Supplier ID": val?.supplier_id,
        "Notification Recipients": Array.isArray(val?.notification_recipients) ? val?.notification_recipients.join(", ") : val?.notification_recipients[0],
        "Supplier Administrators": Array.isArray(val?.supplier_administrators) ? val?.supplier_administrators.join(", ") : val?.supplier_administrators[0],
        "Created Date": val?.supplier_created_date,
        Users: val?.total_count
      });
    });
    exportToCSVMethod(csvData, "Supplier List");
  };


  return (
    roles?.some((val) => val === "CONSOLE_GLOBAL_ADMIN") && (
      <>
        <div className="tile sm-12 lg-12">
          <div className="content">
            <div className="header">
              <div className="left">
                <div className="title"> Suppliers</div>
                <div className="subtitle">
                  Add/Update domains for users to access the application
                </div>
              </div>
            </div>
            <div className="content">
              {addnewsupplier.isOpen && distributionData.getCCList && (
                <AddUpdateSupplier
                  addnewsupplier={addnewsupplier}
                  setaddnewsupplier={setaddnewsupplier}
                  confirmationMessage={confirmationMessage}
                  setconfirmationMessage={setconfirmationMessage}
                  setLoading={setLoading}
                  setErrMsg={setErrMsg}
                  headersList={headersList}
                  ValidsupplierList={ValidsupplierList}
                  loadingVal={loadingVal}
                  distributionData={distributionData}
                  getAdministrators={getAdministrators}
                  setAdministrators={setAdministrators}
                  getRecipients={getRecipients}
                  setRecipients={setRecipients}
                />
              )}
              <div className="column">
                {loadingVal ? (
                  <Loading />
                ) : errMsg !== "Success" && errMsg !== "" ? (
                  <AlertEDS errMsg={errMsg} status_code="error" close={false} />
                ) : (
                  <>
                    {removedItems().map((item, k) => (
                      <table
                        className="table defaultwidth"
                        style={{ tableLayout: `auto` }}
                        key={k}
                      >
                        <thead>
                          <tr>
                            {Object.keys(item).map((key) => (
                              <th key={key}>{key}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {Object.values(item).map((value) => (
                              <td key={value}>{value}</td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    ))}

                    <br></br>
                    <div className="table-top">
                      <div className="table-top-left">
                        <div className="table-info"></div>
                      </div>
                      <div className="table-top-right">
                        <div className="table-buttons">
                          <button
                            className="btn"
                            id="add_new_supplier"
                            onClick={handleAddDomain}
                            title="Add New Supplier"
                          >
                            + New
                          </button>

                          <button
                            className="btn"
                            id="exportData"
                            onClick={() => callExport()}
                          >
                            Export
                          </button>
                        </div>
                        <div className="table-search">
                          <input
                            type="text"
                            className="with-icon"
                            placeholder="Search for..."
                          />
                          <div className="suffix icon-inside">
                            <i className="icon icon-search"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <table
                      id="SupplierList"
                      className="table stickyHeader horizonatalScroll dashed compact"
                      style={{ tableLayout: `auto` }}
                    ></table>
                    <div className="table-bottom">
                      <div className="pagination-group">
                        <ul className="pagination"></ul>
                        <div className="actions">
                          <label className="left">Go to</label>
                          <input type="number" min="1" />

                          <label className="left">Show</label>
                          <div className="select" data-type="single">
                            <button className="btn current-options">5</button>
                            <div className="options-list">
                              <div className="item active">5</div>
                              <div className="item">10</div>
                              <div className="item">15</div>
                              <div className="item">All</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  )
}
