import { URL } from "../utils/Constant";
export const getChangePassword = async (
  dataJSON,
  setLoading,
  setMsg,
  headersList,
  callLogout
) => {
  setLoading(true);
  const jsonData = JSON.stringify(dataJSON);
  const resp = await fetch(URL.ChangePassword, {
    method: "PUT",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    setMsg(data.message);
    setLoading(false);
    if (data.status_code === 200) {
      setTimeout(() => {
        callLogout();
      }, 2000);
    }
  }
};
