import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { Tree } from "@eds/vanilla";
import pin from "../../asset/Icons/pin.svg";
import pin_solid from "../../asset/Icons/pin_solid.svg";
import "../NavigationTree/NavigationTree.scss";
import routes from "./navigation.json";
import { updateFavourites } from "../../redux/slices/accessSlice";
import { useAddFavoritesMutation } from "../../redux/services/authApi";

export default function NavigationTree() {
  const usergroupId = useSelector(
    (state) => state.loggedInUser.user.usergroupId
  );
  const favourites = useSelector((state) => state.userAccess.favourites);
  const dispatch = useDispatch();
  const rolesMap = useSelector((state) => state.userAccess.roles);

  const [addFavorites] = useAddFavoritesMutation();

  const favoritesDebounce = useCallback(
    _.debounce((updatedIcons) => {
      addFavorites({
        favouritelist: updatedIcons,
        apptype: "dashboard",
      });
    }, 1000),
    []
  );

  const isIconHighlighted = (iconId) => {
    return favourites.includes(iconId);
  };

  const toggleIconHighlight = (iconId) => {
    const isHighlighted = isIconHighlighted(iconId);
    const updatedIcons = isHighlighted
      ? favourites.filter((id) => id !== iconId)
      : [...favourites, iconId];

    dispatch(updateFavourites(updatedIcons));

    favoritesDebounce(updatedIcons);
  };

  useEffect(() => {
    if (!_.isEmpty(rolesMap)) {
      const tree = new Tree(document.querySelector(".tree.navigation"));
      tree.init();
      return () => {
        tree.destroy();
      };
    }
  }, [rolesMap]);

  const accessMatcher = (tab) => {
    if (tab === "Welcome") return true;
    return rolesMap.some((val) => val === tab);
  };

  const renderPin = (id) => {
    const isPinned = isIconHighlighted(id);
    return (
      <span className="star-icon-container">
        <span
          className="star-icon highlighted"
          title={isPinned ? "Unpin" : "Pin"}
          onClick={(e) => {
            e.preventDefault();
            toggleIconHighlight(id);
          }}
        >
          <img
            src={String(isPinned ? pin_solid : pin)}
            alt=""
            className="pin-icon"
          />
        </span>
      </span>
    );
  };

  const renderChildren = (child) => {
    const isNew = child.id === "CONSOLE_AI";
    return child.rolesMap?.some(accessMatcher) ? (
      child.children.length ? (
        <li key={child.id}>
          <span className="title item">
            {child.id === "CONSOLE_SITEWALK" && usergroupId === "12"
              ? "Safety Observation"
              : child.title}
          </span>
          <ul>{child.children.map((route) => renderChildren(route))}</ul>
        </li>
      ) : (
        <li className="navlink-item" key={child.id}>
          <NavLink to={child.path} className="item">
            {child.imgPath && (
              <img
                src={require(`../../${child.imgPath}`)}
                alt=""
                className="icon-white-color sidebar-icons"
              />
            )}
            {child.title}
            {isNew && (
              <kbd
              className="tag green"
                style={{
                  marginLeft: "10px",
                  marginTop: "6px",
                  fontSize: "0.65rem",
                }}
              >
                New
              </kbd>
            )}

            {child.favourite && renderPin(child.favourite.id)}
          </NavLink>
        </li>
      )
    ) : null;
  };

  return (
    <div className="tree navigation">
      <ul>{routes.map((route) => renderChildren(route))}</ul>
    </div>
  );
}
