import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Routing from "../../Routing";
import "./sidebar.scss";
import navigation from "../NavigationTree/navigation.json";
import { Layout as EdsLayout } from "@eds/vanilla/common/scripts/Layout.js";
import Systembar from "../../components/Sysbar/Systembar";
import NavigationTree from "../NavigationTree/NavigationTree";
import EDSGlobalNavigation from "../../components/EDSGlobalNavigation/EDSGlobalNavigation";
import SwitchAccount from "../Main/SwitchAccount";

export default function SideBar() {
  const [getPageTitle, setPageTitle] = useState();
  const location = useLocation();

  const flateenNavigation = (data) => {
    return data.reduce((acc, val) => {
      if (val.children.length) {
        acc.push(...flateenNavigation(val.children));
      } else {
        acc.push({ ...val });
      }
      return acc;
    }, []);
  };

  const getPages = flateenNavigation(navigation);

  useEffect(() => {
    const layout = new EdsLayout(document.querySelector("body"));
    layout.init();
    return () => {
      layout.destroy();
    };
  }, []);

  useEffect(() => {
    const currentTab = getPages.find((val) =>
      location.pathname.startsWith(val.path)
    );
    if (currentTab?.pageTitle) {
      setPageTitle(currentTab?.pageTitle);
    } else {
      const pathName = location.pathname
        .replace("/", " ")
        .replace("-", " ")
        .trim();
      setPageTitle(pathName.charAt(0).toUpperCase() + pathName.slice(1));
    }
  }, [location.pathname]);

  return (
    <main>
      <Systembar />

      <SwitchAccount />

      <EDSGlobalNavigation />

      <div className="app">
        <nav className="appbar">
          <div className="actions-left">
            <div className="item">
              <i className="navigation-toggle closed"></i>
            </div>
            <div className="menu-anchor open-menu">Menu</div>
            <div className="title open-menu">
              <span className="title-name">{getPageTitle}</span>
            </div>
          </div>
        </nav>
        <div className="appbody">
          <div className="appnav">
            <NavigationTree />
          </div>
          <div className="appbody">
            <div className="appcontent">
              <Routing />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
