import { URL } from "../utils/Constant";
import moment from "moment-timezone";

export const getUser = async (
  setLoading,
  setUserData,
  setErrMsg,
  datepickerData,
  headersList
) => {
  setLoading(true);
  let startDate = "",
    endDate = "";
  if (datepickerData.startDate !== "") {
    startDate = moment(datepickerData.startDate).format("YYYY-MM-DD");
  } else {
    startDate = "";
  }
  if (datepickerData.endDate !== "") {
    endDate = moment(datepickerData.endDate).format("YYYY-MM-DD");
  } else {
    endDate = "";
  }
  try {
    const resp = await fetch(
      URL.ERICSION.GET_ADMIN_VIEW_USER +
        "?start_date=" +
        startDate +
        "&end_date=" +
        endDate +
        "&active_user=" +
        datepickerData.formIdData +
        "&dashboard_user=" +
        datepickerData.dashboardAcessId,
      { method: "GET", headers: headersList }
    );
    if (resp.ok) {
      const data = await resp.json();
      setUserData(JSON.parse(data.data || "[]"));
      setErrMsg(
        data.message === "Success" && JSON.parse(data.data).length < 1
          ? "No data available in this date range"
          : data.message
      );
      setLoading(false);
    } else {
      const data = await resp.json();
      setLoading(false);
      setErrMsg(data.message || "Internal Server Error");
    }
  } catch (err) {
    setErrMsg("Internal Server Error");
    setLoading(false);
  }
};

export const getDeleteUser = async (setLoading, _deleteData, headersList) => {
  setLoading(true);
  const response = { errorMsg: "", statusCode: 200 };
  try {
    const resp = await fetch(
      URL.ERICSION.GET_ADMIN_DELETE_USER +
        "?employeeId=" +
        _deleteData.empId +
        "&email=" +
        _deleteData.email,
      { method: "DELETE", headers: headersList }
    );
    const data = await resp.json();
    response.errorMsg = data.message || "Internal Server Error";
    response.statusCode = data.status_code === 200 ? 200 : 201;
  } catch {
    response.errorMsg = "Internal Server Error";
    response.statusCode = 201;
  }
  setLoading(false);
  return response;
};

export const getRoles = async (
  setLoading,
  setRolesData,
  setErrMsg,
  rolesData,
  headersList
) => {
  setLoading(true);

  const resp = await fetch(
    URL.ERICSION.GET_ROLE_DETAILS + "?apptype=Dashboard",
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data = await resp.json();
    setRolesData(data.data.dashboard);
    setErrMsg(data.message);
    setLoading(false);
  }
};

export const updateUserStatus = async (param, setErrMsg, headersList) => {
  const jsonData = JSON.stringify(param);
  const resp = await fetch(URL.ERICSION.UPDATE_USER_STATUS, {
    method: "PUT",
    headers: headersList,
    body: jsonData,
  });
  if (resp.ok) {
    const data = await resp.json();
    setErrMsg(data.message);
    return data;
  }
};

export const resetUserPassword = async (
  param,
  setLoading,
  setErrMsg,
  headersList
) => {
  setLoading(true);
  const jsonData = JSON.stringify(param);
  const resp = await fetch(URL.ERICSION.RESET_ADMIN_PASSWORD, {
    method: "PUT",
    headers: headersList,
    body: jsonData,
  });
  if (resp.ok) {
    const data = await resp.json();
    setErrMsg(data.message);
    setLoading(false);
    return data;
  }
};

export const getDashboardRoles = async (
  setLoading,
  setRolesDashboardData,
  setErrMsg,
  headersList
) => {
  setLoading({
    dashboardRole: true,
  });
  const resp = await fetch(
    URL.ERICSION.GET_ROLE_DETAILS + "?apptype=dashboard",
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data = await resp.json();
    setRolesDashboardData(data.data.dashboard);
    setErrMsg({
      dashboardRole: data.message,
    });
    setLoading({
      dashboardRole: false,
    });
  }
};

export const getCompanyAndRoles = async (
  setLoading,
  setStatusMessage,
  setFilterList,
  headers
) => {
  const defaultResponse = {
    data: {
      country: [],
      roles: [],
    },
    message: "Internal Server Error",
    status_code: 200,
  };
  setLoading((val) => ({ ...val, getCall: true }));
  await fetch(URL.ERICSION.GET_COMPANY_AND_ROLES, {
    method: "GET",
    headers,
  })
    .then(async (resp) => await resp.json())
    .then((data) => {
      setFilterList(data?.data || defaultResponse.data);
      setStatusMessage(data?.message || defaultResponse.message);
      setLoading((val) => ({ ...val, getCall: false }));
    })
    .catch(() => {
      setFilterList(defaultResponse.data);
      setStatusMessage(defaultResponse.message);
      setLoading((val) => ({ ...val, getCall: false }));
    });
};

export const UpdateUserDetails = async (options) => {
  return await fetch(URL.ERICSION.UPDATE_USER_ROLES, options)
    .then(async (resp) => await resp.json())
    .catch(() => ({
      data: "{}",
      message: "Internal Server Error",
      status_code: 201,
    }));
};
