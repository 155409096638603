import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./reportstats.scss";
import moment from "moment-timezone";
import Loading from "../../components/Loading/Loading";
import StateWiseSubmissions from "./Graphs/StateWiseSubmissions";
import AverageTimePerSection from "./Graphs/AverageTimePerSection";
import _ from "lodash";
import FormSubmissionsGraph from "./Graphs/FormSubmissionsGraph";
import ClimbNonClimbGraph from "./Graphs/ClimbNonClimbGraph";
import PPEConfidence from "./Graphs/PPEConfidence";
import OverriddenCountPerPPE from "./Graphs/OverriddenCountPerPPE";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetClimberMutation,
  useGetERCAnalyticsMutation,
  useGetFormSubmissionMutation,
  useGetScoresMutation,
} from "../../redux/services/safetyFormsApi";
import CrewLeadScores from "./Graphs/CrewLeadScores";

export default function ReportStats() {
  const { start_date, end_date } = useSelector(
    (state) => state.userAccess.filter_dates
  );
  // const [startDate, setStartDate] = useState(
  //   new Date(
  //     moment().local().subtract(1, "weeks").format("YYYY-MM-DD 00:00:00")
  //   )
  // );
  const [startDate, setStartDate] = useState(
    new Date(
        moment(start_date).local().format("YYYY-MM-DD 00:00:00")
      )
  );

  // const [endDate, setEndDate] = useState(
  //   new Date(moment().local().format("YYYY-MM-DD 23:59:59"))
  // );
  const [endDate, setEndDate] = useState(
    new Date(moment(end_date).local().format("YYYY-MM-DD 00:00:00"))
  );
  const [formIdData, setFormIdData] = useState([]);
  const [isAutoCall, setIsAutoCall] = useState(false);
  const [currentDay, setCurrentDay] = useState(
    moment().local().format("YYYY-MM-DD")
  );
  const [getForms, setForms] = useState([]);
  const navigate = useNavigate();
  const usergroupId = useSelector(
    (state) => state.loggedInUser.user.usergroupId
  );
  const features = useSelector((state) => state.userAccess.features);
  const forms = useSelector((state) => state.userAccess.forms);
  const [getERCAnalytics, { data: ercData, isLoading: ercLoading }] =
    useGetERCAnalyticsMutation();
  const [getFormSubmission, formSumbimissionDetails] =
    useGetFormSubmissionMutation();
  const [getClimber, climberData] = useGetClimberMutation();
  const [getScores, scoresData] = useGetScoresMutation();

  let datepickerData = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setFormIdData,
    formIdData,
  };

  const callGetReportStats = (formId = []) => {
    const param = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      formId: _.isEmpty(formId) ? formIdData : formId,
      response_type: "2",
      timeZone: moment.tz.guess(true),
      status: [1, 2, 3, 4, 6],
    };
    if (
      !_.isNull(startDate) &&
      !_.isNull(endDate) &&
      !_.isEmpty(param.formId)
    ) {
      const formData = new FormData();
      for (let i in param) {
        formData.append(i, param[i]);
      }
      getERCAnalytics(formData);
      getFormSubmission(formData);
      !["22", "30"].includes(usergroupId) && getClimber(formData);
      features.some((val) => val?.name === "SCORE" && val?.value) &&
        getScores(formData);
    }
  };

  useEffect(() => {
      const intervalId = setInterval(() => {
        const newDay = moment().local().format("YYYY-MM-DD");
        if (currentDay !== newDay) {
          setCurrentDay(newDay);
          setStartDate(
            new Date(
              moment(currentDay, "YYYY-MM-DD").format("YYYY-MM-DD 00:00:00")
            )
          );
          setEndDate(
            new Date(moment(newDay, "YYYY-MM-DD").format("YYYY-MM-DD 23:59:59"))
          );
          setIsAutoCall(true);
        }
      }, 60000);
      return () => clearInterval(intervalId);
    
  }, [ currentDay]);

  useEffect(() => {
    if (!_.isEmpty(forms)) {
      let _setForm = [];
      forms?.safety_forms?.forEach(
        (i) =>
          i.value && _setForm.push({ formId: i.formid, form_name: i.formname })
      );
      setForms(_setForm);
      let formId = _setForm?.map((i) => i.formId);
      if (usergroupId === "1") {
        let arr = ["10", "14", "7", "5"];
        formId = formId.filter((item) => arr.includes(item));
      }
      setFormIdData(formId);
      setIsAutoCall(true);
    }
  }, [usergroupId]);

  useEffect(() => {
    if (isAutoCall) {
      callGetReportStats();
      setIsAutoCall(false);
    }
  }, [isAutoCall]);

  const disableApply =
    _.isNull(startDate) ||
    _.isNull(endDate) ||
    formIdData.length < 1 ||
    ercLoading ||
    formSumbimissionDetails.isLoading ||
    climberData.isLoading ||
    scoresData.isLoading;

  return !_.isEmpty(features) && !_.isEmpty(getForms) ? (
    <div className="row">
      <div className="tile filterDiv">
        <CustomDatePickerForm
          formName="report-stats"
          datepickerData={datepickerData}
          filterData={getForms}
          callApply={() => {
            setIsAutoCall(true);
          }}
          disableButtton={disableApply}
        />
      </div>
      <>
        <FormSubmissionsGraph data={formSumbimissionDetails} />
        {!["22", "30"].includes(usergroupId) && (
          <ClimbNonClimbGraph data={climberData} />
        )}
        <div className="tile md-12 sm-12 lg-4">
          <div className="header">
            <div className="left">
              <div className="title">Average Time per Section (Minutes)</div>
            </div>
          </div>
          <div className="content">
            <div className="column">
              {ercLoading && <Loading />}
              {ercData?.message && ercData?.message !== "Success" && (
                <AlertEDS
                  errMsg={ercData?.message}
                  status_code={201}
                  close={false}
                />
              )}
              {ercData?.data?.avg_time_js && (
                <AverageTimePerSection
                  avg_time_js={JSON.parse(ercData?.data?.avg_time_js || "{}")}
                />
              )}
            </div>
          </div>
        </div>
        <div className="tile md-6 sm-12 lg-4">
          <div className="header">
            <div className="left">
              <div className="title">Average AI Confidence % Per PPE</div>
            </div>
          </div>
          <div className="content">
            <div className="column">
              {ercLoading && <Loading />}
              {ercData?.message && ercData?.message !== "Success" && (
                <AlertEDS
                  errMsg={ercData?.message}
                  status_code={201}
                  close={false}
                />
              )}
              {ercData?.data?.confidencegrp_js && (
                <PPEConfidence
                  confidencegrp_js={JSON.parse(
                    ercData?.data?.confidencegrp_js || "{}"
                  )}
                />
              )}
            </div>
          </div>
        </div>
        <div className="tile md-6 sm-12 lg-4">
          <div className="header">
            <div className="left">
              <div className="title">Overridden Count Per PPE</div>
            </div>
          </div>
          <div className="content">
            <div className="column">
              {ercLoading && <Loading />}
              {ercData?.message && ercData?.message !== "Success" && (
                <AlertEDS
                  errMsg={ercData?.message}
                  status_code={201}
                  close={false}
                />
              )}
              {ercData?.data?.overriddengrp_js && (
                <OverriddenCountPerPPE
                  overriddengrp_js={JSON.parse(
                    ercData?.data?.overriddengrp_js || "{}"
                  )}
                />
              )}
            </div>
          </div>
        </div>
        <div className="tile sm-12 md-12 lg-12 xl-12">
          <div className="header">
            <div className="left">
              <div className="title">Submissions Map</div>
            </div>
          </div>
          <div className="content">
            <div className="column">
              {ercLoading && <Loading />}
              {ercData?.message && ercData?.message !== "Success" && (
                <AlertEDS
                  errMsg={ercData?.message}
                  status_code={201}
                  close={false}
                />
              )}
              {ercData?.data?.zonereport_countjs && (
                <StateWiseSubmissions
                  usergroup_location={ercData?.data.usergroup_location}
                  zonereport_countjs={JSON.parse(
                    ercData.data.zonereport_countjs || "{}"
                  )}
                />
              )}
            </div>
          </div>
        </div>
        {features.some((val) => val?.name === "SCORE" && val?.value) && (
          <>
            <CrewLeadScores data={scoresData} />
          </>
        )}
      </>
    </div>
  ) : null;
}
