import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import navigation from "../NavigationTree/navigation.json";

export const Favourites = () => {
  const [getFavourites, setFavourites] = useState([]);
  const favourites = useSelector((state) => state.userAccess.favourites);

  const flateenFavourites = (data) => {
    return data.reduce((acc, val) => {
      if (val.children.length) {
        acc.push(...flateenFavourites(val.children));
      } else {
        val.favourite && acc.push({ ...val.favourite, path: val.path });
      }
      return acc;
    }, []);
  };

  const favouritesList = flateenFavourites(navigation);

  useEffect(() => {
    const data = favouritesList.filter((value) =>
      favourites.includes(value.id)
    );
    setFavourites(data);
  }, [favourites]);

  return (
    <div className="row">
      <div className="tile sm-12">
        <div className="header">
          <div className="left">
            {favourites?.length === 0 ? (
              <div className="title">Get started</div>
            ) : (
              <div className="title">
                Quick Access{" "}
                <span className="tooltip pointer">
                  <i className="icon icon-info"></i>{" "}
                  <span className="message right">
                    Your Pinned tabs will be listed here for quick access
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="content">
          {favourites?.length === 0 ? (
            <p>Select a menu item from the left to continue.</p>
          ) : (
            getFavourites?.map((val) => (
              <button key={val.id} className="btn button-needed">
                <NavLink to={val.path} className="item" id={val.id}>
                  {val.value}
                </NavLink>
              </button>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
