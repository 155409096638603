import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getListHazardIdentification } from "../../api/HazardID.api";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import HazardIDTable from "./HazardIDTable";
import HazardIDCreate from "./HazardIDCreate";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";

export default function HazardID() {
    const [getListHazardIdenti, setListHazardIdenti] = useState([]);
    const [isLoading, setIsLoading] = useState({
        getListHazardIdentification: false
    });
    const [errMsg, setError] = useState({
        getListHazardIdentification: "",
        folderUploadLoading: ""
    });
    const [getCreateHazard, setCreateHazard] = useState(false);
    const { headersList } = useSelector((state) => state.envVariables);
    const [getSite_type, setSite_type] = useState("");
    const [getCustomer, setCustomer] = useState("");

    let datepickerData = {
        getSite_type,
        setSite_type,
        getCustomer,
        setCustomer,
    };

    const callRefresh = async () => {
        let param;
        if (getSite_type !== "" && getCustomer === "") {
            param = "?site_type=" + getSite_type
        }
        else if (getCustomer !== "" && getSite_type === "") {
            param = "?customer=" + getCustomer
        }
        else if (getCustomer !== "" && getSite_type !== "") {
            param = "?site_type=" + getSite_type + "&customer=" + getCustomer
        } else {
            param = ""
        }
        await getListHazardIdentification(headersList, param, setIsLoading, setError, setListHazardIdenti);
    }

    useEffect(() => {
        callRefresh();
    }, []);

    const filterData = {
        "SiteTypeOption": ['In-Building / Data Center', 'Guyed', 'Ground', 'Monopole', 'Rooftop', 'Self Support', 'Shelter', 'Stealth Pole', 'Tower', 'Utility', 'Other'],
        "customerOption": ['Airwavz (Emerging Business)', 'Arelion', 'AT-T', 'COVID-19 Reports', 'Diamond (Emerging Business)', 'Google', 'IVC (Illinois Valley Cellular)', 'Pine Cellular', 'Rogers', 'T-Mobile', 'Totem (Emerging Business)', 'US Cellular Corp', 'Verizon', 'Viaero', 'Other']
    }

    useEffect(() => {
        if (errMsg.folderUploadLoading !== "") {
            setTimeout(() => { callRefresh(); setError((val) => ({ ...val, folderUploadLoading: "" })); }, 8000);
        }
    }, [getCreateHazard]);

    return (
        <div className="HazardID">
            <div className="tile filterDiv">
                <CustomDatePickerForm
                    formName="hazardID"
                    datepickerData={datepickerData}
                    filterData={filterData}
                    callApply={callRefresh}
                    disableButtton={isLoading.getListHazardIdentification}

                />
            </div>
            <div className="tile sm-12 md-12 lg-12 xl-12">
                <div className="header">
                    <div className="left">
                        <div className="title">Hazard Identification</div>
                    </div>
                    <div className="right">
                        <div className="actionItem" onClick={() => setCreateHazard(true)}>
                            <span className="tooltip pointer">
                                <i className="icon icon-plus text-xl"></i>
                                <span className="message left blue">Add New Request</span>
                            </span>
                        </div>
                    </div>
                </div>

                {errMsg.folderUploadLoading !== "" &&
                    (
                        <AlertEDS errMsg={errMsg.folderUploadLoading} status_code={errMsg.folderUploadLoading.includes('Error') ? 201 : 200} close={false} />
                    )}

                <div className="content">
                    <div className="column">
                        {isLoading.getListHazardIdentification ? (
                            <Loading />
                        ) : (
                            <>
                                {errMsg.getListHazardIdentification !== "Success" ? (
                                    <AlertEDS errMsg={errMsg.getListHazardIdentification} status_code={201} close={false} />
                                ) : (
                                    <HazardIDTable
                                        getListHazardIdenti={getListHazardIdenti}
                                        callRefresh={callRefresh}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="header">
                    <div className="left">
                        <div className="disclaimer">
                            <span className="disclaimer-text" style={{ whiteSpace: "nowrap", marginLeft: "8px" }}>Disclaimer:</span>
                            <span style={{ color: "#888", alignItems: "center" }}>
                                {" "}
                                This feature is offered for experimentation purposes only. It
                                should not be used to draw decisive conclusions about the
                                environment.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {getCreateHazard && (
                <HazardIDCreate
                    setCreateHazard={setCreateHazard}
                    setError={setError}
                />
            )}
        </div>
    )
}