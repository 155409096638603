import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL } from "../../utils/Constant";

export const sitewalkAnalyticsApi = createApi({
  reducerPath: "sitewalkAnalyticsAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().envVariables.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSafetyActions: builder.mutation({
      query: (body) => ({
        url: URL?.ERICSION?.GET_SAFETY_ACTIONS,
        method: "POST",
        body,
      }),
    }),
    auditsByCustomer: builder.mutation({
      query: (body) => ({
        url: URL?.ERICSION?.GET_AUDITS_BY_CUSTOMER,
        method: "POST",
        body,
      }),
    }),
    auditsByBusiness: builder.mutation({
      query: (body) => ({
        url: URL?.ERICSION?.GET_AUDITS_BY_BUSINESS,
        method: "POST",
        body,
      }),
    }),
    auditsByAuditType: builder.mutation({
      query: (body) => ({
        url: URL?.ERICSION?.GET_AUDITCOUNT_BY_TYPE,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetSafetyActionsMutation,
  useAuditsByCustomerMutation,
  useAuditsByBusinessMutation,
  useAuditsByAuditTypeMutation,
} = sitewalkAnalyticsApi;

export default sitewalkAnalyticsApi;
