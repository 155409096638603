import { useState, useEffect, useRef } from "react";
import { Dialog, Accordion } from "@eds/vanilla";
import _ from "lodash";
import Loading from "../Loading/Loading";
import moment from "moment-timezone";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
export default function ModalDownLoadPopup({
  Showpopupdownload,
  ReportsDataformatted,
  setReportsDataformatted,
  setShowpopupdownload,
  totaltime_js,
  setShowMdlA,
  callGetApproveRejectOptions,
  setLoadingM,
  setErrMsgM,
  headers,
  setDataMdlA,
  handleShowIncidentPop,
  formList,
}) {
  const rolesMap = useSelector((state) => state.userAccess.roles);

  const [isIframeScrolledToBottom, setIsIframeScrolledToBottom] =
    useState(false);
  const iframeRef = useRef();
  useEffect(() => {
    const dialogs = document.getElementById("PreviewDownload");
    if (dialogs) {
      Array.from(dialogs).forEach((dialogDOM) => {
        const dialog = new Dialog(dialogDOM);
        dialog.init();
      });
    }
  }, []);

  const handleScroll = debounce(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      const isAtBottom =
        iframe.contentWindow.innerHeight + iframe.contentWindow.scrollY >=
        iframe.contentDocument.body.offsetHeight;
      setIsIframeScrolledToBottom((prevIsAtBottom) => {
        if (!prevIsAtBottom && isAtBottom) {
          return true;
        }
        return prevIsAtBottom;
      });
    }
  }, 200);

  useEffect(() => {
    const iframe = iframeRef.current;

    const handleIframeScroll = () => {
      handleScroll();
    };

    if (iframe) {
      iframe.contentWindow.addEventListener("scroll", handleIframeScroll);
    }
  }, [handleScroll]);

  const onCloseHandler = () => {
    setShowpopupdownload({ isOpen: false });
    setReportsDataformatted("");
  };

  const approveHandler = async () => {
    let index = Showpopupdownload.Index;
    if (index !== -1) {
      const idx = index;
      let crewLeads = [
        totaltime_js["Crew Lead"][idx],
        ...totaltime_js["Crew Names"][idx],
      ];
      setShowMdlA(true);
      setShowpopupdownload({
        isOpen: false,
        Index: Showpopupdownload.Index,
        Value: Showpopupdownload.Value,
      });
      const reasonOptions = await callGetApproveRejectOptions(
        { status_id: 2 },
        setLoadingM,
        setErrMsgM,
        headers
      );
      setDataMdlA({
        heading: "Approve",
        body: "",
        showClose: true,
        showSave: true,
        reportID: totaltime_js["ReportID"][idx],
        saveBtnName: "Approve",
        status: "approval",
        status_id: 2,
        reasonsOptions: reasonOptions,
        crewLeads: crewLeads.map((val) => ({ formId: val, form_name: val })),
      });
    }
  };

  const rejectHandler = async () => {
    let index = Showpopupdownload.Index;
    if (index !== -1) {
      const idx = index;
      let crewLeads = [
        totaltime_js["Crew Lead"][idx],
        ...totaltime_js["Crew Names"][idx],
      ];
      setShowMdlA(true);
      setShowpopupdownload({
        isOpen: false,
        Index: Showpopupdownload.Index,
        Value: Showpopupdownload.Value,
      });
      const reasonOptions = await callGetApproveRejectOptions(
        { status_id: 3 },
        setLoadingM,
        setErrMsgM,
        headers
      );
      setDataMdlA({
        heading: "Reject",
        body: "",
        showClose: true,
        showSave: true,
        reportID: totaltime_js["ReportID"][idx],
        saveBtnName: "Reject",
        status: "reject",
        status_id: 3,
        reasonsOptions: reasonOptions,
        crewLeads: crewLeads.map((val) => ({ formId: val, form_name: val })),
      });
    }
  };

  const approve = (Submitted_Datetime, status) => {
    let btn = false;
    if (
      moment(Submitted_Datetime, "YYYY/MM/DD HH:mm:ss").isAfter(
        moment("2022/11/22 15:05:26", "YYYY/MM/DD HH:mm:ss")
      )
    ) {
      status === "Submitted" ||
      status === "AI Verified" ||
      status === "Rejected" ||
      status === "AI Rejected"
        ? (btn = true)
        : (btn = false);
      // }
    } else {
      status === "Submitted" || status === "Rejected"
        ? (btn = true)
        : (btn = false);
    }
    return btn;
  };

  const reject = (status) => {
    let btn = false;

    status === "Submitted" ||
    status === "AI Verified" ||
    status === "Approved" ||
    status === "AI Rejected"
      ? (btn = true)
      : (btn = false);
    return btn;
  };

  const colorRed = (status) => {
    let btn = false;

    status === "Submitted" || status === "Rejected"
      ? (btn = true)
      : (btn = false);

    return btn;
  };
  return (
    <div
      id="PreviewDownload"
      className="dialog show fullscreen"
      data-trigger="#open-modalList"
      data-type="fullscreen"
    >
      <div className="content">
        <div className="top">
          <div
            className="title"
            style={{ fontWeight: "bold", fontFamily: "Ericsson Hilda" }}
          >
            Review
          </div>
          <div className="right">
            <button
              className="btn-icon"
              data-close="true"
              onClick={() => onCloseHandler()}
            >
              <i className="icon icon-cross"></i>
            </button>
          </div>
        </div>
        {ReportsDataformatted !== "" ? (
          <>
            <div
              className="title"
              style={{
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              Report Preview
            </div>
            <div className="body">
              <div
                style={{
                  width: "86vw",
                  height: "400px",
                  overflow: "hidden",
                  marginTop: "-25px",
                }}
              >
                <iframe
                  title="Report Content"
                  ref={iframeRef}
                  sandbox="allow-same-origin allow-scripts allow-downloads allow-popups"
                  srcDoc={ReportsDataformatted}
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fafafa",
                  }}
                />
              </div>
            </div>

            {Showpopupdownload.Value[6] === 'Yes' && !_.isEmpty(
              rolesMap?.find(
                (val) =>
                  val === "CONSOLE_GLOBAL_ADMIN" ||
                  val === "CONSOLE_SAFETY_FORM_APPROVALS"
              )
            ) ? (
              <div
                className="bottom"
                style={{ textAlign: "center", marginTop: "-10px" }}
              >
                {formList.find(
                  (form) =>
                    form.form_name === Showpopupdownload.Value[5] &&
                    form.enforce_manual_review
                ) && (
                  <>
                    <button
                      className="btn success"
                      disabled={
                        !approve(
                          Showpopupdownload.Value[0],
                          Showpopupdownload.Value[1]
                        ) || !isIframeScrolledToBottom
                      }
                      onClick={approveHandler}
                    >
                      Approve
                    </button>

                    <button
                      className="btn warning"
                      disabled={
                        !reject(Showpopupdownload.Value[1]) ||
                        !isIframeScrolledToBottom
                      }
                      onClick={rejectHandler}
                    >
                      Reject
                    </button>
                    <button
                      className="btn redColor"
                      disabled={
                        !colorRed(Showpopupdownload.Value[1]) ||
                        !isIframeScrolledToBottom
                      }
                      onClick={(e) =>
                        handleShowIncidentPop(Showpopupdownload.Index)
                      }
                    >
                      Code Red
                    </button>
                  </>
                )}
              </div>
            ) : null}
          </>
        ) : (
          <div className="body">
            <Loading width={"28"} height={"28"} />
          </div>
        )}
      </div>
    </div>
  );
}
