import { useState } from "react";
import { Link } from "react-router-dom";
import "./footer.scss";
import { useSelector } from "react-redux";

export default function Footer() {
  const [getYear, setCurrentYear] = useState(new Date().getFullYear());
  const { hyperlink } = useSelector((state) => state.loggedInUser.user);

  return (
    <div className="footer">
        <Link className="footerLink" target="_blank" to={hyperlink !== undefined ? hyperlink : "https://safeworkblobstore.blob.core.windows.net/safeworkdev/Icons/PrivacyNoticeExternal.pdf?sp=r&st=2024-01-03T15:03:20Z&se=2030-01-01T23:03:20Z&spr=https&sv=2022-11-02&sr=b&sig=X9WE0cThVzR8pm5CIWzIN4NYhPZzWd6O6jfMW%2FLoBEg%3D"}>Privacy</Link> | &copy; Safe Worker AI 2020-{getYear}
    </div>
  );
}
