import { useState } from "react";
import moment from "moment/moment";
import platform from "platform";
import EDSCustomMultiselect from "../../components/EDSCustomMultiselect/EDSCustomMultiselect";
import Loading from "../../components/Loading/Loading";
import {
  useGetHaloFeaturesListQuery,
  useSubmitSupportFormMutation,
} from "../../redux/services/supportApi";
import { supportEmail } from "../../utils/Constant";
import { impactOptions, urgencyOptions } from "../../utils/variables";

export default function SupportForm() {
  const [getSupportForm, setSupportForm] = useState({
    affecteddate: moment().local().format("YYYY-MM-DDTHH:mm"),
  });
  const {
    data: featureOptions,
    error: featureError,
    isFetching: featureLoading,
  } = useGetHaloFeaturesListQuery();
  const [
    submitSupportForm,
    { data: supportData, isLoading: submitLoading, error: submitError },
  ] = useSubmitSupportFormMutation();

  const handleInputChange = (input) => {
    setSupportForm((val) => ({ ...val, ...input }));
  };

  const handleSubmitSupportForm = () => {
    const formData = new FormData();
    formData.append(
      "userdevice",
      JSON.stringify({
        Browser: platform.name,
        os: platform.os.family,
        version: platform.version,
      })
    );
    const acceptedFiles = getSupportForm?.imagelist || [];
    for (let i in getSupportForm) {
      formData.append(i, getSupportForm[i]);
    }
    formData.delete("imagelist");
    for (const file of acceptedFiles) {
      formData.append("imagelist", file, file.name);
    }
    submitSupportForm(formData);
  };

  const disableSubmitButton =
    !(
      getSupportForm?.summary?.length &&
      getSupportForm?.feature?.length &&
      getSupportForm?.details?.length
    ) || submitLoading;

  return (
    <div className="row">
      <div className="tile sm-12">
        <div className="header">
          <div className="left">
            <div className="title">Contact Us via Email</div>
          </div>
        </div>
        <div className="content">
          <p>
            You can connect with us over our support email address{" "}
            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>. Our dedicated
            team is ready to address your questions and concerns promptly.
          </p>
        </div>
      </div>
      <div className="tile sm-12" id="t-basic">
        <div className="header">
          <div className="left">
            <div className="title">Support Request Form</div>
            <div className="subtitle">
              Submit your queries through our request form
            </div>
          </div>
        </div>
        <div className="content">
          <div className="eds-form">
            <div className="row form-body">
              <div className="column sm-12 md-12 lg-6">
                <div className="form-group">
                  <div className="form-element">
                    <label htmlFor="summary" className="required">
                      Summary
                    </label>
                    <input
                      name="summary"
                      type="text"
                      value={getSupportForm.summary || ""}
                      onChange={(event) => {
                        handleInputChange({ summary: event.target.value });
                      }}
                    />
                  </div>
                  <div className="form-element">
                    <label htmlFor="feature" className="required">
                      Feature
                    </label>
                    {featureLoading ? (
                      <Loading />
                    ) : (
                      <>
                        <EDSCustomMultiselect
                          setStatusOption={(value) => {
                            handleInputChange({ feature: value?.[0] || "" });
                          }}
                          formDetailsUG={
                            featureOptions?.data?.map((val) => ({
                              formId: val,
                              form_name: val,
                            })) || []
                          }
                          defaultValues={getSupportForm.feature || []}
                          filterName="support-form-feature"
                          dataType="input-single"
                        />
                        {featureError && (
                          <p className="color-red">
                            {`${featureError.status} - Internal Server Error`}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                  <div className="form-element">
                    <label htmlFor="impact">Impact</label>
                    <EDSCustomMultiselect
                      setStatusOption={(value) => {
                        handleInputChange({ impact: value?.[0] || "" });
                      }}
                      formDetailsUG={impactOptions}
                      defaultValues={[]}
                      filterName="support-form-impact"
                      dataType="input-single"
                    />
                  </div>
                  <div className="form-element">
                    <label htmlFor="urgency">Urgency</label>
                    <EDSCustomMultiselect
                      setStatusOption={(value) => {
                        handleInputChange({ urgency: value?.[0] || "" });
                      }}
                      formDetailsUG={urgencyOptions}
                      defaultValues={[]}
                      filterName="support-form-urgency"
                      dataType="input-single"
                    />
                  </div>
                </div>
              </div>
              <div className="column sm-12 md-12 lg-6">
                <div className="form-group">
                  <div className="form-element">
                    <label htmlFor="affected">Affected Since</label>
                    <input
                      type="datetime-local"
                      value={getSupportForm.affecteddate || ""}
                      onChange={(event) => {
                        handleInputChange({ affecteddate: event.target.value });
                      }}
                    />
                  </div>
                  <div className="form-element">
                    <label htmlFor="description" className="required">
                      Description
                    </label>
                    <textarea
                      name="description"
                      rows={"5"}
                      value={getSupportForm.details || ""}
                      onChange={(event) => {
                        handleInputChange({ details: event.target.value });
                      }}
                    />
                  </div>
                  <div className="form-element">
                    <label htmlFor="attachments">
                      Attachmets
                      <span className="tooltip ml-sm">
                        <i className="icon icon-info-solid"></i>
                        <span className="message top">
                          Only image/png, image/gif, image/jpeg allowed
                        </span>
                      </span>
                    </label>
                    <label>
                      <span className="btn">
                        <input
                          type="file"
                          multiple
                          accept="image/png, image/gif, image/jpeg"
                          onChange={(event) => {
                            handleInputChange({
                              imagelist: (
                                getSupportForm?.imagelist || []
                              ).concat(Array.from(event.target.files)),
                            });
                          }}
                        />
                        <i className="icon icon-upload"></i>
                        Choose file
                      </span>
                    </label>
                    {getSupportForm?.imagelist?.length ? (
                      <div>
                        <span
                          className="pill toggle"
                          style={{ float: "right" }}
                          onClick={() => {
                            setSupportForm((val) => ({
                              ...val,
                              imagelist: [],
                            }));
                          }}
                        >
                          Clear All
                        </span>
                        <div className="tree">
                          <ul>
                            {getSupportForm.imagelist.map((val, ind) => (
                              <li key={ind}>
                                <span className="item">
                                  <i className="icon icon-image"></i>
                                  {val.name}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto mt-xl">
              {submitLoading && <Loading />}
              {submitError && (
                <p className="color-red">
                  {" "}
                  {`${submitError.status} - Internal Server Error`}
                </p>
              )}
              {supportData && supportData?.status_code !== 200 && (
                <p className="color-red">{supportData.message}</p>
              )}
              {supportData && supportData?.status_code === 200 && (
                <p className="color-green">
                  Your support request has been received. Our team will get back
                  to you
                </p>
              )}
            </div>
            <div className="form-footer">
              <button
                className="btn primary"
                disabled={disableSubmitButton}
                onClick={handleSubmitSupportForm}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
