import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import {
  getUserManagementExpiry,
  setUserManagementExpiry,
} from "../../api/UserMangementExpiry.api";

export default function InactivityPeriod() {
  const [getusergroupExpiry, setusergroupExpiry] = useState(0);
  const [validationperiod, setvalidationperiod] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [loadingVal, setLoading] = useState(false);
  const [successMessage, setsuccessMessage] = useState({
    errMsg: "",
    status_code: 200,
  });

  const { headersList } = useSelector((state) => state.envVariables);

  useEffect(() => {
    getUserManagementExpiry(
      setusergroupExpiry,
      {},
      setLoading,
      setErrMsg,
      headersList
    );
  }, [headersList]);

  useEffect(() => {
    setvalidationperiod(getusergroupExpiry || 0);
  }, [getusergroupExpiry]);

  const submintInactivityPeriod = async () => {
    const updatedParam = { validation_period: validationperiod };
    await setUserManagementExpiry(
      updatedParam,
      setLoading,
      setErrMsg,
      headersList
    );
    if (errMsg === "Success" || errMsg === "") {
      setsuccessMessage({
        errMsg: "Inactivity period has been sucessfully set.",
        status_code: 200,
      });
      setusergroupExpiry(validationperiod);
      setTimeout(() => {
        setsuccessMessage("");
      }, 5000);
    }
  };

  return (
    <div className="tile sm-12">
      <div className="header">
        <div className="left">
          <div className="title">User Deactivation</div>
          <div className="subtitle">{`User inactivity period for your group is set to ${getusergroupExpiry} days`}</div>
        </div>
      </div>
      <div className="content">
        {loadingVal ? (
          <Loading />
        ) : errMsg !== "Success" && errMsg !== "" ? (
          <AlertEDS errMsg={errMsg} status_code="error" close={false} />
        ) : (
          <div className="eds-form">
            <div className="form-body">
              <div className="form-group">
                <div className="form-element">
                  <span>
                    <input
                      type="number"
                      className={
                        0 <= validationperiod <= 120
                          ? "validation valid"
                          : "validation invalid"
                      }
                      placeholder="Days"
                      min={0}
                      max={120}
                      value={validationperiod}
                      onChange={(e) => setvalidationperiod(e.target.value)}
                    />
                    <div className="suffix">days</div>
                    <span className="validation-msg">
                      Value should be between 0-120
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {successMessage.errMsg && (
              <AlertEDS
                errMsg={successMessage.errMsg}
                status_code={successMessage.status_code}
                close={false}
              />
            )}
            <div className="form-footer">
              <button
                className="btn primary"
                onClick={submintInactivityPeriod}
                disabled={validationperiod <= 0 || validationperiod > 120}
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
