import React, { useEffect } from "react";
import { ColorScale, HorizontalBarChartStacked } from "@eds/vanilla";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";
export default function ActionsByCreatorGraph({ actionsByCreator }) {
  useEffect(() => {
    const data = {
      common: actionsByCreator?.map((obj) => obj?.Name),
      series: [
        {
          name: "Open",
          values: actionsByCreator?.map((obj) => obj?.OpenCount),
        },
        {
          name: "Closed",
          values: actionsByCreator?.map((obj) => obj?.CloseCount),
        },
      ],
    };
    const chartHeight = data?.common?.length * 40;
    const mycolorScale = new ColorScale({ reverse: true });
    const chart = new HorizontalBarChartStacked({
      element: document.getElementById("actionItemsByAuditor"),
      data: data,
      x: { unit: "Count" },
      height: chartHeight,
      colorScale: mycolorScale,
    });
    mycolorScale.init();
    chart.init();
    return () => {
      chart.destroy();
      let element = document.getElementById("actionItemsByAuditor");
      while (element?.firstChild) {
        element.removeChild(element.firstChild);
      }
    };
  }, [actionsByCreator]);
  return (
    <>
      <CustomGraphActions
        nodeId={"actionItemsByAuditor"}
        fileName={"ActionItemsByAuditor"}
        data={actionsByCreator}
      />
      <div id="actionItemsByAuditor"></div>
    </>
  );
}
