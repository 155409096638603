import { Sysbar } from "@eds/vanilla/common/scripts/Sysbar";
import { NavLink } from "react-router-dom";
// import { ThemeSwitch } from "@eds/vanilla/theme-switch/ThemeSwitch.js";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLogout } from "../../api/login.api";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { b2cPolicies } from "../../authConfig";
// import {resetEnvVariables} from "../../redux/slices/envVariablesSlice";
// import {removeLoggedInUserDetails} from "../../redux/slices/userSlice";
// import { useMsal } from '@azure/msal-react';

export default function Systembar({ }) {
  const [getUserGroupName, setUserGroupName] = useState("");
  const [getIdpTokenStatus, setIdpTokenStatus] = useState(true);
  const { authorized_groups, usergroupId, firstName, lastName, hyperlink } =
    useSelector((state) => state.loggedInUser.user);
  const accessToken = useSelector((state) => state.envVariables.accessToken);
  const [callLogout] = useLogout();
  const { instance } = useMsal();
  // const { instance } = useMsal();

  // const useLogout = () => {
  //   const dispatch = useDispatch();
  //   const handleLogoutRedirect = () => {

  //     dispatch(resetEnvVariables());
  //     dispatch(removeLoggedInUserDetails());
  //     instance.logoutRedirect();
  //   };
  //   return [handleLogoutRedirect]
  // }

  function ThemeSwitcher() {
    // const themeSwitch = new ThemeSwitch();

    useEffect(() => {
      const themeInput = document.querySelector("label.switch input");
      if (localStorage.getItem("theme") === "light") {
        themeInput.checked = true;
      }
    }, []);

    function handleThemeToggle() {
      // themeSwitch.toggle();
      const themeInput = document.querySelector("label.switch input");
      if (themeInput.checked) {
        document.body.classList.remove("dark");
        document.body.classList.add("light");
        localStorage.setItem("theme", "light");
      } else {
        document.body.classList.remove("light");
        document.body.classList.add("dark");
        localStorage.setItem("theme", "dark");
      }
    }

    return (
      <label className="switch">
        <input type="checkbox" onChange={handleThemeToggle} />
        <i className="ball"></i>
        <span data-enabled="Light" data-disabled="Dark"></span>
      </label>
    );
  }

  useEffect(() => {
    if (accessToken) {
      const base64Url = accessToken.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      if (sessionStorage.getItem('idp_access_token') === null) {
        setIdpTokenStatus(JSON.parse(jsonPayload).idp_access_token ? true : false);
        sessionStorage.setItem('idp_access_token', JSON.parse(jsonPayload).idp_access_token ? true : false)
      }else{
        setIdpTokenStatus(sessionStorage.getItem('idp_access_token') === "true" ? true : false)
      }
    }

    const currentGroup =
      authorized_groups?.find((val) => val.id === usergroupId) || {};
    setUserGroupName(currentGroup.GroupName || "");
    const syspanel = document.querySelector(".syspanel");
    const panel = new Sysbar(syspanel);
    panel.init();
    return panel.destroy();
  }, [authorized_groups, usergroupId]);

  const changePassword = () => {
    instance
      .loginRedirect(b2cPolicies.authorities.changePassword)
      .catch((error) => console.log(error));
  };

  return (
    <aside className="syspanel hidden">
      <div className="settings">
        <div className="syspanel-top">
          <span className="syspanel-title"></span>
          <i className="syspanel-close icon icon-cross"></i>
        </div>
        <div className="row">
          <div className="column sm-12 container">
            <div className="profile">
              <i className="icon icon-profile"></i>
              <div className="username">
                {`${firstName} ${lastName}`}
                <br />
                <span className="text-sm">{getUserGroupName}</span>
              </div>
            </div>

            <div className="content">
              <div className="title">My settings</div>
              <div className="item mb-sm">
                <div className="left">
                  <label>Switch theme</label>
                </div>
                <div className="right">
                  <ThemeSwitcher />
                </div>
              </div>
              {authorized_groups?.length ? (
                <div className="item mb-sm">
                  <div className="left">
                    {/* <NavLink > */}
                      <label id="switch-organization">Switch Organization</label>
                    {/* </NavLink> */}
                  </div>
                </div>
              ) : null}
              {!getIdpTokenStatus && (
                <div className="item mb-sm">
                  <div className="left">
                    <a rel="noreferrer">
                      <label onClick={changePassword}>Change Password</label>
                    </a>
                  </div>
                </div>
              )}
              <div className="item mb-sm">
                <div className="left">
                  <NavLink to="/Get-Support">
                    <label>Get Support</label>
                  </NavLink>
                </div>
              </div>
            </div>
            {/* <div className="bottom">
              <NavLink
                to="/change-password"
                onClick={() => setPageTitle("Change Password")}
              >
                <label>Change Password</label>
              </NavLink>
            </div> */}
            <div className="bottom">
              <button type="button" className="btn big" onClick={callLogout}>
                <i className="icon icon-logout"></i> Sign out
              </button>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
