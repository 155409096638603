import { useEffect, useState } from "react";
import { HorizontalBarChart, ColorScale } from "@eds/vanilla";
import moment from "moment-timezone";
export default function RegionLeaderSummary({ regionLeadersData, getMonths }) {
  // console.log("RegionLeaderSumamry !!!!");
  const [selectedMonth, setSelectedMonth] = useState(getMonths?.[0]);

  const getChartData = () => {
    const databyMonth = regionLeadersData?.filter(
      (val) => val?.Date === selectedMonth
    );
    const data = {
      common: databyMonth?.map(
        (val) =>
          `${val?.Region} ${val?.["Total Walks Completed"]}/${val?.["Stretch Target"]}`
      ),
      series: [
        {
          name: "Region Leader Summary",
          values: databyMonth?.map((val) =>
            (
              (val?.["Total Walks Completed"] * 100) /
              val?.["Stretch Target"]
            ).toFixed(0)
          ),
        },
      ],
    };
    return data;
  };

  useEffect(() => {
    const data = getChartData();
    const mycolorScale = new ColorScale({
      colors: ["color-data-6"],
    });
    mycolorScale.init();
    const chartHeight = data?.common?.length * 40;
    const chart = new HorizontalBarChart({
      element: document.getElementById("regionLeaderSummary-graph"),
      data: data,
      legend: {
        isHidden: true,
      },
      thresholds: [
        {
          moreThan: 85,
          color: "green",
        },
        {
          lessThan: 50,
          color: "red",
        },
      ],
      x: {
        unit: "Completed %",
      },
      height: chartHeight,
      colorScale: mycolorScale,
    });
    chart.init();
    return () => {
      chart.destroy();
      let element = document.getElementById("regionLeaderSummary-graph");
      while (element?.firstChild) {
        element.removeChild(element.firstChild);
      }
    };
  }, [selectedMonth]);

  return (
    <>
      <div id="radio-pills">
        {getMonths?.data?.map((val, ind) => (
          <span
            key={ind}
            className={`pill toggle ${
              val !== selectedMonth ? "unselected" : ""
            }`}
            onClick={() => {
              setSelectedMonth(val);
            }}
          >
            {moment(val).format(getMonths?.type === "year" ? "YYYY" : "MMM YY")}
          </span>
        ))}
      </div>
      <div id="regionLeaderSummary-graph"></div>
    </>
  );
}
