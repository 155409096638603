import { URL } from "../utils/Constant";
export const getValidSupplier = async (
  setvaliddomainlist,
  param,
  setLoading,
  setErrMsg,
  headersList
) => {
  setLoading(true);
  const resp = await fetch(URL.ERICSION.GET_VALID_DOMAIN, {
    method: "GET",
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (data.status_code === 200) {
      setvaliddomainlist(data.data);
      setLoading(false);
    } else {
      setLoading(false);
      setErrMsg(data.message);
    }
  }
};

export const updateSupplier = async (
  setconfirmationMessage,
  param,
  setLoading,
  setErrMsg,
  headersList
) => {
  setLoading(true);
  let jsonData = JSON.stringify(param);
  try {
    const resp = await fetch(URL.ERICSION.GET_VALID_DOMAIN, {
      method: "PUT",
      body: jsonData,
      headers: headersList,
    });
    const data = await resp.json();
    // if (resp.ok) {
    //   if (data.status_code === 200) {
    setLoading(false);
    setconfirmationMessage({
      errorMsg: data.message,
      statusCode: data.status_code,
    });

    // }
    // } else {
    // setLoading(false);
    // setconfirmationMessage({
    //   errorMsg: data.message,
    //   statusCode: data.status_code,
    // });
    // }
  } catch {
    setLoading(false);
    setconfirmationMessage({
      errorMsg: "Internal Server Error",
      statusCode: 400,
    });
  }
};

export const AddSupplier = async (
  setconfirmationMessage,
  param,
  setLoading,
  setErr,
  headersList
) => {
  setLoading(true);
  let jsonData = JSON.stringify(param);
  try {
    const resp = await fetch(URL.ERICSION.GET_VALID_DOMAIN, {
      method: "POST",
      body: jsonData,
      headers: headersList,
    });
    const data = await resp.json();
    if (resp.ok) {
      if (data.status_code === 200) {
        setErr("");
        setconfirmationMessage({
          errorMsg: data.message,
          statusCode: data.status_code,
        });
        setLoading(false);
      } else {
        setLoading(false);
        setErr(data.error);
      }
    } else {
      setLoading(false);
      setconfirmationMessage({
        errorMsg: data.message,
        statusCode: data.status_code,
      });
    }
  } catch {
    setLoading(false);
    setconfirmationMessage({
      errorMsg: "Internal Server Error",
      statusCode: 400,
    });
  }
};

export const DeleteSupplierEntry = async (
  setconfirmationMessage,
  param,
  setLoading,
  setErrMsg,
  headersList
) => {
  setLoading(true);
  let jsonData = JSON.stringify(param);
  try {
    const resp = await fetch(URL.ERICSION.GET_VALID_DOMAIN, {
      method: "Delete",
      body: jsonData,
      headers: headersList,
    });
    const data = await resp.json();
    if (resp.ok) {
      if (data.status_code === 200) {
        setconfirmationMessage({
          errorMsg: data.message,
          statusCode: data.status_code,
        });
        setLoading(false);
      }
    } else {
      setLoading(false);
      setconfirmationMessage({
        errorMsg: data.message,
        statusCode: data.status_code,
      });
    }
  } catch {
    setLoading(false);
    setconfirmationMessage({
      errorMsg: "Internal Server Error",
      statusCode: 400,
    });
  }
};
